export const CONTACT_MESSAGE_ADD_REQUEST = 'CONTACT_MESSAGE_ADD_REQUEST'
export const CONTACT_MESSAGE_ADD_SUCCESS = 'CONTACT_MESSAGE_ADD_SUCCESS'
export const CONTACT_MESSAGE_ADD_FAIL = 'CONTACT_MESSAGE_ADD_FAIL'
export const CONTACT_MESSAGE_ADD_RESET = 'CONTACT_MESSAGE_ADD_RESET'

export const CONTACT_MESSAGE_DETAILS_REQUEST = 'CONTACT_MESSAGE_DETAILS_REQUEST'
export const CONTACT_MESSAGE_DETAILS_SUCCESS = 'CONTACT_MESSAGE_DETAILS_SUCCESS'
export const CONTACT_MESSAGE_DETAILS_FAIL = 'CONTACT_MESSAGE_DETAILS_FAIL'

export const CONTACT_MESSAGE_UPDATE_REQUEST = 'CONTACT_MESSAGE_UPDATE_REQUEST'
export const CONTACT_MESSAGE_UPDATE_SUCCESS = 'CONTACT_MESSAGE_UPDATE_SUCCESS'
export const CONTACT_MESSAGE_UPDATE_FAIL = 'CONTACT_MESSAGE_UPDATE_FAIL'
export const CONTACT_MESSAGE_UPDATE_RESET = 'CONTACT_MESSAGE_UPDATE_RESET'

export const CONTACT_MESSAGE_LIST_REQUEST = 'CONTACT_MESSAGE_LIST_REQUEST'
export const CONTACT_MESSAGE_LIST_SUCCESS = 'CONTACT_MESSAGE_LIST_SUCCESS'
export const CONTACT_MESSAGE_LIST_FAIL = 'CONTACT_MESSAGE_LIST_FAIL'