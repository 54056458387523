import React, { useState,  useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { AnimatePresence } from "framer-motion"
import { createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import ScrollToTop from './components/Layout/ScrollToTop'
import Test from './components/ui/Test'
import Layout from './layouts/Dashboard/Layout'

import './App.css'

import { RequireAuth, RequirePhoneVerification } from './features/authenication'
import Home from './pages/Home'
import SignUp from './pages/common/SignUp'
import Login from './pages/common/Login'
import ResetPassword from './pages/common/ResetPassword'
import OtpVerification from './pages/common/OtpVerification'
import NewPassword from './pages/common/NewPassword'
import PhoneVerification from './pages/common/PhoneVerification'
import Message from './pages/Message'
import Appointment from './pages/common/appointmentDetails/AppointmentDetails'
import  Support from './pages/common/support/Support'
import  Profile from './pages/common/profile/Profile'
import  Privacy from './pages/common/privacy/Privacy'
import  TermsAndConditions from './pages/common/TermsAndConditions'
import  Contact from './pages/common/contact/Contact'

import BarberSignUp from './pages/barber/BarberSignUp'
import BarberSignIn from './pages/barber/BarberSignIn'
import  BarberDashboard from './pages/barber/Dashboard'
import  BarberUpdateDetails from './pages/barber/BarberUpdateDetails'
import  BarberApointments from './pages/barber/appointments/Appointments'
import  BarberTransactions from './pages/barber/transactions/Transaction'
import  BarberMessages from './pages/barber/Messages'
// import BarberServices from './pages/barber/BarberServices'
import BarberServices from './pages/barber/services/Services'
import BarberDetails from './pages/barber/BarberDetails'


// import  CustomerDashboard from './pages/customer/Dashboard'
import  CustomerDashboard from './pages/customer/dashboard/Dashboard'
import  CustomerNewAppointment from './pages/customer/BookAppointment'
import  CustomerRescheduleAppointment from './pages/customer/rescheduleAppointment/RescheduleAppointment'
// import  CustomerNewBooking from './pages/customer/NewBooking'
import  CustomerApppointments from './pages/customer/appointments/Appointments'
// import  CustomerTransactions from './pages/customer/Transactions'
import  CustomerTransactions from './pages/customer/transactions/Transaction'
import  CustomerProfile from './pages/customer/Profile'
import  BarbersList from './pages/customer/BarbersList'


import  AdminSignIn from './pages/admin/AdminSignIn'
import  AdminDashboard from './pages/admin/dashboard/Dashboard'
import  AdminBarbers from './pages/admin/barbers/Barbers'
import  AdminBarberDetails from './pages/admin/BarberDetails/BarberDetails'
import  AdminUsers from './pages/admin/users/Users'
import  AdminAppointments from './pages/admin/appointments/Appointments'
import  AdminTransactions from './pages/admin/transactions/Transactions'
import  AdminAnalytics from './pages/admin/analytics/Analytics'
import  AdminSupport from './pages/admin/support/Support'
import  AdminContactMessages from './pages/admin/contacts/Contacts'

// import DashboardLayout from './components/Dashboard/DashboardLayout'


import NotFound from './pages/NotFound'
import UnAuthorized from './pages/UnAuthorized'

import ROLES from './constants/roleConstants'
import Notification from './components/ui/Notification';
import { NewTicketForm, TicketChat } from './features/support';
import Store from './pages/barber/store/Store';
import Register from './pages/barber/Register';
import Withdraw from './pages/barber/withdraw/Withdraw';

// const isPhoneVerified = (phone) => {
//   const isVerified = (phone?.phoneNumber !== "" && phone?.isVerified === true)
//   console.log({isVerified})
//   return isVerified 
//  }

function App() {
  const [mode, setMode] = useState("light")
  const location = useLocation();

  const theme = createTheme({
    palette: {
      mode: mode,
      primary: {
        main: '#009be5',
      },
      // primary: lightBlue,
      // secondary: {
      //   main: '#081627',
      // },
      custom: {
        black: '#081627',
        contrastText: mode === "light" ? '#081627': "#fff",
        white: mode === "light" ? '#fff' : '#121212',
        lightgray: '#d3d3d3',  // Light Gray
        backgroundColor: mode === "light" ? '#f9f8f8' : '#121212',  // Light Gray
        lightPurple: mode === "light" ? '#8884d8': '#8884dF',
        lightGreen: '#82ca9d'
      },
      overrides: {
        // MuiTypography: {
        //   h1: {
        //     fontSize: "5rem",
        //     [breakpoints.down("xs")]: {
        //       fontSize: "3rem"
        //     }
        //   }
        // }
        root: {
          "& .MuiDataGrid-renderingZone": {
            maxHeight: "none !important"
          },
          "& .MuiDataGrid-cell": {
            lineHeight: "unset !important",
            maxHeight: "none !important",
            whiteSpace: "normal"
          },
          "& .MuiDataGrid-row": {
            maxHeight: "none !important"
          }
        }
      }
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
        <AnimatePresence initial={false} exitBeforeEnter>
          <ScrollToTop>
          <Notification />
          <Routes location={location} key={location.pathname}>
            {/* <Layout /> */}
            
            {/* <Navbar /> */}
              {/* <Switch> */}
                <Route path="/" element={<Layout mode={mode} setMode={setMode} />}>
                  {/* public routes  */}
                  {/* <Route element={<Navbar />}> */}
                    <Route path="/" exact element={<Home setMode={setMode} />}/>
                    <Route path="sign-up" element={<SignUp />}/>
                    <Route path="sign-in"  element={<Login />}/>
                    <Route path="reset-password"  element={<ResetPassword />}/>
                    <Route path="otp-verification"  element={<OtpVerification />}/>
                    <Route path="new-password"  element={<NewPassword />}/>
                    <Route path="barber-sign-up"  element={<Register />}/>
                    <Route path="barber-sign-in"  element={<BarberSignIn />}/>
                    <Route path="admin/sign-in"  element={<AdminSignIn />}/>
                    {/* <Route path="barbers"  element={<BarbersList />}/> */}
                    <Route path="/privacy-policy"  element={<Privacy />}/>
                    <Route path="/terms-and-conditions"  element={<TermsAndConditions />}/>
                    <Route path="/contact-us"  element={<Contact />}/>
                    <Route path="/unauthorized" element={<UnAuthorized />} />
                    
                  {/* </Route> */}

                {/* Private Routes  */}
                <Route element={<RequireAuth allowedRoles={[ROLES.CLIENT, ROLES.BARBER]}/>}>
                  <Route path="phone-verification"  element={<PhoneVerification />}/>
                </Route>

                {/* Requrie Phone Verification */}
                <Route element={<RequirePhoneVerification/> }>

                  {/* clients private routes */}
                  <Route element={<RequireAuth allowedRoles={[ROLES.CLIENT]}/>}>
                    <Route path="barber/:barberId"  element={<BarberDetails />}/>
                    <Route path="/dashboard" element={<CustomerDashboard />}/>
                    <Route path="/bookings" element={<CustomerApppointments />}/>
                    <Route path="/book-appointment" element={<CustomerNewAppointment/>}/>
                    <Route path="/appointment/:id/reschedule" element={<CustomerRescheduleAppointment/>}/>
                    <Route path="/transactions" element={<CustomerTransactions />}/>
                    {/* <Route path="/profile" element={<CustomerProfile />}/> */}
                  </Route>

                  {/*barber private routes */}
                  <Route element={<RequireAuth allowedRoles={[ROLES.BARBER]}/>}>
                    {/* <Route element={<DashboardLayout />} > */}
                      <Route path="barber-dashboard"  element={<BarberDashboard/>}/>
                      <Route path="store-management"  element={<Store />}/>
                      <Route path="barber-services"  element={<BarberServices/>}/>
                      <Route path="barber-transactions"  element={<BarberTransactions/>}/>
                      <Route path="withdraw"  element={<Withdraw />}/>
                      <Route path="barber-appointments"  element={<BarberApointments/>}/>
                      <Route path="barber-messages"  element={<BarberMessages/>}/>
                    
                    {/* </Route> */}
                  </Route>

                   {/* client and barber routes */}
                   {/* <Route element={<RequireAuth allowedRoles={[ROLES.BARBER, ROLES.CLIENT]}/>}>
                    <Route path="otp-verification"  element={<OtpVerification />}/>
                    <Route path="phone-verification"  element={<PhoneVerification />}/>
                  </Route> */}
                
                  {/* client, barber, and admin private routes */}
                  <Route element={<RequireAuth allowedRoles={[ROLES.BARBER, ROLES.CLIENT, ROLES.ADMIN]}/>}>
                    <Route path="/appointment/:id" element={<Appointment />}/> 
                    <Route path="/profile" element={<Profile />}/> 
                    <Route path="/messages" element={<Message />}/>  
                    <Route path="/support" element={<Support />}/>
                    <Route path="/support/new-ticket" element={<NewTicketForm />}/>                 
                    <Route path="/support/chat/:ticketId" element={<TicketChat />}/>                 
                  </Route>

                  {/* private routes */}
                  <Route element={<RequireAuth allowedRoles={[ROLES.ADMIN]} />}>
                    <Route path="admin-dashboard"  element={<AdminDashboard />}/>
                    <Route path="admin-appointments"  element={<AdminAppointments/>}/>
                    <Route path="admin-transactions"  element={<AdminTransactions/>}/>
                    <Route path="admin-analytics"  element={<AdminAnalytics />}/>
                    <Route path="admin-barbers-list"  element={<AdminBarbers />}/>
                    <Route path="admin/barber-details/:id"  element={<AdminBarberDetails />}/>
                    <Route path="admin-users-list"  element={<AdminUsers />}/>
                    <Route path="admin-support"  element={<AdminSupport />}/>
                    <Route path="admin-contact-messages"  element={<AdminContactMessages />}/>
                  </Route>
                </Route>

                  {/* <Route path="/test" element={<Test />}/> */}
                    {/* catch all */}
                      <Route path="*" element={<NotFound />} />

                </Route>
              {/* </Switch> */}

              {/* <Footer /> */}
          </Routes>
      </ScrollToTop>
    </AnimatePresence>
   </ThemeProvider>
  );
}

export default App;
