import {
    CONVERSATION_ADD_REQUEST,
    CONVERSATION_ADD_SUCCESS,
    CONVERSATION_ADD_FAIL,
    CONVERSATION_ADD_RESET,
    CONVERSATION_DETAILS_REQUEST,
    CONVERSATION_DETAILS_SUCCESS,
    CONVERSATION_DETAILS_FAIL,
} from '../constants/chatConstants'

//ADD CONVERSATION
export const conversationAddReducer = (state = { }, action) => {
    switch (action.type) {
        case CONVERSATION_ADD_REQUEST:
            return { loading: true}
        case CONVERSATION_ADD_SUCCESS:
            return { loading: false, success: true  }
        case CONVERSATION_ADD_FAIL:
            return { loading: false, error: action.payload }
        case CONVERSATION_ADD_RESET:
            return { success: false, error: null }
        default:
            return state
    }
}

//GET CON
export const conversationDetailsReducer = (state = { conversation: {} }, action) => {
    switch (action.type) {
        case CONVERSATION_DETAILS_REQUEST:
            return { loading: true}
        case CONVERSATION_DETAILS_SUCCESS:
            return { loading: false, conversation: action.payload }
        case CONVERSATION_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}
