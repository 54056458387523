import React, { useState, useRef, useEffect } from 'react'
import { Container, Card, Box, Typography, Grid, Button, TextField, Stack } from '@mui/material'
import { UPDATE_ALERT } from "../../../redux/constants/alertConstants"
import { useDispatch, useSelector } from 'react-redux'
import BackDropLoader from '../../../components/ui/BackDropLoader'
import { Link, useNavigate, useParams } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Message from './Message'
import { AddTicketMessage, getTicketDetails, listTicketMessages, markTicketAsOpened, markTicketAsResolved } from "../../../redux/actions/ticketActions"
import { TICKET_ADD_MESSAGE_RESET } from '../../../redux/constants/ticketConstants';
import DoneIcon from '@mui/icons-material/Done';
import ROLES from '../../../constants/roleConstants';
import { useTheme } from '@mui/material/styles'

const TicketChat = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { ticketId } = useParams()
    const [text, setText] = useState('')
    const [messages, setMessages] = useState([])
    const [loading, setLoading] = useState(false)
    const theme = useTheme()

    const scrollRef = useRef()

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const ticketMessageAdd = useSelector(state => state.ticketMessageAdd)
    const { loading: loadingAddMessage, error: errorAddMessage, message } = ticketMessageAdd

    const ticketMessageList = useSelector(state => state.ticketMessageList)
    const { loading: loadingMessages, error: errorMessages, messages: ticketMessages } = ticketMessageList

    const ticketDetails = useSelector(state => state.ticketDetails)
    const { loading: loadingDetails, error: errorDetails, ticket } = ticketDetails

    const ticketMarkResolved = useSelector(state => state.ticketMarkResolved)
    const { loading: loadingResolved, error: errorResolved, success: successResolved } = ticketMarkResolved

    const ticketMarkOpened = useSelector(state => state.ticketMarkOpened)
    const { loading: loadingOpened, error: errorOpened, success: successOpened } = ticketMarkOpened

    useEffect(() => {
        setLoading(true)
        // if (messages.length === 0) dispatch(listTicketMessages(ticketId))
        dispatch(getTicketDetails(ticketId))
         // if(userInfo) {
        //     setName(userInfo.firstName + " " + userInfo.lastName)
        //     setEmail(userInfo.email.emailAddress)
        // }
        setLoading(false)
    }, [])

    useEffect(() => {
        setLoading(true)
        if (ticket?.messages) {
            setMessages(ticket.messages)
        }
         // if(userInfo) {
        //     setName(userInfo.firstName + " " + userInfo.lastName)
        //     setEmail(userInfo.email.emailAddress)
        // }
        setLoading(false)
    }, [ticket?.messages])

    useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: "smooth" })
    }, [messages])

    useEffect(() => {
        if(text && message) {
            console.log({message})
            setMessages([...messages, message]) 
            setText("")
            // dispatch({ type: TICKET_ADD_MESSAGE_RESET })
        }
        
    }, [message])

    useEffect(() => {
        // if(successResolved) {
        //     if (userInfo.role === ROLES.ADMIN) navigate('/admin-support')
        //     else navigate('/support')
        //     // dispatch({ type: TICKET_ADD_MESSAGE_RESET })
        // }
        dispatch(getTicketDetails(ticketId))
    }, [successResolved, successOpened])

    const submitHandler = (e) => {
        e.preventDefault();
        
        if (!text) {
            dispatch({ 
                type: UPDATE_ALERT, 
                payload: {
                    open: true, 
                    severity: 'error', 
                    message: 'Please enter message' 
                } 
            })
        } else {
            dispatch(AddTicketMessage({ticketId, text}))
        }      
    }

    const markResolvedHandler = (e) => {
        console.log("Called")
        // e.preventDefault();
        dispatch(markTicketAsResolved(ticketId))    
    }

    const markOpenedHandler = (e) => {
        console.log("Called")
        // e.preventDefault();
        dispatch(markTicketAsOpened(ticketId))    
    }

  return (
    <>
    {
        loading || loadingDetails || loadingResolved || loadingOpened
        // || loadingMessages
        ? <BackDropLoader />
        :        
        <>
        <Box sx={{ display: "flex", justifyContent: 'space-between' }}>
            {/* <Box sx={{display: 'flex'}}> */}
                <Link to='/support' style={{ textDecoration: 'none' }}>
                    <Button 
                        // style={{margin: "0 10px"}}
                        startIcon={<ArrowBackIcon />}
                        >
                        Go to Tickets
                    </Button>
                </Link>
            {/* </Box> */}
            { ticket?.ticketDetails?.status === "resolved" 
                ? (
                    <Button 
                    // style={{margin: "0 10px"}}
                    startIcon={<DoneIcon />}
                    color="primary"
                    variant="outlined"
                    onClick={(e) => markOpenedHandler()}
                    >
                        Mark as Unresolved
                </Button>
                ) : (
                    <Button 
                    // style={{margin: "0 10px"}}
                    startIcon={<DoneIcon />}
                    color="success"
                    variant="outlined"
                    onClick={(e) => markResolvedHandler()}
                    >
                        Mark as resolved
                    </Button>
                )
            }
            
         </Box>
         <Container component="main" maxWidth="lg" sx={{ marginTop: 3, overflowY: "scroll", height: "50vh"}} >
             {/* <Card
             sx={{
                 marginTop: 3,
                 marginBottom: 3,
                 padding: "24px 18px",
                 display: 'flex',
                 flexDirection: 'column',
             }}
             elevation={2}
             >             */}
             {/* <Box sx={{ height: '50vh', overflow: 'scroll' }} > */}
             <Box >
                 {messages?.map((m, index) => (
                    // <div key={index} ref={scrollRef}>
                    //     <Message  
                    //     message={m} 
                    //     own={m.senderId === user._id} 
                    //     profilePicture={
                    //         m.senderId === user._id 
                    //             ? user.avatar 
                    //             : friendPicture
                    //     }
                    //     />
                    // </div>
                    <Box 
                        key={m?._id}
                        ref={scrollRef}
                        sx={{ 
                            background: theme.palette.custom.white,
                            padding: "18px 12px",                            
                            }}>
                        <Message message={m} />
                    </Box>
                ))}
             </Box> 
             {/* </Card> */}
         </Container> 
         <Container component="main" maxWidth="lg" >
             <Card
             sx={{
                 marginTop: 2,
                 marginBottom: 2,
                 padding: "18px 32px",
                 display: 'flex',
                 flexDirection: 'column',
             }}
             elevation={2}
             >
             {/* <Typography variant="h6">
                 Add New Message 
             </Typography> */}
            
             <Box component="form" onSubmit={submitHandler} sx={{ mt: 2 }}>
                 <Grid item xs={12} mb={1}>    
                     {/* <TextField
                         name="message"
                         label="Message"
                         variant="outlined"
                         size="small"
                         type="text"
                         value={text}
                         onChange={(e) => setText(e.target.value)}
                         required
                         fullWidth
                         multiline
                         rows={3}
                         maxRows={20}
                     /> */}
                     <TextField
                         name="message"
                         label="Message"
                         variant="outlined"
                         size="small"
                         type="text"
                         value={text}
                         onChange={(e) => setText(e.target.value)}
                         required
                         fullWidth
                         multiline
                         rows={2}
                         maxRows={20}
                         inputProps={{ maxLength: 200 }}
                     />
                     <Typography variant="caption" color="text.disabled" align="right" gutterBottom>
                            Max length 200 characters
                        </Typography>
                 </Grid>
                 {/* </Grid> */}
                 <Grid item xs={12} sx={{ mt: 3 }}>
                     {/* <Stack 
                         direction="row" 
                         justifyContent="end"
                         spacing={2}
                         >
                             <Button 
                                 variant="outlined"
                                 fullWidth 
                                 onClick={resetHandler} 
                             >
                                 Reset
                             </Button> */}
                             <Button 
                                 variant="contained"
                                 fullWidth
                                 type="submit"

                             >
                                 Submit
                             </Button>
                     {/* </Stack>   */}
                 </Grid >
                
             </Box> 
             </Card>
         </Container> 
     </>
    }
    </>
  )
}

export default TicketChat