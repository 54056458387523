// import { useState } from 'react'
// import 'react-phone-number-input/style.css'
// // import './intlPhoneInput.css'
// import PhoneInput from 'react-phone-number-input'
// import flags from 'react-phone-number-input/flags'

// // import { PhoneInput } from "react-contact-number-input";


// import PhoneInput2 from 'react-phone-input-2'
// // import 'react-phone-input-2/lib/style.css'

// const IntlPhoneInput = () => {
//     const [value, setValue] = useState()
//     return (
//         <PhoneInput2
//         country={'aus'}
//         value={value}
//         onChange={setValue}
//       />
      
//     )
// {/* <PhoneInput
//   disabled={disabled}
//   containerClass={containerClass}
//   countryCode={currentCountryCode}
//   onChange={handleOnChange}
//   placeholder={placeholder}
// />; */}

// }

// export default IntlPhoneInput


import React, { useState } from "react";
import "./intlPhoneInput.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import startsWith from 'lodash.startswith';

const IntlPhoneInput = ({ phoneNumber, setPhoneNumber, disabled=false }) => {
  // const [phone, setPhone] = useState("");
  let handleOnChange = value => {
    setPhoneNumber(value);
    // setPhoneNumber(value)
    // console.log({value})
  };
  // const submitHandler = (phone) => {
  //   console.log({phone})
  //   setPhoneNumber(phone)
  // }
  return (
    <div className={disabled ? "inactive" : ""}>
        <PhoneInput
        inputProps={{
          name: 'phone',
          required: true,
          autoFocus: true,
          // disabled: {disabled}
        }}
        isValid={(value, country) => {
          if (value.match(/12345/)) {
            return 'Invalid value: '+value+', '+country.name;
          } else if (value.match(/1234/)) {
            return false;
          } else {
            return true;
          }
        }}
        // enableAreaCodeStretch
        autoFormat
        country="au"
        // preferredCountries={'au'}
        // value=""
        // onChange={phone => setPhoneNumber(phone)}
        // onChange={(e) => submitHandler(e)}
        value={phoneNumber}
        onChange={handleOnChange}
      />
  </div>
  );
};
export default IntlPhoneInput;