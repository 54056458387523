import axios from 'axios'
import {
    CART_ADD_ITEM,
    CART_REMOVE_ITEM,
    CART_EMPTY,
    CART_SAVE_DATE,
    CART_SAVE_TIME,
    CART_SAVE_BARBER,
    CART_SAVE_PAYMENT_METHOD
} from '../constants/cartConstants'

export const addToCart = (serviceId) => async (dispatch, getState) => {

    // const { userLogin: { userInfo }} = getState()

    // const config = {
    //     headers: {
    //         'Content-type': 'application/json',
    //         Authorization: `Bearer ${userInfo.accessToken}`
    //     },
    // }

    // Fetch service details
    const { data: serviceData } = await axios.get(`/api/stores/services/${serviceId}`)
    dispatch({
        type: CART_ADD_ITEM,
        payload: { 
            serviceId: serviceData._id,  
            name: serviceData.name,
            amount: serviceData.amount
        }
    })

    // localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems)) 
}

export const removeFromCart = (id) => (dispatch, getState) => {
    console.log({id})
    dispatch({
        type: CART_REMOVE_ITEM,
        payload: id
    })

    // localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const emptyCart = () => (dispatch, getState) => {
    dispatch({
        type: CART_EMPTY
    })

    // localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const saveBarberDetails = (storeId, storeName) => (dispatch) => {
    dispatch({
        type: CART_SAVE_BARBER,
        payload: {
            storeId, 
            storeName
        }
    })

    // localStorage.setItem('storeDetails', JSON.stringify({storeId, storeName}))
}

export const saveAppointmentDate = (data) => (dispatch) => {
    dispatch({
        type: CART_SAVE_DATE,
        payload: data
    })
    console.log("Date saved in cart")
    console.log(data)

    dispatch({
        type: CART_SAVE_TIME,
        payload: ''
    })
    // localStorage.setItem('appointmentDate', JSON.stringify(data))
}

export const saveAppointmentTime = (data) => (dispatch) => {
    dispatch({
        type: CART_SAVE_TIME,
        payload: data
    })

    // localStorage.setItem('appointmentTime', JSON.stringify(data))
}

export const savePaymentMethod = (data) => (dispatch) => {
    dispatch({
        type: CART_SAVE_PAYMENT_METHOD,
        payload: data
    })

    // localStorage.setItem('paymentMethod', JSON.stringify(data))
}


