import { Box, CircularProgress, Fab } from '@mui/material';
import { useEffect, useState } from 'react';
import { Check, Save, Visibility } from '@mui/icons-material';
import { green } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_ALERT } from '../../../redux/constants/alertConstants';
import { getContactMessageDetails, updateContactMessage } from '../../../redux/actions/contactActions';
import { CONTACT_MESSAGE_UPDATE_RESET } from '../../../redux/constants/contactConstants';

const ContactActions = ({ params, rowId, setRowId, setOpenModal }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);

  const contactMessageUpdate = useSelector(state => state.contactMessageUpdate)
  const {  error, success: updateStatus } = contactMessageUpdate

  const viewMessageHandler = (e) => {
    e.preventDefault()
    setOpenModal(true)
    const { _id } = params.row;
    dispatch(getContactMessageDetails(_id))
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true);
    const { _id } = params.row;
    dispatch(updateContactMessage(_id))
  };

  useEffect(() => {
    if (rowId === params.id && updateStatus) {
        setLoading(false)
        dispatch({ type: UPDATE_ALERT, 
            payload: 
            {open: true, severity: 'success', message: 'Message updated successfully.' 
          } 
        })
        dispatch({ type: CONTACT_MESSAGE_UPDATE_RESET })
    }
    if(error) {
      setLoading(false)
      dispatch({ type: UPDATE_ALERT, 
        payload: 
        {open: true, severity: 'error', message: error 
      } 
    })
    }
  }, [rowId, updateStatus, error]);

  return (
    <Box display="flex">
      <Box
        sx={{
          m: 1,
          position: 'relative',
        }}
      >
          <Fab
            color="primary"
            sx={{
              width: 40,
              height: 40,
            }}
            onClick={viewMessageHandler}
          >
            <Visibility />
          </Fab>
      </Box>
      <Box
        sx={{
          m: 1,
          position: 'relative',
        }}
      >
          <Fab
            color="success"
            sx={{
              width: 40,
              height: 40,
            }}
            disabled={params.id !== rowId || loading}
            onClick={handleSubmit}
          >
            <Save />
          </Fab>
        {loading && (
          <CircularProgress
            size={52}
            sx={{
              color: green[500],
              position: 'absolute',
              top: -6,
              left: -6,
              zIndex: 1,
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default ContactActions;