import axios from 'axios'
import {
    STORE_SERVICE_CATEGORY_ADD_FAIL,
    STORE_SERVICE_CATEGORY_ADD_REQUEST,
    STORE_SERVICE_CATEGORY_ADD_SUCCESS,
    STORE_SERVICE_ADD_FAIL,
    STORE_SERVICE_ADD_REQUEST,
    STORE_SERVICE_ADD_SUCCESS,
    STORE_SERVICE_LIST_FAIL,
    STORE_SERVICE_LIST_REQUEST,
    STORE_SERVICE_LIST_SUCCESS,
    STORE_DETAILS_REQUEST,
    STORE_DETAILS_SUCCESS,
    STORE_DETAILS_FAIL,
    STORE_SERVICE_DELETE_REQUEST,
    STORE_SERVICE_DELETE_SUCCESS,
    STORE_SERVICE_DELETE_FAIL,
    STORE_SERVICE_EDIT_REQUEST,
    STORE_SERVICE_EDIT_SUCCESS,
    STORE_SERVICE_EDIT_FAIL,
    STORE_SERVICE_DETAILS_REQUEST,
    STORE_SERVICE_DETAILS_SUCCESS,
    STORE_SERVICE_DETAILS_FAIL,
    STORE_SCHEDULE_ADD_REQUEST,
    STORE_SCHEDULE_ADD_SUCCESS,
    STORE_SCHEDULE_ADD_FAIL,
    STORE_SERVICE_CATEGORY_LIST_REQUEST,
    STORE_SERVICE_CATEGORY_LIST_SUCCESS,
    STORE_SERVICE_CATEGORY_LIST_FAIL,
    STORE_ADD_REQUEST,
    STORE_ADD_SUCCESS,
    STORE_ADD_FAIL,
    STORE_SCHEDULE_DETAILS_REQUEST,
    STORE_SCHEDULE_DETAILS_SUCCESS,
    STORE_SCHEDULE_DETAILS_FAIL,
    STORE_DETAILS_UPDATE_REQUEST,
    STORE_DETAILS_UPDATE_SUCCESS,
    STORE_DETAILS_UPDATE_FAIL,
    STORE_DETAILS_VIEW_REQUEST,
    STORE_DETAILS_VIEW_SUCCESS,
    STORE_DETAILS_VIEW_FAIL,
    
} from '../constants/storeConstants'

// STORE
export const addStore = ({ name, workAreaAddress, coordinates, forMen, forWomen, experience, about }) => async (dispatch, getState) => {
    try {
        dispatch({
            type: STORE_ADD_REQUEST
        })

        // const { userLogin: { userInfo }} = getState()

        // const config = {
        //     headers: {
        //         'Content-type': 'application/json',
        //         Authorization: `Bearer ${userInfo.accessToken}`
        //     },
        // }
        const { barberRegister: { accessToken }} = getState()
        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            },
        }

        console.log("start register")
        const { data } = await axios.post(
                                    '/api/stores/register',
                                    { name, workAreaAddress, coordinates, forMen, forWomen, experience, about },
                                    config
                                )
        console.log("registered")
        dispatch({
            type: STORE_ADD_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: STORE_ADD_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}


export const getStoreDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: STORE_DETAILS_REQUEST
        })

        const { data } = await axios.get(`/api/stores/${id}`)

        dispatch({
            type: STORE_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: STORE_DETAILS_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

//SERVICES

export const getServiceDetails = (serviceId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: STORE_SERVICE_DETAILS_REQUEST
        })

        const { data } = await axios.get(`/api/stores/services/${serviceId}`)

        dispatch({
            type: STORE_SERVICE_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: STORE_SERVICE_DETAILS_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const listCategories = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: STORE_SERVICE_CATEGORY_LIST_REQUEST,
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.get('/api/stores/categories-list', config)

        dispatch({
            type: STORE_SERVICE_CATEGORY_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: STORE_SERVICE_CATEGORY_LIST_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}


export const addNewServiceCategory = ({name}) => async (dispatch, getState) => {
    try {
        dispatch({
            type: STORE_SERVICE_CATEGORY_ADD_REQUEST
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.post(
            '/api/stores/category',
            { name },
            config
        )

        dispatch({
            type: STORE_SERVICE_CATEGORY_ADD_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: STORE_SERVICE_CATEGORY_ADD_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const addNewService = ({ name, selectedCategoryId, amount, duration, description }) => async (dispatch, getState) => {
    try {
        dispatch({
            type: STORE_SERVICE_ADD_REQUEST
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.post(
            '/api/stores/services',
            { name, selectedCategoryId, amount, duration, description },
            config
        )

        dispatch({
            type: STORE_SERVICE_ADD_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: STORE_SERVICE_ADD_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const editService = ({ serviceId, name, amount, duration, description, status }) => async (dispatch, getState) => {
    try {
        dispatch({
            type: STORE_SERVICE_EDIT_REQUEST
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.put(
            `/api/stores/services/${serviceId}`,
            { name, amount, duration, description, status },
            config
        )

        dispatch({
            type: STORE_SERVICE_EDIT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: STORE_SERVICE_EDIT_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const listServices = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: STORE_SERVICE_LIST_REQUEST,
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.get('/api/stores/services', config)

        dispatch({
            type: STORE_SERVICE_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: STORE_SERVICE_LIST_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const deleteServices = (serviceId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: STORE_SERVICE_DELETE_REQUEST,
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.delete(`/api/stores/services/${serviceId}`, config)

        dispatch({
            type: STORE_SERVICE_DELETE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: STORE_SERVICE_DELETE_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const getScheduleDetails = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: STORE_SCHEDULE_DETAILS_REQUEST
        })

        const { userLogin: { userInfo }} = getState()
 
        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.get('/api/stores/schedule', config)


        dispatch({
            type: STORE_SCHEDULE_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: STORE_SCHEDULE_DETAILS_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}



export const addStoreSchedule = ({ schedule }) => async (dispatch, getState) => {
    try {
        dispatch({
            type: STORE_SCHEDULE_ADD_REQUEST
        })

        const { barberRegister: { accessToken }} = getState()
        
        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            },
        }
        
        const { data } = await axios.post(
            '/api/stores/schedule',
            { workingSchedule: schedule },
            config
        )

        dispatch({
            type: STORE_SCHEDULE_ADD_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: STORE_SCHEDULE_ADD_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const updateStoreSchedule = ({ schedule }) => async (dispatch, getState) => {
    try {
        dispatch({
            type: STORE_SCHEDULE_ADD_REQUEST
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.post(
            '/api/stores/schedule',
            { workingSchedule: schedule },
            config
        )

        dispatch({
            type: STORE_SCHEDULE_ADD_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: STORE_SCHEDULE_ADD_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

// ADMIN
export const updateStoreDetails = ({  _id, status, isVerified, experience, forMen, forWomen }) => async (dispatch, getState) => {
    try {
        dispatch({
            type: STORE_DETAILS_UPDATE_REQUEST,
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.patch(
                `/api/stores/update-details/${ _id }`, 
                {  _id, status, isVerified, experience, forMen, forWomen }, 
                config
            )

        dispatch({ type: STORE_DETAILS_UPDATE_SUCCESS, payload: data })

    } catch (error) {
        dispatch({
                    type: STORE_DETAILS_UPDATE_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const getStoreAndBarberDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: STORE_DETAILS_VIEW_REQUEST,
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.get(`/api/stores/admin/get-store-details/${id}`, config)
        console.log({data})
        dispatch({ type: STORE_DETAILS_VIEW_SUCCESS, payload: data })

    } catch (error) {
        dispatch({
                    type: STORE_DETAILS_VIEW_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const editServiceAdmin = ({ storeId, serviceId, name, amount, duration, description, status }) => async (dispatch, getState) => {
    try {
        dispatch({
            type: STORE_SERVICE_EDIT_REQUEST
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.put(
            `/api/stores/admin/${storeId}/services/${serviceId}`,
            { name, amount, duration, description, status },
            config
        )

        dispatch({
            type: STORE_SERVICE_EDIT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: STORE_SERVICE_EDIT_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const deleteServiceAdmin = ({storeId, serviceId}) => async (dispatch, getState) => {
    try {
        dispatch({
            type: STORE_SERVICE_DELETE_REQUEST,
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.delete(`/api/stores/admin/${storeId}/services/${serviceId}`, config)

        dispatch({
            type: STORE_SERVICE_DELETE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: STORE_SERVICE_DELETE_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

