import { Box, Button, CardMedia, Stack, SvgIcon, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import AnimatedPage from '../../components/Layout/AnimatedPage';
import verificationIcon from '../../images/verification.svg';
import { verifyBarber } from '../../redux/actions/barberActions';
import { UPDATE_ALERT } from '../../redux/constants/alertConstants';
import { BARBER_REGISTER_RESET } from '../../redux/constants/barberConstants';
import BackDropLoader from '../../components/ui/BackDropLoader';

const Verification = ({ handleBack, handleNext }) => {
  const dispatch = useDispatch()

  const barberVerify = useSelector(state => state.barberVerify)
  const { loading: verifyLoading, error: verifyError, success: verifySuccess } = barberVerify

  // useEffect(() => {
  //  dispatch({ type: BARBER_REGISTER_RESET })
  // }, [])
  

  useEffect(() => {
    if (verifySuccess) {
      dispatch({ 
        type: UPDATE_ALERT, 
        payload: {
          open: true, 
          severity: 'success', 
          message: "Identity verified successfully."
        }
      })
      handleNext()
      // dispatch({ type: BARBER_REGISTER_RESET })
    } 

    if (verifyError) {
      dispatch({ 
        type: UPDATE_ALERT, 
        payload: {
          open: true, 
          severity: 'error', 
          message: verifyError
        }
      })
    }

  }, [verifySuccess, verifyError])

  const onSubmitHandler = (e) => {
    e.preventDefault()
    dispatch(verifyBarber())
        // setInfoAdded(true)
        
    // handleNext()
  }
  return(
  //   <>
  // {
  //   verifyLoading 
  //   ? <BackDropLoader />
  //   :
    <AnimatedPage>
      <Box   
        component="form" 
        onSubmit={onSubmitHandler} 
        // sx={{ background: '#000' }}
      >
        <Box 
          display="flex"
          justifyContent="center"
        >
          <img src={verificationIcon} height="240"></img>
        </Box>
        <Stack
        direction="row"
        sx={{ mt: 8, pb: 7, justifyContent: 'space-evenly' }}
        >
          <Button
          //   disabled={!activeStep}
            variant="outlined"
            color="primary"
            // onClick={handleBack}
            disabled
            >
            Back
          </Button>
          <Button 
          //   disabled={activeStep === steps.length - 1} 
            // onClick={handleNext}
            type="submit"
            variant="contained"
          >
            Verify
          </Button>
        </Stack>
      </Box>
    </AnimatedPage>
  // }
  // </>
  )

}
export default Verification