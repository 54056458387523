import {
    APPOINTMENT_ADD_FAIL,
    APPOINTMENT_ADD_REQUEST,
    APPOINTMENT_ADD_RESET,
    APPOINTMENT_ADD_SUCCESS,
    APPOINTMENT_ADD_WALLET_FAIL,
    APPOINTMENT_ADD_WALLET_REQUEST,
    APPOINTMENT_ADD_WALLET_RESET,
    APPOINTMENT_ADD_WALLET_SUCCESS,
    APPOINTMENT_BARBER_LIST_FAIL,
    APPOINTMENT_BARBER_LIST_REQUEST,
    APPOINTMENT_BARBER_LIST_SUCCESS,
    APPOINTMENT_BARBER_UPCOMING_LIST_FAIL,
    APPOINTMENT_BARBER_UPCOMING_LIST_REQUEST,
    APPOINTMENT_BARBER_UPCOMING_LIST_SUCCESS,
    APPOINTMENT_CANCEL_ADMIN_FAIL,
    APPOINTMENT_CANCEL_ADMIN_REQUEST,
    APPOINTMENT_CANCEL_ADMIN_RESET,
    APPOINTMENT_CANCEL_ADMIN_SUCCESS,
    APPOINTMENT_CANCEL_FAIL,
    APPOINTMENT_CANCEL_REQUEST,
    APPOINTMENT_CANCEL_RESET,
    APPOINTMENT_CANCEL_SUCCESS,
    APPOINTMENT_CLIENT_UPCOMING_LIST_FAIL,
    APPOINTMENT_CLIENT_UPCOMING_LIST_REQUEST,
    APPOINTMENT_CLIENT_UPCOMING_LIST_SUCCESS,
    APPOINTMENT_COMPLETED_FAIL,
    APPOINTMENT_COMPLETED_REQUEST,
    APPOINTMENT_COMPLETED_RESET,
    APPOINTMENT_COMPLETED_SUCCESS,
    APPOINTMENT_DETAILS_FAIL,
    APPOINTMENT_DETAILS_REQUEST,
    APPOINTMENT_DETAILS_SUCCESS,
    APPOINTMENT_LIST_FAIL,
    APPOINTMENT_LIST_REQUEST,
    APPOINTMENT_LIST_SUCCESS,
    APPOINTMENT_MARK_COMPLETED_BARBER_FAIL,
    APPOINTMENT_MARK_COMPLETED_BARBER_REQUEST,
    APPOINTMENT_MARK_COMPLETED_BARBER_RESET,
    APPOINTMENT_MARK_COMPLETED_BARBER_SUCCESS,
    APPOINTMENT_MARK_COMPLETED_CLIENT_FAIL,
    APPOINTMENT_MARK_COMPLETED_CLIENT_REQUEST,
    APPOINTMENT_MARK_COMPLETED_CLIENT_RESET,
    APPOINTMENT_MARK_COMPLETED_CLIENT_SUCCESS,
    APPOINTMENT_RESCHEDULE_FAIL,
    APPOINTMENT_RESCHEDULE_FEE_FAIL,
    APPOINTMENT_RESCHEDULE_FEE_REQUEST,
    APPOINTMENT_RESCHEDULE_FEE_SUCCESS,
    APPOINTMENT_RESCHEDULE_REQUEST,
    APPOINTMENT_RESCHEDULE_RESET,
    APPOINTMENT_RESCHEDULE_SUCCESS,
    APPOINTMENT_RESCHEDULE_WALLET_FAIL,
    APPOINTMENT_RESCHEDULE_WALLET_REQUEST,
    APPOINTMENT_RESCHEDULE_WALLET_RESET,
    APPOINTMENT_RESCHEDULE_WALLET_SUCCESS,
    APPOINTMENT_USER_LIST_FAIL,
    APPOINTMENT_USER_LIST_REQUEST,
    APPOINTMENT_USER_LIST_SUCCESS,
    
} from '../constants/appointmentConstants'

export const appointmentAddReducer = (state = {}, action) => {
    switch (action.type) {
        case APPOINTMENT_ADD_REQUEST:
            return { loading: true}
        case APPOINTMENT_ADD_SUCCESS:
            return { loading: false, success: true  }
        case APPOINTMENT_ADD_FAIL:
            return { loading: false, error: action.payload }
        case APPOINTMENT_ADD_RESET:
            return { success: false }
        default:
            return state
    }
}

export const appointmentAddWalletReducer = (state = {}, action) => {
    switch (action.type) {
        case APPOINTMENT_ADD_WALLET_REQUEST:
            return { loading: true}
        case APPOINTMENT_ADD_WALLET_SUCCESS:
            return { loading: false, success: true  }
        case APPOINTMENT_ADD_WALLET_FAIL:
            return { loading: false, error: action.payload }
        case APPOINTMENT_ADD_WALLET_RESET:
            return { success: false }
        default:
            return state
    }
}

export const appointmentUserListReducer = (state = { appointments: [] }, action) => {
    switch (action.type) {
        case APPOINTMENT_USER_LIST_REQUEST:
            return { loading: true}
        case APPOINTMENT_USER_LIST_SUCCESS:
            return { loading: false, appointments: action.payload }
        case APPOINTMENT_USER_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const appointmentClientUpcomingListReducer = (state = { appointments: [] }, action) => {
    switch (action.type) {
        case APPOINTMENT_CLIENT_UPCOMING_LIST_REQUEST:
            return { loading: true}
        case APPOINTMENT_CLIENT_UPCOMING_LIST_SUCCESS:
            return { loading: false, appointments: action.payload }
        case APPOINTMENT_CLIENT_UPCOMING_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const appointmentBarberListReducer = (state = { appointments: [] }, action) => {
    switch (action.type) {
        case APPOINTMENT_BARBER_LIST_REQUEST:
            return { loading: true}
        case APPOINTMENT_BARBER_LIST_SUCCESS:
            return { loading: false, appointments: action.payload }
        case APPOINTMENT_BARBER_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const appointmentBarberUpcomingListReducer = (state = { appointments: [] }, action) => {
    switch (action.type) {
        case APPOINTMENT_BARBER_UPCOMING_LIST_REQUEST:
            return { loading: true}
        case APPOINTMENT_BARBER_UPCOMING_LIST_SUCCESS:
            return { loading: false, appointments: action.payload }
        case APPOINTMENT_BARBER_UPCOMING_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const appointmentListReducer = (state = { appointments: [] }, action) => {
    switch (action.type) {
        case APPOINTMENT_LIST_REQUEST:
            return { loading: true}
        case APPOINTMENT_LIST_SUCCESS:
            return { loading: false, appointments: action.payload }
        case APPOINTMENT_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const appointmentDetailsReducer = (state = { appointment: {} }, action) => {
    switch (action.type) {
        case APPOINTMENT_DETAILS_REQUEST:
            return { loading: true}
        case APPOINTMENT_DETAILS_SUCCESS:
            return { loading: false, appointment: action.payload }
        case APPOINTMENT_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const appointmentMarkCompleteClientReducer = (state = {}, action) => {
    switch (action.type) {
        case APPOINTMENT_MARK_COMPLETED_CLIENT_REQUEST:
            return { loading: true}
        case APPOINTMENT_MARK_COMPLETED_CLIENT_SUCCESS:
            return { loading: false, success: true }
        case APPOINTMENT_MARK_COMPLETED_CLIENT_FAIL:
            return { loading: false, error: action.payload }
        case APPOINTMENT_MARK_COMPLETED_CLIENT_RESET:
            return { success: false }
        default:
            return state
    }
}


export const appointmentMarkCompleteBarberReducer = (state = {}, action) => {
    switch (action.type) {
        case APPOINTMENT_MARK_COMPLETED_BARBER_REQUEST:
            return { loading: true}
        case APPOINTMENT_MARK_COMPLETED_BARBER_SUCCESS:
            return { loading: false, success: true }
        case APPOINTMENT_MARK_COMPLETED_BARBER_FAIL:
            return { loading: false, error: action.payload }
        case APPOINTMENT_MARK_COMPLETED_BARBER_RESET:
            return { success: false }
        default:
            return state
    }
}

export const appointmentCancelReducer = (state = {}, action) => {
    switch (action.type) {
        case APPOINTMENT_CANCEL_REQUEST:
            return { loading: true}
        case APPOINTMENT_CANCEL_SUCCESS:
            return { loading: false, success: true }
        case APPOINTMENT_CANCEL_FAIL:
            return { loading: false, error: action.payload }
        case APPOINTMENT_CANCEL_RESET:
                return { loading: false, error: null, success: false }
        default:
            return state
    }
}

export const appointmentRescheduleFeeReducer = (state = {}, action) => {
    switch (action.type) {
        case APPOINTMENT_RESCHEDULE_FEE_REQUEST:
            return { loading: true}
        case APPOINTMENT_RESCHEDULE_FEE_SUCCESS:
            return { loading: false, fee: action.payload }
        case APPOINTMENT_RESCHEDULE_FEE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}


export const appointmentRescheduleReducer = (state = {}, action) => {
    switch (action.type) {
        case APPOINTMENT_RESCHEDULE_REQUEST:
            return { loading: true}
        case APPOINTMENT_RESCHEDULE_SUCCESS:
            return { loading: false, success: true }
        case APPOINTMENT_RESCHEDULE_FAIL:
            return { loading: false, error: action.payload }
        case APPOINTMENT_RESCHEDULE_RESET:
                return { loading: false, success: false }
        default:
            return state
    }
}

export const appointmentRescheduleWalletReducer = (state = {}, action) => {
    switch (action.type) {
        case APPOINTMENT_RESCHEDULE_WALLET_REQUEST:
            return { loading: true}
        case APPOINTMENT_RESCHEDULE_WALLET_SUCCESS:
            return { loading: false, success: true }
        case APPOINTMENT_RESCHEDULE_WALLET_FAIL:
            return { loading: false, error: action.payload }
        case APPOINTMENT_RESCHEDULE_WALLET_RESET:
                return { loading: false, success: false }
        default:
            return state
    }
}

// Adminb
export const appointmentCancelAdminReducer = (state = {}, action) => {
    switch (action.type) {
        case APPOINTMENT_CANCEL_ADMIN_REQUEST:
            return { loading: true}
        case APPOINTMENT_CANCEL_ADMIN_SUCCESS:
            return { loading: false, success: true }
        case APPOINTMENT_CANCEL_ADMIN_FAIL:
            return { loading: false, error: action.payload }
        case APPOINTMENT_CANCEL_ADMIN_RESET:
                return { loading: false, error: null, success: false }
        default:
            return state
    }
}