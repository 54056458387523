import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, Stack, TextField, Typography } from '@mui/material';
import { Autocomplete } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import AnimatedPage from '../../components/Layout/AnimatedPage';
import { UPDATE_ALERT } from '../../redux/constants/alertConstants';
import { STORE_ADD_RESET, STORE_SCHEDULE_ADD_RESET } from '../../redux/constants/storeConstants';
import { addStore } from '../../redux/actions/storeActions'
import BackDropLoader from '../../components/ui/BackDropLoader';
import { BARBER_VERIFY_RESET } from '../../redux/constants/barberConstants';

const StoreDetails = ({ handleBack, handleNext }) => {
  const dispatch = useDispatch()

  const [name, setName] = useState('');
  const [workAreaAddress, setWorkAreaAddress] = useState('');
  const [forMen, setForMen] = useState(false)
  const [forWomen, setForWomen] = useState(false) 
  const [coordinates, setCoordinates] = useState(null);
  const [experience, setExperience] = useState('');
  const [about, setAbout] = useState('');

  const [autocomplete, setAutocomplete] = useState(null);
  const onLoad = (autoC) => setAutocomplete(autoC);

  const registerStore = useSelector(state => state.registerStore)
  const { loading: registerLoading, error: registerError, success: registerSuccess } = registerStore

  useEffect(() => {
   dispatch({ type: BARBER_VERIFY_RESET })
  }, [])

  useEffect(() => {
    //update sucess
      if (registerSuccess) {
        dispatch({ 
          type: UPDATE_ALERT, 
          payload: {
            open: true, 
            severity: 'success', 
            message: 'Store registered successfully.' 
          } 
        })
        // dispatch(getUserDetails())
        dispatch({ type: STORE_ADD_RESET })
        handleNext()
    }
      // user location error
       if(registerError) {
        dispatch({ 
          type: UPDATE_ALERT, 
          payload: {
              open: true, 
              severity: 'error', 
              message: registerError
          } 
        })
      }
  },[registerSuccess, registerError]) 

  const onPlaceChanged = () => {
    //to be chnaged
    setWorkAreaAddress(autocomplete.getPlace().formatted_address)
    const lat = autocomplete.getPlace().geometry.location.lat();
    const lng = autocomplete.getPlace().geometry.location.lng();
    setCoordinates({ lat, lng });
  }

  const submitHandler = (e) => {
    e.preventDefault();

    if(!coordinates) {
      dispatch({ 
        type: UPDATE_ALERT, 
        payload: {
          open: true, 
          severity: 'warning', 
          message: "Please select another address."
        }
      })
    }
    else if(!forMen && !forWomen) {
        dispatch({ 
          type: UPDATE_ALERT, 
          payload: {
            open: true, 
            severity: 'error', 
            message: "Please choose at least one option (For Men or For Women)."
          }
        })
    } else {
        console.log("reg")
        dispatch(addStore({ name, workAreaAddress, coordinates, forMen, forWomen, experience, about }))
    }  
}

  return(
    // <>
    // {
    //   registerLoading 
    //   ? <BackDropLoader />
    //   :
    <AnimatedPage>
      <Box   
        component="form" 
        onSubmit={submitHandler} 
        // sx={{ background: '#000' }}
      >
       <Grid container spacing={3} maxWidth="480px" margin="0 auto">
          <Grid item xs={12}>
            <TextField
              name="name"
              label="Store Name"
              autoFocusP
              variant="outlined"
              size="small"
              type="text"
              required
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged} autoComplete="off">
            <TextField
                name="area"
                label="Work Area Address"
                variant="outlined"
                size="small"
                type="text"
                required
                fullWidth
                inputProps={{
                  autoComplete: 'off'
              }}
                onChange={(e) => setWorkAreaAddress(e.target.value)}
              />
              </Autocomplete>
          </Grid>
          <Grid item xs={5}>
            <TextField
              name="experience"
              label="Experience"
              variant="outlined"
              size="small"
              type="number"
              required
              value={experience}
              fullWidth
              InputProps={{ inputProps: { min: 0, max: 120 } }}
              onChange={(e) => setExperience(e.target.value)}
            />
          </Grid>
          <Grid item xs={7} >
            <Box display="flex" justifyContent="center">
              <FormGroup>
                <FormControlLabel 
                  control={<Checkbox checked={forMen} />} 
                  label="For Men"
                  onChange={() => setForMen(!forMen)}
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel 
                  control={<Checkbox checked={forWomen} />} 
                  label="For Women"
                  onChange={() => setForWomen(!forWomen)}
              />
              </FormGroup>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField
                name="about"
                label="About"
                variant="outlined"
                size="small"
                type="text"
                value={about}
                onChange={(e) => setAbout(e.target.value)}
                required
                fullWidth
                multiline
                rows={6}
                inputProps={{ maxLength: 300 }}
            />
            <Typography variant="caption" color="text.disabled" align="right" gutterBottom>
                Max length 300 characters
            </Typography>
          </Grid>
        </Grid>
      <Stack
        direction="row"
        sx={{ mt: 8, pb: 7, justifyContent: 'space-evenly' }}
      >
        <Button
        //   disabled={!activeStep}
          variant="outlined"
          color="primary"
          onClick={handleBack}
          disabled
          >
          Back
        </Button>
        <Button 
        //   disabled={activeStep === steps.length - 1} 
          // onClick={handleNext}
          type="submit"
          variant="contained"
        >
          Continue
        </Button>
      </Stack>
    </Box>
    </AnimatedPage>
  //   }
  // </>
  )

}
export default StoreDetails