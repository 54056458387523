import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Grid, Paper, Box, Typography, Stack, useTheme, Button, List} from '@mui/material'

import PeopleIcon from '@mui/icons-material/People';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';

import { Title, DataWidget, BarChart, LineChart, AppointmentsList } from '../../../features/dashboard/index'
import AnimatedPage from '../../../components/Layout/AnimatedPage'
import { USER_GOOGLE_AUTH_RESET } from '../../../redux/constants/userConstants';
import { getUserWalletDetails } from '../../../redux/actions/userActions';
import BackDropLoader from '../../../components/ui/BackDropLoader';
import { listClientUpcomingAppointments, listUserAppointments } from '../../../redux/actions/appointmentActions';
import { listUserTransactions } from '../../../redux/actions/transactionActions';
import { Link } from 'react-router-dom';
import StyledAvatar from '../../../features/dashboard/components/StyledAvatar';
import AppointmentsTable from '../appointments/AppointmentsTable'
import TransactionsTable from '../transactions/TransactionsTable'

const Dashboard = () => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    // const [upcomingAppointments, setUpcomingAppointments] = useState([])
  
    const appointmentUserList = useSelector(state => state.appointmentUserList)
    const { loading: loadingAppointments, error: errorAppointments, appointments } = appointmentUserList

    const appointmentClientUpcomingList = useSelector(state => state.appointmentClientUpcomingList)
    const { loading: loadingUpcomingAppointments, error: errorUpcomingAppointments, appointments: upcomingAppointments } = appointmentClientUpcomingList
  
    const transactionUserList = useSelector(state => state.transactionUserList)
    const { loading: loadingTransactions, error: errorTransactions, transactions } = transactionUserList
  
    const userWalletDetails = useSelector(state => state.userWalletDetails)
    const { loading: loadingWallet, wallet } = userWalletDetails
  
    // var upcomingAppointments = appointments?.filter(item => item.status === "upcoming")
    // var upcomingAppointments = appointments?.reduce((acc, item) => 
    //                       item.status === "upcoming" && acc + 1, 0) || 0                                                                         
  
    useEffect(() => {
        // if(appointments?.length === 0) dispatch(listUserAppointments())
        // if(transactions?.length === 0) dispatch(listUserTransactions())
        dispatch(listUserAppointments())
        dispatch(listClientUpcomingAppointments())
        dispatch(listUserTransactions())
        dispatch(getUserWalletDetails())
      },[])
    
    
      // useEffect(() => {
      //   const getUpcomingAppointments = () => {
      //     return appointments?.filter(item => item.status === "upcoming" || item.status === "rescheduled")
      //   }
      //   setLoading(true)
      //   setUpcomingAppointments(getUpcomingAppointments())
      //   setLoading(false)
      // },[appointments])
    
    return (
        <>
          {loading || loadingWallet || loadingAppointments || loadingUpcomingAppointments
          ? <BackDropLoader /> 
          :
          <Box
            sx={{
                display: { xs: 'flex', md: 'grid' },
                gridTemplateColumns: 'repeat(4,1fr)',
                gridAutoRows: 'minmax(100px, auto)',
                gap: 3,
                textAlign: 'center',
                flexDirection: 'column',
                // background: "#fefefe"
            }}
            >
            {/* <Box 
            sx={{
                display: { xs: 'flex', md: 'grid' },
                gridTemplateColumns: 'repeat(24,1fr)',
                justifyContent: 'center',
                // flexDirection: 'column',
                gap: 3,
                background: "#000"
            }}
            > */}
                <DataWidget 
                    title="Wallet Balance" 
                    value={wallet?.balance ? `$${wallet.balance}` : "$0"}
                    Icon={<PaymentsOutlinedIcon color="primary" sx={{ height: 40, width: 80, opacity: 0.4 }} />  }
                />
                <DataWidget 
                    title="Upcoming Appointments" 
                    value={upcomingAppointments?.length}
                    Icon={<CalendarMonthIcon color="success" sx={{ height: 40, width: 80, opacity: 0.4 }} />  }
                />
                <DataWidget 
                    title="Upcoming Appointments" 
                    value={upcomingAppointments?.length}
                    Icon={<CalendarMonthIcon color="success" sx={{ height: 40, width: 80, opacity: 0.4 }} />  }
                />
            {/* </Box> */}
            <Paper elevation={3} sx={{ p: 2, gridColumn: 4, gridRow: '1/4' }}>
          <Box>
            {/* <Typography variant='h6' gutterBottom>Recent Appointments</Typography> */}
            <Title heading="Upcoming Appointments"/>
            {
              upcomingAppointments?.length === 0
                ? <p>No appointments available</p>
                :
                <List>
              {upcomingAppointments?.slice(0, 10).map((item) => (
                  <Paper
                    elevation={3}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                      padding: '14px 8px',
                      marginBottom: "22px"
                    }}
                      key={item._id}
                    >
                    <Box
                      // sx={{
                      //   display: 'flex',
                      //   marginRight: '0px',
                      //   paddingLeft: '0px',
                      //   alignItems: 'center',
                      //   alignContent: 'center',
                      // }}
                    >
                      <StyledAvatar src={item?.storeId?.owner?.avatar} />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1  }}>
                      <Typography
                        component="span"
                        variant="body2"
                        fontWeight="bold"
                        gutterBottom
                        // sx={{
                        //   fontFamily: 'inherit',
                        //   display: 'block',
                        //   whiteSpace: 'nowrap',
                        //   lineHeight: 'inherit',
                        //   fontWeight: 500
                        // }}
                      >
                      {item.storeId?.name}
                      </Typography>
                      <Typography
                        component="span"
                        variant="body2"
                        // sx={{
                        //   display: 'block',
                        //   whiteSpace: 'nowrap',
                        //   lineHeight: 'inherit',
                        // }}
                      >
                        {item.appointmentDate.substring(0, 10)} ({item.appointmentTime})
                      </Typography>
                    </Box>
                    <Link to={`/appointment/${item._id}`} style={{textDecoration: 'none'}}>
                          <Button variant='contained' size="small">View</Button>
                    </Link>
                </Paper>
              ))}
            </List>
            }
            
          </Box>
          {/* <Divider sx={{ mt: 3, mb: 3, opacity: 0.7 }} /> */}
        </Paper>
        <Paper elevation={3} sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              paddingBottom: "24px",
              flexWrap: 'wrap',
              gridColumn: '1/4'
            }}>
              <Box sx={{ height: '50vh', width: '100%', marginBottom: "40px" }}>
                <Title heading="Recent Appointments" />
                <AppointmentsTable appointments={appointments?.slice(0, 4)} />
              </Box>
        </Paper>
        <Paper elevation={3} sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              paddingBottom: "24px",
              flexWrap: 'wrap',
              gridColumn: '1/4'
            }}>
              <Box sx={{ height: '50vh', width: '100%' }}>
                <Title heading="Recent Transactions" />
                <TransactionsTable transactions={transactions?.slice(0, 4)} />
              </Box>
        </Paper>
        </Box>
        }
    </>
)
}

export default Dashboard