import axios from 'axios'
import {
    BARBER_LOGIN_FAIL,
    BARBER_LOGIN_REQUEST,
    BARBER_LOGIN_SUCCESS,
    BARBER_LOGOUT,
    BARBER_REGISTER_FAIL,
    BARBER_REGISTER_REQUEST,
    BARBER_REGISTER_RESET,
    BARBER_REGISTER_SUCCESS,
    BARBER_VERIFY_FAIL,
    BARBER_VERIFY_REQUEST,
    BARBER_VERIFY_SUCCESS,
} from '../constants/barberConstants'
import { USER_LOGIN_SUCCESS } from '../constants/userConstants';


export const loginBarber = (email, password) => async (dispatch) => {
    try {
        dispatch({
            type: BARBER_LOGIN_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'application/json'
            },
        }

        const { data } = await axios.post(
            '/api/barbers/login',
            { email, password },
            config
        )

        dispatch({
            type: BARBER_LOGIN_SUCCESS,
            payload: data
        })

        localStorage.setItem('userInfo', JSON.stringify(data))

    } catch (error) {
        dispatch({
                    type: BARBER_LOGIN_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const logoutBarber = () => (dispatch) => {
    localStorage.removeItem('userInfo')
    dispatch({ type: BARBER_LOGOUT })
    
    // dispatch({ type: BARBER_DETAILS_RESET })
    // dispatch({ type: ORDER_LIST_MY_RESET })
    // dispatch({ type: BARBER_LIST_RESET })
}

export const registerBarber = ({name, email, password, address, workAreaAddress, coordinates, forMen, forWomen}) => async (dispatch) => {
    try {
        dispatch({
            type: BARBER_REGISTER_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'application/json'
            },
        }

        const { data } = await axios.post(
            '/api/barbers/old',
            { name, email, password, address, workAreaAddress, coordinates, forMen, forWomen, referral : "a415be91" },
            config
        )

        dispatch({
            type: BARBER_REGISTER_SUCCESS,
            payload: data
        })

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data
        })

        // localStorage.setItem('userInfo', JSON.stringify(data))

    } catch (error) {
        dispatch({
                    type: BARBER_REGISTER_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}
export const registerNewBarber = ({ firstName, lastName, email, gender, phoneNumber, password, address, referral }) => async (dispatch) => {
    try {
        dispatch({
            type: BARBER_REGISTER_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'application/json'
            },
        }

        const { data } = await axios.post(
            '/api/barbers/web',
            {firstName, lastName, email, gender, phoneNumber, password, address, referral },
            config
        )

        console.log({data})

        dispatch({
            type: BARBER_REGISTER_SUCCESS,
            payload: data
        })

        // dispatch({
        //     type: USER_LOGIN_SUCCESS,
        //     payload: data
        // })

        // localStorage.setItem('userInfo', JSON.stringify(data))

    } catch (error) {
        dispatch({
                    type: BARBER_REGISTER_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const verifyBarber = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: BARBER_VERIFY_REQUEST
        })

        const { barberRegister: { accessToken }} = getState()
        
        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            },
        }

        const { data } = await axios.put(
            '/api/barbers/verify',
            { },
            config
        )

        dispatch({
            type: BARBER_VERIFY_SUCCESS,
            payload: data
        })

        // dispatch({
        //     type: USER_LOGIN_SUCCESS,
        //     payload: data
        // })

        // localStorage.setItem('userInfo', JSON.stringify(data))

    } catch (error) {
        dispatch({
                    type: BARBER_VERIFY_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}
