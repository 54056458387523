import { useEffect, useMemo, useState } from 'react';
import { Avatar, Box, Button, Rating, Stack, Typography } from '@mui/material';
import { DataGrid, gridClasses, GridToolbar } from '@mui/x-data-grid';
import moment from 'moment';
import { grey } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import { listTransactions } from '../../../redux/actions/transactionActions';
import BackDropLoader from '../../../components/ui/BackDropLoader';
import TextButton from '../../../components/ui/TextButton';
import { getContactMessages } from '../../../redux/actions/contactActions';
import FeedbackActions from './FeedbackActions';
import FeedbackModal from './FeedbackModal';

const Feedbacks = () => {
    const dispatch = useDispatch()
    
    const storeAndBarberDetails = useSelector(state => state.storeAndBarberDetails)
    const { loading, error: errorDetails, data } = storeAndBarberDetails

  const [pageSize, setPageSize] = useState(10);
  const [rowId, setRowId] = useState(null);

  const [openModal, setOpenModal] = useState(false)
  const [feedbackDetails, setFeedbackDetails] = useState({})

  const NoRowsOverlay = () => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        No feedbacks available
      </Stack>
    );
  }

  const columns = useMemo(
    () => [
      { 
        field: 'name', 
        headerName: 'Client Name', 
        width: 180, 
        sortable: false,
        valueGetter: (params) => 
        params.row.client ? params.row.client?.firstName + " " + params.row.client?.lastName : "Barbarly User"
    },
    { 
        field: 'rating',
        headerName: 'Rating', 
        width: 160,
        // valueGetter: (params) => params.row.rating
        renderCell: (params) => <Rating sx={{my: 2}}  name="half-rating-read" value={params.row.rating} precision={0.5} readOnly />
    },
    { 
        field: 'comment',
        headerName: 'Comment', 
        width: 280,
        valueGetter: (params) => params.row.comment
    },
    { 
        field: 'createdAt',
        headerName: 'Date', 
        width: 160,
        valueGetter: (params) => params.row.createdAt.substring(0, 10)
    },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 80,
        type: 'actions',
        renderCell: (params) => ( 
               <FeedbackActions {...{ params, rowId, setRowId, setOpenModal, setFeedbackDetails }} />
        ),
      },
    ],
    [rowId]
  );

  return (
    <>
    {
        loading 
        ? <BackDropLoader />
        :
        <Box
        sx={{
            height: '70vh',
            width: '100%',
        }}
        >
        <DataGrid
            columns={columns}
            rows={data?.store?.reviews || []}
            getRowId={(row) => row._id}
            rowsPerPageOptions={[5, 10, 20]}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            components={{
              NoRowsOverlay,
              Toolbar: GridToolbar
            }}
            getRowSpacing={(params) => ({
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
            })}
            sx={{
            [`& .${gridClasses.row}`]: {
                bgcolor: (theme) =>
                theme.palette.mode === 'light' ? grey[200] : grey[900],
            },
            }}
            onCellEditCommit={(params) => setRowId(params.id)}
        />
        </Box>
    }
        <FeedbackModal 
          openModal={openModal}
          onClose={ () => setOpenModal(false)}
          feedback={feedbackDetails}
        />
    </>
  );
};

export default Feedbacks;
