import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';


import { styled } from "@mui/material/styles"

const StyledCard = styled(Card)({
    margin: "0 auto",
    // width: '320px',
    // width: "max-content"
})


const BasicCard = ({ header, content}) => {
  return (
    <StyledCard>
        {header}
        <CardContent>
           {content}
        </CardContent>
    </StyledCard>
  )
}

export default BasicCard