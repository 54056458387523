import { Avatar, Box, Rating, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react'

const Feedback = ({review}) => {
    const theme = useTheme()
  return (
    <Box 
        style={{ 
            margin: "18px 0",
            padding: "18px"
        }}
        bgcolor={theme.palette.custom.backgroundColor}
    >
        <Box
            sx={{
                display: "flex",
                alignItems: 'center',
            }}
        >
            <Avatar src={review?.client?.avatar} />
            <Box ml={1}>
                <Typography fontWeight="bold">{review?.client?.firstName || "Barbaly"} {review?.client?.lastName || "User"}</Typography>
                <Rating value={review?.rating} precision={0.5} readOnly/>
            </Box>
            <Typography marginLeft="auto">{review?.createdAt.substring(0, 10)}</Typography>
        </Box>
        <Box>
           <Typography mt={1.5} mx={1}>{review?.comment}</Typography>
        </Box>
    </Box>
  )
}

export default Feedback