import { Box, Paper, Tab, Tabs, Typography } from '@mui/material';

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import AnimatedPage from '../../../components/Layout/AnimatedPage';
import BackDropLoader from '../../../components/ui/BackDropLoader';
import { EditLocation, EditService, EditSlot } from '../../../features/store';

import PropTypes from 'prop-types';
import TopNavigation from '../../../components/ui/TopNavigation';
import { getUserDetails } from '../../../redux/actions/userActions';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


const Store = () => {
  const dispatch = useDispatch()
  // const [loading, setLoading] = useState(false)

  const userLogin = useSelector(state => state.userLogin)
  const { loading, error, userInfo } = userLogin

  // const userDetails = useSelector(state => state.userDetails)
  // const { loading, error, user } = userDetails

  // useEffect(() => {
  //   dispatch(getUserDetails())
  // }, [])


  return (
    <AnimatedPage>
        <>
        {(loading) 
        ? <BackDropLoader /> 
        : 
        <Box
            sx={{
                display: { xs: 'flex', md: 'grid' },
                // gridTemplateColumns: 'repeat(3,1fr)',
                gridAutoRows: 'minmax(100px, auto)',
                // gap: 3,
                textAlign: 'center',
                flexDirection: 'column',
                // maxHeight: 'max-content',
                position: 'relative'
            }}
        >
            <Paper 
                elevation={3}
                p={0}
                // padding: 0
                // sx={{ background: "#000", padding: "0"}}
            >
            <TopNavigation 
              // TabOneHeading="Update Location"
              // TabOneContent={<EditLocation  />}
              TabOneHeading="Service Management"
              TabOneContent={<EditService />}
              TabTwoHeading="Schedule Management"
              TabTwoContent={<EditSlot />}
              // TabFourHeading="Change Location"
              // TabFourContent={<EditLocation />}
            />
            </Paper>
        </Box>
        }
        </>
    </AnimatedPage>
  )}

export default Store