import { Collapse, Alert, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const MsgAlert = ({type, message, openAlert, setOpenAlert}) => {
  console.log({message, openAlert})
  return (
    <Collapse in={openAlert} sx={{marginBottom: "14px"}}>
    <Alert
      severity={type}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={() => {
            setOpenAlert(false);
          }}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
      sx={{ my: 1 }}
    >
     {message}
    </Alert>
  </Collapse>
  )
}

export default MsgAlert