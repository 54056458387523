import {
    USER_BARBER_LIST_FAIL,
    USER_BARBER_LIST_REQUEST,
    USER_BARBER_LIST_RESET,
    USER_BARBER_LIST_SUCCESS,
    USER_CLIENT_LIST_FAIL,
    USER_CLIENT_LIST_REQUEST,
    USER_CLIENT_LIST_RESET,
    USER_CLIENT_LIST_SUCCESS,
    USER_DELETE_FAIL,
    USER_DELETE_REQUEST,
    USER_DELETE_SUCCESS,
    USER_DETAILS_FAIL,
    USER_DETAILS_REQUEST,
    USER_DETAILS_RESET,
    USER_DETAILS_SUCCESS,
    USER_GOOGLE_AUTH_FAIL,
    USER_GOOGLE_AUTH_REQUEST,
    USER_GOOGLE_AUTH_SUCCESS,
    USER_LIST_FAIL,
    USER_LIST_REQUEST,
    USER_LIST_RESET,
    USER_LIST_SUCCESS,
    USER_LOGIN_FAIL,
    USER_LOGIN_REQUEST,
    USER_LOGIN_RESET,
    USER_LOGIN_SUCCESS,
    USER_LOGOUT,
    USER_PASSWORD_RESET_FAIL,
    USER_PASSWORD_RESET_REQUEST,
    USER_PASSWORD_RESET_RESET,
    USER_PASSWORD_RESET_SUCCESS,
    USER_REGISTER_FAIL,
    USER_REGISTER_REQUEST,
    USER_REGISTER_RESET,
    USER_REGISTER_SUCCESS,
    USER_SAVE_PASSWORD_FAIL,
    USER_SAVE_PASSWORD_REQUEST,
    USER_SAVE_PASSWORD_RESET,
    USER_SAVE_PASSWORD_SUCCESS,
    USER_SEND_VERIFICATION_EMAIL_FAIL,
    USER_SEND_VERIFICATION_EMAIL_REQUEST,
    USER_SEND_VERIFICATION_EMAIL_RESET,
    USER_SEND_VERIFICATION_EMAIL_SUCCESS,
    USER_STATUS_UPDATE_FAIL,
    USER_STATUS_UPDATE_REQUEST,
    USER_STATUS_UPDATE_RESET,
    USER_STATUS_UPDATE_SUCCESS,
    USER_UPDATE_FAIL,
    USER_UPDATE_LOCATION_FAIL,
    USER_UPDATE_LOCATION_REQUEST,
    USER_UPDATE_LOCATION_RESET,
    USER_UPDATE_LOCATION_SUCCESS,
    USER_UPDATE_PASSWORD_FAIL,
    USER_UPDATE_PASSWORD_REQUEST,
    USER_UPDATE_PASSWORD_RESET,
    USER_UPDATE_PASSWORD_SUCCESS,
    USER_UPDATE_PHONE_FAIL,
    USER_UPDATE_PHONE_REQUEST,
    USER_UPDATE_PHONE_RESET,
    USER_UPDATE_PHONE_SUCCESS,
    USER_UPDATE_PROFILE_FAIL,
    USER_UPDATE_PROFILE_REQUEST,
    USER_UPDATE_PROFILE_RESET,
    USER_UPDATE_PROFILE_SUCCESS,
    USER_UPDATE_REQUEST,
    USER_UPDATE_RESET,
    USER_UPDATE_SUCCESS,
    USER_VERIFY_RESET_CODE_FAIL,
    USER_VERIFY_RESET_CODE_REQUEST,
    USER_VERIFY_RESET_CODE_RESET,
    USER_VERIFY_RESET_CODE_SUCCESS,
    USER_VERIFY_UPDATE_PHONE_FAIL,
    USER_VERIFY_UPDATE_PHONE_REQUEST,
    USER_VERIFY_UPDATE_PHONE_RESET,
    USER_VERIFY_UPDATE_PHONE_SUCCESS,
    USER_WALLET_DETAILS_FAIL,
    USER_WALLET_DETAILS_REQUEST,
    USER_WALLET_DETAILS_SUCCESS
} from '../constants/userConstants'


export const userLoginReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_LOGIN_REQUEST:
            return { loading: true}
        case USER_LOGIN_SUCCESS:
            return { loading: false, userInfo: action.payload }
        case USER_LOGIN_FAIL:
            return { loading: false, error: action.payload }
        case USER_LOGIN_RESET:
            return { loading: false, error: false }
        case USER_LOGOUT:
            return {}
        default:
            return state
    }
}

export const userGoogleAuthReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_GOOGLE_AUTH_REQUEST:
            return { loading: true}
        case USER_GOOGLE_AUTH_SUCCESS:
            return { loading: false, googleAuthInfo: action.payload }
        case USER_GOOGLE_AUTH_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const userRegisterReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_REGISTER_REQUEST:
            return { loading: true}
        case USER_REGISTER_SUCCESS:
            return { loading: false, success: true }
        case USER_REGISTER_FAIL:
            return { loading: false, error: action.payload }
        case USER_REGISTER_RESET:
                return {}
        default:
            return state
    }
}

export const userDetailsReducer = (state = { user: {} }, action) => {
    switch (action.type) {
        case USER_DETAILS_REQUEST:
            return {...state, loading: true}
        case USER_DETAILS_SUCCESS:
            return { loading: false, user: action.payload }
        case USER_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        case  USER_DETAILS_RESET:
            return { user: {} }
        default:
            return state
    }
}

export const userWalletDetailsReducer = (state = { }, action) => {
    switch (action.type) {
        case USER_WALLET_DETAILS_REQUEST:
            return {...state, loading: true}
        case USER_WALLET_DETAILS_SUCCESS:
            return { loading: false, wallet: action.payload }
        case USER_WALLET_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const userUpdateProfileReducer = (state = { }, action) => {
    switch (action.type) {
        case USER_UPDATE_PROFILE_REQUEST:
            return { loading: true }
        case USER_UPDATE_PROFILE_SUCCESS:
            return { loading: false, success: true, userInfo: action.payload }
        case USER_UPDATE_PROFILE_FAIL:
            return { loading: false, error: action.payload }
        case USER_UPDATE_PROFILE_RESET:
            return { success: false }
        default:
            return state
    }
}

// Update user password
export const userUpdatePasswordReducer = (state = { }, action) => {
    switch (action.type) {
        case USER_UPDATE_PASSWORD_REQUEST:
            return { loading: true }
        case USER_UPDATE_PASSWORD_SUCCESS:
            return { loading: false, success: true }
        case USER_UPDATE_PASSWORD_FAIL:
            return { loading: false, error: action.payload }
        case USER_UPDATE_PASSWORD_RESET:
            return { success: false }
        default:
            return state
    }
}

// Update phone number
export const userUpdatePhoneReducer = (state = { }, action) => {
    switch (action.type) {
        case USER_UPDATE_PHONE_REQUEST:
            return { loading: true }
        case USER_UPDATE_PHONE_SUCCESS:
            return { loading: false, success: true }
        case USER_UPDATE_PHONE_FAIL:
            return { loading: false, error: action.payload }
        case USER_UPDATE_PHONE_RESET:
            return { success: false }
        default:
            return state
    }
}

// verify phone number
export const userVerifyPhoneReducer = (state = { }, action) => {
    switch (action.type) {
        case USER_VERIFY_UPDATE_PHONE_REQUEST:
            return { loading: true }
        case USER_VERIFY_UPDATE_PHONE_SUCCESS:
            return { loading: false, success: true }
        case USER_VERIFY_UPDATE_PHONE_FAIL:
            return { loading: false, error: action.payload }
        case USER_VERIFY_UPDATE_PHONE_RESET:
            return { success: false }
        default:
            return state
    }
}

// Update user location
export const userUpdateLocationReducer = (state = { }, action) => {
    switch (action.type) {
        case USER_UPDATE_LOCATION_REQUEST:
            return { loading: true }
        case USER_UPDATE_LOCATION_SUCCESS:
            return { loading: false, success: true }
        case USER_UPDATE_LOCATION_FAIL:
            return { loading: false, error: action.payload }
        case USER_UPDATE_LOCATION_RESET:
            return { success: false }
        default:
            return state
    }
}


export const userDeleteReducer = (state = { }, action) => {
    switch (action.type) {
        case USER_DELETE_REQUEST:
            return { loading: true}
        case USER_DELETE_SUCCESS:
            return { loading: false, success: true }
        case USER_DELETE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const userPasswordResetReducer = (state = { }, action) => {
    switch (action.type) {
        case USER_PASSWORD_RESET_REQUEST:
            return { loading: true}
        case USER_PASSWORD_RESET_SUCCESS:
            return { loading: false, success: true }
        case USER_PASSWORD_RESET_FAIL:
            return { loading: false, error: action.payload }
        case USER_PASSWORD_RESET_RESET:
            return { success: false }
    default:
            return state
    }
}

export const userVerifyResetCodeReducer = (state = { }, action) => {
    switch (action.type) {
        case USER_VERIFY_RESET_CODE_REQUEST:
            return { loading: true}
        case USER_VERIFY_RESET_CODE_SUCCESS:
            return { loading: false, token: action.payload }
        case USER_VERIFY_RESET_CODE_FAIL:
            return { loading: false, error: action.payload }
        case USER_VERIFY_RESET_CODE_RESET:
            return { token: null }
    default:
            return state
    }
}

export const userSavePasswordReducer = (state = { }, action) => {
    switch (action.type) {
        case USER_SAVE_PASSWORD_REQUEST:
            return { loading: true}
        case USER_SAVE_PASSWORD_SUCCESS:
            return { loading: false, success: true }
        case USER_SAVE_PASSWORD_FAIL:
            return { loading: false, error: action.payload }
        case USER_SAVE_PASSWORD_RESET:
            return { success: false }
    default:
            return state
    }
}

export const userSendVerificationEmailReducer = (state = { }, action) => {
    switch (action.type) {
        case USER_SEND_VERIFICATION_EMAIL_REQUEST:
            return { loading: true}
        case USER_SEND_VERIFICATION_EMAIL_SUCCESS:
            return { loading: false, success: true }
        case USER_SEND_VERIFICATION_EMAIL_FAIL:
            return { loading: false, error: action.payload }
        case USER_SEND_VERIFICATION_EMAIL_RESET:
            return { success: false }
    default:
            return state
    }
}

// ADMIN
export const userStatusUpdateReducer = (state = { }, action) => {
    switch (action.type) {
        case USER_STATUS_UPDATE_REQUEST:
            return { loading: true}
        case USER_STATUS_UPDATE_SUCCESS:
            return { loading: false, success: true }
        case USER_STATUS_UPDATE_FAIL:
            return { loading: false, error: action.payload }
        case USER_STATUS_UPDATE_RESET:
            return { success: false }
    default:
            return state
    }
}

export const clientListReducer = (state = { clients: [] }, action) => {
    switch (action.type) {
        case USER_CLIENT_LIST_REQUEST:
            return { loading: true}
        case USER_CLIENT_LIST_SUCCESS:
            return { loading: false,  clients: action.payload }
        case USER_CLIENT_LIST_FAIL:
            return { loading: false, error: action.payload }
        case USER_CLIENT_LIST_RESET:
            return { clients: [] }
        default:
            return state
    }
}

export const userListReducer = (state = { users: [] }, action) => {
    switch (action.type) {
        case USER_LIST_REQUEST:
            return { loading: true}
        case USER_LIST_SUCCESS:
            return { loading: false,  users : action.payload }
        case USER_LIST_FAIL:
            return { loading: false, error: action.payload }
        case USER_LIST_RESET:
            return { users: [] }
        default:
            return state
    }
}

export const barberListReducer = (state = { barbers: [] }, action) => {
    switch (action.type) {
        case USER_BARBER_LIST_REQUEST:
            return { loading: true}
        case USER_BARBER_LIST_SUCCESS:
            return { loading: false, barbers: action.payload }
        case USER_BARBER_LIST_FAIL:
            return { loading: false, error: action.payload }
        case USER_BARBER_LIST_RESET:
            return { barbers: [] }
        default:
            return state
    }
}

