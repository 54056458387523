import {Button} from '@mui/material'

const TextButton = ({color, disabled = false, children}) => {
  return (
    <Button 
      color={color}
      disabled={disabled}
      sx={{fontWeight: "bold", textTransform: "none"}}
    >
      {children}
    </Button>
  )
}

export default TextButton