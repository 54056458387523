export const BARBER_LOGIN_REQUEST = 'BARBER_LOGIN_REQUEST'
export const BARBER_LOGIN_SUCCESS = 'BARBER_LOGIN_SUCCESS'
export const BARBER_LOGIN_FAIL = 'BARBER_LOGIN_FAIL'
export const BARBER_LOGOUT = 'BARBER_LOGOUT'

export const BARBER_REGISTER_REQUEST = 'BARBER_REGISTER_REQUEST'
export const BARBER_REGISTER_SUCCESS = 'BARBER_REGISTER_SUCCESS'
export const BARBER_REGISTER_FAIL = 'BARBER_REGISTER_FAIL'
export const BARBER_REGISTER_RESET = 'BARBER_REGISTER_RESET'

export const BARBER_VERIFY_REQUEST = 'BARBER_VERIFY_REQUEST'
export const BARBER_VERIFY_SUCCESS = 'BARBER_VERIFY_SUCCESS'
export const BARBER_VERIFY_FAIL = 'BARBER_VERIFY_FAIL'
export const BARBER_VERIFY_RESET = 'BARBER_VERIFY_RESET'


