import React, {useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom';
import { BottomNavigation, CssBaseline, Grid, Box, BottomNavigationAction, FormControl, InputLabel, Select, MenuItem, IconButton, Typography,  } from '@mui/material'

import { getPlacesData } from '../api'
import AnimatedPage from '../components/Layout/AnimatedPage'
import { HeroSection, Featured, StatsDetails, GetStartedApp, 
  RightDetails, LeftDetails, GetStartedWeb, FAQs } from '../features/homepage/index'
import SearchBox from '../components/ui/SearchBox/SearchBox'
import { List, Map } from '../features/search/index'

import ListIcon from '@mui/icons-material/List'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import TuneIcon from '@mui/icons-material/Tune'

import BookAppointment from './customer/BookAppointment'

import Dummy from "../images/dummy.png";
import LaptopDummy from "../images/laptop-dummy.jpg";
import MultiDevicedummy from "../images/multiple-device-dummy.jpg";
import MultiDeviceBook from "../images/multiple-devices-book-appointment.jpg";
import PaymentOptions from "../images/secure-payment-options.jpg";
import SecurePayTab from "../images/secure-pay-tab.jpg";
import BarberManagementTool from "../images/barber-management.png";
// import Payouts from "../images/payouts.jpg";
import AppPayouts from "../images/app-payouts.png";
import axios from 'axios';
import BackDropLoader from '../components/ui/BackDropLoader';

const Home = ({setMode}) => {
  const [value, setValue] = useState(0);
  const ref = useRef();
  // useEffect(() => {
  //     ref.current.ownerDocument.body.scrollTop = 0;
  // }, [value]);

  const navigate = useNavigate()
  const location = useLocation()

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin
  
  useEffect(() => {
    setMode("light")
   }, []);

  useEffect(() => {
    if (userInfo && location.pathname != "/")
        navigate('/dashboard', { replace: true })
   }, [userInfo])

  // useEffect(() => {
  //   var filter = {
  //         rating,
  //         forMen: type === "men" || type === "both" ? true : false,
  //         forWomen: type === "women" || type === "both" ? true : false,
  //       }
  //   if(bounds.sw && bounds.ne) {
  //     setIsLoading(true)
  //     getPlacesData(bounds.sw, bounds.ne, filter.rating, filter.forMen, filter.forWomen)
  //       .then((data) => {
  //         setPlaces(data)
  //         // setFilteredPlaces([])
  //         setIsLoading(false)
  //       })
  //   }
    
  //   // if(bounds.sw && bounds.ne) {
  //   //   setIsLoading(true);
  //   //   getPlacesData(coordinates)
  //   //     .then((data) => {
  //   //       setPlaces(data);
  //   //       setFilteredPlaces([])
  //   //       setIsLoading(false);
  //   //     });
  //   // }
    
  // }, [bounds, rating, type])

  return (
      <AnimatedPage>
        {/* <CssBaseline /> */}
        <HeroSection myRef={ref} />
      {/* <BookAppointment /> */}
          {/* Barber Dashboard */}
          <RightDetails 
            img={MultiDeviceBook}
            // img={Dummy}
            heading="Hassle-free Appointment Booking for Clients."
            text="Barbarly offers an online booking system through a website and mobile app that allows 
            clients to schedule appointments with the nearest available barbers at their convenience. 
            Clients can easily cancel or reschedule appointments through the platform, which ensures a 
            positive experience and prevents any frustration."
          />

          <LeftDetails 
            // img={PaymentOptions}
            img={SecurePayTab}
            heading="Secure Online Payment Options."
            text="Barbarly offers PayPal as a secure online payment option for clients. 
            PayPal is a widely used payment platform allowing users to send and receive payments 
            securely through their website or mobile app for online appointments. By accepting PayPal, 
            Barbarly provides a convenient and safe payment experience for clients while also protecting 
            their sensitive information."
          />

          {/* Stats Details */}
          <StatsDetails />

          {/* Get Started Web */}
          <GetStartedWeb />

          <RightDetails 
            img={LaptopDummy}
            // img={Dummy}
            heading="Powerful Management Tool for Barbers."
            text="Barbarly provides a powerful management tool for barbers to manage their business operations efficiently. 
            With Barbarly, barbers can manage their schedules and services, track appointments, and communicate with their 
            clients seamlessly. Barbarly streamlines the daily tasks of barbers, allowing them to focus on delivering high-quality 
            services to their clients."
          />

          <LeftDetails 
            // img={MultiDevicedummy}
            img={AppPayouts}
            heading="Low Commission, Easy Payout."
            text="Barbarly offers easy payout options, allowing barbers to receive their earnings quickly and conveniently. 
            Clients can make payments securely through PayPal. By providing low commission rates and easy payout options, 
            Barbarly ensures a fair and transparent payment system for both clients and barbers."
          />

          {/* Featured cities */}
          {/* <Featured /> */}

          {/* Get Started App */}
          <GetStartedApp />

          {/* FAQs */}
          <FAQs />
      </AnimatedPage>
  )
}

export default Home