import { Button, Typography, Card, CardContent, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom"

import { styled } from "@mui/material/styles"
import AnimatedPage from '../components/Layout/AnimatedPage'
const StyledCard = styled(Card)({
    display: "flex",
    flexDirection: "column",
    width: "368px",
    height: "60vh",
    margin: "40px auto",
    justifyContent: "center"
})

const UnAuthorized = () => {
    const navigate = useNavigate()
    const goBack = () => navigate(-1)
    return (
        <AnimatedPage>
            <StyledCard elevation={3}>
                <Typography 
                    variant="h5" 
                    align="center"
                >
                    Unauthorized: You don't have permission to view this page.
                </Typography>
                <CardContent>
                    <Button 
                        variant="contained" 
                        fullWidth 
                        onClick={goBack}
                    >
                        Go Back
                    </Button>
                </CardContent>
            </StyledCard>
        </AnimatedPage>
    )
}

export default UnAuthorized