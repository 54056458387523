import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BasicModal from './BasicModal'
import Alert from '../Alert'
import Loader from '../Loader'

import { Box, TextField, Grid, Button, Stack, MenuItem } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { addNewServiceCategory, listCategories } from '../../../redux/actions/storeActions'

const NewCategoryModal = ({ title, open, onClose }) => {
    const dispatch = useDispatch() 
    const [name, setName] = useState('')

    const [openAlert, setOpenAlert] = useState(false)

    const serviceCategoryAdd = useSelector(state => state.serviceCategoryAdd)
    const { loading, error, success } = serviceCategoryAdd

    const serviceCategoryList = useSelector(state => state.serviceCategoryList)
    const { loading: loadingCategories, error: errorCategories, categories } = serviceCategoryList
  
    useEffect(() => {
        dispatch(listCategories())
      },[])

      useEffect(() => {
        console.log({categories})
      },[categories])

  useEffect(() => {
    if(success) {
        setName('')
        onClose()
    }
    if(error)
        setOpenAlert(true)
  },[error, success])

  const addCategoryHandler = ({ name }) => {
    dispatch(addNewServiceCategory({ name }))
   }

    const submitHandler = (e) => {
        e.preventDefault()
        addCategoryHandler({ name })
    }

    const getContent = () => (
    <Box component="form" onSubmit={submitHandler} sx={{ mt: 3 }}>
        {loading && loadingCategories && <Loader />}
        {error && 
            <Alert 
                type="error"
                message={error} 
                openAlert={openAlert} 
                setOpenAlert={setOpenAlert} 
            />
        }
         
         <Grid container spacing={2}>
           <Grid item xs={12}>
             <TextField
                id="outlined-select-category"
                select
                label="Select Category"
                value={name}
                // onChange={(e) => setSelectedCategoryId(e.target.value)}
                onChange={(e) => setName(e.target.value)}
                required
                fullWidth
                >
                {categories?.map((item) => (
                    <MenuItem 
                        key={item._id}
                        value={item.name}
                    >
                        {item.name}
                    </MenuItem>
                ))}
                </TextField>
           </Grid>

            <Grid item xs={12}>
                <Stack 
                    direction="row" 
                    justifyContent="end"
                    spacing={2}
                >
                    <Button 
                        variant="outlined"  
                        onClick={onClose}
                        >
                        Cancel
                    </Button>
                    <Button 
                        variant="contained"
                        type="submit"
                    >
                        Save
                    </Button>
                </Stack>  
            </Grid >
        </Grid>       
    </Box>
    )

  return (
    <BasicModal
        open={open}
        onClose={onClose}
        title={title}
        content={getContent()}
        validate={() => {}}
    >
    </BasicModal>
  )
}

export default NewCategoryModal