import React from 'react'
import {Container, Card} from '@mui/material'

const FormContainer = ({children}) => {
  return (
    <Container component="main" maxWidth="xs">
    <Card
      sx={{
        marginTop: 10,
        marginBottom: 10,
        padding: "42px 32px 52px 32px",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
      elevation={2}
    >
     {children}  
     </Card>
    </Container> 
  )
}

export default FormContainer