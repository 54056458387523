import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Avatar, Grid, Button, TextField, Box, Typography } from '@mui/material'
// import GoogleIcon from '@mui/icons-material/Google'

// import { gapi } from 'gapi-script'

import { resetUserPassword } from '../../redux/actions/userActions'

import AnimatedPage from '../../components/Layout/AnimatedPage'
import Loader from '../../components/ui/Loader'
import Alert from '../../components/ui/Alert'
import FormContainer from '../../components/ui/Form/FormContainer'
import Password from '../../components/ui/Form/Password'

import roleBasedRedirect from '../../utils/roleBasedRedirect'
import { USER_PASSWORD_RESET_RESET, USER_REGISTER_RESET } from '../../redux/constants/userConstants'


const ResetPassword = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const [email, setEmail] = useState('')
  const [message, setMessage] = useState(null)
  const [openAlert, setOpenAlert] = useState(false) 
 
  const userPasswordReset = useSelector(state => state.userPasswordReset)
  const { loading, error, success } = userPasswordReset

  useEffect(() => {
    dispatch({ type: USER_PASSWORD_RESET_RESET })
},[])  

  useEffect(() => {
    if (success)
        navigate('/otp-verification')
        // dispatch(logout())
},[success])  

useEffect(() => {
  // if (success) {
  //   setMessage("Registered success, you can now sign in.")
  //   setOpenAlert(true)
  // }
  if(error) {
    setMessage(error)
    setOpenAlert(true)
  }
},[error]) 

  const submitHandler = (e) => {
      e.preventDefault();
    //   if (!email || !password) {
    //     setMessage('Please fill in all fileds.')
    //     setOpenAlert(true)
    // } else {
        setMessage(null)
        setOpenAlert(false)
        // dispatch({type: USER_REGISTER_RESET})
        dispatch(resetUserPassword(email))
    // }    
  }

  return (
    <AnimatedPage>
      <FormContainer>
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          </Avatar>
          <Typography variant="h6">
            Reset Password
          </Typography>
          <Box component="form" onSubmit={submitHandler} sx={{ mt: 3 }}>
            {loading && <Loader />}
            {message  &&
             <Alert 
                type="error" 
                message={message}
                openAlert={openAlert}
                setOpenAlert={setOpenAlert}
              />
            }
            <Grid container>
              <Grid item xs={12} mb={1}>
                <TextField
                  name="email"
                  label="Email"
                  autoFocus
                  variant="outlined"
                  size="small"
                  type="text"
                  required
                  fullWidth
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="flex-end">
              <Button
                type="submit"
                variant="contained"
                style={{margin: '16px 0 8px 0'}}
              >
                Continue
              </Button>
            </Box>
          </Box>
      </FormContainer>
    </AnimatedPage>
  );
}

export default ResetPassword