import React, {useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom';
import { BottomNavigation, CssBaseline, Grid, Box, BottomNavigationAction, FormControl, InputLabel, Select, MenuItem, IconButton, Typography,  } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { getPlacesData } from '../../api'
import AnimatedPage from '../../components/Layout/AnimatedPage'
import { HeroSection, Featured } from '../../features/homepage/index'
import SearchBox from '../../components/ui/SearchBox/SearchBox'
import { List, Map } from '../../features/search/index'

import ListIcon from '@mui/icons-material/List'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import TuneIcon from '@mui/icons-material/Tune'
import axios from 'axios';
import BackDropLoader from '../../components/ui/BackDropLoader';

const BookAppointment = () => {
  const [value, setValue] = useState(0);
  const ref = useRef();
  const theme = useTheme()
  
  const [address, setAddress] = useState('')
  const [places, setPlaces] = useState([])
  // const [filteredPlaces, setFilteredPlaces] = useState([])
  const [type, setType] = useState('both')
  const [rating, setRating] = useState(0)

  const [childClicked, setChildClicked] = useState(null)

  // const [coordinates, setCoordinates] = useState({ lat: -25.274398, lng: 133.715136})
  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0});
  const [bounds, setBounds] = useState({})

  const [isLoading, setIsLoading] = useState(false)

  const userLogin = useSelector(state => state.userLogin)
  const { loading, error, userInfo } = userLogin

  useEffect(() => {
    setIsLoading(true)
    if(userInfo?.addressDetails?.coordinates) {
      setCoordinates({
        lat: userInfo?.addressDetails?.coordinates?.lat,
        lng: userInfo?.addressDetails?.coordinates?.lng,
      })
    }
    setIsLoading(false)
  }, [])
  
  const getBrowserLocation = () => {
    if ("geolocation" in navigator) {
      console.log("Available")
  } else {
      console.log("Not Available")
  }
    navigator.geolocation.getCurrentPosition( async ({ coords: {latitude, longitude} }) => {
      const address = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=`+ latitude + "," + longitude + "&sensor=true&key=AIzaSyB3ezN_I7Dj2LPapIciwQiOGQWudADnl2A")
      console.log({address})
      setAddress(address?.data?.results[0].formatted_address)
      setCoordinates({ lat: latitude, lng: longitude })
    })
    // if (navigator.geolocation) {
    //   navigator.permissions
    //     .query({ name: "geolocation" })
    //     .then(function (result) {
    //       if (result.state === "granted") {
    //         console.log(result.state);
    //         navigator.permissions.revoke({name:'geolocation'});
    //         // setAddress('')
    //         // navigator.geolocation.getCurrentPosition(({ coords: {latitude, longitude} }) => {
    //         //   setCoordinates({ lat: latitude, lng: longitude });
    //         // })
    //       } else if (result.state === "prompt") {
    //         setAddress('')
    //         navigator.geolocation.getCurrentPosition(({ coords: {latitude, longitude} }) => {
    //           setCoordinates({ lat: latitude, lng: longitude });
    //         })
    //       } else if (result.state === "denied") {
    //          navigator.permissions.revoke({name:'geolocation'});
            
    //       }
    //       result.onchange = function () {
    //         console.log(result.state);
    //       };
    //     });
    // } else {
    //   alert("Sorry Not available!");
    // }
  }

  useEffect(() => {
    var filter = {
          rating,
          forMen: type === "men" || type === "both" ? true : false,
          forWomen: type === "women" || type === "both" ? true : false,
        }
    if(bounds.sw && bounds.ne) {
      setIsLoading(true)
      getPlacesData(bounds.sw, bounds.ne, filter.rating, filter.forMen, filter.forWomen)
        .then((data) => {
          setPlaces(data)
          // setFilteredPlaces([])
        })
    }
    
    // if(bounds.sw && bounds.ne) {
    //   setIsLoading(true);
    //   getPlacesData(coordinates)
    //     .then((data) => {
    //       setPlaces(data);
    //       setFilteredPlaces([])
    //       setIsLoading(false);
    //     });
    // }
    setIsLoading(false)
  }, [bounds, rating, type])


  return (
    <>
  {
    isLoading 
    ? <BackDropLoader />
    :
    <Box>
        <SearchBox 
        setCoordinates={setCoordinates} 
        getBrowserLocation={getBrowserLocation} 
        address={address}
        setAddress={setAddress}
        />
        <Box 
        ref={ref}
        backgroundColor={theme.palette.custom.white}
        pt={3}
      >
      <Box sx={{ display: 'flex', maxWidth: '1024px', margin: '0 auto', padding: {xs: '0 18px', md: '0'} }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <FormControl sx={{my: 2, mr: 2, minWidth: 80 }} size="small">
            <InputLabel id="rating">Rating</InputLabel>
            <Select id="rating" label="Rating" value={rating} onChange={(e) => setRating(e.target.value)}>
              <MenuItem value={0}>All</MenuItem>
              <MenuItem value={3}>3.0 +</MenuItem>
              <MenuItem value={4}>4.0 +</MenuItem>
              <MenuItem value={4.5}>4.5 +</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{my: 2, mr: 2, minWidth: 120 }} size="small">
            <InputLabel id="For">Specialist For</InputLabel>
            <Select id="specialistFor" label="Specialist For" value={type} onChange={(e) => setType(e.target.value)}>
              <MenuItem value="both">Both</MenuItem>
              <MenuItem value="men">Men</MenuItem>
              <MenuItem value="women">Women</MenuItem>
            </Select>
          </FormControl>
          {/* <IconButton sx={{ background: '#000', padding: '6px 14px', borderRadius: '6px'}}>
            <TuneIcon sx={{ color: '#FFF' }} />
          </IconButton> */}
        </Box>
        {/* <Box>
          <BottomNavigation
            showLabels
            value={value}
            onChange={(e, newValue) => setValue(newValue)}
          >
            <BottomNavigationAction label="List" icon={<ListIcon />} />
            <BottomNavigationAction label="Map" icon={<LocationOnIcon />} />
          </BottomNavigation>
        </Box> */}
      </Box>

      <Typography variant="h6" align='center' mb={4}>
        {places.length > 0 ? places.length : 'No' } Barber{places.length > 1 ? 's ' : ' ' }available at selected location
      </Typography>
      <List 
              isLoading={isLoading} 
              coordinates={coordinates}
              setCoordinates={setCoordinates}
              // places={filteredPlaces.length ? filteredPlaces : places }
              // places={filteredPlaces.length ? filteredPlaces : places } 
              places={places}
              childClicked={childClicked}
            />
             <Map 
              coordinates={coordinates}
              setCoordinates={setCoordinates}
              setBounds={setBounds}
              // places={filteredPlaces.length ? filteredPlaces : places }   
              // places={ (rating > 0) ? filteredPlaces : places }   
              places={places}
              setChildClicked={setChildClicked}
            />
      {/* {
        {
          0: <List 
              isLoading={isLoading} 
              coordinates={coordinates}
              setCoordinates={setCoordinates}
              // places={filteredPlaces.length ? filteredPlaces : places }
              // places={filteredPlaces.length ? filteredPlaces : places } 
              places={places}
              childClicked={childClicked}
            />,
          1: <Map 
              coordinates={coordinates}
              setCoordinates={setCoordinates}
              setBounds={setBounds}
              // places={filteredPlaces.length ? filteredPlaces : places }   
              // places={ (rating > 0) ? filteredPlaces : places }   
              places={places}
              setChildClicked={setChildClicked}
            />,
        }[value]
      } */}
    </Box>
    </Box>
  }
  </>
  )
}

export default BookAppointment