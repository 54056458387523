import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BasicModal from './BasicModal'
import Alert from '../Alert'
import Loader from '../Loader'

import { Box, TextField, Grid, Button, Stack, MenuItem, Typography, FormHelperText } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { addNewServiceCategory, listCategories } from '../../../redux/actions/storeActions'
import HoverRating from '../Rating';
import { UPDATE_ALERT } from '../../../redux/constants/alertConstants';
import { useParams } from 'react-router-dom';
import { addNewFeedback } from '../../../redux/actions/feedbackActions';

const FeedbackModal = ({ title, open, onClose }) => {
    const dispatch = useDispatch() 
    const { id } = useParams()
    const [service, setService] = useState(4)
    const [onTime, setOnTime] = useState(4)
    const [price, setPrice] = useState(4)
    const [comment, setComment] = useState('')

    const [message, setMessage] = useState({ type: 'info', text: ''})
    const [openAlert, setOpenAlert] = useState(false)

    const feedbackAdd = useSelector(state => state.feedbackAdd)
    const { loading, error, success } = feedbackAdd
 

  useEffect(() => {
    if(success) {
        setService(3)
        setOnTime(3)
        setPrice(3)
        setComment('')
        onClose()
      //   dispatch({ 
      //     type: UPDATE_ALERT, 
      //     payload: {
      //     open: true, 
      //     severity: 'success', 
      //     message: "Review submitted successfully."
      //     } 
      // })
    }
    if(error) {
      // setMessage({ type: "error", text: error })
      // setOpenAlert(true)
         dispatch({ 
          type: UPDATE_ALERT, 
          payload: {
          open: true, 
          severity: 'error', 
          message: error
          } 
      })
    }
  },[error, success])

    const submitHandler = (e) => {
        e.preventDefault()
        if(!comment) {
          dispatch({ 
            type: UPDATE_ALERT, 
            payload: {
            open: true, 
            severity: 'error', 
            message: "Please fill comments field."
            } 
        })
        }
        console.log({id, service, onTime, price, comment })
        dispatch(addNewFeedback({id, service, onTime, price, comment}))
    }

    const getContent = () => (
    <Box component="form" onSubmit={submitHandler} sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          {/* {message && 
            <Alert 
                type={message.type}
                message={message.text} 
                openAlert={openAlert} 
                setOpenAlert={setOpenAlert} 
            />
          }   
          {loading && <Loader />}          */}
           <Grid item xs={12}>
            <HoverRating title="Service" value={service} setValue={setService} />
           </Grid>
           <Grid item xs={12}>
            <HoverRating title="On Time" value={onTime} setValue={setOnTime} />
           </Grid>
           <Grid item xs={12}>
            <HoverRating title="Price" value={price} setValue={setPrice} />
           </Grid>
           <Grid item xs={12}>
            <Typography component="legend" gutterBottom>Comments</Typography>
            <TextField
                name="Comments"
                placeholder='Please enter your comments here...'
                variant="outlined"
                size="small"
                type="text"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                required
                fullWidth
                multiline
                rows={6}
                maxRows={12}
                inputProps={{ maxLength: 300 }}
              />
               <Typography variant="caption" color="text.disabled" align="right" gutterBottom>
                Max length 300 characters
              </Typography>
           </Grid>
           <Button
                sx={{
                    fontWeight: "bold",
                    marginTop: "30px"
                }}   
                variant="contained" 
                type="submit"
                color="primary" 
                fullWidth

            >
             Submit Feedback
           </Button>
        </Grid>       
    </Box>
    )

  return (
    <BasicModal
        open={open}
        onClose={onClose}
        title={title}
        content={getContent()}
        validate={() => {}}
    >
    </BasicModal>
  )
}

export default FeedbackModal