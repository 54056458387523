import axios from 'axios'
import { BARBER_REGISTER_RESET } from '../constants/barberConstants';
import { CART_EMPTY } from '../constants/cartConstants';
import {
    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_FAIL,
    USER_LOGIN_FAIL,
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGOUT,
    USER_REGISTER_FAIL,
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_UPDATE_PROFILE_FAIL,
    USER_UPDATE_PROFILE_REQUEST,
    USER_UPDATE_PROFILE_SUCCESS,
    USER_DETAILS_RESET,
    USER_LIST_REQUEST,
    USER_LIST_SUCCESS,
    USER_LIST_FAIL,
    USER_LIST_RESET,
    USER_DELETE_REQUEST,
    USER_DELETE_SUCCESS,
    USER_DELETE_FAIL,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_FAIL,
    USER_UPDATE_REQUEST,
    USER_GOOGLE_AUTH_REQUEST,
    USER_GOOGLE_AUTH_SUCCESS,
    USER_GOOGLE_AUTH_FAIL,
    USER_WALLET_DETAILS_REQUEST,
    USER_WALLET_DETAILS_SUCCESS,
    USER_WALLET_DETAILS_FAIL,
    USER_BARBER_LIST_REQUEST,
    USER_BARBER_LIST_SUCCESS,
    USER_BARBER_LIST_FAIL,
    USER_CLIENT_LIST_REQUEST,
    USER_CLIENT_LIST_SUCCESS,
    USER_CLIENT_LIST_FAIL,
    USER_PASSWORD_RESET_REQUEST,
    USER_PASSWORD_RESET_SUCCESS,
    USER_PASSWORD_RESET_FAIL,
    USER_VERIFY_RESET_CODE_REQUEST,
    USER_VERIFY_RESET_CODE_SUCCESS,
    USER_VERIFY_RESET_CODE_FAIL,
    USER_SAVE_PASSWORD_REQUEST,
    USER_SAVE_PASSWORD_SUCCESS,
    USER_SAVE_PASSWORD_FAIL,
    USER_UPDATE_PASSWORD_REQUEST,
    USER_UPDATE_PASSWORD_SUCCESS,
    USER_UPDATE_PASSWORD_FAIL,
    USER_UPDATE_LOCATION_REQUEST,
    USER_UPDATE_LOCATION_SUCCESS,
    USER_UPDATE_LOCATION_FAIL,
    USER_UPDATE_PHONE_REQUEST,
    USER_UPDATE_PHONE_SUCCESS,
    USER_UPDATE_PHONE_FAIL,
    USER_VERIFY_UPDATE_PHONE_REQUEST,
    USER_VERIFY_UPDATE_PHONE_SUCCESS,
    USER_VERIFY_UPDATE_PHONE_FAIL,
    USER_SEND_VERIFICATION_EMAIL_REQUEST,
    USER_SEND_VERIFICATION_EMAIL_SUCCESS,
    USER_SEND_VERIFICATION_EMAIL_FAIL,
    USER_STATUS_UPDATE_REQUEST,
    USER_STATUS_UPDATE_SUCCESS,
    USER_STATUS_UPDATE_FAIL
} from "../constants/userConstants"


export const login = (email, password) => async (dispatch) => {
    try {
        dispatch({
            type: USER_LOGIN_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'application/json'
            },
        }

        const { data } = await axios.post(
            '/api/users/login',
            { email, password },
            config
        )

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data
        })

        localStorage.setItem('userInfo', JSON.stringify(data))

    } catch (error) {
        // if(error.response.status == 500) error.response.data.message = "Internal Server Error"
        dispatch({
                    type: USER_LOGIN_FAIL,
                    payload: 
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const googleAuth = ({ firstName, lastName, email, avatar }) => async (dispatch) => {
    try {
        dispatch({
            type: USER_GOOGLE_AUTH_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'application/json'
            },
        }

        const { data } = await axios.post(
            '/api/users/google-auth',
            { firstName, lastName, email, avatar },
            config
        )

        // dispatch({
        //     type: USER_GOOGLE_AUTH_SUCCESS,
        //     payload: data
        // })

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data
        })

        // dispatch({
        //     type: USER_DETAILS_SUCCESS,
        //     payload: data
        // })

        localStorage.setItem('userInfo', JSON.stringify(data))

    } catch (error) {
        // if(error.response.status == 500) error.response.data.message = "Internal Server Error"
        dispatch({
                    // type: USER_GOOGLE_AUTH_FAIL,
                    type: USER_LOGIN_FAIL,
                    payload: 
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}


export const logout = () => (dispatch) => {
    localStorage.removeItem('userInfo')
    dispatch({ type: USER_LOGOUT })
    dispatch({ type: USER_DETAILS_RESET })
    // dispatch({ type: ORDER_LIST_MY_RESET })
    // dispatch({ type: USER_LIST_RESET })
    dispatch({ type: CART_EMPTY })
    window.location.reload()
}

export const register = ( {firstName, lastName, email, password }) => async (dispatch) => {
    try {
        dispatch({
            type: USER_REGISTER_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'application/json'
            },
        }

        const { data } = await axios.post(
            '/api/users',
            { firstName, lastName, email, password },
            config
        )

        dispatch({
            type: USER_REGISTER_SUCCESS,
            payload: data
        })

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data
        })


        localStorage.setItem('userInfo', JSON.stringify(data))

    } catch (error) {
        dispatch({
                    type: USER_REGISTER_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const getUserDetails = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_DETAILS_REQUEST
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        // dispatch({
        //     type: USER_LOGIN_REQUEST
        // })

        let { data } = await axios.get('/api/users/profile', config)
        data.accessToken = userInfo.accessToken
        // console.log({data})
        // localStorage.setItem('userInfo', JSON.stringify(data))

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data
        })

         dispatch({
            type: USER_DETAILS_SUCCESS,
            payload: data
        })

        localStorage.setItem('userInfo', JSON.stringify(data))

    } catch (error) {
        dispatch({
                    type: USER_DETAILS_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const getUserWalletDetails = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_WALLET_DETAILS_REQUEST
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.get('/api/users/wallet', config)
        dispatch({
            type: USER_WALLET_DETAILS_SUCCESS,
            payload: data
        })
       
    } catch (error) {
        dispatch({
                    type: USER_WALLET_DETAILS_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const updateUserProfile = ({firstName, lastName, gender}) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_UPDATE_PROFILE_REQUEST,
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.put(
            '/api/users/profile',
            { firstName, lastName, gender },
            config
        )

        dispatch({
            type: USER_UPDATE_PROFILE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: USER_UPDATE_PROFILE_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const updateUserPassword = ({currentPassword, newPassword}) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_UPDATE_PASSWORD_REQUEST,
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.put(
            '/api/users/change-password',
            { currentPassword, newPassword },
            config
        )

        dispatch({
            type: USER_UPDATE_PASSWORD_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: USER_UPDATE_PASSWORD_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const verifyUserPhone = ({ phoneNumber, otp }) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_VERIFY_UPDATE_PHONE_REQUEST,
        })

        // const { userLogin: { userInfo }} = getState()

        // const config = {
        //     headers: {
        //         'Content-type': 'application/json',
        //         Authorization: `Bearer ${userInfo.accessToken}`
        //     },
        // }

        const { barberRegister: { accessToken }} = getState()
        console.log({accessToken})
        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            },
        }

        const { data } = await axios.put(
            '/api/users/verify-phone-update-code',
            { phoneNumber: "+" + phoneNumber, otp },
            config
        )

        dispatch({
            type: USER_VERIFY_UPDATE_PHONE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: USER_VERIFY_UPDATE_PHONE_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const updateUserPhone = ({ phoneNumber }) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_UPDATE_PHONE_REQUEST,
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.post(
            '/api/users/send-phone-update-code',
            { phoneNumber: "+" + phoneNumber },
            config
        )

        dispatch({
            type: USER_UPDATE_PHONE_SUCCESS,
            payload: data
        })

    } catch (error) {
        console.log({error})
        dispatch({
                    type: USER_UPDATE_PHONE_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const verifyUserPhoneUpdate = ({ phoneNumber, otp }) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_VERIFY_UPDATE_PHONE_REQUEST,
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.put(
            '/api/users/verify-phone-update-code',
            { phoneNumber: "+" + phoneNumber, otp },
            config
        )

        dispatch({
            type: USER_VERIFY_UPDATE_PHONE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: USER_VERIFY_UPDATE_PHONE_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const updateUserLocation = ({coordinates, fullAddress}) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_UPDATE_LOCATION_REQUEST,
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.put(
            '/api/users/location',
            { coordinates, completeAddress: fullAddress },
            config
        )

        dispatch({
            type: USER_UPDATE_LOCATION_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: USER_UPDATE_LOCATION_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}


export const listBarbers = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_BARBER_LIST_REQUEST,
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }
        console.log("List barbers ca")
        const { data } = await axios.get('/api/users/barbers', config)
        console.log({data})
        dispatch({
            type: USER_BARBER_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: USER_BARBER_LIST_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const listClients = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_CLIENT_LIST_REQUEST,
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }
        const { data } = await axios.get('/api/users/clients', config)

        dispatch({
            type: USER_CLIENT_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: USER_CLIENT_LIST_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const listUsers = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_LIST_REQUEST,
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }
        const { data } = await axios.get('/api/users', config)

        dispatch({
            type: USER_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: USER_LIST_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}


// export const deleteUser = (id) => async (dispatch, getState) => {
//     try {
//         dispatch({
//             type: USER_DELETE_REQUEST,
//         })

//         const { userLogin: { userInfo }} = getState()

//         const config = {
//             headers: {
//                 Authorization: `Bearer ${userInfo.token}`
//             },
//         }

//         await axios.delete(`/api/users/${id}`, config)

//         dispatch({ type: USER_DELETE_SUCCESS })

//     } catch (error) {
//         dispatch({
//                     type: USER_DELETE_FAIL,
//                     payload:
//                         error.response && error.response.data.message
//                             ? error.response.data.message
//                             : error.message
//                 })
//     }
// }




export const resetUserPassword = (email) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_PASSWORD_RESET_REQUEST,
        })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            },
        }

        const { data } = await axios.post(`/api/users/recover-password`, { email }, config)
        localStorage.setItem('resetEmail', JSON.stringify(email))
        dispatch({ type: USER_PASSWORD_RESET_SUCCESS, payload: data })

    } catch (error) {
        dispatch({
                    type: USER_PASSWORD_RESET_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const verifyResetPasswordCode = (code) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_VERIFY_RESET_CODE_REQUEST,
        })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            },
        }

        const email = JSON.parse(localStorage.getItem('resetEmail'))
        const { data } = await axios.post(`/api/users/verify-reset-code`, { email, otp: Number(code) }, config)
        dispatch({ type: USER_VERIFY_RESET_CODE_SUCCESS, payload: data })

    } catch (error) {
        dispatch({
                    type: USER_VERIFY_RESET_CODE_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}


export const savePassword = ({resetToken, password}) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_SAVE_PASSWORD_REQUEST,
        })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            },
        }

        const email = JSON.parse(localStorage.getItem('resetEmail'))
        const { data } = await axios.post(`/api/users/reset-password`, { email, resetToken, password }, config)
        dispatch({ type: USER_SAVE_PASSWORD_SUCCESS, payload: data })

    } catch (error) {
        dispatch({
                    type: USER_SAVE_PASSWORD_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}


export const sendVerificationEmail = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_SEND_VERIFICATION_EMAIL_REQUEST,
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.get(`/api/users/send-verification-email`, config)

        dispatch({ 
            type: USER_SEND_VERIFICATION_EMAIL_SUCCESS, 
            payload: data
         })

    } catch (error) {
        dispatch({
                    type: USER_SEND_VERIFICATION_EMAIL_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

// ADMIN
export const updateUserStatus = ({ _id, status }) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_STATUS_UPDATE_REQUEST,
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.patch(`/api/users/update-status/${ _id }`, { status }, config)

        dispatch({ type: USER_STATUS_UPDATE_SUCCESS, payload: data })

    } catch (error) {
        dispatch({
                    type: USER_STATUS_UPDATE_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}