import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { cartReducer } from './reducers/cartReducers'
import {
    userLoginReducer,
    userRegisterReducer,
    userDetailsReducer,
    userUpdateProfileReducer,
    userDeleteReducer,
    userGoogleAuthReducer,
    userWalletDetailsReducer,
    barberListReducer,
    clientListReducer,
    userPasswordResetReducer,
    userVerifyResetCodeReducer,
    userSavePasswordReducer,
    userUpdatePasswordReducer,
    userUpdateLocationReducer,
    userUpdatePhoneReducer,
    userVerifyPhoneReducer,
    userSendVerificationEmailReducer,
    userStatusUpdateReducer,
    userListReducer
} from './reducers/userReducers'

import {
    barberLoginReducer,
    barberRegisterReducer,
    barberVerifyReducer,
    // barberDetailsReducer,
    // barberUpdateProfileReducer,
    // barberListReducer,
    // barberDeleteReducer,
    // barberUpdateReducer
} from './reducers/barberReducers'

import {
    registerStoreReducer,
    scheduleAddReducer,
        scheduleDetailsReducer,
        serviceAddReducer,
        serviceCategoryAddReducer,
        serviceCategoryListReducer,
        serviceDeleteReducer,
        serviceDetailsReducer,
        serviceEditReducer,
        serviceListReducer,
        storeAndBarberDetailsReducer,
        storeDetailsReducer,
        storeDetailsUpdateReducer
} from './reducers/storeReducers'
import { 
    appointmentAddReducer,
    appointmentAddWalletReducer,
    appointmentBarberListReducer,
    appointmentBarberUpcomingListReducer,
    appointmentCancelAdminReducer,
    appointmentCancelReducer,
    appointmentClientUpcomingListReducer,
    appointmentDetailsReducer,
    appointmentListReducer,
    appointmentMarkCompleteBarberReducer,
    appointmentMarkCompleteClientReducer,
    appointmentRescheduleFeeReducer,
    appointmentRescheduleReducer,
    appointmentRescheduleWalletReducer,
    appointmentUserListReducer
} from './reducers/appointmentReducers';
import { transactionAdminListReducer, transactionUserListReducer, transactionVerifyReducer } from './reducers/transactionReducers';
import { notificationListReducer, notificationUnreadCountReducer } from './reducers/notificationReducers';
import { contactMessageAddReducer, contactMessageDetailsReducer, contactMessageListReducer, contactMessageUpdateReducer } from './reducers/contactReducers';
import { alertReducer } from './reducers/alertReducers';
import { tabReducer } from './reducers/localReducers';
import { adminDashboardDataReducer, adminWalletDetailsReducer } from './reducers/adminReducers';
import { ticketAddReducer, ticketDetailsReducer, ticketListReducer, ticketMarkOpenedReducer, ticketMarkResolvedReducer, ticketMessageAddReducer, ticketMessageListReducer, ticketUserListReducer } from './reducers/ticketReducers';
import { conversationAddReducer, conversationDetailsReducer } from './reducers/chatReducers';
import { feedbackAddReducer } from './reducers/feedbackReducers';
import { paypalDetailsReducer, paypalUpdateEmailReducer, paypalUpdateEmailVerifyReducer, withdrawAmountReducer, withdrawAmountVerifyReducer } from './reducers/walletReducers';

const reducer = combineReducers({
    userLogin: userLoginReducer,
    userGoogleAuth: userGoogleAuthReducer,
    userRegister: userRegisterReducer,
    userDetails: userDetailsReducer,
    userUpdatePassword: userUpdatePasswordReducer,
    userUpdatePhone: userUpdatePhoneReducer,
    userVerifyPhone: userVerifyPhoneReducer,
    userUpdateLocation: userUpdateLocationReducer,
    userWalletDetails: userWalletDetailsReducer,
    userUpdateProfile: userUpdateProfileReducer,
    userPasswordReset: userPasswordResetReducer,
    userVerifyResetCode: userVerifyResetCodeReducer,
    userSavePassword: userSavePasswordReducer,
    userSendVerificationEmail: userSendVerificationEmailReducer,
    barberList: barberListReducer,
    // clientList: clientListReducer,
    userList: userListReducer,
    userDelete: userDeleteReducer,
    notificationList: notificationListReducer,
    notificationUnreadCount: notificationUnreadCountReducer,
    cart: cartReducer,
    barberLogin: barberLoginReducer,
    barberRegister: barberRegisterReducer,
    barberVerify: barberVerifyReducer,
    registerStore: registerStoreReducer,
    serviceCategoryList: serviceCategoryListReducer,
    serviceCategoryAdd: serviceCategoryAddReducer,
    serviceDetails: serviceDetailsReducer,
    serviceAdd: serviceAddReducer,
    serviceEdit: serviceEditReducer,
    serviceList: serviceListReducer,
    serviceDelete: serviceDeleteReducer,
    scheduleDetails: scheduleDetailsReducer,
    scheduleAdd: scheduleAddReducer,
    storeDetails: storeDetailsReducer,
    appointmentAdd: appointmentAddReducer,
    appointmentAddWallet: appointmentAddWalletReducer,
    // appointmentAddWallet: appointmentAddWalletReducer,
    appointmentMarkCompleteClient: appointmentMarkCompleteClientReducer,
    appointmentMarkCompleteBarber: appointmentMarkCompleteBarberReducer,
    appointmentCancel: appointmentCancelReducer,
    appointmentRescheduleFee: appointmentRescheduleFeeReducer,
    appointmentReschedule: appointmentRescheduleReducer,
    appointmentRescheduleWallet: appointmentRescheduleWalletReducer,
    appointmentUserList: appointmentUserListReducer,
    appointmentClientUpcomingList: appointmentClientUpcomingListReducer,
    appointmentBarberList: appointmentBarberListReducer,
    appointmentBarberUpcomingList: appointmentBarberUpcomingListReducer,
    appointmentAdminList: appointmentListReducer,
    appointmentDetails: appointmentDetailsReducer,
    transactionUserList: transactionUserListReducer,
    transactionAdminList:  transactionAdminListReducer,
    alertData: alertReducer,
    tabReducer: tabReducer,
    adminDashboardData: adminDashboardDataReducer,
    adminWalletDetails: adminWalletDetailsReducer,
    ticketAdd: ticketAddReducer,
    ticketUserList: ticketUserListReducer,
    ticketList: ticketListReducer,
    ticketMessageAdd: ticketMessageAddReducer,
    ticketMessageList: ticketMessageListReducer,
    ticketDetails: ticketDetailsReducer,
    ticketMarkResolved: ticketMarkResolvedReducer,
    ticketMarkOpened: ticketMarkOpenedReducer,
    conversationAdd: conversationAddReducer,
    conversationDetails: conversationDetailsReducer,
    feedbackAdd: feedbackAddReducer,
    paypalDetails: paypalDetailsReducer,
    paypalUpdateEmail:  paypalUpdateEmailReducer,
    paypalUpdateEmailVerify:  paypalUpdateEmailVerifyReducer,
    withdrawAmount: withdrawAmountReducer,
    withdrawAmountVerify: withdrawAmountVerifyReducer, 
    contactMessageAdd: contactMessageAddReducer, 
    // ADMIN
    userStatusUpdate: userStatusUpdateReducer,
    storeDetailsUpdate: storeDetailsUpdateReducer,
    contactMessageList: contactMessageListReducer,
    contactMessageDetails: contactMessageDetailsReducer,
    contactMessageUpdate: contactMessageUpdateReducer,
    transactionVerify: transactionVerifyReducer,
    storeAndBarberDetails: storeAndBarberDetailsReducer,
    appointmentCancelAdmin: appointmentCancelAdminReducer
})

const cartItemsFromStorage = localStorage.getItem('cartItems')
    ? JSON.parse(localStorage.getItem('cartItems'))
    : []

const userInfoFromStorage = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null

// const paymentMethodFromStorage = localStorage.getItem('paymentMethod')
//     ? JSON.parse(localStorage.getItem('paymentMethod'))
//     : null

    const initialState = {
        cart: {
            cartItems: cartItemsFromStorage,
            // paymentMethod: paymentMethodFromStorage
        },
        userLogin: { userInfo: userInfoFromStorage },
        // tab: 0
        // alert: { open: false, severity: 'info', message: ''}
    }

const middleware = [thunk]

const store = createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
)

export default store