import {
    CONTACT_MESSAGE_ADD_FAIL,
    CONTACT_MESSAGE_ADD_REQUEST, CONTACT_MESSAGE_ADD_RESET, CONTACT_MESSAGE_ADD_SUCCESS, CONTACT_MESSAGE_DETAILS_FAIL, CONTACT_MESSAGE_DETAILS_REQUEST, CONTACT_MESSAGE_DETAILS_SUCCESS, CONTACT_MESSAGE_LIST_FAIL, CONTACT_MESSAGE_LIST_REQUEST, CONTACT_MESSAGE_LIST_RESET, CONTACT_MESSAGE_LIST_SUCCESS, CONTACT_MESSAGE_UPDATE_FAIL, CONTACT_MESSAGE_UPDATE_REQUEST, CONTACT_MESSAGE_UPDATE_RESET, CONTACT_MESSAGE_UPDATE_SUCCESS
} from '../constants/contactConstants'

export const contactMessageAddReducer = (state = {}, action) => {
    switch (action.type) {
        case CONTACT_MESSAGE_ADD_REQUEST:
            return { loading: true}
        case CONTACT_MESSAGE_ADD_SUCCESS:
            return { loading: false, success: true  }
        case CONTACT_MESSAGE_ADD_FAIL:
            return { loading: false, error: action.payload }
        case CONTACT_MESSAGE_ADD_RESET:
            return { success: false }
        default:
            return state
    }
}

export const contactMessageListReducer = (state = { messages: []  }, action) => {
    switch (action.type) {
        case CONTACT_MESSAGE_LIST_REQUEST:
            return { loading: true}
        case CONTACT_MESSAGE_LIST_SUCCESS:
            return { loading: false, messages: action.payload  }
        case CONTACT_MESSAGE_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const contactMessageDetailsReducer = (state = { messageDetails: {} }, action) => {
    switch (action.type) {
        case CONTACT_MESSAGE_DETAILS_REQUEST:
            return { loading: true}
        case CONTACT_MESSAGE_DETAILS_SUCCESS:
            return { loading: false, messageDetails: action.payload  }
        case CONTACT_MESSAGE_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}


export const contactMessageUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case CONTACT_MESSAGE_UPDATE_REQUEST:
            return { loading: true}
        case CONTACT_MESSAGE_UPDATE_SUCCESS:
            return { loading: false, success: true  }
        case CONTACT_MESSAGE_UPDATE_FAIL:
            return { loading: false, error: action.payload }
        case CONTACT_MESSAGE_UPDATE_RESET:
            return { success: false }
        default:
            return state
    }
}