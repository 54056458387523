import React from 'react'
import { useTheme } from '@mui/material/styles'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Title from './Title';

const BiaxialBarChart = ({title, data, key1, key2}) => {
   const theme = useTheme()
    return (
        <div style={{ width: '100%', height: 320, paddingBottom: '40px' }}>
        <Title heading={title}/>
        <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 20,
            // right: 30,
            // left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis yAxisId="right" orientation="left" stroke={theme.palette.custom.lightGreen} />
          <YAxis yAxisId="left" orientation="right" stroke={theme.palette.custom.lightPurple}  />
          <Tooltip />
          <Legend />
          <Bar yAxisId="left" dataKey={key2} fill={theme.palette.custom.lightGreen}/>
          <Bar yAxisId="right" dataKey={key1} fill={theme.palette.custom.lightPurple} />
        </BarChart>
      </ResponsiveContainer>
        </div>
  )
}

export default BiaxialBarChart