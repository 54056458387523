import { Button, Grid, TextField } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/ui/Loader';
import SearchBox from '../../components/ui/SearchBox/SearchBox';
import { getUserDetails, updateUserLocation } from '../../redux/actions/userActions';
import { UPDATE_ALERT } from '../../redux/constants/alertConstants';
import { USER_UPDATE_LOCATION_RESET } from '../../redux/constants/userConstants';
import Map from './Map/Map';

const EditLocation = () => {
  const dispatch = useDispatch()
  const [address, setAddress] = useState('')
  const [fullAddress, setFullAddress] = useState('')
  const [bounds, setBounds] = useState({})
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null})
  const [childClicked, setChildClicked] = useState(null)


  const getBrowserLocation = () => {
    if ("geolocation" in navigator) {
      console.log("Available")
  } else {
      console.log("Not Available")
  }
    setAddress('')
    navigator.geolocation.getCurrentPosition(async ({ coords: {latitude, longitude} }) => {
      const address = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=`+ latitude + "," + longitude + "&sensor=true&key=AIzaSyB3ezN_I7Dj2LPapIciwQiOGQWudADnl2A")
      console.log({address})
      setAddress(address?.data?.results[0].formatted_address)
      setCoordinates({ lat: latitude, lng: longitude })
    })
  }

  const userLogin = useSelector(state => state.userLogin)
  const { loading: loadingUser, error: errorUser, userInfo: user } = userLogin

  const userUpdateLocation = useSelector(state => state.userUpdateLocation)
  const { loading: loadingLocation, error: errorLocation, success: successLocation } = userUpdateLocation

  useEffect(() => {
    if(user) {
      setFullAddress(user?.addressDetails?.completeAddress)
      setCoordinates(user?.addressDetails?.coordinates)
    }
  }, [])

useEffect(() => {
  //update sucess
    if (successLocation) {
      dispatch({ 
        type: UPDATE_ALERT, 
        payload: {
          open: true, 
          severity: 'success', 
          message: 'Location updated successfully.' 
        } 
      })
      dispatch(getUserDetails())
      dispatch({ type: USER_UPDATE_LOCATION_RESET })
  }
    // user location error
     if(errorUser) {
      dispatch({ 
        type: UPDATE_ALERT, 
        payload: {
            open: true, 
            severity: 'error', 
            message: "Error updating location."
        } 
      })
    }
},[successLocation, errorLocation]) 

  // const coordinatesChangeHandler = ({lat, lng}) => {
  //   setCoordinates()
  // }

  const submitHandler = (e) => {
      e.preventDefault();
     if (coordinates?.lat == 0 || coordinates?.lng == 0){
        dispatch({ 
          type: UPDATE_ALERT, 
          payload: {
              open: true, 
              severity: 'error', 
              message: 'Please select your location on map.' 
          } 
      })
     }
     else if (!fullAddress) {
          dispatch({ 
            type: UPDATE_ALERT, 
            payload: {
                open: true, 
                severity: 'error', 
                message: 'Please fill all requried fields.' 
            } 
        })
    } else {
        dispatch(updateUserLocation({ coordinates, fullAddress }))
    }    
  }

  return (
    <Box component="form" onSubmit={submitHandler} sx={{ maxWidth: "756px", margin: '0 auto', mt: 2 }}>
    {(loadingUser) 
    ?
     <Loader />
    :
    <>
      <Map
        coordinates={coordinates}
        setCoordinates={setCoordinates}
        // setBounds={setBounds}
        // places={filteredPlaces.length ? filteredPlaces : places }   
        // places={ (rating > 0) ? filteredPlaces : places }   
        setChildClicked={setChildClicked}
      />
      <Grid spacing={2}>
        <Grid item xs={12} mt={2} mb={4}>
          <SearchBox
            setCoordinates={setCoordinates} 
            getBrowserLocation={getBrowserLocation} 
            address={address}
            setAddress={setAddress}
            />
        </Grid>
        <Grid item xs={12} mb={1}>
            <TextField
              sx={{
                
              }}
              name="fullAddress"
              label="Full Address"
              placeholder='H No Abc, XYZ Street, Sydney, Australia'
              variant="outlined"
              type="text"
              required
              fullWidth
              value={fullAddress}
              onChange={(e) => setFullAddress(e.target.value)}
            />
          </Grid>
      </Grid>
      <Box display="flex" justifyContent="flex-end">
        <Button
          type="submit"
          variant="contained"
          size="large"
          style={{margin: '16px 0 8px 0'}}
        >
          Save Changes
        </Button>
      </Box>
    </>
    }
    </Box>
  )
}

export default EditLocation



