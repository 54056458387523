import React, { useState, useEffect, createRef } from 'react';
import { Box, CircularProgress, Grid, Typography, InputLabel, MenuItem, FormControl, Select, Container } from '@mui/material';

import PlaceDetails from '../PlaceDetails/PlaceDetails';
import BackDropLoader from '../../../../components/ui/BackDropLoader'
// import useStyles from './styles.js';

const List = ({ isLoading, places, childClicked }) => {

  // const classes = useStyles();  
  // const [elRefs, setElRefs] = useState([]);

  // useEffect(() => {
  //   // For Scrolling to clicked (on Map) element in Barbers List
  //   const refs = Array(places?.length).fill().map( (_, i) => elRefs[i] || createRef() );

  //   setElRefs(refs);
  // }, [places]);

  return (
    (
    <Box sx={{maxWidth: '1024px', margin: '0 auto', padding: {xs: '18px', md: '0'}}}>
      {isLoading ? (
          <BackDropLoader />
      ) : (
        // <>
        //     <FormControl sx={{my: 2, mr: 2, minWidth: 80 }} size="small">
        //       <InputLabel id="rating">Rating</InputLabel>
        //       <Select id="rating" label="Rating" value={rating} onChange={(e) => setRating(e.target.value)}>
        //         <MenuItem value={0}>All</MenuItem>
        //         <MenuItem value={3}>3.0 +</MenuItem>
        //         <MenuItem value={4}>4.0 +</MenuItem>
        //         <MenuItem value={4.5}>4.5 +</MenuItem>
        //       </Select>
        //     </FormControl>
        //     <FormControl sx={{my: 2, mr: 2, minWidth: 120 }} size="small">
        //       <InputLabel id="For">Specialist For</InputLabel>
        //       <Select id="specialistFor" label="Specialist For" value={type} onChange={(e) => setType(e.target.value)}>
        //         <MenuItem value="both">Both</MenuItem>
        //         <MenuItem value="men">Men</MenuItem>
        //         <MenuItem value="women">Women</MenuItem>
        //       </Select>
        //     </FormControl>
          <Grid container spacing={3} >
            {places?.map((place, i) => (
              <Grid key={i} item xs={12} md={6} lg={3}>
                <PlaceDetails 
                  place={place} 
                  // selected={Number(childClicked) === i}
                  // refProp={elRefs[i]}
                />
              </Grid>
            ))}
          </Grid>
        // </>
      )}
    </Box>
  ));
}

export default List