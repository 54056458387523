import { useEffect, useMemo, useState } from 'react';
import { Avatar, Box, Menu, Rating, Stack, Typography } from '@mui/material';
import { DataGrid, gridClasses, GridToolbar } from '@mui/x-data-grid';
import moment from 'moment';
import { grey } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import { listBarbers } from '../../../redux/actions/userActions';
import BackDropLoader from '../../../components/ui/BackDropLoader';
import TextButton from '../../../components/ui/TextButton';
import BarbersActions from './BarbersActions'

const Barbers = () => {
    const dispatch = useDispatch()
    const barberList = useSelector(state => state.barberList)
    const { loading, barbers } = barberList

  const [pageSize, setPageSize] = useState(10);
  const [rowId, setRowId] = useState(null);

  const NoRowsOverlay = () => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        No barbers available
      </Stack>
    );
  }

  useEffect(() => {
    dispatch(listBarbers())
  }, []);

  const columns = useMemo(
    () => [
      {
        field: 'avatar',
        headerName: 'Avatar',
        width: 60,
        renderCell: (params) => <Avatar src={params.row?.owner?.avatar} />,
        sortable: false,
        filterable: false,
      },
      { 
        field: 'storeName', 
        headerName: 'Store Name', 
        width: 150, 
        renderCell: (params) => params.row.name,
      },
      {
        field: 'services',
        headerName: 'Services',
        width: 100,
        valueGetter: (params) => {
          let len;
          if (params.row.services) {
              if (params.row.services) {
              len = params.row.services.length
              }
          } else {
              len = 0
          }
          return len;
          }
      },
    { 
      field: 'rating', 
      headerName: 'Rating', 
      width: 160, 
      renderCell: (params) => <Rating sx={{my: 2}}  name="half-rating-read" value={params.row.rating} precision={0.5} readOnly />
    },
      { 
        field: 'experience', 
        headerName: 'Experience (y)', 
        width: 100, 
        editable: true
    },
    
    {
      field: 'forMen',
      headerName: 'For Men',
      width: 100,
      type: 'boolean',
      editable: true
    },
    {
      field: 'forWomen',
      headerName: 'For Women',
      width: 100,
      type: 'boolean',
      editable: true
    },
    { 
      field: 'address', 
      headerName: 'Location', 
      width: 300, 
      sortable: false,
      valueGetter: (params) => {
        let address
        if (params.row.workArea) {
            if (params.row.workArea.address) {
            address = params.row.workArea.address
            }
        } else {
            address = ""
        }
        return address;
        }
  },
    {
        field: 'isVerified',
        headerName: 'Verified',
        width: 100,
        filterable: false,
        type: 'boolean',
        editable: true
      },
      {
        field: 'createdAt',
        headerName: 'Joining Date',
        width: 200,
        renderCell: (params) =>
        moment(params.row.createdAt).format('DD-MM-YYYY HH:MM:SS'),
      },
      {
      field: 'status',
      headerName: 'Status',
      width: 100,
      filterable: false,
      type: 'singleSelect',
      valueOptions: ["active", "inactive", "blocked"],
      editable: true,
      renderCell: (params) => {
        let status, color="primary", disabled=false
        if (params.row.status === "active") {
          status = "Active"
          color = "success"
        }
        else if (params.row.status === "inactive") {
          status = "Inactive"
          color = "primary"
          disabled=true
        } 
        else if (params.row.status === "blocked")  {
          status = "Blocked"
          color = "error"
        }
        // else if (params.row.status === "onhold") {
        //   status = "Onhold"
        //   color="warning"
        // } 
        else status = ""
        return (
          <TextButton 
          variant="outlined" 
          size="small"
          color={color}
          disabled={disabled}
          >
              {status}
          </TextButton>
          // status
        )
      }
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 200,
        type: 'actions',
        renderCell: (params) => (
          <BarbersActions {...{ params, rowId, setRowId }} />
        ),
      },
    ],
    [rowId]
  );

  return (
    <>
    {
        loading 
        ? <BackDropLoader />
        :
        <Box
        sx={{
            height: '70vh',
            width: '100%',
        }}
        >
        <Typography
            variant="h4"
            component="h4"
            sx={{ textAlign: 'center', mt: 3, mb: 3 }}
        >
            Barbers List
        </Typography>
        <DataGrid
            columns={columns}
            rows={barbers}
            getRowId={(row) => row._id}
            rowsPerPageOptions={[5, 10, 20]}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            components={{
              NoRowsOverlay,
              Toolbar: GridToolbar
            }}
            getRowSpacing={(params) => ({
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
            })}
            sx={{
            [`& .${gridClasses.row}`]: {
                bgcolor: (theme) =>
                theme.palette.mode === 'light' ? grey[200] : grey[900],
            },
            }}
            onCellEditCommit={(params) => setRowId(params.id)}
        />
        </Box>
    }
    </>
  );
};

export default Barbers;

