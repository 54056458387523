import { useEffect, useMemo, useState } from 'react';
import { Avatar, Box, Button, Typography, Stack } from '@mui/material';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { grey } from '@mui/material/colors';
import { Link } from 'react-router-dom';
import moment from 'moment';
import BackDropLoader from '../../../components/ui/BackDropLoader';
import TextButton from '../../../components/ui/TextButton';
import { useSelector } from 'react-redux';
import ROLES from '../../../constants/roleConstants';

const TicketsTable = ({tickets}) => {
  const [pageSize, setPageSize] = useState(10);
  const [rowId, setRowId] = useState(null);

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const NoRowsOverlay = () => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        No tickets available
      </Stack>
    );
  }
  const columns = useMemo(
    () => [
      {
        field: 'ref',
        headerName: 'Reference',
        width: 100,
        valueGetter: (params) => params.row.id,
        sortable: false,
        filterable: false
      },
    { 
    field: 'subject',
    headerName: 'Subject', 
    width: 440,
    valueGetter: (params) => params.row.subject,
    sortable: false,
    },
    // {
    //   field: 'created',
    //   headerName: 'Date Created',
    //   width: 180,
    //   renderCell: (params) =>
    //   moment(params.row.createdAt).format('YYYY-MM-DD  h:mm a')
    // },
    {
      field: 'createdBy',
      headerName: 'Created By',
      width: 180,
      // sortable: false,
      filterable: false,
      renderCell: (params) => {
        let creator
        if (params.row.createdBy) {
          if(params.row.createdBy === "support") {
            creator = "Support"
          }
          else {
            creator = (userInfo?.role === ROLES.ADMIN) ? "Support" : userInfo?.firstName + " " + userInfo?.lastName
          } 
        }
        else{
         creator = ""
        }
        return (
          <Box sx={{ display: "flex", flexDirection: "column"}}>
          <Typography
            fontWeight="bold"
            variant="body2"
          >
            {creator}
          </Typography>
          <Typography
            variant="body2"
          >
            {moment(params.row.updatedAt).format('YYYY-MM-DD  h:mm a')}
          </Typography>
          </Box>
        )
      }
    },
    {
      field: 'lastActionB',
      headerName: 'Last Action By',
      width: 180,
      sortable: false,
      filterable: false,
      renderCell: (params) =>
      <Box sx={{ display: "flex", flexDirection: "column"}}>
        <Typography
          fontWeight="bold"
          variant="body2"
        >
          {params.row.lastActionBy === 'user' ? userInfo?.firstName + " " + userInfo?.lastName : 'Support'}
        </Typography>
        <Typography
          variant="body2"
        >
          {moment(params.row.updatedAt).format('YYYY-MM-DD  h:mm a')}
        </Typography>
        </Box>
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 140,
      filterable: false,
      // sortable: false,
      renderCell: (params) => {
        let status, color="primary", disabled=false
        if (params.row.status === "opened") {
          status = "Opened"
          color = "primary"
        }
        else if (params.row.status === "resolved") {
          status = "Resolved"
          color = "success"
        } 
        else status = ""
        return (
          <TextButton 
          variant="outlined" 
          size="small"
          color={color}
          disabled={disabled}
          >
              {status}
          </TextButton>
          // status
        )
      }
      },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 180,
      type: 'actions',
      renderCell: (params) => (
          <Link to={`/support/chat/${params.row._id}`} style={{textDecoration: 'none'}}>
              <Button variant='contained' size="small">View</Button>
          </Link>
      ),
    },
    ],
    [rowId]
  );

  return (
    <DataGrid
            columns={columns}
            rows={tickets}
            getRowId={(row) => row._id}
            rowsPerPageOptions={[5, 10, 20]}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            components={{ NoRowsOverlay }}
            getRowSpacing={(params) => ({
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
            })}
            sx={{
            [`& .${gridClasses.row}`]: {
                bgcolor: (theme) =>
                theme.palette.mode === 'light' ? grey[200] : grey[900],
            },
            }}
            onCellEditCommit={(params) => setRowId(params.id)}
        />
  )
}

export default TicketsTable