import * as React from 'react'
import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer, BarChart, Tooltip, CartesianGrid } from 'recharts'
import { useTheme } from '@mui/material/styles'
import Title from './Title';

// const theme = useTheme()

const Chart = ({title, data, xAxisKey, lineKey}) => {
  console.log({xAxisKey, lineKey})
  const theme = useTheme();

  return (
    <div style={{ width: '100%', height: 300, paddingBottom: '40px' }}>
      <Title heading={title}/>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            // left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey={xAxisKey}
            // stroke={theme.palette.success.main}
          />
          <YAxis
            // stroke={theme.palette.primary.main}
          >
            {/* <Label
              // angle={270}
              position="left"
              style={{
                textAnchor: 'middle',
                // fill: `${theme.palette.primary.main}`,
              }}
            >
              Clients
            </Label> */}
          </YAxis>
          <Tooltip cursor={false} />
          <Line
            isAnimationActive={false}
            type="monotone"
            dataKey={lineKey}
            stroke={theme.palette.success.light}
            dot={true}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default Chart