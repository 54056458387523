export const APPOINTMENT_ADD_REQUEST = 'APPOINTMENT_ADD_REQUEST'
export const APPOINTMENT_ADD_SUCCESS = 'APPOINTMENT_ADD_SUCCESS'
export const APPOINTMENT_ADD_FAIL = 'APPOINTMENT_ADD_FAIL'
export const APPOINTMENT_ADD_RESET = 'APPOINTMENT_ADD_RESET'

export const APPOINTMENT_ADD_WALLET_REQUEST = 'APPOINTMENT_ADD_WALLET_REQUEST'
export const APPOINTMENT_ADD_WALLET_SUCCESS = 'APPOINTMENT_ADD_WALLET_SUCCESS'
export const APPOINTMENT_ADD_WALLET_FAIL = 'APPOINTMENT_ADD_WALLET_FAIL'
export const APPOINTMENT_ADD_WALLET_RESET = 'APPOINTMENT_ADD_WALLET_RESET'

export const APPOINTMENT_USER_LIST_REQUEST = 'APPOINTMENT_USER_LIST_REQUEST'
export const APPOINTMENT_USER_LIST_SUCCESS = 'APPOINTMENT_USER_LIST_SUCCESS'
export const APPOINTMENT_USER_LIST_FAIL = 'APPOINTMENT_USER_LIST_FAIL'

export const APPOINTMENT_CLIENT_UPCOMING_LIST_REQUEST = 'APPOINTMENT_CLIENT_UPCOMING_LIST_REQUEST'
export const APPOINTMENT_CLIENT_UPCOMING_LIST_SUCCESS = 'APPOINTMENT_CLIENT_UPCOMING_LIST_SUCCESS'
export const APPOINTMENT_CLIENT_UPCOMING_LIST_FAIL = 'APPOINTMENT_CLIENT_UPCOMING_LIST_FAIL'

export const APPOINTMENT_BARBER_LIST_REQUEST = 'APPOINTMENT_BARBER_LIST_REQUEST'
export const APPOINTMENT_BARBER_LIST_SUCCESS = 'APPOINTMENT_BARBER_LIST_SUCCESS'
export const APPOINTMENT_BARBER_LIST_FAIL = 'APPOINTMENT_BARBER_LIST_FAIL'

export const APPOINTMENT_BARBER_UPCOMING_LIST_REQUEST = 'APPOINTMENT_BARBER_UPCOMING_LIST_REQUEST'
export const APPOINTMENT_BARBER_UPCOMING_LIST_SUCCESS = 'APPOINTMENT_BARBER_UPCOMING_LIST_SUCCESS'
export const APPOINTMENT_BARBER_UPCOMING_LIST_FAIL = 'APPOINTMENT_BARBER_UPCOMING_LIST_FAIL'

export const APPOINTMENT_LIST_REQUEST = 'APPOINTMENT_LIST_REQUEST'
export const APPOINTMENT_LIST_SUCCESS = 'APPOINTMENT_LIST_SUCCESS'
export const APPOINTMENT_LIST_FAIL = 'APPOINTMENT_LIST_FAIL'

export const APPOINTMENT_DETAILS_REQUEST = 'APPOINTMENT_DETAILS_REQUEST'
export const APPOINTMENT_DETAILS_SUCCESS = 'APPOINTMENT_DETAILS_SUCCESS'
export const APPOINTMENT_DETAILS_FAIL = 'APPOINTMENT_DETAILS_FAIL'

export const APPOINTMENT_CANCEL_FEE_REQUEST = 'APPOINTMENT_CANCEL_FEE_REQUEST'
export const APPOINTMENT_CANCEL_FEE_SUCCESS = 'APPOINTMENT_CANCEL_FEE_SUCCESS'
export const APPOINTMENT_CANCEL_FEE_FAIL = 'APPOINTMENT_CANCEL_FEE_FAIL'

export const APPOINTMENT_CANCEL_REQUEST = 'APPOINTMENT_CANCEL_REQUEST'
export const APPOINTMENT_CANCEL_SUCCESS = 'APPOINTMENT_CANCEL_SUCCESS'
export const APPOINTMENT_CANCEL_FAIL = 'APPOINTMENT_CANCEL_FAIL'
export const APPOINTMENT_CANCEL_RESET = 'APPOINTMENT_CANCEL_FAIL'

export const APPOINTMENT_RESCHEDULE_FEE_REQUEST = 'APPOINTMENT_RESCHEDULE_FEE_REQUEST'
export const APPOINTMENT_RESCHEDULE_FEE_SUCCESS = 'APPOINTMENT_RESCHEDULE_FEE_SUCCESS'
export const APPOINTMENT_RESCHEDULE_FEE_FAIL = 'APPOINTMENT_RESCHEDULE_FEE_FAIL'

export const APPOINTMENT_RESCHEDULE_REQUEST = 'APPOINTMENT_RESCHEDULE_REQUEST'
export const APPOINTMENT_RESCHEDULE_SUCCESS = 'APPOINTMENT_RESCHEDULE_SUCCESS'
export const APPOINTMENT_RESCHEDULE_FAIL = 'APPOINTMENT_RESCHEDULE_FAIL'
export const APPOINTMENT_RESCHEDULE_RESET = 'APPOINTMENT_RESCHEDULE_FAIL'

export const APPOINTMENT_RESCHEDULE_WALLET_REQUEST = 'APPOINTMENT_RESCHEDULE_WALLET_REQUEST'
export const APPOINTMENT_RESCHEDULE_WALLET_SUCCESS = 'APPOINTMENT_RESCHEDULE_WALLET_SUCCESS'
export const APPOINTMENT_RESCHEDULE_WALLET_FAIL = 'APPOINTMENT_RESCHEDULE_WALLET_FAIL'
export const APPOINTMENT_RESCHEDULE_WALLET_RESET = 'APPOINTMENT_RESCHEDULE_WALLET_FAIL'

export const APPOINTMENT_MARK_COMPLETED_CLIENT_REQUEST = 'APPOINTMENT_MARK_COMPLETED_CLIENT_REQUEST'
export const APPOINTMENT_MARK_COMPLETED_CLIENT_SUCCESS = 'APPOINTMENT_MARK_COMPLETED_CLIENT_SUCCESS'
export const APPOINTMENT_MARK_COMPLETED_CLIENT_FAIL = 'APPOINTMENT_MARK_COMPLETED_CLIENT_FAIL'
export const APPOINTMENT_MARK_COMPLETED_CLIENT_RESET = 'APPOINTMENT_COMPLETED_CLIENT_FAIL'

export const APPOINTMENT_MARK_COMPLETED_BARBER_REQUEST = 'APPOINTMENT_MARK_COMPLETED_BARBER_REQUEST'
export const APPOINTMENT_MARK_COMPLETED_BARBER_SUCCESS = 'APPOINTMENT_MARK_COMPLETED_BARBER_SUCCESS'
export const APPOINTMENT_MARK_COMPLETED_BARBER_FAIL = 'APPOINTMENT_MARK_COMPLETED_BARBER_FAIL'
export const APPOINTMENT_MARK_COMPLETED_BARBER_RESET = 'APPOINTMENT_COMPLETED_BARBER_FAIL'

// Admin
export const APPOINTMENT_CANCEL_ADMIN_REQUEST = 'APPOINTMENT_CANCEL_ADMIN_REQUEST'
export const APPOINTMENT_CANCEL_ADMIN_SUCCESS = 'APPOINTMENT_CANCEL_ADMIN_SUCCESS'
export const APPOINTMENT_CANCEL_ADMIN_FAIL = 'APPOINTMENT_CANCEL_ADMIN_FAIL'
export const APPOINTMENT_CANCEL_ADMIN_RESET = 'APPOINTMENT_CANCEL_ADMIN_FAIL'