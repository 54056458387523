// import {
//     Box,
//     Button,
//     Container,
//     Stack,
//     Step,
//     StepButton,
//     Stepper,
//     Typography,
//   } from '@mui/material';
//   import React, { useState } from 'react';
// //   import AddDetails from './addDetails/AddDetails';
// //   import AddImages from './addImages/AddImages';
// //   import AddLocation from './addLocation/AddLocation';
  
//   const NonLinearStepper = ({
//     StepOneLabel,
//     StepOneContent,
//     StepTwoLabel,
//     StepTwoContent,
//     StepThreeLabel,
//     StepThreeContent,
//     StepFourLabel,
//     StepFourContent,
//     allowNextStep=false
//   }) => {
//     console.log({allowNextStep})
//     const [activeStep, setActiveStep] = useState(1);
//     const [steps, setSteps] = useState([
//       // { label: 'Label 1', completed: false },
//       // { label: 'Label 2', completed: false },
//       { label: StepOneLabel, completed: false },
//       { label: StepTwoLabel, completed: false },
//       ... StepThreeLabel ? [{ label: StepThreeLabel, completed: false }] : [],
//       ...StepFourLabel ? [{ label: StepFourLabel, completed: false }] : []
//     ]);
//     const handleNext = () => {
//       if (activeStep < steps.length - 1) {
//         setActiveStep((activeStep) => activeStep + 1);
//       } else {
//         const stepIndex = findUnfinished();
//         setActiveStep(stepIndex);
//       }
//     };
//     const checkDisabled = () => {
//       if (!allowNextStep.allowNextStep) return false;
//       if (activeStep < steps.length - 1) return false;
//       const index = findUnfinished();
//       if (index !== -1) return false;
//       return true;
//     };
//     const findUnfinished = () => {
//       return steps.findIndex((step) => !step.completed);
//     };
//     return (
//       <Container sx={{ my: 4 }}>
//         <Stepper
//           alternativeLabel
//           nonLinear
//           activeStep={activeStep}
//           sx={{ mb: 3 }}
//         >
//           {steps.map((step, index) => (
//             <Step key={step.label} completed={step.completed}>
//               <StepButton onClick={() => setActiveStep(index)}>
//                 {step.label}
//               </StepButton>
//             </Step>
//           ))}
//         </Stepper>
        // <Box>
        //   {
        //     {
        //       0: <StepOneContent />,
        //       1: <StepTwoContent />,
        //       2: <StepThreeContent />,
        //       3: <StepFourContent />
        //     //   0: {StepOneContent },
        //     //   1: {StepTwoContent },
        //     //   2: {StepThreeContent },
        //     }[activeStep]
        //   }
        // </Box>
        // <Stack
        //   direction="row"
        //   sx={{ pt: 2, pb: 7, justifyContent: 'space-around' }}
        // >
        //   <Button
        //     color="inherit"
        //     disabled={!activeStep}
        //     onClick={() => setActiveStep((activeStep) => activeStep - 1)}
        //   >
        //     Back
        //   </Button>
        //   <Button disabled={checkDisabled()} onClick={handleNext}>
        //     Next
        //   </Button>
        // </Stack>
//       </Container>
//     );
//   };
  
//   export default NonLinearStepper;

import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux'
import {
    Stepper, Step, StepLabel,Button, Container, 
    Grid, Box, Typography, Card, StepButton
 }from "@mui/material";
 
import { styled } from "@mui/material/styles"
import AnimatedPage from "../Layout/AnimatedPage";
const StyledCard = styled(Card)(({ theme }) => ({
  margin: 30,
  padding: 16
}))

export default function App({
      StepOneLabel,
      StepOneContent,
      StepTwoLabel,
      StepTwoContent,
      StepThreeLabel,
      StepThreeContent,
      StepFourLabel,
      StepFourContent,
    }) {
  const [activeStep, setActiveStep] = React.useState(0);
  // const [nextDayDate, setNextDayDate] = useState(null)
  const [steps, setSteps] = useState([
          { label: StepOneLabel, completed: false },
          { label: StepTwoLabel, completed: false },
          ... StepThreeLabel ? [{ label: StepThreeLabel, completed: false }] : [],
          ...StepFourLabel ? [{ label: StepFourLabel, completed: false }] : []
        ]);


  const handleNext = () => {
    const markStepAsComplete = (index) => {
      setSteps((steps) => {
        steps[index].completed = true
        return [...steps]
      })
    }
    markStepAsComplete(activeStep)
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };
  
  const handleBack = () => {
    const markStepAsIncomplete = (index) => {
      setSteps((steps) => {
        steps[index].completed = false
        return [...steps]
      })
    }
    markStepAsIncomplete(activeStep - 1)
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

 return (
   <AnimatedPage>
    <Container sx={{
        // background: "#000",
        // display: "flex",
        // justifyContent: "center",
        // alignContent: "space-between",
        mt: 4,
      }}
    >
                <Stepper
                  alternativeLabel
                  activeStep={activeStep}
                  sx={{ mb: 8 }}
                >
                {steps.map((step, index) => (
                    <Step key={step.label} completed={step.completed}>
                      <StepButton onClick={() => setActiveStep(index)}>
                        {step.label}
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
                <div>
                    {/* {activeStep === steps.length ? (
                    <div>
                        All steps completed
                        <Button onClick={handleReset}>Reset</Button>
                    </div>
                    ) : ( */}
                      <Box mt={5} >
                      {
                        {
                          0: <StepOneContent 
                              steps={steps}
                             activeStep={activeStep}
                              handleBack={handleBack}
                              handleNext={handleNext}  
                          />,
                          1: <StepTwoContent 
                             activeStep={activeStep}
                              handleBack={handleBack}
                              handleNext={handleNext}  
                          />,
                          2: <StepThreeContent 
                              activeStep={activeStep}
                              handleBack={handleBack}
                              handleNext={handleNext}  
                          />,
                          3: <StepFourContent 
                              activeStep={activeStep}
                              handleBack={handleBack}
                              handleNext={handleNext}  
                          />
                        //   0: {StepOneContent },
                        //   1: {StepTwoContent },
                        //   2: {StepThreeContent },
                        }[activeStep]
                      }
                    </Box>
                  {/* )}  */}
                </div>                
    </Container>
  </AnimatedPage>
  );
}