import {
    ADMIN_DASHBOARD_DATA_FAIL,
    ADMIN_DASHBOARD_DATA_REQUEST, ADMIN_DASHBOARD_DATA_SUCCESS, ADMIN_WALLET_DATA_FAIL, ADMIN_WALLET_DATA_REQUEST, ADMIN_WALLET_DATA_SUCCESS
} from '../constants/adminConstants'

export const adminDashboardDataReducer = (state = { }, action) => {
    switch (action.type) {
        case ADMIN_DASHBOARD_DATA_REQUEST:
            return {...state, loading: true}
        case ADMIN_DASHBOARD_DATA_SUCCESS:
            return { loading: false, data: action.payload }
        case ADMIN_DASHBOARD_DATA_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const adminWalletDetailsReducer = (state = { }, action) => {
    switch (action.type) {
        case ADMIN_WALLET_DATA_REQUEST:
            return {...state, loading: true}
        case ADMIN_WALLET_DATA_SUCCESS:
            return { loading: false, wallet: action.payload }
        case ADMIN_WALLET_DATA_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}