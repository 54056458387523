import { useTheme } from '@mui/material/styles'
import React from 'react'

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Title from './Title';


const LineChartComp = ({title, data, xAxisKey, yAxisKey}) => {
  const theme = useTheme()
  return (
        <div style={{ width: '100%', height: 320, paddingBottom: '40px' }}>
        <Title heading={title}/>
        <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 10,
            // right: 30,
            // left: 20,
            bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis yAxisId="left" />
          <YAxis yAxisId="right" orientation="right" />
          <Tooltip />
          <Legend />
          <Line yAxisId="left" type="monotone" dataKey="clients" stroke={theme.palette.success.light} activeDot={{ r: 8 }} />
          <Line yAxisId="right" type="monotone" dataKey="barbers" stroke={theme.palette.warning.light} />
        </LineChart>
      </ResponsiveContainer>
        </div>
  )
}

export default LineChartComp