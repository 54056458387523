import { Box, Button, Divider, List, Paper, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useDispatch, useSelector } from 'react-redux'
import { AppointmentsList, BarChart, DataWidget, LineChart, PieChart, Title } from '../../../features/dashboard'
import { UserDetails } from '../../../features/profile'
import Group from '@mui/icons-material/Group'
import MenuIcon from '@mui/icons-material/Menu'
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined'
import PeopleIcon from '@mui/icons-material/People'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ContentCutIcon from '@mui/icons-material/ContentCut'
import moment from 'moment'
import StyledAvatar from '../../../features/dashboard/components/StyledAvatar'
import { Link, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { getStoreAndBarberDetails } from  '../../../redux/actions/storeActions'
import BackDropLoader from '../../../components/ui/BackDropLoader'
import { UPDATE_ALERT } from '../../../redux/constants/alertConstants';
import BiaxialChart from '../../../features/dashboard/components/BiaxialChart'
import TextButton from '../../../components/ui/TextButton'
import TopNavigation from '../../../components/ui/TopNavigation';
import { Appointments, EditService, StoreDetails, Transactions, Feedbacks } from '../../../features/store';

const BarberDetails = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { id } = useParams()
  // const userLogin = useSelector(state => state.userLogin)
  const [loading, setLoading] = useState(false)
  // const { userInfo } = userLogin

  const storeAndBarberDetails = useSelector(state => state.storeAndBarberDetails)
  const { loading: loadingDetails, error: errorDetails, data } = storeAndBarberDetails

  const serviceEdit = useSelector(state => state.serviceEdit)
  const { success: successEdit } = serviceEdit

  const serviceDelete= useSelector(state => state.serviceDelete)
  const { success: successDelete } = serviceDelete


  useEffect(() => {
    setLoading(true)
    dispatch(getStoreAndBarberDetails(id))
    setLoading(false)
    // if (users.length === 0) dispatch(listUsers())
    // dispatch({ type: UPDATE_ALERT, payload: {open: true, severity: 'error', message: 'Test message' } })
  }, [dispatch, successEdit, successDelete])

  const barChartData = [
    {
      month: 'Jan',
      income: 200,
    },
    {
      month: 'Feb',
      income: 230,
    },
    {
      month: 'Mar',
      income: 250,
    },
    {
      month: 'Apr',
      income: 220,
    },
    {
      month: 'May',
      income: 280,
    },
    {
      month: 'Jun',
      income: 300,
    },
    {
      month: 'Jul',
      income: 290,
    },
    {
      month: 'Aug',
      income: 350,
    },
    {
      month: 'Sep',
      income: 320,
    },
    {
      month: 'Oct',
      income: 500,
    },
    {
      month: 'Nov',
      income: 450,
    },
  ];
  
// Generate Clients Data
function createData(month, noOfClients) {
  return { month, noOfClients };
}
  const lineChartData = [
    createData('Jan', 0),
    createData('Feb', 8),
    createData('Mar', 10),
    createData('Apr', 8),
    createData('May', 6),
    createData('Jun', 7),
    createData('Jul', 10),
    createData('Aug', 9),
    createData('Sep', 15),
    createData('Oct', 16),
    createData('Nov', 17),
  ];

  const Test = () => <Typography>TEST</Typography>
  

  return (
    <>
      {loading || loadingDetails
      ? <BackDropLoader /> 
      :
      <Box
      sx={{
        display: { xs: 'flex', md: 'grid' },
        gridTemplateColumns: 'repeat(4,1fr)',
        gridAutoRows: 'minmax(100px, auto)',
        gap: 3,
        textAlign: 'center',
        flexDirection: 'column',
      }}
    >
      <DataWidget 
        title="Balance" 
        value={`$${data?.wallet?.balance}`}
        Icon={<PaymentsOutlinedIcon color="info" sx={{ height: 40, width: 80, opacity: 0.4 }} />  }
      />
      <DataWidget 
        title="Total Milestones"
        value={`$${data?.totalMilestonesAmount}`}
        Icon={<PaymentsOutlinedIcon color="" sx={{ color: theme.palette.custom.lightPurple, height: 40, width: 80, opacity: 0.5 }} />  }
      />
        <DataWidget 
          title="Total Earnings" 
          value={`$${data?.wallet?.totalEarnings}`}
          Icon={<PaymentsOutlinedIcon color="primary" sx={{ height: 40, width: 80, opacity: 0.4 }} />  }
        />
        <DataWidget 
          title="Onhold Balance"
          value={`$${data?.wallet?.onHoldBalance}`}
          Icon={<PaymentsOutlinedIcon color="" sx={{ height: 40, width: 80, opacity: 0.4 }} />  }
        />
      <DataWidget 
        title="Total Withdrawal" 
        value={`$${data?.wallet?.totalWithdrawalAmount}`}
        Icon={<PaymentsOutlinedIcon color="warning" sx={{ height: 35, width: 80, opacity: 0.4 }} />  }
      />
        <DataWidget 
          title="Upcoming Appointments" 
          value={`${data?.upcomingAppointmentsCount}`}
          Icon={<CalendarMonthIcon color="success" sx={{ height: 35, width: 80, opacity: 0.4 }} />  }
        />
      <DataWidget 
        title="Services" 
        value={`${data?.totalServicesCount}`}
        Icon={<ContentCutIcon color="warning" sx={{ height: 35, width: 80, opacity: 0.4 }} />  }
      />
      <DataWidget 
        title="Total Clients" 
        value={`${data?.uniqueClientsCount}`}
        Icon={<PeopleIcon color="success" sx={{ height: 35, width: 80, opacity: 0.5  }} />  }
      />
          <Paper 
              elevation={3} 
              sx={{ pt: 4, pb: 2, gridColumn: '1/2'}}
          >
            <StoreDetails store={data?.store} />
          </Paper>
       <Paper elevation={3} sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            paddingBottom: "24px",
            flexWrap: 'wrap',
            gridColumn: '2/5', gridRow: '3/4' 
          }}>
          <TopNavigation
              TabOneHeading="Appointments"
              TabOneContent={<Appointments />}
              TabTwoHeading="Services"
              TabTwoContent={<EditService />}
              TabThreeHeading="Feedbacks"
              TabThreeContent={<Feedbacks />}
              // TabFourHeading="Feedbacks"
              // TabFourContent={<Feedbacks />}
            />
      </Paper>
      </Box>
      }
    </>
  )
}

export default BarberDetails