import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Box, Typography, Checkbox, 
    Card, Divider, Button
 } from '@mui/material'
import { styled } from "@mui/material/styles"

import FormContainer from './FormContainer'
import Password from './Password'

import { addToCart,removeFromCart } from '../../../redux/actions/cartActions'
import AnimatedPage from '../../Layout/AnimatedPage'
import ContentCutIcon from '@mui/icons-material/ContentCut'

const StyledBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft: 10
  }))

//   const StyledCard = styled(Card)(({ theme }) => ({
//     margin: 30,
//     padding: 16
//   }))

const ServiceForm = (
     {    services,
          activeStep,
          steps,
          handleBack,
          handleNext
     }
     ) => {
     const dispatch = useDispatch()

     const cart = useSelector(state => state.cart)
     const { cartItems } = cart

     const handleChange = (e) => {

          const checked = e.target.checked
          const value = e.target.value
            
          if (!checked) {
               dispatch(removeFromCart(value))
              
          } else {
               dispatch(addToCart(value))
          }
     }   
     
     const checkSelection = (id) => {
          const isChecked = cart?.cartItems?.some(item => item.serviceId === id)
          return isChecked
     } 


  return (
    <AnimatedPage>
     {services?.length 
          ? 
          <>
          {services?.map( (service) => (
               <Box key={service._id}>
                    {service?.subCategories?.length > 0 && 
                     <Box display="flex" alignItems="center">
                         {/* <ContentCutIcon fontSize="small" /> */}
                         <Typography sx={{ marginTop: 0.4, marginLeft: 0.8, fontSize: 18, textDecorationLine: 'underline'}} >{service.category}</Typography> 
                    </Box>
                        
                    }
                    {service?.subCategories?.map((item) => (
                    <Box key={item._id} style={{ display: (item.status === "active") ? "" : "none" }}>
                              <StyledBox>
                                   <Box flex="4">
                                        <Typography sx={{fontWeight: "bold", marginLeft: 2}} variant="body2" gutterBottom>{item.name}</Typography>
                                        {/* <Typography variant="caption">{item.description}</Typography> */}
                                   </Box>
                                   
                                   <Typography flex="2">${item.amount}</Typography>
                                   {/* <Button 
                                        sx={{padding: 0}} 
                                        onClick={() => handleChange(category._id)}
                                   > */}
                                        <Checkbox 
                                        flex="2"
                                        value={item._id}
                                        checked={checkSelection(item._id)}
                                        onChange={handleChange} />           
                                   {/* </Button> */}
                              </StyledBox>
               
                              <Divider sx={{margin: "10px 0"}} />
                         </Box>
                    )) }

               </Box>   
          ))}
               <Box style={{display:"flex", justifyContent: "flex-end", marginTop: "20px"}}>
                    <Button  
                    variant="contained" 
                    color="primary" 
                    onClick={handleNext}
                    disabled={
                         (cartItems.length === 0 || activeStep === steps.length - 1) 
                    }
                    >
                    Next
                    </Button>
               </Box>
          </>
          : 
          <Typography align="center" fontWeight="bold">No Services added yet</Typography>
          }
    </AnimatedPage>
  )
}

export default ServiceForm