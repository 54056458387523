import { useLocation, Navigate, Outlet, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import roleBasedRedirect from "../../../utils/roleBasedRedirect"
import { useEffect, useState } from "react";
import { UPDATE_ALERT } from "../../../redux/constants/alertConstants";
import { getUserDetails } from "../../../redux/actions/userActions";
import BackDropLoader from '../../../components/ui/BackDropLoader'


const RequirePhoneVerification = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    // const [loading, setLoading] = useState(false)

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const userDetails = useSelector(state => state.userDetails)
    const { loading } = userDetails


    const isPhoneVerified = () => {
      // console.log("Phone details: ", userInfo?.phone)
      const isVerified = (userInfo?.phone?.phoneNumber !== "" && userInfo?.phone?.isVerified === true)
      // console.log({isVerified})
      return isVerified 
     }

    // const userDetails = useSelector(state => state.userDetails)
    // const { user } = userDetails

    // useEffect(() => {
    //     if (isPhoneVerified(userInfo?.phone) && location.pathname == "/phone-verification") 
    //     navigate('/dashboard')
    // }, [userInfo?.phone])

    // useEffect(() => {
    //     setLoading(true)
    //     // const user = JSON.parse(localStorage.getItem("userInfo"));
    //     if (!userInfo.role) {
    //       navigate("/dashboard")
          
    //     } 
    //     else {
    //         // location.pathname === "/phone-verification" ? navigate("/dashboard") : navigate(location)
    //         /// Check if phone number is verified
    //       if(userInfo?.phone?.phoneNumber == "" || userInfo?.phone?.isVerified == false) {
    //         console.log("phone not verified")
    //         navigate('/phone-verification')
    //         dispatch({ 
    //           type: UPDATE_ALERT, 
    //           payload: {
    //             open: true, 
    //             severity: 'info', 
    //             message: 'Please verify your phone number.' 
    //           } 
    //         })
    //     }
    //     }
    //     setLoading(false)
    //   }, [userInfo])



    // const redirectBasedOnRole = () => {
    //     if ()
    // }

    // return(
    //     // loading && !userInfo
    //     // ? <BackDropLoader />
    //     // : 
    //       userInfo
    //       ?
    //         userInfo?.role && allowedRoles?.includes(userInfo.role) 
    //             ? <Outlet />
    //             // : isPhoneVerified()
    //             //       ? 
    //                 : <Navigate to={roleBasedRedirect(userInfo?.role)} state={{ from: location}} replace />
    //       :<Navigate to="/sign-in" state={{ from: location}} replace />
    // )

    return(
      !userInfo?.phone.isVerified && loading 
      ? <BackDropLoader />
      : 
        userInfo && isPhoneVerified(userInfo?.phone)
        ? <Outlet /> 
        // : <Navigate to="/phone-verification" state={{ from: "/dashboard"}} replace />
        : <Navigate to="/phone-verification" state={{ from: location }} replace />
  )
}

export default RequirePhoneVerification