import {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'
import Calendar from 'react-calendar'
import moment from 'moment'
import 'react-calendar/dist/Calendar.css';
import './Calender.css'


import { saveAppointmentDate, saveAppointmentTime } from '../../../redux/actions/cartActions'
import BackDropLoader from '../BackDropLoader';

const Calender = ({ selectedDate, selectedTime, setSlots }) => {
    const dispatch = useDispatch()    
    const [value, setValue] = useState(selectedDate)
    const [nextDayDate, setNextDayDate] = useState(null)
    
    const storeDetails = useSelector(state => state.storeDetails)
    const { loading: loadingStore, store } = storeDetails

    const [availableDays, setAvailableDays] = useState(store?.workingSchedule?.filter(day => day.isWorkday))
    // const [availableDays, setAvailableDays] = useState([])
    // const availableDays = [
    //   {
    //     weekday: 1,
    //     isWorkday: true,
    //     slots: ['10:00', '11:00', '12:00', '13:00']
    //   },
    //   {
    //     weekday: 2,
    //     isWorkday: true,
    //     slots: ['12:00', '13:00', '14:00', '15:00']
    //   },
    //   {
    //     weekday: 3,
    //     isWorkday: true,
    //     slots: ['9:00', '10:00', '11:00', '12:00']
    //   },
    //   {
    //     weekday: 4,
    //     isWorkday: true,
    //     slots: ['10:00', '11:00', '12:00', '13:00']
    //   },
    //   {
    //     weekday: 5,
    //     isWorkday: true,
    //     slots: ['10:00', '11:00', '12:00', '13:00']
    //   },
    //   {
    //     weekday: 6,
    //     isWorkday: false,
    //   },
    // ]

    useEffect(() => {
      if(store)
        setAvailableDays(store?.workingSchedule?.filter(day => day.isWorkday))
    }, [])
  
    useEffect (() => {
      var tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate()+1);
      setNextDayDate(tomorrow)
      console.log("Date selected on Load")
      console.log(value)
      dispatch(saveAppointmentDate(value))
      setValue(selectedDate)
      setAvailableDays(store?.workingSchedule?.filter(day => day.isWorkday))
      const { slots } = availableDays?.find(item => item.weekday === value?.getDay()) || []
      console.log({slots})
      setSlots(slots)
      // dispatch(saveAppointmentTime(slots?.[0]?.slots[0]))
    }, [store, dispatch])


    // const [date, setDate] = useState([
    //     new Date(2022, 9, 22),
    //     // new Date(2021, 6, 10),
    //     new Date(2022, 9, 28),
        
    //   ]);

    

    // const mark = [
    //     '22-09-2022',
    //     '26-09-2022'
    // ]
    const mark = []
    const onChange = (value) => {
      console.log("Date selected on Calender")
      console.log(value)
      const { slots } = availableDays?.find(item => item.weekday === value?.getDay()) || []
      console.log({slots})
      setSlots(slots)
      setValue(value)
      dispatch(saveAppointmentTime(slots[0]))
      dispatch(saveAppointmentDate(value))
    }

  return (
    <>
    {loadingStore || availableDays?.length == 0 
    ? <BackDropLoader />
    :
      <Box display="flex" justifyContent="center">
        {/* <Calendar 
          value={value}
          minDate={new Date()}
          allowPartialRange={false}

          /> */}
        <Calendar 
          onChange={onChange}
          value={value}
          // tileClassName={({ date, view }) => {
          //     if(mark.find(x=>x===moment(date).format("DD-MM-YYYY"))){
          //     return  'highlight'
          //     }
          // }}
          // tileContent={({ date }) => availableDays.every(day => date.getDay() !== day.weekday) ? <p><small>Off</small></p> : null}
          tileDisabled={({ date }) => availableDays?.every(day => date.getDay() !== day.weekday )}
    
          // maxDate={new Date(2020, 1, 0)}
          minDate={new Date()}
        />

          {/* <Calendar 
            onChange={onChange} 
            value={value}
            minDate={new Date()}
            /> */}
      </Box>
    }
    </>
  );
}

export default Calender