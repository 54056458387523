import { 
    NOTIFICATION_LIST_FAIL, 
    NOTIFICATION_LIST_REQUEST, 
    NOTIFICATION_LIST_SUCCESS, 
    NOTIFICATION_UNREAD_COUNT_FAIL, 
    NOTIFICATION_UNREAD_COUNT_REQUEST,
    NOTIFICATION_UNREAD_COUNT_RESET,
    NOTIFICATION_UNREAD_COUNT_SUCCESS
} from '../constants/notificationConstants'

 export const notificationListReducer = (state = {notifications: [] }, action) => {
     switch (action.type) {
         case NOTIFICATION_LIST_REQUEST:
             return { loading: true}
         case NOTIFICATION_LIST_SUCCESS:
             return { loading: false, notifications: action.payload }
         case NOTIFICATION_LIST_FAIL:
             return { loading: false, error: action.payload }
         default:
             return state
     }
 }

 export const notificationUnreadCountReducer = (state = {}, action) => {
    switch (action.type) {
        case NOTIFICATION_UNREAD_COUNT_REQUEST:
            return { loading: true}
        case NOTIFICATION_UNREAD_COUNT_SUCCESS:
            return { loading: false, count: action.payload }
        case NOTIFICATION_UNREAD_COUNT_FAIL:
            return { loading: false, error: action.payload }
        case NOTIFICATION_UNREAD_COUNT_RESET:
            return { loading: false, count: 0 }
        default:
            return state
    }
}
