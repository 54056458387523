import { useEffect, useState } from "react";
import { Divider, IconButton, InputAdornment, Snackbar, TextField } from "@mui/material";
import { useTheme } from '@mui/material/styles'

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useDispatch } from "react-redux";
import { UPDATE_ALERT } from "../../redux/constants/alertConstants";

const CopyToClipboardButton = ({referralCode}) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('')

  useEffect(() => {
    if(referralCode) {
      setValue(referralCode)
    }
  }, [])

  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(value);
    dispatch({ 
      type: UPDATE_ALERT, 
      payload: {
          open: true, 
          severity: 'success', 
          message: 'Referral code copied to clipboard.' 
      } 
  })
  };

  return (
    <>
       <TextField
        label="Referral code"
        variant="outlined"
        value={value}
        // disabled
        sx={{
          margin: '28px 34px 8px 34px',
          input: { color: theme.palette.primary.main, fontWeight: 'bold' } 
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <IconButton sx={{ margin: "0", padding: "0"}} onClick={handleClick}>
                <ContentCopyIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

export default CopyToClipboardButton;
