
import axios from 'axios';
import {
    WALLET_PAYPAL_DETAILS_REQUEST,
    WALLET_PAYPAL_DETAILS_SUCCESS,
    WALLET_PAYPAL_DETAILS_FAIL,
    PAYPAL_EMAIL_UPDATE_REQUEST,
    PAYPAL_EMAIL_UPDATE_SUCCESS,
    PAYPAL_EMAIL_UPDATE_FAIL,
    PAYPAL_EMAIL_UPDATE_VERIFY_REQUEST,
    PAYPAL_EMAIL_UPDATE_VERIFY_SUCCESS,
    PAYPAL_EMAIL_UPDATE_VERIFY_FAIL,
    PAYPAL_WITHDRAW_REQUEST,
    PAYPAL_WITHDRAW_SUCCESS,
    PAYPAL_WITHDRAW_FAIL,
    PAYPAL_WITHDRAW_VERIFY_REQUEST,
    PAYPAL_WITHDRAW_VERIFY_SUCCESS,
    PAYPAL_WITHDRAW_VERIFY_FAIL
} from '../constants/walletConstants'

export const getPaypalWalletDetails = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: WALLET_PAYPAL_DETAILS_REQUEST
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.get('/api/wallets/paypal', config)
        dispatch({
            type: WALLET_PAYPAL_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: WALLET_PAYPAL_DETAILS_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const updatePaypalEmail = ({email}) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PAYPAL_EMAIL_UPDATE_REQUEST
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.post('/api/wallets/paypal/send-code', { email }, config)
        dispatch({
            type: PAYPAL_EMAIL_UPDATE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: PAYPAL_EMAIL_UPDATE_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const verifyUpdatePaypalEmail = ({ otp }) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PAYPAL_EMAIL_UPDATE_VERIFY_REQUEST
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.post('/api/wallets/paypal/verify-code', { otp }, config)
        dispatch({
            type: PAYPAL_EMAIL_UPDATE_VERIFY_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: PAYPAL_EMAIL_UPDATE_VERIFY_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const withdrawAmountFromPaypal = ({amount}) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PAYPAL_WITHDRAW_REQUEST
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.post('/api/wallets/paypal/withdraw/send-code', { amount }, config)
        dispatch({
            type: PAYPAL_WITHDRAW_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: PAYPAL_WITHDRAW_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const verifyWithdrawFromPaypal = ({ amount, otp }) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PAYPAL_WITHDRAW_VERIFY_REQUEST
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.post('/api/wallets/paypal/withdraw', { amount, otp }, config)
        dispatch({
            type: PAYPAL_WITHDRAW_VERIFY_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: PAYPAL_WITHDRAW_VERIFY_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}
