import { useEffect, useMemo, useState } from 'react';
import { Avatar, Box, Button, Stack, Typography } from '@mui/material';
import { DataGrid, gridClasses, GridToolbar } from '@mui/x-data-grid';
import moment from 'moment';
import { grey } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import { listAppointments } from '../../../redux/actions/appointmentActions';
import BackDropLoader from '../../../components/ui/BackDropLoader';
import TextButton from '../../../components/ui/TextButton';
import { Link } from 'react-router-dom';

const Transactions = () => {
    const dispatch = useDispatch()
    const appointmentAdminList = useSelector(state => state.appointmentAdminList)
    const { loading, error, appointments } = appointmentAdminList

  const [pageSize, setPageSize] = useState(10);
  const [rowId, setRowId] = useState(null);

  const NoRowsOverlay = () => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        No appointments available
      </Stack>
    );
  }

  useEffect(() => {
    dispatch(listAppointments())
  }, []);

  const columns = useMemo(
    () => [
      {
        field: 'ID',
        headerName: 'ID',
        width: 100,
        valueGetter: (params) => "APT-"+params.row.id,
        sortable: false,
      },
    { 
    field: 'client',
    headerName: 'Client Name', 
    width: 180,
    valueGetter: (params) => {
        let clientName;
        if (params.row.clientId) {
            clientName = params.row.clientId.firstName + " " + params.row.clientId.lastName
        } else {
            clientName = ""
        }
        return clientName;
      }
    },
    { 
    field: 'barber',
    headerName: 'Barber Name', 
    width: 180,
    valueGetter: (params) => {
        let storeName
        if (params.row.storeId) {
            storeName = params.row.storeId.name
        } else {
            storeName = ""
        }
        return storeName;
      }
    },
    {
      field: 'amount',
      headerName: 'Amount ($)',
      width: 140,
      valueGetter: (params) => params.row.totalPrice + " AUD" 
    },
    {
      field: 'appointmentDetails',
      headerName: 'Date & Time',
      width: 150,
      sortable: false,
      filterable: false,
      renderCell: (params) =>
      moment(params.row.appointmentDate).format('YYYY-MM-DD') + " " + params.row.appointmentTime
      },
    {
      field: 'isReviewed',
      headerName: 'Reviewed',
      type: "boolean",
      width: 100,
    },
      {
          field: 'createdAt',
          headerName: 'Created At',
          width: 200,
          renderCell: (params) =>
          moment(params.row.createdAt).format('YYYY-MM-DD HH:MM:SS'),
        },        
        {
        field: 'status',
        headerName: 'Status',
        width: 120,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          let status, color="primary", disabled=false
          if (params.row.status === "upcoming") {
            status = "Upcoming"
            color = "primary"
          }
          else if (params.row.status === "completed") {
            status = "Completed"
            color = "success"
            disabled=true
          } 
          else if (params.row.status === "cancelled")  {
            status = "Cancelled"
            color = "error"
          }
          else if (params.row.status === "rescheduled")  {
            status = "rescheduled"
            color = "warning"
          }
          else status = ""
          return (
            <TextButton 
            variant="outlined" 
            size="small"
            color={color}
            disabled={disabled}
            >
                {status}
            </TextButton>
            // status
          )
        }
        },
      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        renderCell: (params) => (
            <Link to={`/appointment/${params.row._id}`} style={{textDecoration: 'none'}}>
                <Button variant='contained' size="small">View</Button>
            </Link>
        ),
      },
    ],
    [rowId]
  );

  return (
    <>
    {
        loading 
        ? <BackDropLoader />
        :
        <Box
        sx={{
            height: '70vh',
            width: '100%',
        }}
        >
        <Typography
            variant="h4"
            component="h4"
            sx={{ textAlign: 'center', mt: 3, mb: 3 }}
        >
            Appointments List
        </Typography>
        <DataGrid
            columns={columns}
            rows={appointments}
            getRowId={(row) => row._id}
            rowsPerPageOptions={[5, 10, 20]}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            components={{
              NoRowsOverlay,
              Toolbar: GridToolbar
            }}
            getRowSpacing={(params) => ({
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
            })}
            sx={{
            [`& .${gridClasses.row}`]: {
                bgcolor: (theme) =>
                theme.palette.mode === 'light' ? grey[200] : grey[900],
            },
            }}
            onCellEditCommit={(params) => setRowId(params.id)}
        />
        </Box>
    }
    </>
  );
};

export default Transactions;