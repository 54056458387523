import { Divider, Typography, Box } from '@mui/material';
import React from 'react'

const Field = ({ title, value, disabled, textColor }) => {
  return (
    <>
        <Divider />
            <Box display="flex" justifyContent="space-between" px={3} py={1.6} >
                <Typography fontWeight="bold" align="left">
                    {title}
                </Typography>
                <Typography 
                    sx={{
                        maxWidth:"240px",
                        // color: disabled ? "text.disabled" : textColor
                    }}
                    color={disabled ? "text.disabled" : textColor }
                    align="right"
                    
                    >
                    {value}
                </Typography>
            </Box>
        <Divider />
    </>
  )
}

export default Field