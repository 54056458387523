import axios from 'axios';
import {
    FEEDBACK_ADD_REQUEST,
    FEEDBACK_ADD_SUCCESS,
    FEEDBACK_ADD_FAIL
} from '../constants/feedbackConstants'

export const addNewFeedback = ({ id, service, onTime, price, comment  }) => async (dispatch, getState) => {
    try {
        dispatch({
            type: FEEDBACK_ADD_REQUEST
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.post(
            `/api/reviews/${id}`,
            { service, onTime, price, comment },
            config
        )

        dispatch({
            type: FEEDBACK_ADD_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: FEEDBACK_ADD_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}