import {
    FEEDBACK_ADD_REQUEST,
    FEEDBACK_ADD_SUCCESS,
    FEEDBACK_ADD_FAIL,
    FEEDBACK_ADD_RESET,
    FEEDBACK_SHOW_UPDATE
} from '../constants/feedbackConstants'

//ADD FEEDBACK
export const feedbackAddReducer = (state = { }, action) => {
    switch (action.type) {
        case FEEDBACK_ADD_REQUEST:
            return { loading: true}
        case FEEDBACK_ADD_SUCCESS:
            return { loading: false, success: true  }
        case FEEDBACK_ADD_FAIL:
            return { loading: false, error: action.payload }
        case FEEDBACK_ADD_RESET:
            return { success: false }
        default:
            return state
    }
}

