import React, {useState} from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Chip, Button, Typography, Stack, Grid } from '@mui/material'

import Calender from '../Calender/Calender';
import { saveAppointmentTime } from '../../../redux/actions/cartActions'
import convertDateTimeToMilliseconds from '../../../utils/convertDateTimeToMilliseconds'
import { DatePicker } from '@mui/x-date-pickers';
import useFetch from '../../../hooks/useFetch';
import AnimatedPage from '../../Layout/AnimatedPage';

import moment from 'moment'

const DateTimeForm = (
        { 
          selectedDate, 
          selectedTime,
          activeStep,
          handleBack,
          handleNext
        }
      ) => {
  const dispatch = useDispatch()
  const [slots, setSlots] = useState([])
  // const [selectedSlot, setSelectedSlot] = useState(null)
  // const [appointmentTime, setAppointmentTime] = useState(null)

  const cart = useSelector(state => state.cart)
  const { cartItems } = cart

  const storeDetails = useSelector(state => state.storeDetails)
  const { store } = storeDetails

  // const getDateTimeInMilliseconds = (date, slot) => {
  //     var year = date.getFullYear();
  //     var month = date.getMonth() + 1; // Jan is 0, dec is 11
  //     var day = date.getDate();
  //     var dateString = '' + year + '-' + month + '-' + day;
  //     var dateTime = moment(`${dateString} ${slot}`, 'YYYY-MM-DD HH:mm').format(); 
  //     var time = new Date(dateTime).getTime()
  //     return time
  // }

  // const hasNotPassed = (selectedDate, selectedSlot) => {
  //   console.log({ selectedSlot })
  //   const currentDateString =  moment(new Date(), 'YYYY-MM-DD HH:mm:ss').format();
  //   let currentDateTime = moment(`${currentDateString} ${selectedSlot}`, 'YYYY-MM-DD HH:mm:ss').format();
  //   currentDateTime = new Date(new Date(currentDateTime).getTime())
  //     console.log(convertDateTimeToMilliseconds(selectedDate, selectedSlot))
  //     console.log(currentDateTime.getTime())
  //     const val = currentDateTime.getTime() < convertDateTimeToMilliseconds(selectedDate, selectedSlot)
  //     console.log({val})
  //     return val
  // }

  const hasNotPassed = (selectedSlot) => {
    // console.log({ slots })
    // console.log({ selectedSl })
    // const currentDateString =  moment(new Date(), 'YYYY-MM-DD HH:mm:ss').format();
    // let currentDateTime = moment(`${currentDateString} ${selectedSlot}`, 'YYYY-MM-DD HH:mm:ss').format();
    // currentDateTime = new Date(new Date(currentDateTime).getTime())
    //   console.log(convertDateTimeToMilliseconds(selectedDate, selectedSlot))
    //   console.log(currentDateTime.getTime())
    // var date = new Date(new Date().getTime() + 1 * 1000 * 60 * 60) // add 1 hour
    var date = new Date() // add 1 hour
      const val = date.getTime() < convertDateTimeToMilliseconds(selectedDate, selectedSlot)
      return val
  }

  const checkSlotAvailability = (selectedDate, selectedSlot) => {
    // const isFound= store?.bookedSlots.some(dateTime => 
    //                                             new Date(dateTime).getTime() == convertDateTimeToMilliseconds(selectedDate, selectedSlot)
    //                                           )
    const isFound= store?.bookedSlots.some(dateTime => {
      // const currentDateString =  moment(new Date(), 'YYYY-MM-DD HH:mm:ss').format();
      // let currentDateTime = moment(`${currentDateString} ${selectedSlot}`, 'YYYY-MM-DD HH:mm:ss').format();
      // currentDateTime = new Date(new Date(currentDateTime).getTime())
      // console.log({currentDateTime})
        return new Date(dateTime).getTime() == convertDateTimeToMilliseconds(selectedDate, selectedSlot)
        // return convertDateTimeToMilliseconds(new Date(), selectedSlot) >= convertDateTimeToMilliseconds(selectedDate, selectedSlot) 
      })
    return !isFound
  }

  const handleTimeChange = (time) => {
    // console.log("Handle change called")
    // console.log({time})
    // setSelectedSlot(time)
    // setAppointmentTime(time)
    dispatch(saveAppointmentTime(time))
  }

  const checkSlotHandler = async () => {
    // console.log("Check Slot called")
    // const { data } = await axios.get(`/api/stores/schedule/${store._id}?date=${selectedDate}&time=${selectedTime}`,)
    // console.log({data})
    handleNext()
  }
  return (
    <AnimatedPage>
      {/* <DatePicker /> */}
      <Calender
        workingSchedule={store?.workingSchedule} 
        selectedDate={selectedDate}
        selectedTime={selectedTime}
        setSlots={setSlots}
        />
      <Box display="flex" justifyContent="center">
        {slots 
          ?
            // slots.every(slot => slot.isBooked === true) 
            //   ?
            //   <Box>
            //     <Typography color="error" align="center" fontStyle="italic">Sorry, no slots available for this date.</Typography>
            //     <Typography color="error" align="center">Please select another date.</Typography>
            //   </Box>
              // :
              <Box display="flex" overflow="auto">
                {slots.map((slot) => (
                  <Box my={2}>
                    {checkSlotAvailability(selectedDate, slot) && hasNotPassed(slot) &&
                      <Button 
                        key={slot._id}
                        // id={index}
                        sx={{margin: 1}}
                        variant={slot === selectedTime ? "contained" : "outlined"}
                        color="primary" 
                        size="small"
                        onClick={() => handleTimeChange(slot)}
                        // disabled={isSlotAvailable(slot)} 
                      >
                        {slot}
                      </Button> 
                    } 
                  </Box>
                ))}
            </Box>
          :
          <Box>
            <Typography color="error" align="center" fontStyle="italic">Sorry, no slots available for today.</Typography>
            <Typography color="error" align="center">Please select another date.</Typography>
          </Box>
        }
      </Box>
      <Box style={{display:"flex", justifyContent: "flex-end", gap: "10px", marginTop: "20px"}}>
        <Button 
            disabled={activeStep === 0} 
            onClick={handleBack}
            variant="outlined"
        >
          Back
        </Button>
        <Button  
        variant="contained" 
        color="primary" 
        onClick={checkSlotHandler}
        disabled={ !cart.appointmentDate || !cart.appointmentTime }
        >
          Next
        </Button>
      </Box>
    </AnimatedPage>
  )
}

export default DateTimeForm