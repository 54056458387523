import React, { useState } from 'react'

import { 
    InputLabel,IconButton,
    OutlinedInput, FormControl, InputAdornment
  } from '@mui/material'
  
  import Visibility from '@mui/icons-material/Visibility';
  import VisibilityOff from '@mui/icons-material/VisibilityOff';

const Password = ({label="Password", password, setPassword, size="small", disabled=false }) => {

    const [showPassword, setShowPassword] = useState(false)

    const handleMouseDownPassword = (e) => {
    e.preventDefault();
    };

    return (
    <FormControl
        variant="outlined"
        size="small"
        fullWidth
        required
        disabled={disabled}
        >
        <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
        <OutlinedInput
            // id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            size={size}
            onChange={(e) => setPassword(e.target.value)}
            endAdornment={
            <InputAdornment position="end">
                <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                >
                {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
            </InputAdornment>
            }
            label={label}
        />
    </FormControl>
    )
    }

export default Password