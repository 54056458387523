import { Button, styled, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import Dummy from "../../../../images/dummy.png";
// import LaptopDummy from "../../../../images/laptop-dummy.jpg";
import bookAppointmentPic from "../../../../images/book-appointment.png";

import { useNavigate } from "react-router-dom";
import CustomButton from '../../../../components/ui/Button/Button'
const GetStarted = () => {
  const navigate = useNavigate() 

    const CustomContainer = styled(Container)(({ theme }) => ({
    // backgroundColor: "#17275F",
    backgroundColor: theme.palette.custom.black,
    height: "420px",
    borderRadius: "15px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing(3, 3, 0, 3),
      width: "90%",
    },
  }));

  const CustomBox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(10, 0, 0, 0),
    margin: theme.spacing(0, 2, 0, 2),
    [theme.breakpoints.down("md")]: {
      padding: "0",
    },
  }));

  const CustomBoxContainer = styled(Box)(({ theme }) => ({
    maxWidth: "480px",
    [theme.breakpoints.down("md")]: {
      marginBottom: 40,
    },
  }));

  const ImgContainer = styled(Box)(({ theme }) => ({
    maxWidth: "480px",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%"
    },
  }));

  const onBookHandler = (e) => {
    e.preventDefault()
    navigate('/sign-up')
  }

  return (
    <Box className="section">
    <CustomBox>
      <CustomContainer>
        <ImgContainer>
          <img
              src={bookAppointmentPic}
              loading="lazy"
              // src={Dummy}
              alt="Barbarly, book appointment easily"
              style={{ maxWidth: "100%" }}
            />
        </ImgContainer>
          {/* Content */}
        <CustomBoxContainer>
          <Typography
            sx={{ fontSize: "35px", color: "white", fontWeight: "700" }}
          >
            Book your appointment
          </Typography>
          <Typography
            sx={{ fontSize: "16px", color: "#ccc", fontWeight: "500", mt: 3, mb: 5 }}
          >
            Experience a premium grooming service with our skilled barbers and 
            book your appointment now for a fresh and stylish look.
          </Typography>

            <CustomButton
              className='btns'
              buttonStyle='btn--primary'
              buttonSize='btn--large'  
              onClick={onBookHandler}
            >
              Get Started
              
            </CustomButton>
            {/* <Button
              variant="contained"
            >
              Get Started
            </Button> */}
        </CustomBoxContainer>
        
      </CustomContainer>
    </CustomBox>
  </Box>
  );
};

export default GetStarted;