import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux'
import {
    Stepper, Step, StepLabel,Button, Container, 
    Grid, Box, Typography, Card
 }from "@mui/material";
 
import { styled } from "@mui/material/styles"

import AnimatedPage from "../../../components/Layout/AnimatedPage"
// import FormContainer from "./FormContainer"
import DateTimeForm from "../../../components/ui/Form/DateTimeForm"
import RescheduleCheckout from "../../../components/ui/Form/RescheduleCheckout"
import BackDropLoader from "../../../components/ui/BackDropLoader"
import { useParams } from "react-router-dom";
import { getAppointmentDetails, getAppointmentRescheduleFee } from "../../../redux/actions/appointmentActions"
import { getStoreDetails } from "../../../redux/actions/storeActions";

const StyledCard = styled(Card)(({ theme }) => ({
  margin: 30,
  padding: 16
}))


function getSteps() {
  return ["Date & Time", "Checkout"];
}

// var tomorrow = new Date();
// tomorrow.setDate(tomorrow.getDate()+1);


const RescheduleAppointment = () => {
   const dispatch = useDispatch()
   const { id } = useParams() 
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  // const [nextDayDate, setNextDayDate] = useState(null)
  const steps = getSteps();

  const cart = useSelector(state => state.cart)

  const { cartItems } = cart

//   const storeDetails = useSelector(state => state.storeDetails)
//     const { store } = storeDetails

const appointmentDetails = useSelector(state => state.appointmentDetails)
 const { loading, error, appointment } = appointmentDetails

 const appointmentRescheduleFee = useSelector(state => state.appointmentRescheduleFee)
 const { loading: loadingFee, error: errorFee, fee } = appointmentRescheduleFee

 const storeDetails = useSelector(state => state.storeDetails)
 const { loading: loadingStore } = storeDetails

 const appointmentReschedule = useSelector(state => state.appointmentReschedule)
    const { loading: loadingReschedule } = appointmentReschedule

    const appointmentRescheduleWallet = useSelector(state => state.appointmentRescheduleWallet)
    const { loading: loadingRescheduleWallet } = appointmentRescheduleWallet

 useEffect(() => {
    if (!appointment?._id){
        dispatch(getAppointmentDetails(id))
    } else {
      dispatch(getStoreDetails(appointment?.storeId?._id))
      dispatch(getAppointmentRescheduleFee(id))
    }
 }, [id])

  // const storeDetails = useSelector(state => state.storeDetails)
  // const { store } = storeDetails

  const isStepOptional = step => {
    return step === 1;
  };

  const isStepSkipped = step => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

  setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  
  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };
  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }
  setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(prevSkipped => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const getNextAvailableDay = () => {
    
    // var date = new Date()
    // var now = date.getDay()
    // var day = store.workingHours[1].weekday
    // var diff = day - now;
    // diff = diff < 1 ? 7 + diff : diff;
    // // Get the timestamp for the desired day
	  // var nextDayTimestamp = date.getTime() + (1000 * 60 * 60 * 24 * diff);
    // // Get the next day
    // const nextDate = new Date(nextDayTimestamp)
    // console.log(nextDate)
	  // return nextDate;
      // const currentDate = new Date()
      // const nextAvailableDay = store.workingHours[0].weekday || currentDate.getDay() 
      // console.log({nextAvailableDay})
    // const nextAvailableDate = new Date(
    //                                   currentDate.getFullYear(), 
    //                                   currentDate.getMonth() + 1, 
    //                                   0
    //                                   )

    // console.log(currentDate)
    // console.log(store.workingHours)
    // const currentDayExistsInAvailableDays = store.workingHours?.every((day) => day.weekday == currentDate.getDay())
    // console.log(currentDayExistsInAvailableDays)
    // return currentDate
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <DateTimeForm 
                  selectedDate={cart?.appointmentDate || new Date()} 
                  selectedTime={cart?.appointmentTime} 
                  activeStep={activeStep}
                  handleBack={handleBack}
                  handleNext={handleNext}
                />
      case 1:
        return <RescheduleCheckout 
                  activeStep={activeStep}
                  handleBack={handleBack}
                  handleNext={handleNext}  
              />
      default:
        return "Appointment scheduled successully";
    }
  }

  
  return (
   <AnimatedPage>
    { loading || loadingStore || loadingFee || loadingReschedule || loadingRescheduleWallet
        ? <BackDropLoader />
        :
        <Container sx={{mt: 8 }}>
            <Grid xs={12} md={6}>
                <Box sx={{width: "600px", margin: '0 auto',}}>
                    <Typography variant="h5" align="center" mb={4}>Reschedule Appointment</Typography>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        // if (isStepOptional(index)) {
                        //     labelProps.optional = "optional";
                        // }
                        // if (isStepSkipped(index)) {
                        //     stepProps.completed = false;
                        // }
                        return (
                            <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                        })}
                    </Stepper>
                    <div>
                        {activeStep === steps.length ? (
                        <div>
                            All steps completed
                            <Button onClick={handleReset}>Reset</Button>
                        </div>
                        ) : (
                        <Box sx={{margin: 2}} >
                            <StyledCard>
                            <Typography 
                                variant="body1"
                                fontWeight="bold" 
                                color="primary" 
                                align="right" 
                                gutterBottom
                                sx={{ display: (activeStep === steps.length -1) ? "none" : "" }}
                                >Reschedule Fee ${fee?.rescheduleFee}
                                </Typography>
                            {/* <Typography variant="h6" align="center" gutterBottom>Select {steps[activeStep]}</Typography> */}
                            {getStepContent(activeStep)}
                            </StyledCard>
                            {/* <Box style={{display:"flex", justifyContent: "flex-end", gap: "10px"}}>
                                <Button 
                                    disabled={activeStep === 0} 
                                    onClick={handleBack}
                                    variant="outlined"
                                >
                                    Back
                                </Button>
                                <Button  
                                variant="contained" 
                                color="primary" 
                                onClick={handleNext}
                                disabled={
                                    // cartItems.length === 0 || activeStep === steps.length - 1  
                                    cartItems.length === 0 
                                    ? true
                                    : !appointmentDate && !appointmentTime
                                }
                                >
                                Next
                                </Button>
                            </Box> */}
                        </Box>
                        )}
                    </div>                
                </Box>
            </Grid>
        </Container>
    }
  </AnimatedPage>
  );
}


export default RescheduleAppointment