export const STORE_ADD_REQUEST = 'STORE_ADD_REQUEST'
export const STORE_ADD_SUCCESS = 'STORE_ADD_SUCCESS'
export const STORE_ADD_FAIL = 'STORE_ADD_FAIL'
export const STORE_ADD_RESET = 'STORE_ADD_RESET'

export const STORE_SERVICE_CATEGORY_ADD_REQUEST = 'STORE_SERVICE_CATEGORY_ADD_REQUEST'
export const STORE_SERVICE_CATEGORY_ADD_SUCCESS = 'STORE_SERVICE_CATEGORY_ADD_SUCCESS'
export const STORE_SERVICE_CATEGORY_ADD_FAIL = 'STORE_SERVICE_CATEGORY_ADD_FAIL'
export const STORE_SERVICE_CATEGORY_ADD_RESET = 'STORE_SERVICE_CATEGORY_ADD_RESET'

export const STORE_SERVICE_CATEGORY_LIST_REQUEST = 'STORE_SERVICE_CATEGORY_LIST_REQUEST'
export const STORE_SERVICE_CATEGORY_LIST_SUCCESS = 'STORE_SERVICE_CATEGORY_LIST_SUCCESS'
export const STORE_SERVICE_CATEGORY_LIST_FAIL = 'STORE_SERVICE_CATEGORY_LIST_FAIL'

export const STORE_SERVICE_DETAILS_REQUEST = 'STORE_SERVICE_DETAILS_REQUEST'
export const STORE_SERVICE_DETAILS_SUCCESS = 'STORE_SERVICE_DETAILS_SUCCESS'
export const STORE_SERVICE_DETAILS_FAIL = 'STORE_SERVICE_DETAILS_FAIL'
export const STORE_SERVICE_DETAILS_RESET = 'STORE_SERVICE_DETAILS_RESET'

export const STORE_SERVICE_ADD_REQUEST = 'STORE_SERVICE_ADD_REQUEST'
export const STORE_SERVICE_ADD_SUCCESS = 'STORE_SERVICE_ADD_SUCCESS'
export const STORE_SERVICE_ADD_FAIL = 'STORE_SERVICE_ADD_FAIL'
export const STORE_SERVICE_ADD_RESET = 'STORE_SERVICE_ADD_RESET'

export const STORE_SERVICE_EDIT_REQUEST = 'STORE_SERVICE_EDIT_REQUEST'
export const STORE_SERVICE_EDIT_SUCCESS = 'STORE_SERVICE_EDIT_SUCCESS'
export const STORE_SERVICE_EDIT_FAIL = 'STORE_SERVICE_EDIT_FAIL'
export const STORE_SERVICE_EDIT_RESET = 'STORE_SERVICE_EDIT_RESET'

export const STORE_SERVICE_LIST_REQUEST = 'STORE_SERVICE_LIST_REQUEST'
export const STORE_SERVICE_LIST_SUCCESS = 'STORE_SERVICE_LIST_SUCCESS'
export const STORE_SERVICE_LIST_FAIL = 'STORE_SERVICE_LIST_FAIL'

export const STORE_SERVICE_DELETE_REQUEST = 'STORE_SERVICE_DELETE_REQUEST'
export const STORE_SERVICE_DELETE_SUCCESS = 'STORE_SERVICE_DELETE_SUCCESS'
export const STORE_SERVICE_DELETE_FAIL = 'STORE_SERVICE_DELETE_FAIL'
export const STORE_SERVICE_DELETE_RESET = 'STORE_SERVICE_DELETE_RESET'

export const STORE_DETAILS_REQUEST = 'STORE_DETAILS_REQUEST'
export const STORE_DETAILS_SUCCESS = 'STORE_DETAILS_SUCCESS'
export const STORE_DETAILS_FAIL = 'STORE_DETAILS_FAIL'
export const STORE_DETAILS_RESET = 'STORE_DETAILS_RESET'

export const STORE_SCHEDULE_DETAILS_REQUEST = 'STORE_SCHEDULE_DETAILS_REQUEST'
export const STORE_SCHEDULE_DETAILS_SUCCESS = 'STORE_SCHEDULE_DETAILS_SUCCESS'
export const STORE_SCHEDULE_DETAILS_FAIL = 'STORE_SCHEDULE_DETAILS_FAIL'

export const STORE_SCHEDULE_ADD_REQUEST = 'STORE_SCHEDULE_ADD_REQUEST'
export const STORE_SCHEDULE_ADD_SUCCESS = 'STORE_SCHEDULE_ADD_SUCCESS'
export const STORE_SCHEDULE_ADD_FAIL = 'STORE_SCHEDULE_ADD_FAIL'
export const STORE_SCHEDULE_ADD_RESET = 'STORE_SCHEDULE_ADD_RESET'

// ADMIN
export const STORE_DETAILS_UPDATE_REQUEST = 'STORE_DETAILS_UPDATE_REQUEST'
export const STORE_DETAILS_UPDATE_SUCCESS = 'STORE_DETAILS_UPDATE_SUCCESS'
export const STORE_DETAILS_UPDATE_FAIL = 'STORE_DETAILS_UPDATE_FAIL'
export const STORE_DETAILS_UPDATE_RESET = 'STORE_DETAILS_UPDATE_RESET'

export const STORE_DETAILS_VIEW_REQUEST = 'STORE_DETAILS_VIEW_REQUEST'
export const STORE_DETAILS_VIEW_SUCCESS = 'STORE_DETAILS_VIEW_SUCCESS'
export const STORE_DETAILS_VIEW_FAIL = 'STORE_DETAILS_VIEW_FAIL'
export const STORE_DETAILS_VIEW_RESET = 'STORE_DETAILS_VIEW_RESET'



