import axios from 'axios'
import moment from 'moment'
import {
    APPOINTMENT_ADD_FAIL,
    APPOINTMENT_ADD_REQUEST,
    APPOINTMENT_ADD_SUCCESS,
    APPOINTMENT_ADD_WALLET_FAIL,
    APPOINTMENT_ADD_WALLET_REQUEST,
    APPOINTMENT_ADD_WALLET_SUCCESS,
    APPOINTMENT_BARBER_LIST_FAIL,
    APPOINTMENT_BARBER_LIST_REQUEST,
    APPOINTMENT_BARBER_LIST_SUCCESS,
    APPOINTMENT_BARBER_UPCOMING_LIST_FAIL,
    APPOINTMENT_BARBER_UPCOMING_LIST_REQUEST,
    APPOINTMENT_BARBER_UPCOMING_LIST_SUCCESS,
    APPOINTMENT_CANCEL_ADMIN_FAIL,
    APPOINTMENT_CANCEL_ADMIN_REQUEST,
    APPOINTMENT_CANCEL_ADMIN_SUCCESS,
    APPOINTMENT_CANCEL_FAIL,
    APPOINTMENT_CANCEL_REQUEST,
    APPOINTMENT_CANCEL_SUCCESS,
    APPOINTMENT_CLIENT_UPCOMING_LIST_FAIL,
    APPOINTMENT_CLIENT_UPCOMING_LIST_REQUEST,
    APPOINTMENT_CLIENT_UPCOMING_LIST_SUCCESS,
    APPOINTMENT_DETAILS_FAIL,
    APPOINTMENT_DETAILS_REQUEST,
    APPOINTMENT_DETAILS_SUCCESS,
    APPOINTMENT_LIST_FAIL,
    APPOINTMENT_LIST_REQUEST,
    APPOINTMENT_LIST_SUCCESS,
    APPOINTMENT_MARK_COMPLETED_BARBER_FAIL,
    APPOINTMENT_MARK_COMPLETED_BARBER_REQUEST,
    APPOINTMENT_MARK_COMPLETED_BARBER_SUCCESS,
    APPOINTMENT_MARK_COMPLETED_CLIENT_FAIL,
    APPOINTMENT_MARK_COMPLETED_CLIENT_REQUEST,
    APPOINTMENT_MARK_COMPLETED_CLIENT_SUCCESS,
    APPOINTMENT_RESCHEDULE_FAIL,
    APPOINTMENT_RESCHEDULE_FEE_FAIL,
    APPOINTMENT_RESCHEDULE_FEE_REQUEST,
    APPOINTMENT_RESCHEDULE_FEE_SUCCESS,
    APPOINTMENT_RESCHEDULE_REQUEST,
    APPOINTMENT_RESCHEDULE_SUCCESS,
    APPOINTMENT_RESCHEDULE_WALLET_FAIL,
    APPOINTMENT_RESCHEDULE_WALLET_REQUEST,
    APPOINTMENT_RESCHEDULE_WALLET_SUCCESS,
    APPOINTMENT_USER_LIST_FAIL,
    APPOINTMENT_USER_LIST_REQUEST,
    APPOINTMENT_USER_LIST_SUCCESS,
    
} from '../constants/appointmentConstants'

//APP

export const addNewAppointmentUsingPaypalCheckout = ({
                        storeDetails,
                        services,
                        appointmentDate, 
                        appointmentTime,
                        totalPrice,
                        paymentMethod,
                        paypalPaymentResult,
                        addressDetails
                    }) => async (dispatch, getState) => {
    try {
        dispatch({
            type: APPOINTMENT_ADD_REQUEST
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }
        console.log("sending add appt to API")
        // const updatedDate = new Date(new Date(appointmentDate).getTime()+(1*24*60*60*1000))
        const updatedDate = new Date(new Date(appointmentDate).getTime())
        const dateString =  moment(updatedDate, 'YYYY-MM-DD HH:mm:ss').format();
        console.log(dateString)

        const { data } = await axios.post(
            '/api/appointments/paypal',
                {
                storeDetails,
                services,
                appointmentDate: dateString, 
                appointmentTime,
                totalPrice,
                paymentMethod,
                paypalPaymentResult,
                addressDetails
            },
            config
        )

        dispatch({
            type: APPOINTMENT_ADD_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: APPOINTMENT_ADD_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}


export const addNewAppointmentUsingWalletCheckout = ({
        storeDetails,
        services,
        appointmentDate, 
        appointmentTime,
        totalPrice,
        addressDetails
    }) => async (dispatch, getState) => {
       try {
            dispatch({
                type: APPOINTMENT_ADD_WALLET_REQUEST
            })

            const { userLogin: { userInfo }} = getState()

            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.accessToken}`
                },
            }
            // const updatedDate = new Date(new Date(appointmentDate).getTime()+(1*24*60*60*1000))
            const updatedDate = new Date(new Date(appointmentDate).getTime())
            const dateString =  moment(updatedDate, 'YYYY-MM-DD HH:mm:ss').format();
            console.log(dateString)

            const { data } = await axios.post('/api/appointments/wallet',{
                    storeDetails,
                    services,
                    appointmentDate: dateString, 
                    appointmentTime,
                    totalPrice,
                    addressDetails
                },
                config
            )

            dispatch({
                type: APPOINTMENT_ADD_WALLET_SUCCESS,
                payload: data
            })

        } catch (error) {
            dispatch({
                type: APPOINTMENT_ADD_WALLET_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message
        })
}
}


export const listUserAppointments = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: APPOINTMENT_USER_LIST_REQUEST,
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.get('/api/appointments/client', config)

        dispatch({
            type: APPOINTMENT_USER_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: APPOINTMENT_USER_LIST_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const listClientUpcomingAppointments = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: APPOINTMENT_CLIENT_UPCOMING_LIST_REQUEST,
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.get('/api/appointments/client/upcoming', config)

        dispatch({
            type: APPOINTMENT_CLIENT_UPCOMING_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: APPOINTMENT_CLIENT_UPCOMING_LIST_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const listBarberAppointments = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: APPOINTMENT_BARBER_LIST_REQUEST,
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.get('/api/appointments/barber', config)

        dispatch({
            type: APPOINTMENT_BARBER_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: APPOINTMENT_BARBER_LIST_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const listBarberUpcomingAppointments = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: APPOINTMENT_BARBER_UPCOMING_LIST_REQUEST,
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.get('/api/appointments/barber/upcoming', config)

        dispatch({
            type: APPOINTMENT_BARBER_UPCOMING_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: APPOINTMENT_BARBER_UPCOMING_LIST_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}


export const listAppointments = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: APPOINTMENT_LIST_REQUEST,
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.get('/api/appointments', config)

        dispatch({
            type: APPOINTMENT_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: APPOINTMENT_LIST_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const getAppointmentDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: APPOINTMENT_DETAILS_REQUEST
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.get(`/api/appointments/details/${id}`, config)

        dispatch({
            type: APPOINTMENT_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: APPOINTMENT_DETAILS_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}
export const markAppointmentAsCompletedByClient = (id) => async (dispatch, getState) => {

    try {
        dispatch({
            type: APPOINTMENT_MARK_COMPLETED_CLIENT_REQUEST
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.put(`/api/appointments/${id}/complete/client`, {}, config)
        dispatch({
            type: APPOINTMENT_MARK_COMPLETED_CLIENT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: APPOINTMENT_MARK_COMPLETED_CLIENT_FAIL,
                    payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message
                })
    }
}

export const markAppointmentAsCompletedByBarber = (id) => async (dispatch, getState) => {

    try {
        dispatch({
            type: APPOINTMENT_MARK_COMPLETED_BARBER_REQUEST
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.put(`/api/appointments/${id}/complete/barber`, {}, config)
        dispatch({
            type: APPOINTMENT_MARK_COMPLETED_BARBER_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: APPOINTMENT_MARK_COMPLETED_BARBER_FAIL,
                    payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message
                })
    }
}

export const cancelAppointment = (id) => async (dispatch, getState) => {
    console.log("cancel appt called")
    try {
        dispatch({
            type: APPOINTMENT_CANCEL_REQUEST
        })

       const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.put(`/api/appointments/${id}`,{}, config)
        console.log({data})
        dispatch({
            type: APPOINTMENT_CANCEL_SUCCESS,
            payload: data
        })

    } catch (error) {
        console.log({error})
        dispatch({
                    type: APPOINTMENT_CANCEL_FAIL,
                    payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message
                })
    }
}

export const getAppointmentRescheduleFee = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: APPOINTMENT_RESCHEDULE_FEE_REQUEST
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.get(`/api/appointments/${id}/reschedule-fee`, config)

        dispatch({
            type: APPOINTMENT_RESCHEDULE_FEE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: APPOINTMENT_RESCHEDULE_FEE_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const rescheduleAppointmentWithPaypal = ({
        id, 
        appointmentDate, 
        appointmentTime, 
        paypalPaymentResult
    }) => async (dispatch, getState) => {
    try {
        dispatch({
            type: APPOINTMENT_RESCHEDULE_REQUEST
        })

       const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.put(`/api/appointments/${id}/reschedule/paypal`,
                                            {
                                                appointmentDate, 
                                                appointmentTime,
                                                paypalPaymentResult
                                            }, 
                                            config
                                        )
        dispatch({
            type: APPOINTMENT_RESCHEDULE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: APPOINTMENT_RESCHEDULE_FAIL,
                    payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message
                })
    }
}

export const rescheduleAppointmentWithWallet = ({
    id, 
    appointmentDate, 
    appointmentTime
}) => async (dispatch, getState) => {
try {
    dispatch({
        type: APPOINTMENT_RESCHEDULE_WALLET_REQUEST
    })

   const { userLogin: { userInfo }} = getState()

    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.accessToken}`
        },
    }

    const { data } = await axios.put(`/api/appointments/${id}/reschedule/wallet`,
                                        {
                                            appointmentDate, 
                                            appointmentTime
                                        }, 
                                        config
                                    )
    dispatch({
        type: APPOINTMENT_RESCHEDULE_WALLET_SUCCESS,
        payload: data
    })

} catch (error) {
    dispatch({
                type: APPOINTMENT_RESCHEDULE_WALLET_FAIL,
                payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message
            })
}
}

// ADMIN
export const listBarberAppointmentsAdmin = (storeId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: APPOINTMENT_BARBER_LIST_REQUEST,
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.get(`/api/appointments/admin/${storeId}`, config)

        dispatch({
            type: APPOINTMENT_BARBER_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: APPOINTMENT_BARBER_LIST_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const cancelAppointmentAdmin = (id) => async (dispatch, getState) => {
    console.log("cancel appt admin called")
    try {
        dispatch({
            type: APPOINTMENT_CANCEL_ADMIN_REQUEST
        })

       const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.put(`/api/appointments/admin/${id}/cancel`,{}, config)
        console.log({data})
        dispatch({
            type: APPOINTMENT_CANCEL_ADMIN_SUCCESS,
            payload: data
        })

    } catch (error) {
        console.log({error})
        dispatch({
                    type: APPOINTMENT_CANCEL_ADMIN_FAIL,
                    payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message
                })
    }
}

