import axios from "axios";
import { CONTACT_MESSAGE_ADD_FAIL, CONTACT_MESSAGE_ADD_REQUEST, CONTACT_MESSAGE_ADD_SUCCESS, CONTACT_MESSAGE_DETAILS_FAIL, CONTACT_MESSAGE_DETAILS_REQUEST, CONTACT_MESSAGE_DETAILS_SUCCESS, CONTACT_MESSAGE_LIST_FAIL, CONTACT_MESSAGE_LIST_REQUEST, CONTACT_MESSAGE_LIST_SUCCESS, CONTACT_MESSAGE_UPDATE_FAIL, CONTACT_MESSAGE_UPDATE_REQUEST, CONTACT_MESSAGE_UPDATE_SUCCESS } from "../constants/contactConstants";


export const addNewContactMessage = ({ name, email, subject, message  }) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CONTACT_MESSAGE_ADD_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'application/json',
            },
        }

        const { data } = await axios.post(
            `/api/contacts`,
            { name, email, subject, message },
            config
        )

        dispatch({
            type: CONTACT_MESSAGE_ADD_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: CONTACT_MESSAGE_ADD_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const getContactMessages = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: CONTACT_MESSAGE_LIST_REQUEST
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.get(
            `/api/contacts`,
            config
        )

        dispatch({
            type: CONTACT_MESSAGE_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: CONTACT_MESSAGE_LIST_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const getContactMessageDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CONTACT_MESSAGE_DETAILS_REQUEST
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.get(
            `/api/contacts/${id}`,
            config
        )

        dispatch({
            type: CONTACT_MESSAGE_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: CONTACT_MESSAGE_DETAILS_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}


export const updateContactMessage = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CONTACT_MESSAGE_UPDATE_REQUEST
        })

        const { userLogin: { userInfo }} = getState()


        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.patch(
            `/api/contacts/${id}`,
            {},
            config
        )

        dispatch({
            type: CONTACT_MESSAGE_UPDATE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: CONTACT_MESSAGE_UPDATE_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}