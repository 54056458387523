import React, { useEffect, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import { IconButton, Paper, Typography, useMediaQuery } from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import Rating from '@material-ui/lab/Rating'

import useStyles from './styles';

import { Link } from 'react-router-dom';


const Map = ({ coordinates, setCoordinates, setChildClicked }) => {
  const classes = useStyles();
  const isDesktop = useMediaQuery('(min-width:600px)');
  const [centerCoordinates, setCenterCoordinates] = useState({})
  
  useEffect(() => {
    if (coordinates) {
      setCenterCoordinates(coordinates)
    }
  }, [])

  //AIzaSyB3ezN_I7Dj2LPapIciwQiOGQWudADnl2A

  return (
    <div className={classes.mapContainer} >
      {/* {!isDesktop && <Heading text="Map View"/>} */}
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyB3ezN_I7Dj2LPapIciwQiOGQWudADnl2A' }}
        defaultCenter={centerCoordinates}
        center={coordinates}
        defaultZoom={14}
        margin={[50, 50, 50, 50]}
        options={''}
        onChange={(e) => {
          setCoordinates({lat: e.center.lat, lng: e.center.lng});
        }}
        // onChildClick={(child) => setChildClicked(child)} 
      >
        {coordinates &&
          <div
            className={classes.markerContainer}
            lat={Number(coordinates?.lat)}
            lng={Number(coordinates?.lng)}
          >
              <IconButton>
                <LocationOnIcon color="error" fontSize="large" />
              </IconButton>
          </div>
        }
      </GoogleMapReact>
    </div>
  )
}

export default Map;


