import Group from '@mui/icons-material/Group';
import { Typography, Card, Box, Paper } from '@mui/material';
import { styled } from "@mui/material/styles"

const StyledCard = styled(Card)(({ theme }) => ({

   height: "116px",
   padding: "8px",
   color: "grey",
   borderRadius: "14px"
    // width: '80vw',
}))

const StyleTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.contrastText,
}))

// 8c8c8c

const DataWidget = ({borderColor, Icon, title, value}) => {
  return (
    
    // sx={{ border: `1px solid ${borderColor}` }}
    // <StyledCard align="center" >
    //     <Box mb={1}>
    //       {Icon}
    //     </Box>
    //     <StyleTypography variant="h6" gutterBottom>{value}</StyleTypography>
    //     <Typography>{title}</Typography>
    // </StyledCard>

<Paper elevation={3} sx={{ p: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
<Typography >{title}</Typography>
<Box
  sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }}
>
  {Icon}
  <Typography variant="h5">{value}</Typography>
</Box>
</Paper>
  )
}

export default DataWidget