import {
    TICKET_ADD_FAIL,
    TICKET_ADD_MESSAGE_FAIL,
    TICKET_ADD_MESSAGE_REQUEST,
    TICKET_ADD_MESSAGE_RESET,
    TICKET_ADD_MESSAGE_SUCCESS,
    TICKET_ADD_REQUEST, TICKET_ADD_RESET, TICKET_ADD_SUCCESS, TICKET_DETAILS_FAIL, TICKET_DETAILS_REQUEST, TICKET_DETAILS_SUCCESS, TICKET_LIST_FAIL, TICKET_LIST_REQUEST, TICKET_LIST_SUCCESS, TICKET_MARK_OPENED_FAIL, TICKET_MARK_OPENED_REQUEST, TICKET_MARK_OPENED_RESET, TICKET_MARK_OPENED_SUCCESS, TICKET_MARK_RESOLVED_FAIL, TICKET_MARK_RESOLVED_REQUEST, TICKET_MARK_RESOLVED_RESET, TICKET_MARK_RESOLVED_SUCCESS, TICKET_MESSAGE_LIST_FAIL, TICKET_MESSAGE_LIST_REQUEST, TICKET_MESSAGE_LIST_SUCCESS, TICKET_USER_LIST_FAIL, TICKET_USER_LIST_REQUEST, TICKET_USER_LIST_SUCCESS
} from '../constants/ticketConstants'

export const ticketAddReducer = (state = {}, action) => {
    switch (action.type) {
        case TICKET_ADD_REQUEST:
            return { loading: true}
        case TICKET_ADD_SUCCESS:
            return { loading: false, success: true  }
        case TICKET_ADD_FAIL:
            return { loading: false, error: action.payload }
        case TICKET_ADD_RESET:
            return { success: false }
        default:
            return state
    }
}

export const ticketUserListReducer = (state = { tickets: [] }, action) => {
    switch (action.type) {
        case TICKET_USER_LIST_REQUEST:
            return { loading: true}
        case TICKET_USER_LIST_SUCCESS:
            return { loading: false, tickets: action.payload }
        case TICKET_USER_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const ticketListReducer = (state = { tickets: [] }, action) => {
    switch (action.type) {
        case TICKET_LIST_REQUEST:
            return { loading: true}
        case TICKET_LIST_SUCCESS:
            return { loading: false, tickets: action.payload }
        case TICKET_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const ticketMessageAddReducer = (state = { message: {} }, action) => {
    switch (action.type) {
        case TICKET_ADD_MESSAGE_REQUEST:
            return { loading: true}
        case TICKET_ADD_MESSAGE_SUCCESS:
            return { loading: false, message: action.payload  }
        case TICKET_ADD_MESSAGE_FAIL:
            return { loading: false, error: action.payload }
        case TICKET_ADD_MESSAGE_RESET:
            return { message: {} }
        default:
            return state
    }
}

export const ticketDetailsReducer = (state = { ticket: {} }, action) => {
    switch (action.type) {
        case TICKET_DETAILS_REQUEST:
            return { loading: true}
        case TICKET_DETAILS_SUCCESS:
            return { loading: false, ticket: action.payload  }
        case TICKET_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const ticketMessageListReducer = (state = { messages: [] }, action) => {
    switch (action.type) {
        case TICKET_MESSAGE_LIST_REQUEST:
            return { loading: true}
        case TICKET_MESSAGE_LIST_SUCCESS:
            return { loading: false, messages: action.payload }
        case TICKET_MESSAGE_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}


export const ticketMarkResolvedReducer = (state = {}, action) => {
    switch (action.type) {
        case TICKET_MARK_RESOLVED_REQUEST:
            return { loading: true}
        case TICKET_MARK_RESOLVED_SUCCESS:
            return { loading: false, success: true }
        case TICKET_MARK_RESOLVED_FAIL:
            return { loading: false, error: action.payload }
        case TICKET_MARK_RESOLVED_RESET:
            return { loading: false, success: false }
        default:
            return state
    }
}

export const ticketMarkOpenedReducer = (state = {}, action) => {
    switch (action.type) {
        case TICKET_MARK_OPENED_REQUEST:
            return { loading: true}
        case TICKET_MARK_OPENED_SUCCESS:
            return { loading: false, success: true }
        case TICKET_MARK_OPENED_FAIL:
            return { loading: false, error: action.payload }
        case TICKET_MARK_OPENED_RESET:
            return { loading: false, success: false }
        default:
            return state
    }
}