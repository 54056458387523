import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input, Box, Button, Stack, IconButton, Typography,Container } from '@mui/material';
import { grey } from '@mui/material/colors';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { styled } from "@mui/material/styles"
import AnimatedPage from '../../../components/Layout/AnimatedPage'
import BasicCard from '../../../components/ui/BasicCard'
import Loader from '../../../components/ui/Loader'
import TextButton from '../../../components/ui/TextButton'
import CategoryModal from '../../../components/ui/Modals/CategoryModal'
import ServiceModal from '../../../components/ui/Modals/ServiceModal'
import EditServiceModal from '../../../components/ui/Modals/EditServiceModal'
import { 
  deleteServiceAdmin,
  deleteServices,
  listServices
 } from '../../../redux/actions/storeActions'
import { 
    STORE_SERVICE_ADD_RESET, 
    STORE_SERVICE_CATEGORY_ADD_RESET, 
    STORE_SERVICE_DELETE_RESET,
    STORE_SERVICE_EDIT_RESET
  } from '../../../redux/constants/storeConstants';
import BackDropLoader from '../../../components/ui/BackDropLoader';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import ROLES from '../../../constants/roleConstants';
import { useParams } from 'react-router-dom';
import ConfirmModal from '../../../components/ui/Modals/ConfirmModal';


const StyledContainer = styled(Container)(({ theme }) => ({
  width: "60vw"
}))

const StyledHeaderBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "end",
  // background: theme.palette.custom.lightgray,
  paddingBottom: "8px",
  [theme.breakpoints.down('md')]: {
    justifyContent: "center",
  },
}))

const StyledBox = styled(Box)(({ theme }) => ({
  // display: "flex",
  // justifyContent: "end",
  // background: theme.palette.custom.lightgray,
  margin: "40px auto"
}))

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  root: {
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "none !important"
    },
    "& .MuiDataGrid-cell": {
      lineHeight: "unset !important",
      maxHeight: "none !important",
      whiteSpace: "normal"
    },
    "& .MuiDataGrid-row": {
      maxHeight: "none !important"
    }
  }
}))

const BarberServices = () => {
  const { id: storeId } = useParams()
  const [openCategoryModal, setOpenCategoryModal] = useState(false)
  const [openServiceModal, setOpenServiceModal] = useState(false)
  const [selectedServiceId, setSelectedServiceId] = useState(null)
  const [openEditServiceModal, setOpenEditServiceModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [loading, setLoading] = useState(false)
  // const [message, setMessage] = useState({text: null, type: 'info'})
  // const [openAlert, setOpenAlert] = useState(false) 

  const [servicesWithCategory, setServicesWithCategory] = useState([])
  // const [services, setServices] = useState([])
  // const [searchResults, setSearchResults] = useState(services)
  const [pageSize, setPageSize] = useState(10);
  const [rowId, setRowId] = useState(null);

  const dispatch = useDispatch()

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const serviceList = useSelector(state => state.serviceList)
  const {  loading: loadingServices, services } = serviceList

  const serviceCategoryAdd = useSelector(state => state.serviceCategoryAdd)
  const { success: successAddCategory } = serviceCategoryAdd

  const serviceAdd = useSelector(state => state.serviceAdd)
  const { success: successAddService } = serviceAdd

  const serviceEdit = useSelector(state => state.serviceEdit)
  const { success: successEditService } = serviceEdit

  const serviceDelete= useSelector(state => state.serviceDelete)
  const { error: errorDeleteService, success: successDeleteService } = serviceDelete

  const storeAndBarberDetails = useSelector(state => state.storeAndBarberDetails)
  const { loading: loadingDetails, error: errorDetails, data } = storeAndBarberDetails

  useEffect(() => {
   if (userInfo?.role === ROLES.BARBER) dispatch(listServices())
  }, [])

  const generateServicesData = (services) => {
    let servicesData = []
    if (services?.length > 0) {
      services?.map((item, index) => {
        item?.subCategories.map((service) => {
          servicesData.push({
            _id: service._id,
            category: item.category,
            name: service.name,
            amount: service.amount,
            duration: service.duration,
            description: service.description,
            status: service.status
          })
        })
      })
    return servicesData
  }
}

  useEffect(() => {
    // let categoryName
    // let inex
    // let servicesData = []
    setLoading(true)
    // if (services?.length > 0) {
    //   services?.map((item, index) => {
    //     item?.subCategories.map((service) => {
    //       servicesData.push({
    //         _id: service._id,
    //         category: item.category,
    //         name: service.name,
    //         amount: service.amount,
    //         duration: service.duration,
    //         description: service.description,
    //         status: service.status
    //       })
    //     })
    //   })
    //   setServicesWithCategory(servicesData)
    // }
    setServicesWithCategory(generateServicesData(services))
    setLoading(false)
  }, [services])

  useEffect(() => {
    setLoading(true)
    setServicesWithCategory(generateServicesData(data?.store?.services))
    setLoading(false)
  }, [data?.store?.services])

  useEffect(() => {
    if (userInfo?.role === ROLES.BARBER) dispatch(listServices())
      if(successAddCategory) {
        // setMessage({ text: "Category added successfully.", type: "success"})
        // setOpenAlert(true)
        dispatch({type: 'UPDATE_ALERT', payload: { open: true, severity: 'success', message: "Category added successfully"}})
        dispatch({ type: STORE_SERVICE_CATEGORY_ADD_RESET })
    }
      if(successAddService) {
          // setMessage({ text: "Service added successfully.", type: "success"})
          // setOpenAlert(true)
          dispatch({type: 'UPDATE_ALERT', payload: { open: true, severity: 'success', message: "Service added successfully"}})
          dispatch({ type: STORE_SERVICE_ADD_RESET })
      }

      if(successDeleteService) {
        // setMessage({ text: "Service deleted successfully.", type: "success"})
        // setOpenAlert(true)
        dispatch({type: 'UPDATE_ALERT', payload: { open: true, severity: 'success', message: "Service deleted successfully"}})
        dispatch({ type: STORE_SERVICE_DELETE_RESET })
      }

      if(successEditService) {
        // setMessage({ text: "Service updated successfully.", type: "success"})
        // setOpenAlert(true)
        dispatch({type: 'UPDATE_ALERT', payload: { open: true, severity: 'success', message: "Service updated successfully"}})
        dispatch({ type: STORE_SERVICE_EDIT_RESET })
      }
  },[dispatch, successAddCategory, successAddService, successDeleteService, successEditService])

  //Delete Service
  useEffect(() => {
    if(errorDeleteService) {
      // setMessage({ text: errorDeleteService, type: "error"})
      // setOpenAlert(true)
      dispatch({type: 'UPDATE_ALERT', payload: { open: true, severity: 'error', message: "Error deleting service"}})
    }
  },[errorDeleteService]) 

  const handleEditService = (serviceId) => {
    console.log("edit called ", serviceId)
    setSelectedServiceId(serviceId)
    setOpenEditServiceModal(true)
    // dispatch(deleteServices(serviceId))
  }

  const handleDeleteService = (serviceId) => {
    if (userInfo?.role === ROLES.ADMIN) dispatch(deleteServiceAdmin({storeId, serviceId}))
    else dispatch(deleteServices(serviceId))
  }

  const NoRowsOverlay = () => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        No services available
      </Stack>
    );
  }

  const columns = useMemo(
    () => [
    { 
    field: 'name',
    headerName: 'Name', 
    width: 200,
    valueGetter: (params) => params.row.name
    },
    {
      field: 'amount',
      headerName: 'Amount (AUD)',
      width: 140,
      valueGetter: (params) => "$"+params.row.amount
    },
    {
      field: 'duration',
      headerName: 'Duration (m)',
      width: 140,
      valueGetter: (params) => params.row.duration + " mins",
      sortable: false 
    }, 
    {
      field: 'category',
      headerName: 'Category',
      width: 140,
      valueGetter: (params) => params.row.category
    }, 
        {
        field: 'status',
        headerName: 'Status',
        width: 140,
        filterable: false,
        // sortable: false,
        renderCell: (params) => {
          let status, color="primary", disabled=false
          if (params.row.status === "active") {
            status = "Active"
            color = "success"
          }
          else if (params.row.status === "inactive")  {
            status = "In active"
            color = "warning"
            disabled = true
          }
          else status = ""
          return (
            <TextButton 
            variant="outlined" 
            size="small"
            color={color}
            disabled={disabled}
            >
                {status}
            </TextButton>
            // status
          )
        }
        },
        {
          field: 'desc',
          headerName: 'Description',
          // width: 260,
          flex: 1,
          valueGetter: (params) => params.row.description
        }, 
      {
        field: 'actions',
        headerName: 'Actions',
        // type: 'actions',
        width: 240,
        renderCell: (params) => (
          <>
            <Button 
              variant='contained' 
              size="small"
              startIcon={<EditIcon />}
              sx={{margin: "0 14px"}}
              onClick={() => handleEditService(params.row._id)}
            >
              Edit
            </Button>
            <Button 
              variant='contained' 
              size="small"
              startIcon={<DeleteIcon />}
              color="error"
              onClick={() => handleDeleteService(params.row._id)}
            >    
              Delete
            </Button>
          </>
        ),
      },
    ],
    [rowId]
  );


  const getHeader = () => {
      const handleSearch = (value) => {
        filterData(value)
      };

      const filterData = (value) => {
        // const lowercasedValue = value.toLowerCase().trim()
        // if (lowercasedValue === '') setServices(searchResults)
        // else {
        //   const filteredData = searchResults.filter(item => {
        //     return Object.keys(item).some(key => 
        //       item[key].toString().toLowerCase().includes(lowercasedValue)
        //       )
        //   })
        //   setServices(filteredData)
        // }
      }

      return (
        
        <StyledHeaderBox>
          {/* <SearchBar 
            placeholder="Search by name"
            onChange={(e) => handleSearch(e.target.value)}
          /> */}
          <Box>
            <Box>
            <Button 
                variant="outlined"
                size="small"
                style={{ margin: "0 10px" }}
                startIcon={<AddIcon />}
                onClick={() => setOpenCategoryModal(true)}
                >
                  Category
              </Button>
              <Button 
                variant="contained"
                size="small"
                startIcon={<AddIcon />}
                onClick={() => setOpenServiceModal(true)}
                // disabled={services?.length > 0 ? false : true}
                >
                  Service
              </Button>
            </Box>
            {/* <IconTextButton>
              <RefreshIcon />
            </IconTextButton> */}

          </Box>
          
        </StyledHeaderBox>
      )
  }

  return (
    <>
    {
      loading || loadingServices
      ? <BackDropLoader />
      : 
      <Box
      sx={{
          // height: '70vh',
          height: '80vh',
          width: '100%',
          // position: 'absolute'
      }}
      >
        {userInfo?.role === ROLES.BARBER && getHeader()}
      {/* <Typography
          variant="h4"
          component="h4"
          sx={{ textAlign: 'center', mt: 3, mb: 3 }}
      >
          Services List
      </Typography> */}
      <DataGrid
          columns={columns}
          rows={servicesWithCategory || []}
          getRowId={(row) => row._id}
          rowsPerPageOptions={[5, 10, 20]}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          components={{ NoRowsOverlay}}
          getRowSpacing={(params) => ({
          top: params.isFirstVisible ? 0 : 5,
          bottom: params.isLastVisible ? 0 : 5,
          })}
          sx={{
          [`& .${gridClasses.row}`]: {
              bgcolor: (theme) =>
              theme.palette.mode === 'light' ? grey[200] : grey[900],
          },
          }}
          onCellEditCommit={(params) => setRowId(params.id)}
      />
        {/* Modals */}
        <CategoryModal 
          title="Add New Category"
          open={openCategoryModal}
          onClose={ () => setOpenCategoryModal(false)}
        />

        <ServiceModal 
          title="Add New Service"
          open={openServiceModal}
          onClose={ () => setOpenServiceModal(false)}
        />
          { openEditServiceModal &&
             <EditServiceModal 
             title="Edit Service"
             serviceId={selectedServiceId}
             open={openEditServiceModal}
             onClose={ () => setOpenEditServiceModal(false)}
           />
          }
      </Box> 
    }
    </>
  )
}

export default BarberServices