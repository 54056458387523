import React from 'react'
import { Alert, Snackbar } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_ALERT } from '../../redux/constants/alertConstants';

const Notification = () => {
    // console.log("Notification called")
  const dispatch = useDispatch()
  const alertData = useSelector(state => state.alertData)
  const { alert } = alertData
    // console.log({alert})

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return
    dispatch({ type: UPDATE_ALERT, payload: { ...alert, open: false } })
  };
  return (
    <Snackbar
      open={alert.open}
      autoHideDuration={8000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      sx={{ opacity: 0.9 }}
    >
      <Alert
        onClose={handleClose}
        severity={alert.severity}
        sx={{ width: '100%', marginTop: "10px"}}
        variant="filled"
        elevation={6}
      >
        {alert.message}
      </Alert>
    </Snackbar>
  );
};

export default Notification;