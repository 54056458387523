export const TRANSACTION_USER_LIST_REQUEST = 'TRANSACTION_USER_LIST_REQUEST'
export const TRANSACTION_USER_LIST_SUCCESS = 'TRANSACTION_USER_LIST_SUCCESS'
export const TRANSACTION_USER_LIST_FAIL = 'TRANSACTION_USER_LIST_FAIL'

export const TRANSACTION_LIST_REQUEST = 'TRANSACTION_LIST_REQUEST'
export const TRANSACTION_LIST_SUCCESS = 'TRANSACTION_LIST_SUCCESS'
export const TRANSACTION_LIST_FAIL = 'TRANSACTION_LIST_FAIL'

export const TRANSACTION_ADMIN_LIST_REQUEST = 'TRANSACTION_ADMIN_LIST_REQUEST'
export const TRANSACTION_ADMIN_LIST_SUCCESS = 'TRANSACTION_ADMIN_LIST_SUCCESS'
export const TRANSACTION_ADMIN_LIST_FAIL = 'TRANSACTION_ADMIN_LIST_FAIL'

export const TRANSACTION_ADMIN_VERIFY_REQUEST = 'TRANSACTION_ADMIN_VERIFY_REQUEST'
export const TRANSACTION_ADMIN_VERIFY_SUCCESS = 'TRANSACTION_ADMIN_VERIFY_SUCCESS'
export const TRANSACTION_ADMIN_VERIFY_FAIL = 'TRANSACTION_ADMIN_VERIFY_FAIL'
export const TRANSACTION_ADMIN_VERIFY_RESET = 'TRANSACTION_ADMIN_VERIFY_RESET'