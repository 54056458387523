import './message.css'
import { format } from 'timeago.js'

const Message = ({ message, own, profilePicture }) => {
  return (
    <div className={own ? "message own" : "message"} >
        <div className="messageWrapper">
            <div className="messageTop">
                <img 
                    className="messageImg"
                    src={profilePicture ? profilePicture : "/images/noAvatar.png"}
                    alt="user image"
                />
                <p className="messageText">{message.text}</p>
            </div>
            <div className="messageBottom">{format(message.createdAt)}</div>
        </div>
    </div>
    //  <div className={own ? "message own" : "message"} >
    //  <div className="messageWrapper">
    //      <div className="messageTop">
    //          <img 
    //              className="messageImg"
    //              src={profilePicture ? profilePicture : "/images/noAvatar.png"}
    //             //  src="/images/noAvatar.png"
    //              alt="user image"
    //          />
    //          <p className="messageText">This is message</p>
    //      </div>
    //      <div className="messageBottom">1 hour ago</div>
    //  </div>
    // </div>
  )
}

export default Message