import { Box, CircularProgress, Fab } from '@mui/material';
import { useEffect, useState } from 'react';
import { Check, Save, Visibility } from '@mui/icons-material';
import { green } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import { updateStoreDetails } from '../../../redux/actions/storeActions';
import { UPDATE_ALERT } from '../../../redux/constants/alertConstants';
import { USER_STATUS_UPDATE_RESET } from '../../../redux/constants/userConstants';
import { STORE_DETAILS_UPDATE_RESET } from '../../../redux/constants/storeConstants';
import { useNavigate } from 'react-router-dom';


const UsersActions = ({ params, rowId, setRowId }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const storeDetailsUpdate = useSelector(state => state.storeDetailsUpdate)
  const {  error, success: updateStatus } = storeDetailsUpdate


  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true);
    const { _id, status, isVerified, experience, forMen, forWomen} = params.row;
    dispatch(updateStoreDetails({ _id, isVerified, experience, forMen, forWomen,  status }))
  };

  useEffect(() => {
    if (rowId === params.id && updateStatus) {
      setLoading(false)
        dispatch({ type: UPDATE_ALERT, 
            payload: 
            {open: true, severity: 'success', message: 'Store details updated successfully.' 
          } 
        })
        dispatch({ type: STORE_DETAILS_UPDATE_RESET })
    }
    if(error) {
      setLoading(false)
      dispatch({ type: UPDATE_ALERT, 
        payload: 
        {open: true, severity: 'error', message: error 
      } 
    })
    }
  }, [rowId, updateStatus, error]);

  const viewBarberDetailsHandler = (e) => {
    e.preventDefault()
    const { _id } = params.row;
    navigate(`/admin/barber-details/${_id}`)
  }

  return (
    <Box display="flex">
    <Box
        sx={{
          m: 1,
          position: 'relative',
        }}
      >
          <Fab
            color="primary"
            sx={{
              width: 40,
              height: 40,
            }}
            onClick={viewBarberDetailsHandler}
          >
            <Visibility />
          </Fab>
      </Box>
    <Box
      sx={{
        m: 1,
        position: 'relative',
      }}
    >
        <Fab
          color="success"
          sx={{
            width: 40,
            height: 40,
          }}
          disabled={params.id !== rowId || loading}
          onClick={handleSubmit}
        >
          <Save />
        </Fab>
       {/* )}  */}
      {loading && (
        <CircularProgress
          size={52}
          sx={{
            color: green[500],
            position: 'absolute',
            top: -6,
            left: -6,
            zIndex: 1,
          }}
        />
      )}
    </Box>
    </Box>
  );
};

export default UsersActions;