import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Avatar, Grid, Button, TextField, Box, Typography } from '@mui/material'
// import GoogleIcon from '@mui/icons-material/Google'

// import { gapi } from 'gapi-script'

import { googleAuth, login, savePassword } from '../../redux/actions/userActions'

import AnimatedPage from '../../components/Layout/AnimatedPage'
import Loader from '../../components/ui/Loader'
import Alert from '../../components/ui/Alert'
import FormContainer from '../../components/ui/Form/FormContainer'
import Password from '../../components/ui/Form/Password'

import roleBasedRedirect from '../../utils/roleBasedRedirect'
import { USER_REGISTER_RESET, USER_VERIFY_RESET_CODE_RESET } from '../../redux/constants/userConstants'
import GoogleAuthButton from '../../components/ui/Form/GoogleAuthButton';
import FacebookAuthButton from '../../components/ui/Form/FacebookAuthButton';

import LockIcon from '@mui/icons-material/Lock';


const Login = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState(null)
  const [openAlert, setOpenAlert] = useState(false) 
 
  const userLogin = useSelector(state => state.userLogin)
  const { loading, userInfo } = userLogin

  const userVerifyResetCode = useSelector(state => state.userVerifyResetCode)
  const { token } = userVerifyResetCode
  
  const userSavePassword = useSelector(state => state.userSavePassword)
  const { error, success } = userSavePassword

  // const from = location.state?.from?.pathname

  useEffect(() => {
    if (userInfo)
        // navigate(from, { replace: true })
        navigate('/')
        // dispatch(logout())
},[userInfo])  

useEffect(() => {
  if (success) {
    dispatch({ type: USER_VERIFY_RESET_CODE_RESET })
    navigate('/sign-in')
  }
  if(error) {
    setMessage(error)
    setOpenAlert(true)
  }
},[error, success]) 

  const submitHandler = (e) => {
    e.preventDefault();
    if (password.length < 6) {
      setMessage('Password must be of at least 6 characters.')
      setOpenAlert(true)
    }
    else if (password !== confirmPassword) {
        setMessage('Password do not match.')
        setOpenAlert(true)
    } else {
        setMessage(null)
        setOpenAlert(false)
        dispatch(savePassword({resetToken: token?.resetToken, password}))
    }       
  }

  return (
    <AnimatedPage>
      <FormContainer>
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <LockIcon />
          </Avatar>
          <Typography variant="h6">
            New Password
          </Typography>
          <Box component="form" onSubmit={submitHandler} sx={{ mt: 3 }}>
            {loading && <Loader />}
            {message  &&
             <Alert 
                type="error" 
                message={message}
                openAlert={openAlert}
                setOpenAlert={setOpenAlert}
              />
            }
            <Grid container spacing={2}>
              <Grid item xs={12} mb={1}>
               <Password 
                password={password} 
                setPassword={setPassword} 
                />
              </Grid>
              <Grid item xs={12} mb={1}>
               <Password 
                password={confirmPassword} 
                setPassword={setConfirmPassword}
                label="Confim Password" 
                />
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <Button
                type="submit"
                variant="contained"
                style={{margin: '16px 0 8px 0'}}
              >
                Save Password
              </Button>
            </Box>
          </Box>
      </FormContainer>
    </AnimatedPage>
  );
}

export default Login