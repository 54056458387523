import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { 
  Avatar, Grid, Button, TextField, InputLabel,
  Box, Typography, Container, IconButton,
  OutlinedInput, FormControl, InputAdornment,Card
} from '@mui/material'

import { login } from '../../redux/actions/userActions'
import AnimatedPage from '../../components/Layout/AnimatedPage'
import Loader from '../../components/ui/Loader'
import Alert from '../../components/ui/Alert'
import FormContainer from '../../components/ui/Form/FormContainer';
import Password from '../../components/ui/Form/Password';

import roleBasedRedirect from '../../utils/roleBasedRedirect';
import { BARBER_REGISTER_RESET } from '../../redux/constants/barberConstants';
import { UPDATE_ALERT } from '../../redux/constants/alertConstants';
import { STORE_SCHEDULE_ADD_RESET } from '../../redux/constants/storeConstants';


// refresh token
// import { refreshTokenSetup } from '../utils/refreshToken';

// import { GoogleLogin } from 'react-google-login';
// const clientId='417878402253-mubvgcv45ehn4j913s1run81195k0buk.apps.googleusercontent.com'

// const responseGoogle = (response) => {
//   console.log(response);
// }

const Login = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [message, setMessage] = useState(null)
  const [openAlert, setOpenAlert] = useState(false) 
  const dispatch = useDispatch()

  const userLogin = useSelector(state => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const scheduleAdd = useSelector(state => state.scheduleAdd)
  const { success: successSchedule } = scheduleAdd

  // const barberRegister = useSelector(state => state.barberRegister)
  // const { success } = barberRegister

  const from = location.state?.from?.pathname || roleBasedRedirect({role: userInfo?.role})

//   const redirect = location.search ? location.search.split('=')[1] : '/'

useEffect(() => {
  if (successSchedule) {
    dispatch({ 
      type: UPDATE_ALERT, 
      payload: {
        open: true, 
        severity: 'success', 
        message: 'Congrats! Your store has been registered successfully. Please add services so clients can book you.' 
      } 
    })
    // dispatch(getUserDetails())
    dispatch({ type: STORE_SCHEDULE_ADD_RESET })
}
},[]) 

  useEffect(() => {
    if (userInfo)
        navigate(from, { replace: true })
        // dispatch(logout())
},[userInfo])  

useEffect(() => {
  if (error) {
    setMessage(error)
    setOpenAlert(true)
  }
},[error]) 

  const submitHandler = (e) => {
      e.preventDefault();
    //   if (!email || !password) {
    //     setMessage('Please fill in all fileds.')
    //     setOpenAlert(true)
    // } else {
        setMessage(null)
        setOpenAlert(false)
        // dispatch({type: BARBER_REGISTER_RESET})
        dispatch(login(email, password))
    // }    
  }

  return (
      <AnimatedPage>
        <FormContainer>
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          </Avatar>
          <Typography variant="h6">
            Barber Sign In
          </Typography>
          <Box component="form" onSubmit={submitHandler} sx={{ mt: 3 }}>
            {loading && <Loader />}
            {message && 
            <Alert 
              type="error" 
              message={message}
              openAlert={openAlert}
              setOpenAlert={setOpenAlert}
            />
            }
            <Grid container spacing={2}>
              <Grid item xs={12} mb={1}>
                <TextField
                  name="email"
                  label="Email"
                  autoFocus
                  variant="outlined"
                  size="small"
                  type="text"
                  required
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  
                />
              </Grid>
              <Grid item xs={12} mb={1}>
                <Password password={password} setPassword={setPassword} />
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Link to="/reset-password" variant="body2">
                    Forgot password?
                  </Link>
                <Button
                  type="submit"
                  variant="contained"
                  style={{margin: '16px 0 8px 0'}}
                >
                  Sign In
                </Button>
              </Box>
            <Grid container justifyContent="center" mt={5}>
              <Grid item>
                <Link to="/barber-sign-up">
                  <Typography variant="p">Don't have an account? Sign Up</Typography>
                </Link>
              </Grid>
            </Grid>
          </Box>
      </FormContainer>
    </AnimatedPage>
  );
}

export default Login