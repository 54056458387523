import { Avatar, Box, Button, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, TextField, Typography } from '@mui/material';
import { Link, useNavigate, useLocation } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import AnimatedPage from '../../../components/Layout/AnimatedPage';
import FormContainer from '../../../components/ui/Form/FormContainer';
import { UPDATE_ALERT } from '../../../redux/constants/alertConstants';
import { getPaypalWalletDetails, updatePaypalEmail, verifyUpdatePaypalEmail, verifyWithdrawFromPaypal, withdrawAmountFromPaypal } from '../../../redux/actions/walletActions';
import BackDropLoader from '../../../components/ui/BackDropLoader';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import OTPInput, { ResendOTP } from "otp-input-react";
import { PAYPAL_EMAIL_UPDATE_RESET, PAYPAL_EMAIL_UPDATE_VERIFY_RESET, PAYPAL_WITHDRAW_RESET, PAYPAL_WITHDRAW_VERIFY_RESET } from '../../../redux/constants/walletConstants';

const Withdraw = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [amount, setAmount] = useState('')
    const [email, setEmail] = useState('')
    const [emailVerifyOtp, setEmailVerifyOTP] = useState('')
    const [changeEmail, setChangeEmail] = useState(false)
    const [otp, setOtp] = useState('')
  
    const paypalDetails = useSelector(state => state.paypalDetails)
    const { loading: paypalLoading, error: paypalError, paypal } = paypalDetails

    const paypalUpdateEmail = useSelector(state => state.paypalUpdateEmail)
    const { loading: updateLoading, error: updateError, success: updateSuccess } = paypalUpdateEmail

    const paypalUpdateEmailVerify = useSelector(state => state.paypalUpdateEmailVerify)
    const { loading: verifyUpdateLoading, error: verifyUpdateError, success: verifyUpdateSuccess } = paypalUpdateEmailVerify

    const withdrawAmount = useSelector(state => state.withdrawAmount)
    const { loading: withdrawLoading, error: withdrawError, success: withdrawSuccess } = withdrawAmount

    const withdrawAmountVerify = useSelector(state => state.withdrawAmountVerify)
    const { loading: verifyWithdrawLoading, error: verifyWithdrawError, success: verifyWithdrawSuccess } = withdrawAmountVerify
  
    useEffect(() => {
      dispatch(getPaypalWalletDetails())
     }, [])

     useEffect(() => {
        if (updateSuccess) {
           // setChangeEmail(true)
            dispatch({ 
              type: UPDATE_ALERT, 
              payload: {
                open: true, 
                severity: 'success', 
                message: "Verification code has been sent to your phone."
              }
            })
        }
        if (verifyUpdateSuccess) {
            // setChangeEmail(true)
             dispatch({ 
               type: UPDATE_ALERT, 
               payload: {
                 open: true, 
                 severity: 'success', 
                 message: "Paypal email updated successfully."
               }
             })
             dispatch(getPaypalWalletDetails())
             setChangeEmail(false)
             setOtp('')
             dispatch({type: PAYPAL_EMAIL_UPDATE_RESET})
             dispatch({type: PAYPAL_EMAIL_UPDATE_VERIFY_RESET})
         } 
         if (verifyUpdateError) {
            // setChangeEmail(true)
             dispatch({ 
               type: UPDATE_ALERT, 
               payload: {
                 open: true, 
                 severity: 'error', 
                 message: verifyUpdateError
               }
             })
             setOtp('')
         } 

         if (withdrawSuccess) {
            // setChangeEmail(true)
             dispatch({ 
               type: UPDATE_ALERT, 
               payload: {
                 open: true, 
                 severity: 'success', 
                 message: "Verification code has been sent to your phone."
               }
             })
            //  dispatch(getPaypalWalletDetails())
            //  setChangeEmail(false)
            //  setOtp('')
            //  dispatch({type: PAYPAL_EMAIL_UPDATE_RESET})
            //  dispatch({type: PAYPAL_EMAIL_UPDATE_VERIFY_RESET})
         } 
         if (withdrawError) {
            // setChangeEmail(true)
             dispatch({ 
               type: UPDATE_ALERT, 
               payload: {
                 open: true, 
                 severity: 'error', 
                 message: withdrawError
               }
             })
             setOtp('')
         } 

         
         if (verifyWithdrawSuccess) {
            // setChangeEmail(true)
             dispatch({ 
               type: UPDATE_ALERT, 
               payload: {
                 open: true, 
                 severity: 'success', 
                 message: "A withdrawal request has been received, it may take up to 24 hours to transfer funds to your registered PayPal account."
               }
             })
            //  dispatch(getPaypalWalletDetails())
            //  setChangeEmail(false)
             setOtp('')
             setAmount('')
             dispatch({type: PAYPAL_WITHDRAW_RESET })
             dispatch({type: PAYPAL_WITHDRAW_VERIFY_RESET})
             navigate('/barber-transactions')
         } 
         if (verifyWithdrawError) {
            // setChangeEmail(true)
             dispatch({ 
               type: UPDATE_ALERT, 
               payload: {
                 open: true, 
                 severity: 'error', 
                 message: verifyWithdrawError
               }
             })
             setOtp('')
         } 

       }, [updateSuccess, verifyUpdateSuccess, verifyUpdateError, withdrawSuccess, withdrawError, verifyWithdrawSuccess, verifyWithdrawError])
  
    const updateEmailHandler = (e) => {
        e.preventDefault()
        // setChangeEmail(true)
        if (!(email)) {
            dispatch({ 
              type: UPDATE_ALERT, 
              payload: {
                open: true, 
                severity: 'error', 
                message: "Please add a valid paypal email."
              }
            })
          }
          else {
            //   setEmail(email)
              dispatch(updatePaypalEmail({email}))
          }
    }

    const verifyUpdateEmailOtpHandler = (e) => {
        e.preventDefault()
        // setChangeEmail(true)
        if (!(otp)) {
            dispatch({ 
              type: UPDATE_ALERT, 
              payload: {
                open: true, 
                severity: 'error', 
                message: "Please enter the code."
              }
            })
          }
          else {
            //   setEmail(email)
              dispatch(verifyUpdatePaypalEmail({otp}))
          }
    }

    const withdrawAmountHandler = (e) => {
        e.preventDefault()
        // setChangeEmail(true)
        if (!(amount)) {
            dispatch({ 
              type: UPDATE_ALERT, 
              payload: {
                open: true, 
                severity: 'error', 
                message: "Please enter the amount to withdraw."
              }
            })
          }
          else {
            //   setEmail(email)
              dispatch(withdrawAmountFromPaypal({amount}))
          }
    }

    const verifyWithdrawOtpHandler = (e) => {
        e.preventDefault()
        // setChangeEmail(true)
        if (!(otp)) {
            dispatch({ 
              type: UPDATE_ALERT, 
              payload: {
                open: true, 
                severity: 'error', 
                message: "Please enter the code."
              }
            })
          }
          else {
            //   setEmail(email)
              dispatch(verifyWithdrawFromPaypal({amount, otp}))
          }
    }
  
  
    const onSubmitHandler = (e) => {
      e.preventDefault()
      if (!(amount)) {
        dispatch({ 
          type: UPDATE_ALERT, 
          payload: {
            open: true, 
            severity: 'error', 
            message: "Please fill all required fields."
          }
        })
      }
  
      if (amount < 20) {
        dispatch({ 
          type: UPDATE_ALERT, 
          payload: {
            open: true, 
            severity: 'error', 
            message: "Withdraw amount should be min $20."
          }
        })      
      } else {
        //   dispatch(registerNewBarber({firstName, lastName, email, gender, phoneNumber, password, address, referral}))
          // setInfoAdded(true)
      }      
      // handleNext()
    }
  
    // const verifyCodeHandler = (e) => {
    //   e.preventDefault()
  
    //   if (!(otp)) {
    //     dispatch({ 
    //       type: UPDATE_ALERT, 
    //       payload: {
    //         open: true, 
    //         severity: 'error', 
    //         message: "Please enter an otp."
    //       }
    //     })
    //   }
    //   console.log({otp})
    //   dispatch(verifyUserPhone({phoneNumber, otp}))
    //   // handleNext()
    // }
    
    return(
    <>
    { paypalLoading || verifyWithdrawSuccess
      ? <BackDropLoader />
      :  
      <AnimatedPage>
        <FormContainer >
            <Box>
                <Typography variant="h6" mb={4}>Withdraw Amount</Typography>
            </Box>
        <Box component="form" onSubmit={onSubmitHandler}>
          {/* <Grid container spacing={2} maxWidth="800px" margin="0 auto"> */}
              <Grid  item xs={12} mb={2}>
                <TextField
                    id="outlined-select-method"
                    select
                    label="Withdrawal Options"
                    size="small"
                    value="paypal"
                    fullWidth
                    required
                    disabled
                    >
                        <MenuItem value="paypal" >
                            Paypal
                        </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} mb={2}>
                <TextField
                  name="paypalEmail"
                  label="Paypal Email"
                  variant="outlined"
                  size="small"
                  type="email"
                  autoFocus
                  required
                  fullWidth
                  value={changeEmail ? email : paypal?.wallet?.withdrawalDetails?.email}
                  disabled={!changeEmail}
                  onChange={(e) => setEmail(e.target.value)}
                //   disabled={infoAdded} 
                />
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex">
                        <Typography color={changeEmail ? 'text.disabled' : ''}>Verified: </Typography>
                        { paypal?.wallet?.withdrawalDetails?.isVerified 
                            ? <DoneIcon color={changeEmail ? 'disabled' : 'success'} />
                            : <CloseIcon color={changeEmail ? 'disabled' : 'error'} />
                        }
                    </Box>
                    {
                        changeEmail 
                        ?
                            <Button
                                variant="contained"
                                size="small"
                                sx={{ marginTop: 1}}
                                onClick={updateEmailHandler}
                            >
                                Update
                            </Button>
                        : 
                            <Button
                                onClick={() => setChangeEmail(true)}
                            >
                                Change
                            </Button>
                    }
                   

                </Box>
              </Grid>
              {changeEmail && updateSuccess &&
                    <Grid item xs={12} md={12} mb={1} >
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mb={4}>
                        <Typography fontWeight="bold" gutterBottom>Enter Code: &nbsp;</Typography>
                        <OTPInput 
                        value={otp} 
                        onChange={(value) => setOtp(value)}
                        // value={otp?.current?.value} 
                        // ref={otp} 
                        // ref={ n => otp = n }
                        // OTPLength={5} 
                        otpType="number" 
                        disabled={false} 
                        // secure 
                        style={{
                          paddingLeft: 1,
                          marginTop: 8
                        }}
                        />
                    </Box>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={verifyUpdateEmailOtpHandler}
                    >
                        Verify
                    </Button>
                    </Grid>
                }
             {changeEmail || paypal?.wallet?.withdrawalDetails?.isVerified 
              &&
              <>
                <Grid item xs={12} mb={2} mt={5}>
                    <TextField
                    name="withdraw"
                    label="Withdraw Amount"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    InputProps={{ inputProps: { min: 20, max: 1000 } }}
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    disabled={withdrawSuccess}
                    />
                </Grid>
                <Button
                    variant="contained"
                    fullWidth
                    onClick={withdrawAmountHandler}
                    sx={{marginBottom: 2}}
                    disabled={withdrawSuccess}
                >
                    Continue
                </Button>
                {withdrawSuccess &&
                    <Grid item xs={12} md={12} mb={1} >
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mb={4}>
                        <Typography fontWeight="bold" gutterBottom>Enter Code: &nbsp;</Typography>
                        <OTPInput 
                        value={otp} 
                        onChange={(value) => setOtp(value)}
                        // value={otp?.current?.value} 
                        // ref={otp} 
                        // ref={ n => otp = n }
                        // OTPLength={5} 
                        otpType="number" 
                        disabled={false} 
                        // secure 
                        style={{
                          paddingLeft: 1,
                          marginTop: 8
                        }}
                        />
                    </Box>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={verifyWithdrawOtpHandler}
                    >
                        Send Amount
                    </Button>
                    </Grid>
                }
                </>
                }
            {/* </Grid>             */}
            {/* {!infoAdded &&   
              <Box display="flex">
                {registerLoading 
                  ? 
                    <Box margin="0 auto" mt={3}>
                      <CircularProgress />
                    </Box>
                  :
                  <Button
                    sx={{
                      margin: "0 auto",
                      marginTop: "28px",
                      // padding: "10px 40px",
                      // fontWeight: "bold",
                      // textTransform: "capitalize"
                    }}
                    variant="contained"
                    type="submit"
                    // disabled={infoAdded}
                  >
                    Continue
                  </Button>    
                }
              </Box>
            } */}
          {/* </FormContainer> */}
        </Box>
        
        </FormContainer>
      </AnimatedPage>
    }
    </>
    )
}

export default Withdraw