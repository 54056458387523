import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, IconButton, TextField, 
    Grid, Button, MenuItem, InputAdornment, FormControl,
    OutlinedInput, InputLabel, FormHelperText, Stack, Typography, Rating  } from '@mui/material'

import { getContactMessageDetails, updateContactMessage } from '../../../redux/actions/contactActions';
import Loader from '../../../components/ui/Loader';
import Alert from '../../../components/ui/Alert';
import BasicModal from '../../../components/ui/Modals/BasicModal';
import { UPDATE_ALERT } from '../../../redux/constants/alertConstants';
import { CONTACT_MESSAGE_UPDATE_RESET } from '../../../redux/constants/contactConstants';
import moment from 'moment';

const FeedbackModal = (
        { openModal, onClose, feedback }
    ) => {

    const getContent = () => (
    <>
        <Box sx={{ mt: 3 }}>
                <Box>
                <Typography><b>Client:</b> {feedback?.client ? feedback?.client?.firstName + " " + feedback?.client?.lastName : "Barbarly User"}</Typography>
                {/* <Typography gutterBottom><b>Rating: </b> {feedback.rating}</Typography> */}
                <Box display="flex" alignItems="center" gap={1}>
                    <Typography><b>Rating: </b></Typography>
                    <Rating sx={{my: 2}}  name="half-rating-read" value={feedback?.rating} precision={0.5} readOnly />
                </Box>
                <Typography mb={2}><b>Date & Time: </b> { moment(feedback?.createdAt).format('YYYY-MM-DD')}</Typography> 
                <Typography gutterBottom><b>Comment: </b> {feedback?.comment}</Typography>
            </Box> 
        </Box>
        <Box display="flex" justifyContent="flex-end" mt={3}>
            <Button
                variant="contained"
                onClick={onClose}
            >
                Close
            </Button>
        </Box>
    </>
    )


  return (
    <BasicModal
        open={openModal}
        onClose={onClose}
        title="Feedback Details"
        content={getContent()}
    >
    </BasicModal>
  )
}

export default FeedbackModal