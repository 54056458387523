import { useNavigate } from 'react-router-dom'
import {  Box, Typography, Avatar,MenuItem} from '@mui/material'
import NotificationIcon from '@mui/icons-material/Notifications'
import ROLES from '../../constants/roleConstants'
import { format } from 'timeago.js'
import { useSelector } from 'react-redux';

const NavBarNotification = ({notification}) => {
    const navigate = useNavigate()
    
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
 
    const navigateBasedOnType = (e, type) => {
    e.preventDefault()
    // console.log({type})
    // handleClose()
    switch (type) {
        case "appointment": {
            userInfo?.role === ROLES.ADMIN ? navigate('/admin-appointments')
            : userInfo?.role === ROLES.BARBER ? navigate('/barber-appointments') 
                : navigate('/bookings') 
            return
        }   
        case "transaction": {
            userInfo?.role === ROLES.ADMIN ? navigate('/admin-transactions')
            : userInfo?.role === ROLES.BARBER ? navigate('/barber-transactions') 
                : navigate('/transactions')
            return 
        }
        case "message": {
            userInfo?.role === ROLES.ADMIN ? navigate('/admin-contact-messages')
            : navigate('/transactions')
            return 
        }   
        default:
            navigate('/dashboard')
    }

}

    return (
        <MenuItem 
            key={notification._id} 
            onClick={(e) => navigateBasedOnType(e, notification?.type)}
            sx={{
                marginBottom: 0.2
            }}    
        >
            <Avatar>
                <NotificationIcon />
            </Avatar>
            <Box component="div" sx={{ ml: 2 }}>
                <Typography variant="body2" fontWeight="bold" textOverflow="hidden">
                    {notification?.content}
                </Typography>
                <Typography variant="caption" color="primary" fontWeight="bold" sx={{ ml: 'auto', mr: 1 }} >
                    {format(notification?.createdAt)}
                </Typography>
            </Box>
          </MenuItem>
    )
}

export default NavBarNotification