import axios from 'axios';
import React, { useEffect, useState } from 'react'
import BackDropLoader from '../../components/ui/BackDropLoader';

const TermsAndConditions = () => {
  const [loading, setLoading] = useState(false)
  const [content, setContent] = useState('')

  const getTermsAndConditions = async () => {
    setLoading(true)
    try {
        const { data } = await axios.get('/api/terms')
        setContent(data.content)
        setLoading(false)
    } catch (error) {
        setLoading(false)
        setContent('')
    }
}

useEffect(() => {
    getTermsAndConditions()
}, [])

  return (
    <>
    { loading 
    ? <BackDropLoader />
    :
     <div 
      style={{ maxWidth: "980px", margin: "30px auto", padding: "0 20px"}}
      dangerouslySetInnerHTML={{__html: content}}
     >
     </div>
    }
  </>
  )
}

export default TermsAndConditions