import { Box, Paper, Tab, Tabs, Typography } from '@mui/material';

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import AnimatedPage from '../../../components/Layout/AnimatedPage';
import BackDropLoader from '../../../components/ui/BackDropLoader';
import { EditLocation, EditPassword, EditProfile, ProfileDetails, EditPhone } from '../../../features/profile';

import PropTypes from 'prop-types';
import TopNavigation from '../../../components/ui/TopNavigation';
import { getUserDetails } from '../../../redux/actions/userActions';
import ROLES from '../../../constants/roleConstants';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


const Profile = () => {
  const dispatch = useDispatch()
  // const [loading, setLoading] = useState(false)

  const userLogin = useSelector(state => state.userLogin)
  const { loading, error, userInfo: user } = userLogin

  const userVerifyPhone = useSelector(state => state.userVerifyPhone)
  const { loading: loadingVerify, error: errorVerify, success: successVerify } = userVerifyPhone

  // useEffect(() => {
  //   // dispatch(getUserDetails())
  // }, [successVerify])


  return (
    <AnimatedPage>
        <>
        {(loading || loadingVerify) 
        ? <BackDropLoader /> 
        : 
        <Box
            sx={{
                 display: { xs: 'flex', md: 'grid' },
                gridTemplateColumns: 'repeat(3,1fr)',
                gridAutoRows: 'minmax(100px, auto)',
                gap: 3,
                textAlign: 'center',
                flexDirection: 'column',
            }}
        >
            <Paper 
                elevation={3} 
                sx={{ pt: 4, pb: 2, gridColumn: '1/2'}}
            >
              <ProfileDetails />
            </Paper>
            <Paper 
                elevation={3} 
                sx={{ gridColumn: '2/4'}}
            >
            { user?.role === ROLES.ADMIN 
              ? 
                <TopNavigation
                  TabOneHeading="Edit Profile"
                  TabOneContent={<EditProfile user={user} />}
                  TabTwoHeading="Change Password"
                  TabTwoContent={<EditPassword />}
                  TabThreeHeading="Change Phone"
                  TabThreeContent={<EditPhone from="/profile" />}
                />
                :
                <TopNavigation
                  TabOneHeading="Edit Profile"
                  TabOneContent={<EditProfile user={user} />}
                  TabTwoHeading="Change Password"
                  TabTwoContent={<EditPassword />}
                  TabThreeHeading="Change Phone"
                  TabThreeContent={<EditPhone from="/profile" />}
                  TabFourHeading="Change Location"
                  TabFourContent={<EditLocation />}
                />
            }
            </Paper>
        </Box>
        }
        </>
    </AnimatedPage>
  )}

export default Profile