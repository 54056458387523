import { Box, Button, Divider, List, Paper, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useDispatch, useSelector } from 'react-redux'
import { AppointmentsList, BarChart, DataWidget, LineChart, PieChart, Title } from '../../../features/dashboard'
import Group from '@mui/icons-material/Group'
import MenuIcon from '@mui/icons-material/Menu'
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined'
import PeopleIcon from '@mui/icons-material/People'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ContentCutIcon from '@mui/icons-material/ContentCut'
import moment from 'moment'
import StyledAvatar from '../../../features/dashboard/components/StyledAvatar'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { listAppointments } from  '../../../redux/actions/appointmentActions'
import { getAdminDashboardData, getAdminWalletDetails } from  '../../../redux/actions/adminActions'
import BackDropLoader from '../../../components/ui/BackDropLoader'
import { UPDATE_ALERT } from '../../../redux/constants/alertConstants';
import BiaxialChart from '../../../features/dashboard/components/BiaxialChart'
import TextButton from '../../../components/ui/TextButton'
import BiaxialBarChart from '../../../features/dashboard/components/BiaxialBarChart';

const Anayltics = () => {
    const theme = useTheme()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  // const userLogin = useSelector(state => state.userLogin)
  // const { userInfo } = userLogin

  const adminWalletDetails = useSelector(state => state.adminWalletDetails)
  const { loading: loadingWallet, wallet } = adminWalletDetails

  const appointmentAdminList = useSelector(state => state.appointmentAdminList)
  const { loading: loadingAppointments, error: errorAppointments, appointments } = appointmentAdminList

  useEffect(() => {
    setLoading(true)
    dispatch(listAppointments())
    dispatch(getAdminWalletDetails())
    setLoading(false)
    // if (users.length === 0) dispatch(listUsers())
    // dispatch({ type: UPDATE_ALERT, payload: {open: true, severity: 'error', message: 'Test message' } })
  }, [])

  const barChartData = [
    {
      month: 'Jan',
      income: 200,
    },
    {
      month: 'Feb',
      income: 230,
    },
    {
      month: 'Mar',
      income: 250,
    },
    {
      month: 'Apr',
      income: 220,
    },
    {
      month: 'May',
      income: 280,
    },
    {
      month: 'Jun',
      income: 300,
    },
    {
      month: 'Jul',
      income: 290,
    },
    {
      month: 'Aug',
      income: 350,
    },
    {
      month: 'Sep',
      income: 320,
    },
    {
      month: 'Oct',
      income: 500,
    },
    {
      month: 'Nov',
      income: 450,
    },
  ];
  
// Generate revenue Data
function createData(month, revenue) {
  return { month, revenue };
}
  const lineChartData = [
    createData('Jan', 200),
    createData('Feb', 530),
    createData('Mar', 400),
    createData('Apr', 600),
    createData('May', 900),
    createData('Jun', 580),
    createData('Jul', 800),
    createData('Aug', 760),
    createData('Sep', 900),
    createData('Oct', 960),
    createData('Nov', 1020),
  ];

  const ProfitPayoutComparision = [
    {
        month: 'Jan',
        profit: 200,
        payout: 400
      },
      {
        month: 'Feb',
        profit: 230,
        payout: 430
      },
      {
        month: 'Mar',
        profit: 200,
        payout: 400
      },
      {
        month: 'Apr',
        profit: 250,
        payout: 440
      },
      {
        month: 'May',
        profit: 180,
        payout: 380
      },
      {
        month: 'Jun',
        profit: 400,
        payout: 490
      },
      {
        month: 'Jul',
        profit: 200,
        payout: 400
      },
      {
        month: 'Aug',
        profit: 300,
        payout: 460
      },
      {
        month: 'Sep',
        profit: 440,
        payout: 500
      },
      {
        month: 'Oct',
        profit: 500,
        payout: 590
      },
      {
        month: 'Nov',
        profit: 550,
        payout: 700
      },
  ];

  const PieChartData1 = [
    { name: 'Haricut', value: 400 },
    { name: 'Shave', value: 300 },
    { name: 'Massage', value: 300 },
    { name: 'Others', value: 200 },
  ];

  const PieChartData2 = [
    { name: 'A1', value: 100 },
    { name: 'A2', value: 300 },
    { name: 'B1', value: 100 },
    { name: 'B2', value: 80 },
    { name: 'B3', value: 40 },
    { name: 'B4', value: 30 },
    { name: 'B5', value: 50 },
    { name: 'C1', value: 100 },
    { name: 'C2', value: 200 },
    { name: 'D1', value: 150 },
    { name: 'D2', value: 50 },
  ];
  
  

  return (
    <>
      {loading || loadingWallet || loadingAppointments 
      ? <BackDropLoader /> 
      :
      <Box
      sx={{
        display: { xs: 'flex', md: 'grid' },
        gridTemplateColumns: 'repeat(4,1fr)',
        gridAutoRows: 'minmax(100px, auto)',
        gap: 3,
        textAlign: 'center',
        flexDirection: 'column',
      }}
    >
    <DataWidget 
        title="Balance" 
        value={`$${wallet?.revenue}`}
        Icon={<PaymentsOutlinedIcon color="info" sx={{ height: 40, width: 80, opacity: 0.4 }} />  }
      />
      <DataWidget 
        title="Total Revenue" 
        value={`$${wallet?.totalRevenue}`}
        Icon={<PaymentsOutlinedIcon color="primary" sx={{ height: 40, width: 80, opacity: 0.4 }} />  }
      />
    <DataWidget 
        title="Total Profit" 
        value={`$${wallet?.totalProfit}`}
        Icon={<PaymentsOutlinedIcon sx={{ color: theme.palette.custom.lightGreen, height: 40, width: 80, opacity: 0.8 }} />  }
    />
    <DataWidget 
        title="Total Withdrawals" 
        value={`$${wallet?.totalWithdrawalsAmount}`}
        Icon={<PaymentsOutlinedIcon color="warning" sx={{ height: 40, width: 80, opacity: 0.4 }} />  }
    />
    <DataWidget 
        title="Wallet Payouts" 
        value={`$${wallet?.totalInAppPayoutsAmount}`}
        Icon={<PaymentsOutlinedIcon color="error" sx={{ height: 40, width: 80, opacity: 0.4 }} />  }
    />
    <DataWidget 
        title="External Payouts" 
        value={`$${wallet?.totalPayoutsAmount}`}
        Icon={<PaymentsOutlinedIcon sx={{ color: theme.palette.custom.lightPurple, height: 40, width: 80, opacity: 0.7 }} />  }
    />
      <DataWidget 
        title="Milestones" 
        value={`$${wallet?.totalMilestonesAmount}`}
        Icon={<PaymentsOutlinedIcon color="warning" sx={{ height: 40, width: 80, opacity: 0.4 }} />  }
      />
      <DataWidget 
        title="On Hold" 
        value={`$${wallet?.totalOnHoldAmount}`}
        Icon={<PaymentsOutlinedIcon color="" sx={{ height: 40, width: 80, opacity: 0.4 }} />  }
      />
       {/* <Paper elevation={3} sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            paddingBottom: "24px",
            flexWrap: 'wrap',
            gridColumn: 4, gridRow: '3/4' 
          }}>
          <PieChart data1={PieChartData1} data2={PieChartData2} />
      </Paper> */}
      {/* <Paper elevation={3} sx={{ p: 2, gridColumn: 4, gridRow: '3/4' }}>
        <Box>
          <Title heading="Recent Appointments"/>
          <List>
            {appointments?.slice(0,6).map((item) => (
               <Link to={`/appointment/${item._id}`} style={{textDecoration: 'none'}}>
                <Paper
                  elevation={3}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '14px',
                    marginBottom: "22px"
                  }}
                    key={item._id}
                  >
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                      component="span"
                      variant="body2"
                      fontWeight="bold"
 
                    >
                      APT-{item.id} {(() => {
                        switch (item.status) {
                          case "upcoming": return <TextButton 
                                                    variant="outlined" 
                                                    size="small"
                                                    color="primary"
                                                    >
                                                    [Upcoming]
                                                  </TextButton>
                          case "completed": return <TextButton 
                                                    variant="outlined" 
                                                    size="small"
                                                    color="success"
                                                    >
                                                    [Completed]
                                                  </TextButton>
                          case "cancelled": return <TextButton 
                                                    variant="outlined" 
                                                    size="small"
                                                    color="error"
                                                    >
                                                    [Cancelled]
                                                  </TextButton>
                        }
                      })()}
                    </Typography>
                    <Typography
                      component="span"
                      variant="body2"

                    >
                      {item.appointmentDate.substring(0, 10)} ({item.appointmentTime})
                    </Typography>
                  </Box>
                  <Button variant='contained' size="small">View</Button>
              </Paper>
              </Link>
            ))}
          </List>
        </Box>
      </Paper> */}
      <Paper elevation={3} sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            padding: "10px 0 20px 0",
            flexWrap: 'wrap',
            gridColumn: '1/5'
          }}>
          <BiaxialBarChart title="Profit vs Payout" data={ProfitPayoutComparision} key1="payout" key2="profit" />
      </Paper>
      <Paper elevation={3} sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            padding: "10px 0 20px 0",
            flexWrap: 'wrap',
            gridColumn: '1/5', 
          }}>
          <LineChart title="Revenue Overview" data={lineChartData} xAxisKey="month" lineKey="revenue" />
          {/* <BiaxialChart title="Clients & Barbers Overview" /> */}
      </Paper>
      </Box>
      }
    </>
  )
}

export default Anayltics