import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, IconButton, TextField, 
    Grid, Button, MenuItem, InputAdornment, FormControl,
    OutlinedInput, InputLabel, FormHelperText, Stack, Typography  } from '@mui/material'

import { getContactMessageDetails, updateContactMessage } from '../../../redux/actions/contactActions';
import Loader from '../../../components/ui/Loader';
import Alert from '../../../components/ui/Alert';
import BasicModal from '../../../components/ui/Modals/BasicModal';
import { UPDATE_ALERT } from '../../../redux/constants/alertConstants';
import { CONTACT_MESSAGE_UPDATE_RESET } from '../../../redux/constants/contactConstants';
import moment from 'moment';

const ContactModal = (
        { openModal, onClose }
    ) => {
    const dispatch = useDispatch() 

    // const [name, setName] = useState('')
    // const [amount, setAmount] = useState(null)
    // const [duration, setDuration] = useState(null)
    // const [status, setStatus] = useState('')
    // const [description, setDescription] = useState('')
    // const [message, setMessage] = useState({ type: 'info', text: ''})
    // const [openAlert, setOpenAlert] = useState(false)

    const contactMessageDetails = useSelector(state => state.contactMessageDetails)
    const { loading: loadingMessage, error: errorMessage, messageDetails } = contactMessageDetails

    const contactMessageUpdate = useSelector(state => state.contactMessageUpdate)
    const { loading: updateLoading, error: updateError, success: updateStatus } = contactMessageUpdate  

    const markMessageAsResponded = (id) => {
        // e.preventDefault()
        dispatch(markMessageAsResponded(id))
       }
    
    // useEffect(() => {
    //     dispatch(getContactMessageDetails(messageId))
    // }, [dispatch, messageId])

    useEffect(() => {
        if(updateStatus) {
            dispatch({ type: UPDATE_ALERT, 
                payload: 
                {open: true, severity: 'success', message: 'Message updated successfully.' 
              } 
            })
            dispatch({ type: CONTACT_MESSAGE_UPDATE_RESET })
        }
        if(updateError){
            dispatch({ type: UPDATE_ALERT, 
                payload: 
                {open: true, severity: 'error', message: updateError 
              } 
            })
        }
    },[updateError, updateStatus])

    const getContent = () => (
    <>
        <Box sx={{ mt: 3 }}>
            {loadingMessage || updateLoading
                ? <Loader />
                :
                <Box>
                <Typography gutterBottom><b>Name:</b> {messageDetails?.name}</Typography>
                <Typography gutterBottom><b>Email: </b> {messageDetails?.email}</Typography>
                <Typography gutterBottom><b>Date & Time: </b> { moment(messageDetails?.createdAt).format('YYYY-MM-DD HH:MM:SS')}</Typography> 
                <Typography gutterBottom><b>Responded: </b> 
                    {messageDetails?.responded
                        ? "Yes"
                        : "No"
                    }   
                </Typography> 
                <Typography gutterBottom><b>Subject: </b> {messageDetails?.subject}</Typography>
                <Typography gutterBottom><b>Message: </b> {messageDetails?.message}</Typography>
            </Box> 
            } 
        </Box>
        <Box display="flex" justifyContent="flex-end" mt={3}>
            <Button
                variant="contained"
                onClick={onClose}
            >
                Close
            </Button>
        </Box>
    </>
    )


  return (
    <BasicModal
        open={openModal}
        onClose={onClose}
        title="Message Details"
        content={getContent()}
    >
    </BasicModal>
  )
}

export default ContactModal