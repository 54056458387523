export const TICKET_ADD_REQUEST = 'TICKET_ADD_REQUEST'
export const TICKET_ADD_SUCCESS = 'TICKET_ADD_SUCCESS'
export const TICKET_ADD_FAIL = 'TICKET_ADD_FAIL'
export const TICKET_ADD_RESET = 'TICKET_ADD_RESET'

export const TICKET_USER_LIST_REQUEST = 'TICKET_USER_LIST_REQUEST'
export const TICKET_USER_LIST_SUCCESS = 'TICKET_USER_LIST_SUCCESS'
export const TICKET_USER_LIST_FAIL = 'TICKET_USER_LIST_FAIL'

export const TICKET_LIST_REQUEST = 'TICKET_LIST_REQUEST'
export const TICKET_LIST_SUCCESS = 'TICKET_LIST_SUCCESS'
export const TICKET_LIST_FAIL = 'TICKET_LIST_FAIL'

export const TICKET_ADD_MESSAGE_REQUEST = 'TICKET_MESSAGE_ADD_MESSAGE_REQUEST'
export const TICKET_ADD_MESSAGE_SUCCESS = 'TICKET_ADD_MESSAGE_SUCCESS'
export const TICKET_ADD_MESSAGE_FAIL = 'TICKET_ADD_MESSAGE_FAIL'
export const TICKET_ADD_MESSAGE_RESET = 'TICKET_ADD_MESSAGE_RESET'


export const TICKET_MARK_RESOLVED_REQUEST = 'TICKET_MESSAGE_MARK_RESOLVED_REQUEST'
export const TICKET_MARK_RESOLVED_SUCCESS = 'TICKET_MARK_RESOLVED_SUCCESS'
export const TICKET_MARK_RESOLVED_FAIL = 'TICKET_MARK_RESOLVED_FAIL'
export const TICKET_MARK_RESOLVED_RESET = 'TICKET_MARK_RESOLVED_RESET'

export const TICKET_MARK_OPENED_REQUEST = 'TICKET_MESSAGE_MARK_OPENED_REQUEST'
export const TICKET_MARK_OPENED_SUCCESS = 'TICKET_MARK_OPENED_SUCCESS'
export const TICKET_MARK_OPENED_FAIL = 'TICKET_MARK_OPENED_FAIL'
export const TICKET_MARK_OPENED_RESET = 'TICKET_MARK_OPENED_RESET'


export const TICKET_MESSAGE_LIST_REQUEST = 'TICKET_MESSAGE_LIST_REQUEST'
export const TICKET_MESSAGE_LIST_SUCCESS = 'TICKET_MESSAGE_LIST_SUCCESS'
export const TICKET_MESSAGE_LIST_FAIL = 'TICKET_MeSSAGE_LIST_FAIL'

export const TICKET_DETAILS_REQUEST = 'TICKET_DETAILS_REQUEST'
export const TICKET_DETAILS_SUCCESS = 'TICKET_DETAILS_SUCCESS'
export const TICKET_DETAILS_FAIL = 'TICKET_DETAILS_FAIL'