import React, { PureComponent } from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { useTheme } from '@mui/material/styles'
import Title from './Title'


// const getIntroOfPage = (label) => {
//   if (label === 'Mo') {
//     return "Page A is about men's clothing";
//   }
//   if (label === 'Tu') {
//     return "Page B is about women's dress";
//   }
//   if (label === 'Wed') {
//     return "Page C is about women's bag";
//   }
//   if (label === 'Thu') {
//     return 'Page D is about household goods';
//   }
//   if (label === 'Fri') {
//     return 'Page E is about food';
//   }
//   if (label === 'Sat') {
//     return 'Page F is about baby food';
//   }
//   return '';
// };

// const CustomTooltip = ({ active, payload, label }) => {
//   if (active && payload && payload.length) {
//     return (
//       <div className="custom-tooltip">
//         <p className="label">{`${label} : ${payload[0].value}`}</p>
//         <p className="intro">{getIntroOfPage(label)}</p>
//         <p className="desc">Anything you want can be displayed here.</p>
//       </div>
//     );
//   }

//   return null;
// };
const Chart = ({title, data, xAxisKey, yAxisKey}) => {
  const theme = useTheme()

    return (
      <div style={{ width: '100%', height: 320, paddingBottom: '40px' }}>
        <Title heading={title}/>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={data}
            margin={{
              top: 20,
              right: 30,
              // left: 20,
              bottom: 10,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={xAxisKey} />
            <YAxis />
            {/* <Tooltip content={<CustomTooltip />} /> */}
            {/* <Legend /> */}
            <Bar dataKey={yAxisKey} barSize={20} fill={theme.palette.primary.light} />
            <Tooltip  cursor={false}/>
            {/*<Legend />
            <Bar dataKey={data.income} fill="#8884d8" />
            <Bar dataKey={data.month} fill="#82ca9d" /> */}
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
}

export default Chart