import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom'
import { Avatar, Box, Button, Stack, Typography } from '@mui/material';
import { DataGrid, gridClasses, GridToolbar } from '@mui/x-data-grid';
import moment from 'moment';
import { grey } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import { listBarberTransactions } from '../../../redux/actions/transactionActions';
import BackDropLoader from '../../../components/ui/BackDropLoader';
import TextButton from '../../../components/ui/TextButton';

const Transactions = () => {
    const dispatch = useDispatch()
    const transactionUserList = useSelector(state => state.transactionUserList)
    const { loading, error, transactions } = transactionUserList

  const [pageSize, setPageSize] = useState(10);
  const [rowId, setRowId] = useState(null);

  useEffect(() => {
    dispatch(listBarberTransactions())
  }, []);

  const NoRowsOverlay = () => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        No transactions available
      </Stack>
    );
  }

  const columns = useMemo(
    () => [
      {
        field: 'ID',
        headerName: 'ID',
        width: 150,
        valueGetter: (params) => "T-"+params.row.id,
        sortable: false,
      },
      { 
        field: 'aptId', 
        headerName: 'APT ID', 
        width: 150, 
        sortable: false,
        valueGetter: (params) => {
          let aptID;
          if (params.row.appointmentId) {
              if (params.row.appointmentId.id) {
              aptID = "APT-"+params.row.appointmentId.id
              }
          } else {
              aptID = "";
          }
          return aptID;
          }
    },
    // { 
    // field: 'client',
    // headerName: 'Client Name', 
    // width: 180,
    // valueGetter: (params) => {
    //     let clientName;
    //     if (params.row.userId) {
    //         clientName = params.row.userId.firstName + " " + params.row.userId.lastName
    //     } else {
    //         clientName = ""
    //     }
    //     return clientName;
    //   }
    // },
    {
      field: 'amount',
      headerName: 'Amount ($)',
      width: 140,
      valueGetter: (params) => params.row.amount + " " + params.row.currency
      // valueGetter: (params) => {
      //   let amount;
      //   if (params.row.amount > 0) {
      //       amount = "$"+params.row.amount
      //   } else {
      //       amount = params.row.amount.toString().substring(0, 1) + "$" + params.row.amount.toString().substring(1) 
      //   }
      //   return amount;
      //   }
    },
    {
      field: 'Purpose',
      headerName: 'Purpose',
      width: 150,
      // sortable: false,
      filterable: false,
      renderCell: (params) => {
        let status, color="primary", disabled=false
        if (params.row.purpose === "appointment_fee") {
          status = "Appointment fee"
          color = "primary"
        }
        else if (params.row.purpose === "commission") {
          status = "Comission"
          color = "success"
          disabled=true
        } 
        else if (params.row.purpose === "deposit")  {
          status = "Deposit"
          color = "success"
        }
        else if (params.row.purpose === "withdrawal")  {
          status = "Withdraw"
          color = "primary"
        }
        else if (params.row.purpose === "refund")  {
          status = "Refund"
          color = "success"
        }
        else if (params.row.purpose === "transfer")  {
          status = "Transfer"
          color = "primary"
        }
        else if (params.row.purpose === "cancellation_fee")  {
          status = "Cancel Fee"
          color = "error"
        }
        else if (params.row.purpose === "reschedule_fee")  {
          status = "Reschedule Fee"
          color = "error"
        }
        else status = ""
        return (
          <TextButton 
          variant="outlined" 
          size="small"
          color={color}
          disabled={disabled}
          >
              {status}
          </TextButton>
          // status
        )
      },
      },
    {
      field: 'paymentMethod',
      headerName: 'Method',
      width: 140,
    },  
      {
          field: 'createdAt',
          headerName: 'Date & Time',
          width: 200,
          renderCell: (params) =>
          moment(params.row.createdAt).format('YYYY-MM-DD HH:MM:SS'),
        },        
        {
        field: 'status',
        headerName: 'Status',
        width: 100,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          let status, color="primary", disabled=false
          if (params.row.paymentStatus === "successful") {
            status = "Successful"
            color = "success"
          }
          else if (params.row.paymentStatus === "pending") {
            status = "Pending"
            color = "warning"
            disabled=true
          } 
          else if (params.row.paymentStatus === "failed")  {
            status = "Failed"
            color = "error"
          }
          else status = ""
          return (
            <TextButton 
            variant="outlined" 
            size="small"
            color={color}
            disabled={disabled}
            >
                {status}
            </TextButton>
            // status
          )
        }
        },
    //   {
    //     field: 'actions',
    //     headerName: 'Actions',
    //     type: 'actions',
    //     renderCell: (params) => (
    //       <UsersActions {...{ params, rowId, setRowId }} />
    //     ),
    //   },
    ],
    [rowId]
  );

  return (
    <>
    {
        loading 
        ? <BackDropLoader />
        :
        <Box
        sx={{
            height: '70vh',
            width: '100%',
        }}
        >
          <Box display="flex" justifyContent="flex-end">
            <Link to="/withdraw" style={{ textDecoration: "none"}}>
              <Button variant="contained">
                Withdraw Balance
              </Button>
            </Link>
          </Box>
        <Typography
            variant="h4"
            component="h4"
            sx={{ textAlign: 'center', mt: 3, mb: 3 }}
        >
            Transactions List
        </Typography>
        <DataGrid
            columns={columns}
            rows={transactions}
            getRowId={(row) => row._id}
            rowsPerPageOptions={[5, 10, 20]}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            components={{ 
              NoRowsOverlay,
              Toolbar: GridToolbar
            }}
            getRowSpacing={(params) => ({
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
            })}
            sx={{
            [`& .${gridClasses.row}`]: {
                bgcolor: (theme) =>
                theme.palette.mode === 'light' ? grey[200] : grey[900],
            },
            }}
            onCellEditCommit={(params) => setRowId(params.id)}
        />
        </Box>
    }
    </>
  );
};

export default Transactions;