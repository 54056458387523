import axios from 'axios';
import { useEffect, useState } from 'react'
import Badge from '@mui/material/Badge'
import MailIcon from '@mui/icons-material/Mail'
// import './conversations.css'
import { Avatar, Box, Typography } from '@mui/material';

const Conversations = ({ conversation, currentChat, currentUserId, onlineUsers }) => {
  const [user, setUser] = useState(null)

  useEffect(() => {
    const friend = conversation?.members?.firstUser?._id === currentUserId 
    ? conversation?.members?.secondUser
    : conversation?.members?.firstUser
    setUser(friend)
  }, [conversation])

  const checkOnlineStatus = (userId) => {
    const isFriendOnline = onlineUsers.some(user => user.userId === userId)
    return isFriendOnline
  }

  return (
    // <div className={currentChat ? "conversation active" : "conversation"}>
    // {/* <div className="conversation"> */}
    // <div className="conversationWrapper">
    //   <div className="conversationOnlineImgContainer">
    //     <img
    //       className="conversationImg" 
    //       src={user?.avatar ? user.avatar : "/images/noAvatar.png"}
    //       // src="/images/noAvatar.png"
    //       alt="user image"
    //     />
    //     <div className={checkOnlineStatus(user?._id) ? "conversationOnlineBadge" : null }></div>
    //   </div>
    //   <span className="conversationName">{user?.firstName} {user?.lastName}</span>
    //   {/* <span className="conversationName">Test User</span> */}
    // </div>
    // <Badge className="coversationMessageIcon" badgeContent={0} color="error">
    //     <MailIcon color="action" />
    // </Badge>
    // </div>
    <Box style={{
      display: "flex",
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '8px 16px',
      cursor: 'pointer',
      marginTop: '12px',
      backgroundColor: currentChat ? "rgb(245, 245, 245)" : ""
    }}>
    {/* <div className="conversation"> */}
    <Box display="flex" alignItems="center">
      <Box  sx={{
         position: 'relative',
         marginRight: '10px'
      }}>
        <Avatar
          // src={user?.avatar ? user.avatar : "/images/noAvatar.png"}
          src={user?.avatar}
          // src="/images/noAvatar.png"
          alt="user image"
        />
        {checkOnlineStatus(user?._id) &&
          <Box sx={{
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              backgroundColor: 'limegreen',
              position: 'absolute',
              top: '0px',
              right: '0px'
            }}
            >
          </Box>
        }
      </Box>
      <Box>
      <Typography variant="body2" fontWeight="bold" gutterBottom>{user ? user?.firstName + " " + user?.lastName : "Barbarly User"}</Typography>
      <Typography variant="subtitle2" noWrap>{conversation?.lastMessageDetails?.text}</Typography>
      </Box>
     
    </Box>
      <Badge sx={{ marginRight: '40px'}} badgeContent={conversation?.unSeenMessageCount || 0} color="error">
          <MailIcon color="action" />
      </Badge>
    </Box>
  )
}

export default Conversations