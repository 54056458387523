import axios from 'axios'
import { NOTIFICATION_LIST_FAIL, NOTIFICATION_LIST_REQUEST, NOTIFICATION_LIST_SUCCESS, NOTIFICATION_UNREAD_COUNT_FAIL, NOTIFICATION_UNREAD_COUNT_REQUEST, NOTIFICATION_UNREAD_COUNT_RESET, NOTIFICATION_UNREAD_COUNT_SUCCESS } from '../constants/notificationConstants'

export const getUnreadNotificationsCount = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: NOTIFICATION_UNREAD_COUNT_REQUEST,
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.get('/api/notifications/unread-count', config)
        dispatch({
            type: NOTIFICATION_UNREAD_COUNT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: NOTIFICATION_UNREAD_COUNT_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const getNotifications = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: NOTIFICATION_LIST_REQUEST,
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.get('/api/notifications', config)
        

        dispatch({
            type: NOTIFICATION_LIST_SUCCESS,
            payload: data
        })

        dispatch({
            type: NOTIFICATION_UNREAD_COUNT_RESET
        })


    } catch (error) {
        dispatch({
                    type: NOTIFICATION_LIST_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

