export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGIN_RESET = 'USER_LOGIN_RESET'
export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_GOOGLE_AUTH_REQUEST = 'USER_GOOGLE_AUTH_REQUEST'
export const USER_GOOGLE_AUTH_SUCCESS = 'USER_GOOGLE_AUTH_SUCCESS'
export const USER_GOOGLE_AUTH_FAIL = 'USER_GOOGLE_AUTH_FAIL'
export const USER_GOOGLE_AUTH_RESET = 'USER_GOOGLE_AUTH_RESET'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'
export const USER_REGISTER_RESET = 'USER_REGISTER_RESET'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET'

export const USER_WALLET_DETAILS_REQUEST = 'USER_WALLET_DETAILS_REQUEST'
export const USER_WALLET_DETAILS_SUCCESS = 'USER_WALLET_DETAILS_SUCCESS'
export const USER_WALLET_DETAILS_FAIL = 'USER_WALLET_DETAILS_FAIL'

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST'
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS'
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL'
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET'

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'
export const USER_LIST_RESET = 'USER_LIST_RESET'

export const USER_BARBER_LIST_REQUEST = 'USER_BARBER_LIST_REQUEST'
export const USER_BARBER_LIST_SUCCESS = 'USER_BARBER_LIST_SUCCESS'
export const USER_BARBER_LIST_FAIL = 'USER_BARBER_LIST_FAIL'
export const USER_BARBER_LIST_RESET = 'USER_BARBER_LIST_RESET'

export const USER_CLIENT_LIST_REQUEST = 'USER_CLIENT_LIST_REQUEST'
export const USER_CLIENT_LIST_SUCCESS = 'USER_CLIENT_LIST_SUCCESS'
export const USER_CLIENT_LIST_FAIL = 'USER_CLIENT_LIST_FAIL'
export const USER_CLIENT_LIST_RESET = 'USER_CLIENT_LIST_RESET'

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL'

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET'

export const USER_PASSWORD_RESET_REQUEST = 'USER_PASSWORD_RESET_REQUEST'
export const USER_PASSWORD_RESET_SUCCESS = 'USER_PASSWORD_RESET_SUCCESS'
export const USER_PASSWORD_RESET_FAIL = 'USER_PASSWORD_RESET_FAIL'
export const USER_PASSWORD_RESET_RESET = 'USER_PASSWORD_RESET_RESET'

export const USER_VERIFY_RESET_CODE_REQUEST = 'USER_VERIFY_RESET_CODE_REQUEST'
export const USER_VERIFY_RESET_CODE_SUCCESS = 'USER_VERIFY_RESET_CODE_SUCCESS'
export const USER_VERIFY_RESET_CODE_FAIL = 'USER_VERIFY_RESET_CODE_FAIL'
export const USER_VERIFY_RESET_CODE_RESET = 'USER_VERIFY_RESET_CODE_RESET'

export const USER_SAVE_PASSWORD_REQUEST = 'USER_SAVE_PASSWORD_REQUEST'
export const USER_SAVE_PASSWORD_SUCCESS = 'USER_SAVE_PASSWORD_SUCCESS'
export const USER_SAVE_PASSWORD_FAIL = 'USER_SAVE_PASSWORD_FAIL'
export const USER_SAVE_PASSWORD_RESET = 'USER_SAVE_PASSWORD_RESET'

export const USER_UPDATE_PASSWORD_REQUEST = 'USER_UPDATE_PASSWORD_REQUEST'
export const USER_UPDATE_PASSWORD_SUCCESS = 'USER_UPDATE_PASSWORD_SUCCESS'
export const USER_UPDATE_PASSWORD_FAIL = 'USER_UPDATE_PASSWORD_FAIL'
export const USER_UPDATE_PASSWORD_RESET = 'USER_UPDATE_PASSWORD_RESET'

export const USER_UPDATE_PHONE_REQUEST = 'USER_UPDATE_PHONE_REQUEST'
export const USER_UPDATE_PHONE_SUCCESS = 'USER_UPDATE_PHONE_SUCCESS'
export const USER_UPDATE_PHONE_FAIL = 'USER_UPDATE_PHONE_FAIL'
export const USER_UPDATE_PHONE_RESET = 'USER_UPDATE_PHONE_RESET'

export const USER_VERIFY_UPDATE_PHONE_REQUEST = 'USER_VERIFY_UPDATE_PHONE_REQUEST'
export const USER_VERIFY_UPDATE_PHONE_SUCCESS = 'USER_VERIFY_UPDATE_PHONE_SUCCESS'
export const USER_VERIFY_UPDATE_PHONE_FAIL = 'USER_VERIFY_UPDATE_PHONE_FAIL'
export const USER_VERIFY_UPDATE_PHONE_RESET = 'USER_VERIFY_UPDATE_PHONE_RESET'

export const USER_UPDATE_LOCATION_REQUEST = 'USER_UPDATE_LOCATION_REQUEST'
export const USER_UPDATE_LOCATION_SUCCESS = 'USER_UPDATE_LOCATION_SUCCESS'
export const USER_UPDATE_LOCATION_FAIL = 'USER_UPDATE_LOCATION_FAIL'
export const USER_UPDATE_LOCATION_RESET = 'USER_UPDATE_LOCATION_RESET'

export const USER_SEND_VERIFICATION_EMAIL_REQUEST = 'USER_SEND_VERIFICATION_EMAIL_REQUEST'
export const USER_SEND_VERIFICATION_EMAIL_SUCCESS = 'USER_SEND_VERIFICATION_EMAIL_SUCCESS'
export const USER_SEND_VERIFICATION_EMAIL_FAIL = 'USER_SEND_VERIFICATION_EMAIL_FAIL'
export const USER_SEND_VERIFICATION_EMAIL_RESET = 'USER_SEND_VERIFICATION_EMAIL_RESET'

// ADMIN
export const USER_STATUS_UPDATE_REQUEST = 'USER_STATUS_UPDATE_REQUEST'
export const USER_STATUS_UPDATE_SUCCESS = 'USER_STATUS_UPDATE_SUCCESS'
export const USER_STATUS_UPDATE_FAIL = 'USER_STATUS_UPDATE_FAIL'
export const USER_STATUS_UPDATE_RESET = 'USER_STATUS_UPDATE_RESET'