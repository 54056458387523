import {
    TRANSACTION_ADMIN_LIST_FAIL,
    TRANSACTION_ADMIN_LIST_REQUEST,
   TRANSACTION_ADMIN_LIST_SUCCESS,
   TRANSACTION_ADMIN_VERIFY_FAIL,
   TRANSACTION_ADMIN_VERIFY_REQUEST,
   TRANSACTION_ADMIN_VERIFY_RESET,
   TRANSACTION_ADMIN_VERIFY_SUCCESS,
   TRANSACTION_LIST_FAIL,
   TRANSACTION_LIST_REQUEST,
   TRANSACTION_LIST_SUCCESS,
   TRANSACTION_USER_LIST_FAIL,
   TRANSACTION_USER_LIST_REQUEST,
   TRANSACTION_USER_LIST_SUCCESS,
    
} from '../constants/transactionConstants'

export const transactionUserListReducer = (state = {transactions: [] }, action) => {
    switch (action.type) {
        case TRANSACTION_USER_LIST_REQUEST:
            return { loading: true}
        case TRANSACTION_USER_LIST_SUCCESS:
            return { loading: false, transactions: action.payload }
        case TRANSACTION_USER_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const transactionAdminListReducer = (state = {transactions: [] }, action) => {
    switch (action.type) {
        case TRANSACTION_ADMIN_LIST_REQUEST:
            return { loading: true}
        case TRANSACTION_ADMIN_LIST_SUCCESS:
            return { loading: false, transactions: action.payload }
        case TRANSACTION_ADMIN_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}


export const transactionVerifyReducer = (state = {}, action) => {
    switch (action.type) {
        case TRANSACTION_ADMIN_VERIFY_REQUEST:
            return { loading: true}
        case TRANSACTION_ADMIN_VERIFY_SUCCESS:
            return { loading: false, success: true }
        case TRANSACTION_ADMIN_VERIFY_FAIL:
            return { loading: false, error: action.payload}
        case TRANSACTION_ADMIN_VERIFY_RESET:
            return { success: false }
        default:
            return state
    }
}
