import axios from "axios";
import { TICKET_ADD_FAIL, TICKET_ADD_MESSAGE_FAIL, TICKET_ADD_MESSAGE_REQUEST, TICKET_ADD_MESSAGE_SUCCESS, TICKET_ADD_REQUEST, TICKET_ADD_SUCCESS, TICKET_DETAILS_FAIL, TICKET_DETAILS_REQUEST, TICKET_DETAILS_SUCCESS, TICKET_LIST_FAIL, TICKET_LIST_REQUEST, TICKET_LIST_SUCCESS, TICKET_MARK_OPENED_FAIL, TICKET_MARK_OPENED_REQUEST, TICKET_MARK_OPENED_SUCCESS, TICKET_MARK_RESOLVED_FAIL, TICKET_MARK_RESOLVED_REQUEST, TICKET_MARK_RESOLVED_SUCCESS, TICKET_MESSAGE_LIST_FAIL, TICKET_MESSAGE_LIST_REQUEST, TICKET_MESSAGE_LIST_SUCCESS, TICKET_USER_LIST_FAIL, TICKET_USER_LIST_REQUEST, TICKET_USER_LIST_SUCCESS } from "../constants/ticketConstants";



export const AddTicketMessage = ({ ticketId, text }) => async (dispatch, getState) => {
    try {
        dispatch({
        type: TICKET_ADD_MESSAGE_REQUEST
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
                headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }
        const { data } = await axios.post('/api/tickets/messages', { ticketId, text}, config)

        dispatch({
            type: TICKET_ADD_MESSAGE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: TICKET_ADD_MESSAGE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message
        })
    }
}

export const getTicketDetails = (id) => async (dispatch, getState) => {
    try {
    dispatch({
        type: TICKET_DETAILS_REQUEST,
    })

    const { userLogin: { userInfo }} = getState()

    const config = {
            headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.accessToken}`
        },
    }

    const { data } = await axios.get(`/api/tickets/get-ticket-details/${id}`, config)
    console.log(data)
    dispatch({  
        type: TICKET_DETAILS_SUCCESS,
        payload: data
    })

    } catch (error) {
    dispatch({
    type: TICKET_DETAILS_FAIL,
    payload:
        error.response && error.response.data.message
            ? error.response.data.message
            : error.message
    })
    }
}

export const listUserTickets = () => async (dispatch, getState) => {
    try {
    dispatch({
        type: TICKET_USER_LIST_REQUEST,
    })

    const { userLogin: { userInfo }} = getState()

    const config = {
            headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.accessToken}`
        },
    }

    const { data } = await axios.get('/api/tickets', config)

    dispatch({
        type: TICKET_USER_LIST_SUCCESS,
        payload: data
    })

    } catch (error) {
    dispatch({
    type: TICKET_USER_LIST_FAIL,
    payload:
        error.response && error.response.data.message
            ? error.response.data.message
            : error.message
    })
    }
}

export const listTickets = () => async (dispatch, getState) => {
    try {
    dispatch({
        type: TICKET_LIST_REQUEST,
    })

    const { userLogin: { userInfo }} = getState()

    const config = {
            headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.accessToken}`
        },
    }

    const { data } = await axios.get('/api/tickets/all', config)

    dispatch({
        type: TICKET_LIST_SUCCESS,
        payload: data
    })

    } catch (error) {
    dispatch({
    type: TICKET_LIST_FAIL,
    payload:
        error.response && error.response.data.message
            ? error.response.data.message
            : error.message
    })
    }
}

export const addNewTicket = ({ subject, message }) => async (dispatch, getState) => {
    try {
        dispatch({
        type: TICKET_ADD_REQUEST
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
                headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }
        const { data } = await axios.post('/api/tickets', { subject, message}, config)

        dispatch({
            type: TICKET_ADD_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: TICKET_ADD_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message
        })
    }
}

export const listTicketMessages = (ticketId) => async (dispatch, getState) => {
    try {
    dispatch({
        type: TICKET_MESSAGE_LIST_REQUEST,
    })

    const { userLogin: { userInfo }} = getState()

    const config = {
            headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.accessToken}`
        },
    }

    const { data } = await axios.get(`/api/tickets/messages/${ticketId}`, config)

    dispatch({
        type: TICKET_MESSAGE_LIST_SUCCESS,
        payload: data
    })

    } catch (error) {
    dispatch({
    type: TICKET_MESSAGE_LIST_FAIL,
    payload:
        error.response && error.response.data.message
            ? error.response.data.message
            : error.message
    })
    }
}

export const markTicketAsResolved = (id) => async (dispatch, getState) => {

    try {
        dispatch({
            type: TICKET_MARK_RESOLVED_REQUEST
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.put(`/api/tickets/mark-as-resolved/${id}`, {} ,config)
        
        dispatch({
            type: TICKET_MARK_RESOLVED_SUCCESS,
            payload: data
        })

    } catch (error) {
        console.log({error})
        dispatch({
                    type: TICKET_MARK_RESOLVED_FAIL,
                    payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message
                })
    }
}


export const markTicketAsOpened = (id) => async (dispatch, getState) => {

    try {
        dispatch({
            type: TICKET_MARK_OPENED_REQUEST
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.put(`/api/tickets/mark-as-opened/${id}`, {},config)
        
        dispatch({
            type: TICKET_MARK_OPENED_SUCCESS,
            payload: data
        })

    } catch (error) {
        console.log({error})
        dispatch({
                    type: TICKET_MARK_OPENED_FAIL,
                    payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message
                })
    }
}