import { Box, Button, Divider, List, Paper, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { AppointmentsList, BarChart, DataWidget, LineChart, PieChart, Title } from '../../../features/dashboard'
import Group from '@mui/icons-material/Group'
import MenuIcon from '@mui/icons-material/Menu'
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined'
import PeopleIcon from '@mui/icons-material/People'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ContentCutIcon from '@mui/icons-material/ContentCut'
import moment from 'moment'
import StyledAvatar from '../../../features/dashboard/components/StyledAvatar'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { listAppointments } from  '../../../redux/actions/appointmentActions'
import { getAdminDashboardData } from  '../../../redux/actions/adminActions'
import BackDropLoader from '../../../components/ui/BackDropLoader'
import { UPDATE_ALERT } from '../../../redux/constants/alertConstants';
import BiaxialChart from '../../../features/dashboard/components/BiaxialChart'
import TextButton from '../../../components/ui/TextButton'

const Dashboard = () => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  // const userLogin = useSelector(state => state.userLogin)
  // const { userInfo } = userLogin

  const adminDashboardData = useSelector(state => state.adminDashboardData)
  const { loading: loadingData, data } = adminDashboardData

  const appointmentAdminList = useSelector(state => state.appointmentAdminList)
  const { loading: loadingAppointments, error: errorAppointments, appointments } = appointmentAdminList

  useEffect(() => {
    setLoading(true)
    dispatch(listAppointments())
    dispatch(getAdminDashboardData())
    setLoading(false)
    // if (users.length === 0) dispatch(listUsers())
    // dispatch({ type: UPDATE_ALERT, payload: {open: true, severity: 'error', message: 'Test message' } })
  }, [])

  const barChartData = [
    {
      month: 'Jan',
      income: 200,
    },
    {
      month: 'Feb',
      income: 230,
    },
    {
      month: 'Mar',
      income: 250,
    },
    {
      month: 'Apr',
      income: 220,
    },
    {
      month: 'May',
      income: 280,
    },
    {
      month: 'Jun',
      income: 300,
    },
    {
      month: 'Jul',
      income: 290,
    },
    {
      month: 'Aug',
      income: 350,
    },
    {
      month: 'Sep',
      income: 320,
    },
    {
      month: 'Oct',
      income: 500,
    },
    {
      month: 'Nov',
      income: 450,
    },
  ];
  
// Generate Clients Data
function createData(month, noOfClients) {
  return { month, noOfClients };
}

  const lineChartData = [
    createData('Jan', 0),
    createData('Feb', 8),
    createData('Mar', 10),
    createData('Apr', 8),
    createData('May', 6),
    createData('Jun', 7),
    createData('Jul', 10),
    createData('Aug', 9),
    createData('Sep', 15),
    createData('Oct', 16),
    createData('Nov', 17),
  ];

  const BiaxialChartData = [
    {
      name: 'Jan',
      clients: 10,
      barbers: 4,
    },
    {
      name: 'Feb',
      clients: 8,
      barbers: 6,
    },
    {
      name: 'Mar',
      clients: 15,
      barbers: 8,
    },
    {
      name: 'Apr',
      clients: 20,
      barbers: 10,
    },
    {
      name: 'May',
      clients: 15,
      barbers: 7,
    },
    {
      name: 'Jun',
      clients: 12,
      barbers: 14,
    },
    {
      name: 'Jul',
      clients: 18,
      barbers: 20,
    },
    {
      name: 'Aug',
      clients: 24,
      barbers: 14,
    },
    {
      name: 'Sep',
      clients: 30,
      barbers: 24,
    },
    {
      name: 'Oct',
      clients: 34,
      barbers: 20,
    },
    {
      name: 'Nov',
      clients: 40,
      barbers: 26,
    },
  ];
  
  

  return (
    <>
      {loading || loadingData || loadingAppointments 
      ? <BackDropLoader /> 
      :
      <Box
      sx={{
        display: { xs: 'flex', md: 'grid' },
        gridTemplateColumns: 'repeat(4,1fr)',
        gridAutoRows: 'minmax(100px, auto)',
        gap: 3,
        textAlign: 'center',
        flexDirection: 'column',
      }}
    >
      <DataWidget 
        title="Revenue" 
        value={`$${data?.revenue}`}
        Icon={<PaymentsOutlinedIcon color="primary" sx={{ height: 40, width: 80, opacity: 0.4 }} />  }
      />
        <DataWidget 
          title="Milestones" 
          value={`$${data?.totalMilestonesAmount}`}
          Icon={<PaymentsOutlinedIcon color="error" sx={{ height: 40, width: 80, opacity: 0.4 }} />  }
        />
      <DataWidget 
        title="Barbers" 
        value={data?.barbersCount}
        Icon={<ContentCutIcon color="warning" sx={{ height: 35, width: 70, opacity: 0.4 }} />  }
      />
      <DataWidget 
        title="Clients" 
        value={data?.clientsCount}
        Icon={<PeopleIcon color="success" sx={{ height: 40, width: 80, opacity: 0.5 }} />  }
      />
       {/* <Paper elevation={3} sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            paddingBottom: "24px",
            flexWrap: 'wrap',
            gridColumn: 4, gridRow: '2/3' 
          }}>
          <PieChart />
      </Paper> */}
      <Paper elevation={3} sx={{ p: 2, gridColumn: 4, gridRow: '2/4' }}>
        <Box>
          {/* <Typography variant='h6' gutterBottom>Recent Appointments</Typography> */}
          <Title heading="Recent Appointments"/>
          <List>
            {appointments?.slice(0,6).map((item) => (
               <Link to={`/appointment/${item._id}`} style={{textDecoration: 'none'}}>
                <Paper
                  elevation={3}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '14px',
                    marginBottom: "22px"
                  }}
                    key={item._id}
                  >
                  {/* <Box
                    sx={{
                      marginRight: "10px"
                    }}
                  >
                    <StyledAvatar />
                  </Box> */}
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                      component="span"
                      variant="body2"
                      fontWeight="bold"
                      // sx={{
                      //   fontFamily: 'inherit',
                      //   display: 'block',
                      //   whiteSpace: 'nowrap',
                      //   lineHeight: 'inherit',
                      //   fontWeight: 500
                      // }}
                    >
                      APT-{item.id} {(() => {
                        switch (item.status) {
                          case "upcoming": return <TextButton 
                                                    variant="outlined" 
                                                    size="small"
                                                    color="primary"
                                                    >
                                                    [Upcoming]
                                                  </TextButton>
                          case "completed": return <TextButton 
                                                    variant="outlined" 
                                                    size="small"
                                                    color="success"
                                                    >
                                                    [Completed]
                                                  </TextButton>
                          case "cancelled": return <TextButton 
                                                    variant="outlined" 
                                                    size="small"
                                                    color="error"
                                                    >
                                                    [Cancelled]
                                                  </TextButton>
                        }
                      })()}
                    </Typography>
                    <Typography
                      component="span"
                      variant="body2"
                      // sx={{
                      //   display: 'block',
                      //   whiteSpace: 'nowrap',
                      //   lineHeight: 'inherit',
                      // }}
                    >
                      {item.appointmentDate.substring(0, 10)} ({item.appointmentTime})
                    </Typography>
                  </Box>
                  <Button variant='contained' size="small">View</Button>
              </Paper>
              </Link>
            ))}
          </List>
        </Box>
        {/* <Divider sx={{ mt: 3, mb: 3, opacity: 0.7 }} /> */}
      </Paper>
      <Paper elevation={3} sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            padding: "10px 0 20px 0",
            flexWrap: 'wrap',
            gridColumn: '1/4'
          }}>
          <BarChart title="Revenue Overview" data={barChartData} xAxisKey="month" yAxisKey="income" />
      </Paper>
      <Paper elevation={3} sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            padding: "10px 0 20px 0",
            flexWrap: 'wrap',
            gridColumn: '1/4', 
          }}>
          {/* <LineChart title="Clients Overview" data={lineChartData} xAxisKey="month" lineKey="noOfClients" /> */}
          <BiaxialChart title="Clients & Barbers Overview" data={BiaxialChartData} />
      </Paper>
      </Box>
      }
    </>
  )
}

export default Dashboard