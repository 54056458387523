import React, { useState, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Button from '../../../../components/ui/Button/Button'

import './HeroSection.css'
const scrollToRef = (ref) => {
  window.scrollTo(0, (ref.current.offsetTop)-150)  
  window.scrollTo({ behavior: "smooth", block: "start" })  
} 

const HeroSection = ({myRef}) => {

  const executeScroll = () => scrollToRef(myRef)
  const navigate = useNavigate() 

  const urls = [
    "/videos/video-1.mp4", 
    "/videos/video-2.mp4", 
  ];

  const [currentVideoIndex, setCurrentVideoIndex] = useState(0)


  const setNextVideoIndex = (e) => {
    const nextUrlIdx = (currentVideoIndex + 1) % urls.length
    setCurrentVideoIndex(nextUrlIdx) 
  }

  const onBookHandler = (e) => {
    e.preventDefault()
    navigate('/sign-up')
  }

  return (
    <div className='hero-container'>
      <video 
      className="transition" 
        src={urls[currentVideoIndex]} 
        onEnded={setNextVideoIndex}
        autoPlay
        // loop
        muted 
      />
      <h1>BARBER SERVICES</h1>
      <p>at your Doorstep</p>
      <div 
        className="hero-btns"
      >
        <Button
          className='btns'
          // buttonStyle='btn--outline'
          buttonStyle='btn--primary'
          buttonSize='btn--large'  
          onClick={onBookHandler}
        >
          Get Started
          {/* <i class='fas fa-play-circle'></i> */}
          
        </Button>
          {/* <Button
            className='btns'
            buttonStyle='btn--primary'
            buttonSize='btn--large'  
            onClick={onBookHandler}
          >
            Book Now
          </Button> */}
      </div>
    </div>
  )
}

export default HeroSection