import { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { listUserTransactions } from '../../../redux/actions/transactionActions';
import BackDropLoader from '../../../components/ui/BackDropLoader';   
import TransactionsTable from './TransactionsTable';

const Transactions = () => {
    const dispatch = useDispatch()
    const transactionUserList = useSelector(state => state.transactionUserList)
    const { loading, error, transactions } = transactionUserList

  useEffect(() => {
    // if (transactions.length === 0) dispatch(listUserTransactions())
    dispatch(listUserTransactions())
  }, []);

  return (
    <>
    {
        loading 
        ? <BackDropLoader />
        :
        <Box
        sx={{
            height: '70vh',
            width: '100%',
        }}
        >
        <Typography
            variant="h4"
            component="h4"
            sx={{ textAlign: 'center', mt: 3, mb: 3 }}
        >
            Transactions List
        </Typography>
       <TransactionsTable transactions={transactions} />
        </Box>
    }
    </>
  );
};

export default Transactions;