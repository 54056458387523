import * as React from 'react';
import { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useTheme } from '@mui/material/styles'
import { useLocation, useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import Badge from '@mui/material/Badge'
import Tooltip from '@mui/material/Tooltip'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import Divider from '@mui/material/Divider'
import Chip from '@mui/material/Chip'
import IconButton from '@mui/material/IconButton'
import ModeNightIcon from '@mui/icons-material/ModeNight'
import LightModeIcon from '@mui/icons-material/LightMode'
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

import StyledAvatar from '../../features/dashboard/components/StyledAvatar'
import { Stack } from '@mui/material'
import { logout } from '../../redux/actions/userActions'
import { useEffect } from 'react';

const drawerWidthOpen = 240
const paddingIconButton = 10
const marginIconButton = 14
const iconFontSize = 20
const drawerWidthClose =
  (paddingIconButton + marginIconButton) * 2 + iconFontSize

export default function SideNavbar({navbarList, open, setOpen, mode, setMode}) {
  const theme = useTheme();
  // const [open, setOpen] = useState(false);
  const refFocus = useRef();
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  
  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  // useEffect(() => {
  //   if (!open) setOpen(false)
  // }, [open])


  function handleClick (path) {
    // setOpen(!open) //for mobile 
    setTimeout(() => {
      refFocus.current.focus()
    }, 500);
    navigate(path)
  }

  const logoutHandler = () => {
    console.log("Logout handler called")
    dispatch(logout())
    
    // navigate(getRoleBasedLogout(userInfo.role))
}

  const drawerContent = (
    <Box mt={2}>
      <Box 
        display="flex" 
        justifyContent="flex-end" 
        sx={{marginRight: "10px"}}
      >
      {/* { open 
        ? 
          <IconButton sx={{display: {xs: "none", md: "block"}}}  onClick={() => setOpen(!open)}>
            <MenuIcon color="primary"  />
          </IconButton>
        :
          <IconButton sx={{display: {xs: "none", md: "block"}}}  onClick={() => setOpen(!open)}>
            <ChevronLeftIcon color="primary"  />
          </IconButton>
      } */}
      { open &&
        <IconButton sx={{display: {xs: "block", md: "none"}}} onClick={() => setOpen(!open)}>
          <CloseIcon color="primary"  />
        </IconButton>  
      } 
      </Box>
          
      <List dense={true} sx={{margin: "10px 0"}}>
        
        {navbarList.map((key, index) => (
          <>
              <Tooltip
                title={open ? key.text : ''}
                placement={'right'}
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: 'gray',
                      color: 'white',
                      marginLeft: '22px !important',
                      boxShadow: '0px 0px 22px -2px rgba(0,0,0,0.20)',
                    },
                  },
                }}
              >
                <ListItemButton
                  button
                  onClick={() => handleClick(key.path)}
                  sx={{
                    margin: '6px 14px',
                    padding: '10px',
                    borderRadius: '8px',
                    '&:hover': {
                      backgroundColor: '#26284687',
                    },
                  }}

                >
                  <ListItemIcon sx={{ minWidth: '46px' }}>
                    <Badge
                      badgeContent={key.badge}
                      color="primary"
                      // variant="dot"
                    >
                      <key.icon sx={{ fontSize: '22px', color: location.pathname == key.path ? theme.palette.primary.main : "lightgray"}} />
                    </Badge>
                  </ListItemIcon>

                  <ListItemText
                    primary={key.text}
                    primaryTypographyProps={{
                      variant: 'body1',
                    }}
                    sx={{
                      display: 'inline',
                      margin: '0px',
                      overflowX: 'hidden',
                      color: location.pathname == key.path ? "#fff"  : theme.palette.custom.lightgray,
                      whiteSpace: 'nowrap',
                      minWidth: '126px',
                    }}
                  />
                  {/* {key.badge !== 0 ? (
                    <Chip
                      label={key.badge}
                      color={'primary'}
                      size="small"
                      sx={{ height: 'auto' }}
                    />
                  ) : (
                    <></>
                  )} */}
                </ListItemButton>
              </Tooltip>
            {/* )} */}
          </>
        ))}

        <>
          <Tooltip
            title={open ? 'Logout' : ''}
            placement={'right'}
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'gray',
                  color: 'white',
                  marginLeft: '22px !important',
                  boxShadow: '0px 0px 22px -2px rgba(0,0,0,0.20)',
                },
              },
            }}
          >
            <ListItemButton
              button
              onClick={logoutHandler}
              sx={{
                margin: '6px 14px',
                padding: '10px',
                borderRadius: '8px',
                '&:hover': {
                  backgroundColor: '#26284687',
                },
              }}

            >
              <ListItemIcon sx={{ minWidth: '46px' }}>
                  <LogoutIcon sx={{ fontSize: '22px', color: "lightgray"}} />
              </ListItemIcon>

              <ListItemText
                primary="Logout"
                primaryTypographyProps={{
                  variant: 'body1',
                }}
                sx={{
                  display: 'inline',
                  margin: '0px',
                  overflowX: 'hidden',
                  color: theme.palette.custom.lightgray,
                  whiteSpace: 'nowrap',
                  minWidth: '126px',
                }}
              />
            </ListItemButton>
          </Tooltip>
        </>

        <ListItemButton
            button
            sx={{
              display: "flex",
              margin: '6px 18px',
              padding: '10px',
              borderRadius: '8px',
            }}
          >
            <Stack 
              direction="row" 
              spacing={1} 
              alignItems="center" 
              sx={{
                marginLeft: open ? "-20px" : "0px"
                }}>
              {/* <Typography sx={{ color: theme.palette.custom.lightgray }}>Light</Typography> */}
              <LightModeIcon 
                fontSize="small"
                sx={{ 
                  color: theme.palette.custom.lightgray,
                  display: open ? "none" : "block"
                  }}
                  />
              <Switch
                size="small"
                checked={mode === "dark"}
                onChange={e => setMode(mode === "light" ? "dark" : "light")}
              />
              {/* <Typography sx={{ color: theme.palette.custom.lightgray }}>Dark</Typography> */}
              <ModeNightIcon
                fontSize="small" 
                sx={{ 
                  color: theme.palette.custom.lightgray,
                  display: open ? "none" : "block"
                }}
                />
            </Stack>
          </ListItemButton>
        {/* <Divider variant="middle" light={true} /> */}
      </List>

      {/* <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          alignContents: 'center',
          margin: '14px 14px',
          padding: '12px 4px',
          borderTop: '1px solid lightgray',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            marginRight: '18px',
            paddingLeft: '0px',
            alignItems: 'center',
            alignContent: 'center',
          }}
        >
          <StyledAvatar />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          <Typography
            component="span"
            variant="body2"
            sx={{
              fontFamily: 'inherit',
              display: 'block',
              whiteSpace: 'nowrap',
              lineHeight: 'inherit',
              fontWeight: 500,
              color: 'lightgray',
            }}
          >
            User Name  
          </Typography>
          <Typography
            component="span"
            variant="body2"
            sx={{
              display: 'block',
              whiteSpace: 'nowrap',
              lineHeight: 'inherit',
              color: 'lightgray',
            }}
          >
            Welcome Back
          </Typography>
        </Box>
        <IconButton contained sx={{ color: 'lightGray' }}>
          <ModeNightIcon />
        </IconButton>
      </Box> */}
    </Box>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        variant="permanent"
        sx={{
          width: open
          ? { xs: drawerWidthOpen, md: drawerWidthClose }
          : { xs: '0px', md: drawerWidthOpen },
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: open
              ? theme.transitions.duration.leavingScreen
              : theme.transitions.duration.enteringScreen,
          }),
          '& .MuiDrawer-paper': {
            justifyContent: 'start',
            overflowX: 'hidden',
            width: open
            ? { xs: drawerWidthOpen, md: drawerWidthClose }
            : { xs: '0px', md: drawerWidthOpen },
            borderRight: '0px',
            borderRadius: '0px 0px 0px 0px',
            boxShadow: theme.shadows[8],
            backgroundColor: open ? '#11101D' : '#11101D',
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: open
                ? theme.transitions.duration.leavingScreen
                : theme.transitions.duration.enteringScreen,
            }),
          },
        }}
      >
        {drawerContent}
      </Drawer>
      {/* <Box
        component="main"
        sx={{
          backgroundColor: 'lightblue',
          padding: '8px',
          margin: '6px 14px',
        }}
      >
        <Typography>
          Lorem ipsum sir dolor Ullamco veniam consequat mollit cupidatat
          voluptate sint voluptate enim laborum Lorem sint dolore. Sit sit aute
          nulla aute dolore duis in sit qui in exercitation cupidatat ea dolore.
          Culpa sunt pariatur officia cupidatat tempor consectetur ea commodo
          excepteur veniam tempor. Consectetur duis veniam eiusmod id ipsum
          velit irure. Sint sint ipsum esse occaecat. officia.
        </Typography>
        <Switch
          checked={open}
          onChange={() => setOpen((prevOpen) => !prevOpen)}
        >
          switch
        </Switch>
      </Box> */}
    </Box>
  );
}
