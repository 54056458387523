import { styled, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";

const Details = ({ img, heading, text }) => {
  const CustomBox = styled(Box)(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(10),
    padding: theme.spacing(16, 0, 0, 0),
    margin: theme.spacing(0, 2, 0, 2),
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      textAlign: "center",
    },
  }));

  const ImgContainer = styled(Box)(({ theme }) => ({
    width: "100%",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  }));

  const Divider = styled("div")(({ theme }) => ({
    width: "13%",
    height: "5px",
    backgroundColor: "#000339",
    [theme.breakpoints.down("md")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  }));

  return (
    <Box className="section">
      <Container py={5}>
        <CustomBox>
          <ImgContainer>
            <img src={img} loading="lazy" alt="Barbarly" style={{ maxWidth: "100%" }} />
          </ImgContainer>

          <Box sx={{ maxWidth: "540px"}}>
            <Divider />
            <Typography
              sx={{
                fontSize: "35px",
                color: "#000339",
                fontWeight: "700",
                my: 3,
              }}
            >
              {heading}
            </Typography>

            <Typography
              sx={{
                fontSize: "16px",
                color: "#5A6473",
                lineHeight: "27px",
              }}
            >
             {text}
            </Typography>
          </Box>
        </CustomBox>
      </Container>
    </Box>
  );
};

export default Details;