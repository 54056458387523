import axios from  'axios';

// const URL = 'https://travel-advisor.p.rapidapi.com/restaurants/list-in-boundary'

// export const getPlacesData = async (sw, ne) => {
//   console.log("getPlacesData(): ", {sw, ne})
//     try {
//         const { data: { data }} = await axios.get(URL, {
//           params: {
//             bl_latitude: sw.lat,
//             tr_latitude: ne.lat,
//             bl_longitude: sw.lng,
//             tr_longitude: ne.lng,
//           },
//           headers: {
//             'X-RapidAPI-Host': 'travel-advisor.p.rapidapi.com',
//             'X-RapidAPI-Key': 'bc1f7d2bb3mshe121cd638628effp1b0159jsn6d009fb1e9b4'
//           }
//         });

//         return data;
//     } catch (error) {
//         console.log(error);
//     }
// }

const URL = '/api/stores'

export const getPlacesData = async (sw, ne, rating, forMen, forWomen) => {
  console.log("bl_lat: ", sw.lat)
  console.log("tr_lat: ", ne.lat)
  console.log("bl_lng: ", sw.lng)
  console.log("tr_lng: ", ne.lng)
  console.log({rating})
  console.log({forMen})
  console.log({forWomen})
    try {
        const { data } = await axios.get(URL, {
          params: {
            bl_latitude: sw.lat,
            tr_latitude: ne.lat,
            bl_longitude: sw.lng,
            tr_longitude: ne.lng,
            rating,
            forMen, 
            forWomen
          },
        });

        return data;
    } catch (error) {
        console.log(error);
    }
}


// const URL = '/api/barbers'

// export const getPlacesData = async (coordinates) => {
//   // console.log("getPlacesData(): ", {lat, lng})
//   const config = {
//     headers: {
//         'Content-type': 'application/json'
//     },
// }

//     try {
//         const { data } = await axios.get(URL, {
//           params: {
//             latitude: coordinates.lat,
//             longitude: coordinates.lng,
//           }}
//           );
//         console.log({data})
//         return data;
//     } catch (error) {
//         console.log(error);
//     }
// }