import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom'
import { Grid, Box, Card, CardMedia, Divider, Typography,Button } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { 
    cancelAppointment, 
    rescheduleppointmentWithPaypal,
    getAppointmentDetails, 
    markAppointmentAsCompleted, 
    markAppointmentAsCompletedByClient,
    markAppointmentAsCompletedByBarber,
    listUserAppointments,
    cancelAppointmentAdmin,
    // markAppointmentAsCompletedByBarber
 } from '../../../redux/actions/appointmentActions'
import { styled } from "@mui/material/styles"
import BackDropLoader from '../../../components/ui/BackDropLoader'
import TextButton from '../../../components/ui/TextButton'

import AnimatedPage from '../../../components/Layout/AnimatedPage'
import { APPOINTMENT_CANCEL_ADMIN_RESET, APPOINTMENT_CANCEL_RESET, APPOINTMENT_COMPLETED_RESET, APPOINTMENT_MARK_COMPLETED_BARBER_RESET, APPOINTMENT_MARK_COMPLETED_CLIENT_RESET, APPOINTMENT_RESCHEDULE_RESET } from '../../../redux/constants/appointmentConstants';
import ROLES from '../../../constants/roleConstants';
import { addNewConversation } from '../../../redux/actions/chatActions';
import { CONVERSATION_ADD_RESET } from '../../../redux/constants/chatConstants';
import FeedbackModal from '../../../components/ui/Modals/FeedbackModal';
import { UPDATE_ALERT } from '../../../redux/constants/alertConstants';
import { FEEDBACK_ADD_RESET } from '../../../redux/constants/feedbackConstants';

const StyledCard = styled(Card)(({ theme }) => ({
     margin: '0 auto',
     padding: "12px"
}))

const StyledBox = styled(Box)(({ theme }) => ({
   display: "flex",
   justifyContent: "space-between",
   alignItems: 'center',
   margin: 10,
   gap: 140,
  }))

 
const appointmentDateHasPassed = (stringDate) => {
    const today = new Date()
    let date = new Date(stringDate)
    return date.getTime() < today.getTime()
}
const AppointmentDetails = () => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { id } = useParams()

    const [openCancelModal, setOpenCancelModal] = useState(false)
    const [openFeedbackModel, setOpenFeedbackModel] = useState(false)

    const appointmentDetails = useSelector(state => state.appointmentDetails)
    const { loading, error, appointment  } = appointmentDetails

    const appointmentCancel = useSelector(state => state.appointmentCancel)
    const {  loading: cancelLoading, error: cancelError, success  } = appointmentCancel

    const appointmentCancelAdmin = useSelector(state => state.appointmentCancelAdmin)
    const {  loading: cancelAdminLoading, error: cancelAdminError, success: cancelAdminSuccess  } = appointmentCancelAdmin

    const feedbackAdd = useSelector(state => state.feedbackAdd)
    const { loading: feedbackLoading, success: feedbackSuccess } = feedbackAdd
    
    const appointmentReschedule = useSelector(state => state.appointmentReschedule)
    const {  loading: rescheduleLoading, success: reschduleSuccess  } = appointmentReschedule

    const appointmentMarkCompleteClient = useSelector(state => state.appointmentMarkCompleteClient )
    const { loading: completeLoading, error: completeError, success: completeSuccess  } = appointmentMarkCompleteClient 

    const appointmentMarkCompleteBarber = useSelector(state => state.appointmentMarkCompleteBarber )
    const { loading: completeBarberLoading, error: completeBarberError, success: completeBarberSuccess  } = appointmentMarkCompleteBarber 

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const conversationAdd = useSelector(state => state.conversationAdd)
    const {
            loading: loadingAddConversation, 
            error: errorAddConversation, 
            success: successAddConversation 
        } = conversationAdd

    const conversationDetails = useSelector(state => state.conversationDetails)
    const {
            loading: loadingDetailsConversation, 
            error: errorDetailsConversation, 
            success: successDetailsConversation 
        } = conversationDetails

        // useEffect(() => {
        //    navigate("/bookings")
        // },[success]) 
        
      useEffect(() => {
        dispatch(getAppointmentDetails(id))
        if(success) {
            dispatch({ type: APPOINTMENT_CANCEL_RESET })
        }
        if(completeSuccess) {
            dispatch({ type: APPOINTMENT_MARK_COMPLETED_CLIENT_RESET })
        }
        if(completeBarberSuccess) {
            dispatch({ type: APPOINTMENT_MARK_COMPLETED_BARBER_RESET })
        }
        if(reschduleSuccess) {
            dispatch({ type: APPOINTMENT_RESCHEDULE_RESET })
        }
        if(feedbackSuccess) {
            dispatch({ type: FEEDBACK_ADD_RESET })
        }
        if(cancelError) {
            dispatch({ type: APPOINTMENT_CANCEL_RESET })
        }
        if(cancelAdminSuccess || cancelAdminError) {
            dispatch({ type: APPOINTMENT_CANCEL_ADMIN_RESET })
        }
    }, [])   
  
    useEffect(() => {
        // if(success)
            dispatch(getAppointmentDetails(id))
        if(completeBarberSuccess) {
            dispatch({ 
                type: UPDATE_ALERT, 
                payload: {
                open: true, 
                severity: 'success', 
                message: "Confirmation request has been sent to your client."
                } 
            })
            dispatch(listUserAppointments())
        }
        if(completeSuccess) {
            dispatch({ 
                type: UPDATE_ALERT, 
                payload: {
                open: true, 
                severity: 'success', 
                message: "Appointment marked as completed."
                } 
            })
            dispatch(listUserAppointments())
        }
        if (successAddConversation || errorAddConversation) 
            navigate('/messages')
        if (completeError) {
            dispatch({ 
                type: UPDATE_ALERT, 
                payload: {
                open: true, 
                severity: 'error', 
                message: completeError
                } 
            })
        }
        if (completeBarberError) {
            dispatch({ 
                type: UPDATE_ALERT, 
                payload: {
                open: true, 
                severity: 'error', 
                message: completeBarberError
                } 
            })
        }
        if (feedbackSuccess) {
            dispatch({ 
                type: UPDATE_ALERT, 
                payload: {
                open: true, 
                severity: 'success', 
                message: "Feedback added successfully."
                } 
            })
            navigate(`/barber/${appointment?.storeId?._id}`)
        }
        if (cancelError) {
            dispatch({ 
                type: UPDATE_ALERT, 
                payload: {
                open: true, 
                severity: 'error', 
                message: cancelError
                } 
            })
        }
        if (cancelAdminSuccess) {
            dispatch({ 
                type: UPDATE_ALERT, 
                payload: {
                open: true, 
                severity: 'success', 
                message: "Appointment cancelled successfully and funds refunded to client's wallet."
                } 
            })
        }
        if (cancelAdminError) {
            dispatch({ 
                type: UPDATE_ALERT, 
                payload: {
                open: true, 
                severity: 'error', 
                message: cancelAdminError
                } 
            })
        }
    },[
        success, 
        cancelError,
        successAddConversation,
        errorAddConversation,
        completeSuccess,
        completeBarberSuccess, 
        completeError, 
        completeBarberError, 
        feedbackSuccess,
        cancelAdminSuccess,
        cancelAdminSuccess
    ]) 

    const handleCancel = (id) => {
        console.log("called")
        dispatch(cancelAppointment(id))
    }

    const handleCancelbyAdmin = (id) => {
        console.log("Admin cancel called")
        dispatch(cancelAppointmentAdmin(id))
    }

    const handleReschedule = (id) => {
       navigate(`/appointment/${id}/reschedule`)
    }

    const handleCompleteClient = (id) => {
        dispatch(markAppointmentAsCompletedByClient(id)) 
    }

    const handleCompleteBarber = (id) => {
        dispatch(markAppointmentAsCompletedByBarber(id)) 
    }


    const messageHandler = () => {
        console.log("Message Handler")
        let receiverId;
        //CLIENT
        if (userInfo?.role === ROLES.CLIENT) {
            receiverId = appointment?.storeId?.owner?._id
        } else {
            receiverId = appointment?.clientId?._id
        }
        dispatch(addNewConversation({receiverId}))
        // const res = await axios.post(`/conversations`, {
        //               senderId: userInfo?._id,
        //               receiverId
        //           })
        //     }
    }

    return (
    <AnimatedPage>
        {loading || cancelLoading || loadingAddConversation || rescheduleLoading || 
        completeBarberLoading || completeLoading || feedbackLoading || cancelAdminLoading
        ?
          <BackDropLoader /> 
        : 
          appointment &&
            <Grid container maxWidth="md" mx="auto">
                <StyledCard>
                    <Card sx={{p: 1, mt: 2, mb: 4}} elevation={1}>
                        {/* <Box display="flex" alignItems="center"> */}
                            {/* <CardMedia 
                                sx={{ height: 40, width: 40  }}
                                image={appointment?.storeId?.owner?.avatar}
                            /> */}
                            {/* <Typography mx={2} variant="body2" fontWeight="bold">{appointment?.storeId?.name}</Typography> */}
                        {/* </Box>   */}
                            <Typography mx={2} variant="body1" fontWeight="bold" align="center" color="primary">Appointment Details</Typography>
                    </Card>
                    <StyledBox>
                        <Typography flex={1} variant="body2" fontWeight="bold">Appointment ID</Typography>
                        <Typography align="right">APT-{appointment.id}</Typography>
                    </StyledBox>
                    <Divider />
                    {(userInfo?.role === ROLES.BARBER) &&
                        <>
                            <StyledBox>
                                <Typography flex={1} variant="body2"  fontWeight="bold">Client Name</Typography>
                                <Typography align="right">{appointment?.clientId?.firstName} {appointment?.clientId?.lastName}</Typography>
                            </StyledBox>
                            <Divider />
                        </>
                    }
                    {(userInfo?.role === ROLES.CLIENT) &&
                        <>
                            <StyledBox>
                                <Typography flex={1} variant="body2" fontWeight="bold">Barber Name</Typography>
                                <Typography align="right">{appointment?.storeId?.name}</Typography>
                            </StyledBox>
                            <Divider />
                        </>
                    }
                    {/* Services */}
                    <StyledBox>
                        <Typography flex={1} variant="body2" fontWeight="bold">Services</Typography>
                    </StyledBox>
                    <Box ml={1}>
                        {appointment?.services?.map((item) => (
                            <StyledBox>
                                <Typography variant="body2">{item.name}</Typography> 
                                <Typography variant="body2" fontWeight="bold">${item.amount}</Typography> 
                            </StyledBox>
                        ))}
                    </Box>
                    <Divider />
                    <StyledBox>
                        <Typography flex={1} variant="body2" fontWeight="bold">Total Amount</Typography>
                        <Typography variant="h6" color="primary" align="right" fontWeight="bold">${appointment.totalPrice}</Typography>
                    </StyledBox>
                    <Divider />

                    {/* Appointment Date */}
                    <StyledBox>
                        <Typography variant="body2" fontWeight="bold">Appointment Date</Typography>
                        <Typography>{appointment?.appointmentDate?.toString().substring(0, 10)}</Typography>
                    </StyledBox>
                    <Divider />

                    {/* Appointment Time */}
                    <StyledBox>
                        <Typography variant="body2" fontWeight="bold">Appointment Time</Typography>
                        <Typography>{appointment?.appointmentTime}</Typography>
                    </StyledBox>
                    <Divider />

                    <StyledBox>
                        <Typography variant="body2" fontWeight="bold">Address</Typography>
                        <Typography align="right">{appointment?.addressDetails?.completeAddress}</Typography>
                    </StyledBox>
                    <Divider />

                    {/* Address */}
                    {/* <StyledBox>
                        <Typography flex={1} variant="body2" fontWeight="bold">Address</Typography>
                        <Typography align="right" >House No. 123, Street Xyz, Phase 2, Sydney, Australia</Typography>
                    </StyledBox>
                    <Divider /> */}

                    {/* Total Amount*/}
                   
                     <StyledBox>
                        <Typography flex={1} variant="body2" fontWeight="bold">Status</Typography>
                        {appointment.status === "upcoming"  
                                    && <TextButton 
                                            variant="contained" 
                                            size="small"
                                            color="success"
                                            >
                                                Upcoming
                                            </TextButton>
                                }
                                {appointment.status === "delivered"  
                                    && <TextButton 
                                            variant="contained" 
                                            size="small"
                                            color="primary"
                                            >
                                                Delivered
                                            </TextButton>
                                }
                                {appointment.status === "completed"  
                                    && <TextButton 
                                            variant="contained" 
                                            size="small"
                                            color="success"
                                            >
                                                Completed
                                            </TextButton>
                                }
                                {appointment.status === "cancelled"  
                                    && <TextButton 
                                            variant="contained" 
                                            size="small"
                                            color="error"
                                            >
                                                Cancelled
                                            </TextButton>
                                }
                                 {appointment.status === "rescheduled"  
                                    && <TextButton 
                                            variant="contained" 
                                            size="small"
                                            color="warning"
                                            >
                                                Rescheduled
                                            </TextButton>
                                }
                                 {appointment.status === "disputed"  
                                    && <TextButton 
                                            variant="contained" 
                                            size="small"
                                            color="error"
                                            >
                                                Dispute
                                            </TextButton>
                                }
                    </StyledBox>
                    {appointment?.status !== "cancelled" && appointment?.status !== "completed" && 
                        <>
                        {(userInfo?.role === ROLES.CLIENT) 
                            &&
                            <Box sx={{margin: "20px"}}>
                                    <Button 
                                        sx={{
                                            fontWeight: "bold"
                                        }}
                                        variant="outlined" 
                                        color="primary" 
                                        fullWidth
                                        onClick={() => messageHandler()}
                                        >
                                        Message Barber
                                    </Button>
                            </Box> 
                            }
                        {(userInfo?.role === ROLES.BARBER) 
                            &&
                            <Box sx={{margin: "20px"}}>
                                    <Button 
                                        sx={{
                                            fontWeight: "bold"
                                        }}
                                        variant="outlined" 
                                        color="primary" 
                                        fullWidth
                                        onClick={() => messageHandler()}
                                        >
                                        Message Client
                                    </Button>
                            </Box> 
                        }
                        {appointmentDateHasPassed(appointment?.appointmentDate) &&
                            <>
                            
                            {
                                (userInfo?.role === ROLES.CLIENT) &&
                                appointment?.isCompletedBarber && 
                                !appointment?.isCompletedClient && 
                                <Box sx={{margin: "20px"}}>
                                    <Button 
                                        sx={{
                                            fontWeight: "bold"
                                        }} 
                                        variant="outlined" 
                                        color="success"
                                        fullWidth 
                                        onClick={() => handleCompleteClient(appointment?._id)}
                                        >
                                        Mark as Completed
                                    </Button>
                                </Box>
                            }
                             {(userInfo?.role === ROLES.BARBER) &&
                            <Box sx={{margin: "20px"}}>
                                <Button 
                                    sx={{
                                        fontWeight: "bold"
                                    }} 
                                    variant="outlined" 
                                    color="success"
                                    fullWidth 
                                    onClick={() => handleCompleteBarber(appointment?._id)}
                                    disabled={appointment?.isCompletedBarber}
                                    >
                                    {appointment?.isCompletedBarber ? 'Marked as complete' : 'Mark as Completed'}
                                </Button>
                            </Box>
                            }
                            <Box sx={{margin: "20px"}}>
                                <Link to='/support/new-ticket' style={{ textDecoration: 'none' }}>
                                    <Button 
                                        sx={{
                                            fontWeight: "bold"
                                        }}
                                        variant="outlined" 
                                        color="warning" 
                                        fullWidth
                                        >
                                        Dispute
                                    </Button>
                                </Link>
                            </Box> 
                        </>
                        }
                        {!appointmentDateHasPassed(appointment?.appointmentDate) 
                            && (userInfo?.role === ROLES.CLIENT) &&
                            <Box sx={{margin: "20px"}}>
                                <Button 
                                    sx={{
                                        fontWeight: "bold"
                                    }} 
                                    variant="outlined" 
                                    color="warning" 
                                    fullWidth
                                    onClick={() => handleReschedule(appointment?._id)}
                                    >
                                    Reschedule Appointment
                                </Button>
                            </Box> 
                        }
                        {!appointmentDateHasPassed(appointment?.appointmentDate) && (userInfo?.role !== ROLES.ADMIN)
                            &&
                            <Box sx={{margin: "20px"}}>
                                <Button 
                                    sx={{
                                        fontWeight: "bold"
                                    }} 
                                    variant="outlined" 
                                    color="error" 
                                    fullWidth
                                    onClick={() => handleCancel(appointment?._id)}
                                    >
                                    Cancel Appointment
                                </Button>
                            </Box> 
                        } 
                    </>      
                    }  
                    { (userInfo?.role === ROLES.CLIENT) &&
                            appointmentDateHasPassed(appointment?.appointmentDate) && 
                            appointment?.status === "completed" &&
                            !appointment.isReviewed
                            &&
                            <Box sx={{margin: "20px"}}>
                                <Button
                                    sx={{
                                        fontWeight: "bold"
                                    }}   
                                    variant="outlined" 
                                    color="primary" 
                                    fullWidth
                                    onClick={() => setOpenFeedbackModel(true)}
                                    >
                                    Give Feedback
                                </Button>
                            </Box> 
                        }    
                         {(userInfo?.role === ROLES.ADMIN) && (appointment?.status === "upcoming")
                            &&
                            <Box sx={{margin: "20px"}}>
                                <Button 
                                    sx={{
                                        fontWeight: "bold"
                                    }} 
                                    variant="outlined" 
                                    color="error" 
                                    fullWidth
                                    onClick={() => handleCancelbyAdmin(appointment?._id)}
                                    >
                                    Cancel Appointment 
                                </Button>
                            </Box> 
                        } 
                </StyledCard>          
                </Grid>
    }
    {/* Modals */}
    <FeedbackModal
        title="Give Feedback"
        open={openFeedbackModel}
        onClose={ () => setOpenFeedbackModel(false)}
    />
    </AnimatedPage>   
) }


export default AppointmentDetails