import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react'

const BackDropLoader = () => {
  return (
    <Backdrop open={true} sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}>
    <CircularProgress sx={{ color: 'white' }} />
  </Backdrop>
  )
}

export default BackDropLoader