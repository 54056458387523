import { Button, styled, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import andriodAppBadge from "../../../../images/android_app_badge.png";
import iosAppBadge from "../../../../images/ios_app_badge.png";
import PhoneDummy from "../../../../images/phone-dummy.jpg";
import mobileApp from "../../../../images/app.png";
// import CustomButton from "./CustomButton";

const GetStarted = () => {
  const CustomContainer = styled(Container)(({ theme }) => ({
    // backgroundColor: "#17275F",
    backgroundColor: theme.palette.custom.black,
    height: "420px",
    borderRadius: "15px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing(3, 3, 0, 3),
      width: "90%",
    },
  }));

  const CustomBox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(14, 0, 14, 0),
    margin: theme.spacing(0, 2, 0, 2),
    [theme.breakpoints.down("md")]: {
      padding: "0",
    },
  }));

  const CustomBoxContainer = styled(Box)(({ theme }) => ({
    maxWidth: "400px",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      marginBottom: 40,
    },
  }));

  return (
    <Box className="section">
    <CustomBox>
      <CustomContainer>
        <CustomBoxContainer>
          <Typography
            sx={{ fontSize: "35px", color: "white", fontWeight: "500", mb: 2 }}
          >
            Download our App
          </Typography>

          <Typography
              sx={{ fontSize: "16px", color: "#ccc", fontWeight: "500", mt:1 , mb: 4, letterSpacing: 0.5 }}
          >
            Our app is now available for both clients and barbers to download for iOS and Android.
          </Typography>

          {/* <CustomButton
            backgroundColor="#fff"
            color="#17275F"
            buttonText="Get Started Now"
            getStartedBtn={true}
          /> */}
          {/* <Button>
            <img
                src={andriodAppBadge}
                alt="Playstore link"
                // height="60%"
                // width="60%"
                // style={{background: "#FFF", padding: 0, margin: 0}}
            />
          </Button> */}

          {/* <Box> */}
          {/* Android App Link */}

        <a 
            href="https://play.google.com/store/apps/details?id=com.barbarly.app" 
            target="_blank"
            style={{ display: "flex", justifyContent:"center", marginBottom: "8px" }}
        >
            <img
                src={andriodAppBadge}
                alt="Playstore link"
                height="80"
                width="220"
            />
        </a>

          {/* IOS App Link */}
        <a 
            href="https://apps.apple.com/app/barbarly/id6446048289" 
            target="_blank"
            style={{ display: "flex", justifyContent:"center" }}
        >
            <img
                src={iosAppBadge}
                alt="App Store link"
                height="80"
                width="220"
            />
        </a>
          {/* </Box> */}
          
        </CustomBoxContainer>
        {/* <Box sx={{ position: 'relative' }}> */}

        <img
          // src={PhoneDummy}
          src={mobileApp}
          loading="lazy"
          alt="Barbarly"
          style={{ height: "400px"}}
        />
        {/* </Box> */}
      </CustomContainer>
    </CustomBox>
    </Box>
  );
};

export default GetStarted;