import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Avatar, Grid, Button, TextField, Box, Typography } from '@mui/material'
// import GoogleIcon from '@mui/icons-material/Google'

// import { gapi } from 'gapi-script'

import { googleAuth, login } from '../../redux/actions/userActions'

import AnimatedPage from '../../components/Layout/AnimatedPage'
import Loader from '../../components/ui/Loader'
import Alert from '../../components/ui/Alert'
import FormContainer from '../../components/ui/Form/FormContainer'
import Password from '../../components/ui/Form/Password'

import { USER_LOGIN_RESET, USER_REGISTER_RESET, USER_SAVE_PASSWORD_RESET } from '../../redux/constants/userConstants'
import GoogleAuthButton from '../../components/ui/Form/GoogleAuthButton';
import { UPDATE_ALERT } from '../../redux/constants/alertConstants';

const Login = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [message, setMessage] = useState(null)
  const [openAlert, setOpenAlert] = useState(false) 
 
  const userLogin = useSelector(state => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const userSavePassword = useSelector(state => state.userSavePassword)
  const { success } = userSavePassword

  // const from = location.state?.from?.pathname || roleBasedRedirect(userInfo?.role)

  useEffect(() => {
    if (error) {
      dispatch({ type: USER_LOGIN_RESET })
    }
  },[]) 

  useEffect(() => {
    if (userInfo)
        navigate('/dashboard')
},[userInfo])  

useEffect(() => {
  if (success) {
    dispatch({ 
      type: UPDATE_ALERT, 
      payload: {
          open: true, 
          severity: 'success', 
          message: 'Password reset successfully.' 
      } 
  })
  
    dispatch({ type: USER_SAVE_PASSWORD_RESET })
}
  if(error) {
    setMessage(error)
    setOpenAlert(true)
  }
},[error]) 



  const submitHandler = (e) => {
      e.preventDefault();
    //   if (!email || !password) {
    //     setMessage('Please fill in all fileds.')
    //     setOpenAlert(true)
    // } else {
        setMessage(null)
        setOpenAlert(false)
        // dispatch({type: USER_REGISTER_RESET})
        dispatch(login(email, password))
    // }    
  }

  return (
    <AnimatedPage>
        <FormContainer>
            <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            </Avatar>
            <Typography variant="h6">
              Sign In
            </Typography>
            <Box component="form" onSubmit={submitHandler} sx={{ mt: 3 }}>
              {loading && <Loader />}
              {message  &&
              <Alert 
                  type="error" 
                  message={message}
                  openAlert={openAlert}
                  setOpenAlert={setOpenAlert}
                />
              }
              <Grid container spacing={2}>
                <Grid item xs={12} mb={1}>
                  <TextField
                    name="email"
                    label="Email"
                    autoFocus
                    variant="outlined"
                    size="small"
                    type="email"
                    required
                    fullWidth
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} mb={1}>
                <Password password={password} setPassword={setPassword} />
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Link to="/reset-password" variant="body2">
                    Forgot password?
                  </Link>
                <Button
                  type="submit"
                  variant="contained"
                  style={{margin: '16px 0 8px 0'}}
                >
                  Sign In
                </Button>
              </Box>
              
              <Grid container justifyContent="center" mt={5}>
                <Grid item>
                  <Link to="/sign-up" variant="body2">
                    Create Account
                  </Link>
                </Grid>
              </Grid>
            </Box>
            <br/>
            <Typography mb={3}>OR</Typography>
            <Box display="flex" gap={2}>
              <GoogleAuthButton />
              {/* <FacebookAuthButton /> */}
            </Box>
        </FormContainer>
    </AnimatedPage>
  );
}

export default Login