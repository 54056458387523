import * as React from 'react';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import StarIcon from '@mui/icons-material/Star';
import { Typography } from '@mui/material';

// const labels = {
//   1: 'Bad',
//   2: 'Ok',
//   3: 'Ok+',
//   3.5: 'Good',
//   4: 'Good+',
//   4.5: 'Excellent',
//   5: 'Excellent+',
// };

// function getLabelText(value) {
//   return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
// }

const HoverRating = ({title, value, setValue}) =>  {
  return (
    <Box
      sx={{
        width: 200,
        alignItems: 'center',
      }}
    >
       <Typography component="legend">{title}</Typography>
      <Rating
        name="simple-controlled"
        value={value}
        precision={1}
        onChange={(event, newValue) => {
          if (newValue > 1) {
            setValue(newValue);
          } else{
            setValue(1)
          }
        }}
      />
    </Box>
  );
}

export default HoverRating