import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Button from '../../components/ui/Button/Button'

import './Navbar.css'


const Navbar = () => {

    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true)

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false)

    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false)
        } else {
            setButton(true)
        }
    };

    useEffect(() => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
    <>
        <nav className='navbar'>
            <div className='navbar-container'>
                <Link 
                    to='/' 
                    className='navbar-logo'
                    onClick={closeMobileMenu}
                >
                    <i class='fas fa-cut'></i> &nbsp;Barbarly
                </Link>
                <div 
                    className='menu-icon' 
                    onClick={handleClick}
                >
                    <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                </div>
                <ul 
                    className={click ? 'nav-menu active': 'nav-menu'}
                >
                    {/* <li className='nav-item'>
                        <Link 
                            className='nav-links' 
                            onClick={closeMobileMenu}>
                            Book a Barber
                        </Link>
                    </li>                     */}
                    <li className='nav-item'>
                        <Link 
                            to='/sign-in' 
                            className='nav-links' 
                            onClick={closeMobileMenu}>
                            Sign In
                        </Link>
                    </li>                    
                    {/* <li className='nav-item'>
                        <Link 
                            to='/' 
                            className='nav-links' 
                            onClick={closeMobileMenu}>
                            Sign Up
                        </Link>
                    </li>                     */}
                    <li className='nav-item'>
                        <Link 
                            to='/barber-sign-in' 
                            className='nav-links-mobile' 
                            onClick={closeMobileMenu}>
                            For Barbers
                        </Link>
                    </li>                    
                </ul>
                {button && 
                    <Button
                        buttonStyle='btn--outline'
                        buttonSize='btn--medium'
                        path="barber-sign-in"
                    >
                        FOR BARBERS
                    </Button>
                }
            </div>
        </nav>
    </>
    )
}

export default Navbar