import { Box, Fab } from '@mui/material';
import {  Visibility } from '@mui/icons-material';

const FeedbackActions = ({ params,setOpenModal, setFeedbackDetails }) => {

  const viewMessageHandler = (e) => {
    e.preventDefault()
    setOpenModal(true)
    setFeedbackDetails(params.row)
  };

  return (
    <Box display="flex">
      <Box
        sx={{
          m: 1,
          position: 'relative',
        }}
      >
          <Fab
            color="primary"
            sx={{
              width: 40,
              height: 40,
            }}
            onClick={viewMessageHandler}
          >
            <Visibility />
          </Fab>
      </Box>
    </Box>
  );
};

export default FeedbackActions;