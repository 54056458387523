export const WALLET_PAYPAL_DETAILS_REQUEST = 'WALLET_PAYPAL_DETAILS_REQUEST'
export const WALLET_PAYPAL_DETAILS_SUCCESS = 'WALLET_PAYPAL_DETAILS_SUCCESS'
export const WALLET_PAYPAL_DETAILS_FAIL = 'WALLET_PAYPAL_DETAILS_FAIL'

export const PAYPAL_EMAIL_UPDATE_REQUEST = 'PAYPAL_EMAIL_UPDATE_REQUEST'
export const PAYPAL_EMAIL_UPDATE_SUCCESS = 'PAYPAL_EMAIL_UPDATE_SUCCESS'
export const PAYPAL_EMAIL_UPDATE_FAIL = 'PAYPAL_EMAIL_UPDATE_FAIL'
export const PAYPAL_EMAIL_UPDATE_RESET = 'PAYPAL_EMAIL_UPDATE_RESET'

export const PAYPAL_EMAIL_UPDATE_VERIFY_REQUEST = 'PAYPAL_EMAIL_UPDATE_VERIFY_REQUEST'
export const PAYPAL_EMAIL_UPDATE_VERIFY_SUCCESS = 'PAYPAL_EMAIL_UPDATE_VERIFY_SUCCESS'
export const PAYPAL_EMAIL_UPDATE_VERIFY_FAIL = 'PAYPAL_EMAIL_UPDATE_VERIFY_FAIL'
export const PAYPAL_EMAIL_UPDATE_VERIFY_RESET = 'PAYPAL_EMAIL_UPDATE_VERIFY_RESET'


export const PAYPAL_WITHDRAW_REQUEST = 'PAYPAL_WITHDRAW_REQUEST'
export const PAYPAL_WITHDRAW_SUCCESS = 'PAYPAL_WITHDRAW_SUCCESS'
export const PAYPAL_WITHDRAW_FAIL = 'PAYPAL_WITHDRAW_FAIL'
export const PAYPAL_WITHDRAW_RESET = 'PAYPAL_WITHDRAW_RESET'

export const PAYPAL_WITHDRAW_VERIFY_REQUEST = 'PAYPAL_WITHDRAW_VERIFY_REQUEST'
export const PAYPAL_WITHDRAW_VERIFY_SUCCESS = 'PAYPAL_WITHDRAW_VERIFY_SUCCESS'
export const PAYPAL_WITHDRAW_VERIFY_FAIL = 'PAYPAL_WITHDRAW_VERIFY_FAIL'
export const PAYPAL_WITHDRAW_VERIFY_RESET = 'PAYPAL_WITHDRAW_VERIFY_RESET'