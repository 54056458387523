import { Box, Button, Grid, Stack } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import IntlPhoneInput from './IntlPhoneInput/IntlPhoneInput';
import OTPInput, { ResendOTP } from "otp-input-react";
// import OtpInput from 'react-otp-input';
import Stepper from '../../components/ui/Stepper';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_ALERT } from '../../redux/constants/alertConstants';
import { getUserDetails, updateUserPhone, verifyUserPhone, verifyUserPhoneUpdate } from '../../redux/actions/userActions';
import { USER_UPDATE_PHONE_RESET, USER_VERIFY_UPDATE_PHONE_RESET } from '../../redux/constants/userConstants';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

const EditPhone = ({from="/dashboard"}) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const [phoneNumber, setPhoneNumber] = useState('') 
  // const [nextStepHandler, setNextStepHandler] = useState(null) 
  const [otp, setOtp] = useState('') 
  // const [confirmPassword, setConfirmPassword] = useState('') 
  const [code, setCode] = useState('')
  // const [allowNextStep, setAllowNextS] = useState('')
 
  const userUpdatePhone = useSelector(state => state.userUpdatePhone)
  const { loading: loadingPhone, error: errorPhone, success: successPhone } = userUpdatePhone

  const userVerifyPhone = useSelector(state => state.userVerifyPhone)
  const { loading: loadingVerify, error: errorVerify, success: successVerify } = userVerifyPhone

  // const userDetails = useSelector(state => state.userDetails)
  // const { loading: loadingUser, error: errorUser, user } = userDetails

  useEffect(() => {
    dispatch(getUserDetails())
  }, [])

useEffect(() => {
  //update sucess
    if (successPhone) {
      dispatch({ 
        type: UPDATE_ALERT, 
        payload: {
          open: true, 
          severity: 'success', 
          message: 'Verification code has been sent to your email and phone number.' 
        } 
      })
      dispatch({ type: USER_UPDATE_PHONE_RESET })
      console.log("success phone ")
      // nextStepHandler()
  }

  // update error
    if(errorPhone) {
      dispatch({ 
        type: UPDATE_ALERT, 
        payload: {
            open: true, 
            severity: 'error', 
            message: errorPhone
        }
      })
    }

  //verify sucess
    if (successVerify) {
      dispatch({ 
        type: UPDATE_ALERT, 
        payload: {
          open: true, 
          severity: 'success', 
          message: 'Phone updated successfully.' 
        } 
      })
      dispatch(getUserDetails())
      navigate("/profile")
      // dispatch({ type: USER_VERIFY_UPDATE_PHONE_RESET })
      // navigate(from)
  }

  // verify error
    if(errorVerify) {
      dispatch({ 
        type: UPDATE_ALERT, 
        payload: {
            open: true, 
            severity: 'error', 
            message: errorVerify
        }
      })
    }

},[successPhone, errorPhone, successVerify, errorVerify]) 

// const handleOtpChange = (value) => {
//   console.log({value})
//   setOtp(value)
// }

  const phoneSubmitHandler = (e, handleNext) => {
      e.preventDefault()
      console.log("Phone submit called")
      // const phoneValidation = () => {
      //   const regex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
      //   return !(!phoneNumber || regex.test(phoneNumber) === false);
      // }

      if (!phoneNumber || phoneNumber.length < 11) {
        dispatch({ 
          type: UPDATE_ALERT, 
          payload: {
            open: true, 
            severity: 'error', 
            message: "Please enter a valid phone number."
          }
        })
      }
      // else if (!phoneValidation) {
       

      // }
       else {
         dispatch(updateUserPhone({phoneNumber}))
        //  setNextStepHandler(handleNext)
        handleNext()
        // console.log({phoneNumber})
      }       
  }

  const codeSubmitHandler = (e) => {
      e.preventDefault();
   
      if (!code) {
          dispatch({ 
            type: UPDATE_ALERT, 
            payload: {
                open: true, 
                severity: 'error', 
                message: "Please enter verification code."
            }
          })
      }
      // else if (newPassword !== confirmPassword) {
      //     dispatch({ 
      //       type: UPDATE_ALERT, 
      //       payload: {
      //           open: true, 
      //           severity: 'error', 
      //           message: "Password doesn't match."
      //       }
      //     })
      else {
          dispatch(verifyUserPhoneUpdate({phoneNumber, otp: code}))
      }       
  }

  const PhoneInput = ({ steps, activeStep, handleNext }) => {
    return(
      <Box   
        component="form" 
        onSubmit={(e) => phoneSubmitHandler(e, handleNext)} 
        // sx={{ background: '#000' }}
      >
        <IntlPhoneInput
          phoneNumber={phoneNumber}  
          setPhoneNumber={setPhoneNumber}
        />
        <Stack
        direction="row"
        sx={{ mt: 8, pb: 7, justifyContent: 'space-between' }}
        >
          <Button
            disabled={!activeStep}
            variant="outlined"
            color="primary"
            // onClick={handleBack}
            >
            Back
          </Button>
          <Button 
            disabled={activeStep === steps.length - 1} 
            // onClick={handleNext}
            type="submit"
            variant="contained"
          >
            Next
          </Button>
        </Stack>
      </Box>
    )
  }

  const CodeInput = ({ activeStep, handleBack }) => {
    console.log("render code input")
    return(
      <Box 
        component="form" 
        onSubmit={codeSubmitHandler} 
      >
      <OTPInput 
        value={code} 
        onChange={setCode} 
        OTPLength={4} 
        otpType="number" 
        disabled={false} 
        // secure 
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px"
        }}
      />
      {/* <OtpInput
        value={otp}
        onChange={handleOtpChange}
        numInputs={6}
        separator={<span>-</span>}
      /> */}
      <Stack
      direction="row"
      sx={{ mt: 8, pb: 7, justifyContent: 'space-between' }}
      >
        <Button
          disabled={activeStep === 0} 
          onClick={handleBack}
          variant="outlined"
          color="primary"
        >
          Back
        </Button>
        <Button 
          // onClick={handleNext}
          variant="contained"
          type="submit"
        >
          Verify
        </Button>
      </Stack>
    </Box>
    )
  }

  return (
    <Box 
      // component="form" 
      // onSubmit={submitHandler} 
      sx={{ 
        // background: "#000",
        maxWidth: "380px", margin: '0 auto', 
      }}
      // display="flex"
      // justifyContent="center"
    >
      {/* {(loading)  && <Loader />} */}
      {/* <Grid container spacing={2}> */}
        <Stepper
          StepOneLabel="Enter Phone"
          StepOneContent={PhoneInput}
          StepTwoLabel="Verify OTP"
          StepTwoContent={CodeInput}
          // StepThreeLabel="OTP"
          // StepThreeContent={TestInput}
        />
      {/* </Grid> */}
    </Box>
  )
}

export default EditPhone