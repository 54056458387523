import moment from 'moment'

const convertDateTimeToMilliseconds = (date, timeInString) => {
    var year = date.getFullYear();
    var month = date.getMonth() + 1; // Jan is 0, dec is 11
    var day = date.getDate();
    var dateString = '' + year + '-' + month + '-' + day;
    var dateTime = moment(`${dateString} ${timeInString}`, 'YYYY-MM-DD HH:mm').format(); 
    var time = new Date(dateTime).getTime()
    return time
}

export default convertDateTimeToMilliseconds