import axios from 'axios'
import {
    CONVERSATION_ADD_FAIL,
    CONVERSATION_ADD_REQUEST,
    CONVERSATION_ADD_SUCCESS,
    CONVERSATION_DETAILS_FAIL,
    CONVERSATION_DETAILS_REQUEST,
    CONVERSATION_DETAILS_SUCCESS,
    
} from '../constants/chatConstants'

//ADD CONVERSATION

export const addNewConversation = ({ receiverId }) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CONVERSATION_ADD_REQUEST
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.post(
            '/api/conversations',
            { receiverId },
            config
        )

        dispatch({
            type: CONVERSATION_ADD_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: CONVERSATION_ADD_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const getConversationDetails = ({ userId }) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CONVERSATION_DETAILS_REQUEST
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.get(`/api/conversation/${userId}`, config)

        dispatch({
            type: CONVERSATION_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: CONVERSATION_DETAILS_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}
