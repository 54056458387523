import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ROLES from "../constants/roleConstants"

const roleBasedRedirect = ({role}) => {
    console.log("Get Role based nav")
    // console.log({role, storeExists, workingScheduleExists})

    // const isPhoneVerified = (phone) => {
    //   const isVerified = (phone?.phoneNumber !== "" && phone?.isVerified === true)
    //   console.log({isVerified})
    //   return isVerified 
    //  }

    // if (!isPhoneVerified(userPhone)) return "/phone-verification"
    if (role === ROLES.ADMIN) return "/admin-dashboard"
    // else if (role === ROLES.BARBER) {
    //   if (!storeExists) return "/barber-sign-up?tab=2"  
    //   else if (!workingScheduleExists) return "/barber-sign-up?tab=3"  
    //   else return "/barber-dashboard"
    // }
    else if (role === ROLES.BARBER) return "/barber-dashboard"
    else return "/dashboard"
  }

export default roleBasedRedirect