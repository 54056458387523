import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BasicModal from './BasicModal'
import Alert from '../Alert'
import Loader from '../Loader'

import { Box, IconButton, TextField, 
    Grid, Button, MenuItem, InputAdornment, FormControl,
    OutlinedInput, InputLabel, FormHelperText, Stack, Typography  } from '@mui/material'

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { addNewService, listCategories } from '../../../redux/actions/storeActions'
import { UPDATE_ALERT } from '../../../redux/constants/alertConstants';

const NewServiceModal = ({ title, open, onClose }) => {
    const dispatch = useDispatch() 

    const [name, setName] = useState('');
    const [selectedCategoryId, setSelectedCategoryId] = useState(null)
    const [amount, setAmount] = useState(null)
    const [duration, setDuration] = useState(null)
    const [description, setDescription] = useState('')

    const [openAlert, setOpenAlert] = useState(false)

    const serviceList = useSelector(state => state.serviceList)
    const {  loading: loadingServices, services } = serviceList

    // const serviceCategoryList = useSelector(state => state.serviceCategoryList)
    // const { loading: loadingCategories, error: errorCategories, categories } = serviceCategoryList

    const serviceAdd = useSelector(state => state.serviceAdd)
    const { loading, error, success } = serviceAdd
    
    // useEffect(() => {
    //     if(services?.length === 0) {
    //         dispatch({ 
    //             type: UPDATE_ALERT, 
    //             payload: {
    //             open: true, 
    //             severity: 'info', 
    //             message: 'Please add at least one service to active your store.' 
    //             } 
    //         })
    // }
    //   },[services])

      
      useEffect(() => {
          if(success) {
              setName('')
              setSelectedCategoryId(null)
              setAmount(null)
              setDuration(null)
              onClose()
            }
            if(error)
            setOpenAlert(true)
        },[error, success])
        
    const addServiceHandler = ({ name, selectedCategoryId, amount, duration, description }) => {
        console.log({ name, selectedCategoryId, amount, duration, description })
        dispatch(addNewService({ name, selectedCategoryId, amount, duration, description }))
    }

    const submitHandler = (e) => {
        e.preventDefault();
        addServiceHandler({name, selectedCategoryId, amount, duration, description});
    }

    const getContent = () => (
    <Box component="form" onSubmit={submitHandler} sx={{ mt: 3 }}>
         {loading && <Loader />}
        {error && 
            <Alert 
                type="error"
                message={error} 
                openAlert={openAlert} 
                setOpenAlert={setOpenAlert} 
            />
        }
         
         <Grid container spacing={2}>
           <Grid item xs={12}>
            <TextField
                name="Service name"
                label="Service name"
                value={name}
                autoFocus
                variant="outlined"
                size="small"
                type="text"
                required
                fullWidth
                   onChange={(e) => setName(e.target.value)}
                />
           </Grid>
           <Grid item xs={12}>
                <TextField
                    id="outlined-select-category"
                    select
                    label="Select Category"
                    value={selectedCategoryId}
                    // onChange={(e) => setSelectedCategoryId(e.target.value)}
                    onChange={(e) => setSelectedCategoryId(e.target.value)}
                    required
                    fullWidth
                    >
                    {/* {services?.length 
                        ?
                        <> */}
                            {services?.map((service) => (
                                <MenuItem 
                                    key={service._id}
                                    value={service._id}
                                >
                                    {service.category}
                                </MenuItem>
                            ))}
                        {/* </>
                        : 
                        <MenuItem value="">
                           <Typography>No categories added to store</Typography> 
                       </MenuItem>
                    } */}
                </TextField>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-amount"
                        label="Amount"
                        value={amount}
                        inputProps={{ min: 1, max: 500 }}
                        size="small"
                        type="number"
                        required
                        fullWidth
                        onChange={(e) => setAmount(e.target.value)}
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel htmlFor="outlined-adornment-duration">Duration</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-duration"
                        label="Duration"
                        value={duration}
                        size="small"
                        type="number"
                        required
                        fullWidth
                        onChange={(e) => setDuration(e.target.value)}
                        aria-describedby="outlined-duration-helper-text"
                        startAdornment={
                        <InputAdornment position="start">
                            <IconButton edge="start">                         
                                <AccessTimeIcon/>
                            </IconButton>
                        </InputAdornment>
                        }
                        inputProps={{
                          'aria-label': 'duration',
                            min: 1, max: 180 
                        }}
                      />
                      <FormHelperText id="outlined-duration-helper-text">Estimated duration in minutes</FormHelperText>
                </FormControl>
            </Grid> 
            <Grid item xs={12}>
                <TextField
                    name="Short description"
                    label="Short description"
                    value={description}
                    variant="outlined"
                    size="small"
                    type="text"
                    required
                    fullWidth
                    onChange={(e) => setDescription(e.target.value)}
                    />
           </Grid>
            <Grid item xs={12}>
                <Stack 
                    direction="row" 
                    justifyContent="end"
                    spacing={2}
                >
                    <Button 
                        variant="outlined"  
                        onClick={onClose}
                        >
                        Cancel
                    </Button>
                    <Button 
                        variant="contained"
                        type="submit"
                    >
                        Save
                    </Button>
                </Stack>  
            </Grid >
        </Grid>       
    </Box>
    )


  return (
    <BasicModal
        open={open}
        onClose={onClose}
        title={title}
        content={getContent()}
    >
    </BasicModal>
  )
}

export default NewServiceModal