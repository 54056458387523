import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Grid, Button, TextField, Box, MenuItem } from '@mui/material'

import { getUserDetails, updateUserPassword, updateUserProfile } from '../../redux/actions/userActions'
import Loader from '../../components/ui/Loader'
import { USER_UPDATE_PASSWORD_RESET, USER_UPDATE_PROFILE_RESET } from '../../redux/constants/userConstants'
import { UPDATE_ALERT } from '../../redux/constants/alertConstants';
import Password from '../../components/ui/Form/Password';


const EditProfile = () => {
  const dispatch = useDispatch()

  const [currentPassword, setCurrentPassword] = useState('') 
  const [newPassword, setNewPassword] = useState('') 
  const [confirmPassword, setConfirmPassword] = useState('') 
 
  const userUpdatePassword = useSelector(state => state.userUpdatePassword)
  const { loading, error, success } = userUpdatePassword

  // const userDetails = useSelector(state => state.userDetails)
  // const { loading: loadingUser, error: errorUser, user } = userDetails

  // useEffect(() => {
  //   dispatch(getUserDetails())
  // }, [])

useEffect(() => {
  //update sucess
    if (success) {
      dispatch({ 
        type: UPDATE_ALERT, 
        payload: {
          open: true, 
          severity: 'success', 
          message: 'Password updated successfully.' 
        } 
      })
      setCurrentPassword('')
      setNewPassword('')
      setConfirmPassword('')
      dispatch({ type: USER_UPDATE_PASSWORD_RESET })
  }

  // update error
    if(error) {
      dispatch({ 
        type: UPDATE_ALERT, 
        payload: {
            open: true, 
            severity: 'error', 
            message: error
        }
      })
    }

},[success, error]) 

  const submitHandler = (e) => {
      e.preventDefault();
      if (newPassword.length < 6) {
          dispatch({ 
            type: UPDATE_ALERT, 
            payload: {
                open: true, 
                severity: 'error', 
                message: "Password must be at least characters long."
            }
          })
      }
      else if (newPassword !== confirmPassword) {
          dispatch({ 
            type: UPDATE_ALERT, 
            payload: {
                open: true, 
                severity: 'error', 
                message: "Password doesn't match."
            }
          })
      } else {
          dispatch(updateUserPassword({currentPassword, newPassword}))
      }       
  }

  return (
        <Box component="form" onSubmit={submitHandler} sx={{ maxWidth: "480px", margin: '0 auto', mt: 5 }}>
            {(loading)  && <Loader />}
            <Grid container spacing={2}>
              <Grid item xs={12} mb={1}>
                <Password 
                  password={currentPassword} 
                  setPassword={setCurrentPassword}
                  label="Current Password" 
                  size="medium" 
                  />
              </Grid>
              <Grid item xs={12} mb={1}>
                <Password 
                  password={newPassword} 
                  setPassword={setNewPassword}
                  label="New Password"
                  size="medium" 
                  />
              </Grid>
              <Grid item xs={12} mb={1}>
                <Password 
                  password={confirmPassword} 
                  setPassword={setConfirmPassword}
                  label="Confim Password" 
                  size="medium" 
                  />
              </Grid>
            
              </Grid>
              <Box display="flex" justifyContent="flex-end" mt={2}>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  style={{margin: '16px 0 8px 0'}}
                >
                Save Changes
              </Button>
              </Box>
        </Box>
  );
}

export default EditProfile