import React, { useEffect, useMemo, useState } from 'react'
import { Box, Divider, Tab, Tabs, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_TAB } from '../../redux/constants/localConstants';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const TopNavigation = ({
        TabOneHeading,
        TabOneContent,
        TabTwoHeading,
        TabTwoContent,
        TabThreeHeading,
        TabThreeContent,
        TabFourHeading,
        TabFourContent
    }) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    // const [value, setValue] = useState(0);

    const tabReducer = useSelector(state => state.tabReducer)
    const { tab: value } = tabReducer

    useEffect(() => {
      dispatch({ 
        type: UPDATE_TAB, 
        payload: 0
      })
    }, [])

    const handleChange = (e, newValue) => {
        // setValue(newValue);
        dispatch({ 
          type: UPDATE_TAB, 
          payload: newValue 
        })
      }

    

    return (
            <Box sx={{ width: '100%', pt: 1.6 }}>
              <Tabs
                value={value}
                onChange={handleChange}s
                // onChange={(e) => setValue(e.target.value)}
                // textColor="secondary"
                // indicatorColor="secondary"
                // aria-label="secondary tabs example"
              >
                <Tab 
                    label={TabOneHeading} 
                    sx={{
                        fontWeight: "bold",
                        textTransform: 'capitalize'
                    }}
                    {...a11yProps(0)}
                />
                <Tab 
                    label={TabTwoHeading} 
                    sx={{
                        fontWeight: "bold",
                        textTransform: 'capitalize'
                    }}
                    {...a11yProps(1)}
                />
                {TabThreeContent && 
                     <Tab 
                     label={TabThreeHeading}
                     sx={{
                         fontWeight: "bold",
                         textTransform: 'capitalize'
                     }}
                     {...a11yProps(2)}
                 />
                }
                {TabFourContent && 
                     <Tab 
                     label={TabFourHeading} 
                     sx={{
                         fontWeight: "bold",
                         textTransform: 'capitalize'
                        }}
                        {...a11yProps(2)}
                        />
                    } 
              </Tabs>
                <Divider />
              <Box>
                <TabPanel value={value} index={0}>
                  {TabOneContent}
                </TabPanel>
                <TabPanel value={value} index={1}>
                  {TabTwoContent}
                </TabPanel>
                {TabThreeContent && 
                  <TabPanel value={value} index={2}>
                      {TabThreeContent}
                  </TabPanel>
                  } 
                {TabFourContent && 
                  <TabPanel value={value} index={3}>
                      {TabFourContent}
                  </TabPanel>
                  } 
              </Box>
            </Box>
    )
}

export default TopNavigation