import React, { useEffect, useState } from 'react'
import FAQ from './FAQ';
import { Box, Container, Typography } from '@mui/material';
import axios from 'axios';


const Faqs = () => {
  const [FAQS, setFAQS] = useState([])
  // cont [loading, setLoading] = useState(false) 

  useEffect(() => {
    const getFAQS = async () => {
      const config = {
        headers: {
            'Content-type': 'application/json'
        },
    }

      const res = await axios.get('/api/faqs', config)
      setFAQS(res.data)
    }
    getFAQS()
  }, [])
  
  return (
    <Box className="section">
        <Typography
          align="center"
              sx={{
                fontSize: "42px",
                color: "#000339",
                fontWeight: "700",
                my: 3,
              }}
            >
              FAQs
            </Typography>
        <Container>
          {FAQS?.map((item, index) => (
            <FAQ key={index} faq={item} />
          ))}
        </Container>
    </Box>
  )
}

export default Faqs