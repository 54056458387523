// import * as React from 'react';
// import { DataGrid, GridToolbar } from '@mui/x-data-grid';
// import { useDemoData } from '@mui/x-data-grid-generator';

// export default function Barbers() {
//   const { data } = useDemoData({
//     dataSet: 'Commodity',
//     rowLength: 100,
//     maxColumns: 6,
//   });

//   return (
//     <div style={{ height: 400, width: '100%' }}>
//       <DataGrid
//         {...data}
//         components={{
//           Toolbar: GridToolbar,
//         }}
//       />
//     </div>
//   );
// }
import { useEffect, useMemo, useState } from 'react';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import { DataGrid, gridClasses, GridToolbar } from '@mui/x-data-grid';
import moment from 'moment';
import { grey } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import { listClients, listUsers } from '../../../redux/actions/userActions';
import BackDropLoader from '../../../components/ui/BackDropLoader';
import TextButton from '../../../components/ui/TextButton';
import UsersActions from './UsersActions'
import ROLES from '../../../constants/roleConstants';

const Clients = () => {
    const dispatch = useDispatch()
    // const clientList = useSelector(state => state.clientList)
    // const { loading, clients } = clientList

    const userList = useSelector(state => state.userList)
    const { loading, users } = userList

  const [pageSize, setPageSize] = useState(10);
  const [rowId, setRowId] = useState(null);
  
  const NoRowsOverlay = () => {
    return (
      <Stack   height="100%" alignItems="center" justifyContent="center">
        No clients available
      </Stack>
    );
  }

  useEffect(() => {
    // if (clients.length === 0) dispatch(listClients())
    dispatch(listUsers())

  }, []);

  const columns = useMemo(
    () => [
      {
        field: 'avatar',
        headerName: 'Avatar',
        width: 60,
        renderCell: (params) => <Avatar src={params.row.avatar} />,
        sortable: false,
        filterable: false,
      },
      { 
        field: 'firstName', 
        headerName: 'Name', 
        width: 170, 
        renderCell: (params) => params.row.firstName + " " + params.row.lastName,
    },
    { 
    field: 'email',
    headerName: 'Email', 
    width: 220,
    // renderCell: (params) => params.row?.email?.emailAddress || ""
    valueGetter: (params) => {
        let email;
        if (params.row.email.emailAddress) {
            if (params.row.email.emailAddress) {
            email = params.row.email.emailAddress
            }
        } else {
            email = "";
        }
        return email;
        }
    },
    {
      field: 'accountType',
      headerName: 'Type',
      width: 100,
    },
    { 
    field: 'phone',  
    headerName: 'Phone', 
    width: 150,
    sortable: false,
    valueGetter: (params) => {
      let phone;
      if (params.row.phone) {
          if (params.row.phone.phoneNumber) {
          phone = params.row.phone.phoneNumber
          }
      } else {
          phone = "";
      }
      return phone;
      }
    },
    { 
        field: 'gender', 
        headerName: 'Gender', 
        width: 80, 
        valueGetter: (params) => {
            let gender;
            if (params.row.gender) {
                if (params.row.gender === "male") gender = "M"
                else if (params.row.gender === "female") gender = "F"
            } else gender = ""
            return gender
            }
        },
        { 
          field: 'role', 
          headerName: 'Role', 
          width: 100, 
          valueGetter: (params) => {
              let role;
              if (params.row.role) {
                  if (params.row.role === ROLES.CLIENT) role = "client"
                  else if (params.row.role === ROLES.BARBER) role = "barber"
                  else role = "admin"
              } else role = ""
              return role
              }
          },
        {
          field: 'createdAt',
          headerName: 'Joining Date',
          width: 200,
          renderCell: (params) =>
          moment(params.row.createdAt).format('DD-MM-YYYY HH:MM:SS'),
        },        
        {
        field: 'status',
        headerName: 'Status',
        width: 100,
        filterable: false,
        type: 'singleSelect',
        valueOptions: ["active", "inactive", "blocked"],
        editable: true,
        renderCell: (params) => {
          let status, color="primary", disabled=false
          if (params.row.status === "active") {
            status = "Active"
            color = "success"
          }
          else if (params.row.status === "inactive") {
            status = "Inactive"
            color = "primary"
            disabled=true
          } 
          else if (params.row.status === "blocked")  {
            status = "Blocked"
            color = "error"
          }
          // else if (params.row.status === "onhold") {
          //   status = "Onhold"
          //   color="warning"
          // } 
          else if (params.row.status === "removed") {
            status = "Removed"
            color="error"
          } 
          else status = ""
          return (
            <TextButton 
            variant="outlined" 
            size="small"
            color={color}
            disabled={disabled}
            >
                {status}
            </TextButton>
            // status
          )
        }
        },
      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        renderCell: (params) => (
          <UsersActions {...{ params, rowId, setRowId }} />
        ),
      },
    ],
    [rowId]
  );

  return (
    <>
    {
        loading 
        ? <BackDropLoader />
        :
        <Box
        sx={{
            height: '70vh',
            width: '100%',
        }}
        >
        <Typography
            variant="h4"
            component="h4"
            sx={{ textAlign: 'center', mt: 3, mb: 3 }}
        >
            Users List
        </Typography>
        <DataGrid
            columns={columns}
            // rows={clients}
            rows={users}
            getRowId={(row) => row._id}
            rowsPerPageOptions={[5, 10, 20]}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            components={{
              NoRowsOverlay,
              Toolbar: GridToolbar
            }}
            getRowSpacing={(params) => ({
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
            })}
            sx={{
            [`& .${gridClasses.row}`]: {
                bgcolor: (theme) =>
                theme.palette.mode === 'light' ? grey[200] : grey[900],
            },
            }}
            onCellEditCommit={(params) => setRowId(params.id)}
        />
        </Box>
    }
    </>
  );
};

export default Clients;