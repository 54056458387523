import { Box, Button, CircularProgress, Grid, MenuItem, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { registerNewBarber } from '../../redux/actions/barberActions';
import AnimatedPage from '../../components/Layout/AnimatedPage';
import FormContainer from '../../components/ui/Form/FormContainer';
import Password from '../../components/ui/Form/Password';
import PhoneInput from '../../components/ui/PhoneInput';
import { UPDATE_ALERT } from '../../redux/constants/alertConstants';
import { IntlPhoneInput } from '../profile';
import { BARBER_REGISTER_RESET } from '../../redux/constants/barberConstants';
import OTPInput, { ResendOTP } from "otp-input-react";
import { verifyUserPhone } from '../../redux/actions/userActions';
import { USER_VERIFY_UPDATE_PHONE_RESET } from '../../redux/constants/userConstants';

const PersonalDetails = ({ steps, activeStep, handleNext }) => {
  const dispatch = useDispatch()

  const [firstName, setFirstName] = useState('')
  const [lastName, setlastName] = useState('')
  const [email, setEmail] = useState('')
  const [gender, setGender] = useState('')
  const [referral, setReferral] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [address, setAddress] = useState('')
  const [infoAdded, setInfoAdded] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [otp, setOtp] = useState('')

  const barberRegister = useSelector(state => state.barberRegister)
  const { loading: registerLoading, error: registerError, success: registerSuccess } = barberRegister

  const userVerifyPhone = useSelector(state => state.userVerifyPhone)
  const { loading: phoneLoading, error: phoneError, success: phoneSuccess } = userVerifyPhone

  // useEffect(() => {
  //   dispatch({ type: BARBER_REGISTER_RESET })
  //  }, [])

  useEffect(() => {
    if (registerSuccess) {
      dispatch({ 
        type: UPDATE_ALERT, 
        payload: {
          open: true, 
          severity: 'success', 
          message: "Verification code sent to your email and phone number."
        }
      })
      // dispatch({ type: BARBER_REGISTER_RESET })
      setInfoAdded(true)
    } 

    if (phoneSuccess) {
      dispatch({ 
        type: UPDATE_ALERT, 
        payload: {
          open: true, 
          severity: 'success', 
          message: "Phone number verified successfully."
        }
      })
      // dispatch({ type: BARBER_REGISTER_RESET })
      dispatch({ type: USER_VERIFY_UPDATE_PHONE_RESET })
      handleNext()
    } 

    if (registerError) {
      dispatch({ 
        type: UPDATE_ALERT, 
        payload: {
          open: true, 
          severity: 'error', 
          message: registerError
        }
      })
    }

    if (phoneError) {
      dispatch({ 
        type: UPDATE_ALERT, 
        payload: {
          open: true, 
          severity: 'error', 
          message: phoneError
        }
      })
    }
    
  }, [registerSuccess, registerError, phoneSuccess, phoneError])

  const onSubmitHandler = (e) => {
    e.preventDefault()
    if (!(firstName && lastName && email && address && password && confirmPassword)) {
      dispatch({ 
        type: UPDATE_ALERT, 
        payload: {
          open: true, 
          severity: 'error', 
          message: "Please fill all required fields."
        }
      })
    }

    if (password.length < 6) {
      dispatch({ 
        type: UPDATE_ALERT, 
        payload: {
          open: true, 
          severity: 'error', 
          message: "Password must be of at least 6 characters."
        }
      })
    }
    else if (password !== confirmPassword) {
      dispatch({ 
        type: UPDATE_ALERT, 
        payload: {
          open: true, 
          severity: 'error', 
          message: "Password do not match."
        }
      })
    } else if (!phoneNumber || phoneNumber.length < 11) {
      dispatch({ 
        type: UPDATE_ALERT, 
        payload: {
          open: true, 
          severity: 'error', 
          message: "Please enter a valid phone number."
        }
      })
    } else {
        dispatch(registerNewBarber({firstName, lastName, email, gender, phoneNumber, password, address, referral}))
        // setInfoAdded(true)
    }      
    // handleNext()
  }

  const verifyCodeHandler = (e) => {
    e.preventDefault()

    if (!(otp)) {
      dispatch({ 
        type: UPDATE_ALERT, 
        payload: {
          open: true, 
          severity: 'error', 
          message: "Please enter an otp."
        }
      })
    }
    // console.log({otp})
    dispatch(verifyUserPhone({phoneNumber, otp}))
    // handleNext()
  }
  
  return(
    <AnimatedPage>
      {/* <FormContainer > */}
      <Box component="form" onSubmit={onSubmitHandler}>
        <Grid container spacing={2} maxWidth="800px" margin="0 auto">
            <Grid item xs={12} md={6} mb={1}>
              <TextField
                name="firstName"
                label="First Name"
                autoFocus
                variant="outlined"
                size="small"
                type="text"
                required
                fullWidth
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                disabled={infoAdded} 
              />
            </Grid>
            <Grid item xs={12} md={6} mb={1}>
              <TextField
                name="lastName"
                label="Last Name"
                variant="outlined"
                size="small"
                type="text"
                required
                fullWidth
                value={lastName}
                onChange={(e) => setlastName(e.target.value)}
                disabled={infoAdded} 
              />
            </Grid>
            <Grid item xs={12} md={6} mb={1}>
              <TextField
                name="email"
                label="Email"
                variant="outlined"
                size="small"
                type="email"
                required
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={infoAdded} 
              />
            </Grid>
            <Grid  item xs={12} md={6} mb={1}>
              <TextField
                  id="outlined-select-gender"
                  select
                  label="Gender (optional)"
                  size="small"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  fullWidth
                  disabled={infoAdded} 
                  >
                      <MenuItem value="male" >
                          Male
                      </MenuItem>
                      <MenuItem value="female" >
                          Female
                      </MenuItem>
                      
              </TextField>
            </Grid>
            <Grid item xs={12} mb={1}>
              <TextField
                name="address"
                label="Address"
                variant="outlined"
                size="small"
                type="text"
                required
                fullWidth
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                disabled={infoAdded} 
              />
            </Grid>
            <Grid item xs={12} md={6} mb={1}>
              <Password  
                password={password}
                setPassword={setPassword}
                disabled={infoAdded} 
              />
            </Grid>
            <Grid item xs={12} md={6} mb={1}>
              <Password 
                label="Confirm Password" 
                password={confirmPassword}
                setPassword={setConfirmPassword}
                disabled={infoAdded} 
              />
            </Grid>
            <Grid item xs={12} md={6} mb={1}>
              {/* <PhoneInput
                value={phoneNumber}  
                setValue={setPhoneNumber}
              /> */}
               <IntlPhoneInput
                phoneNumber={phoneNumber}  
                setPhoneNumber={setPhoneNumber}
                disabled={infoAdded}
              />
            </Grid>
            <Grid item xs={12} md={6} mb={1}>
              <TextField
                name="referral"
                label="Referral Code (Optional)"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={referral}
                onChange={(e) => setReferral(e.target.value)}
                disabled={infoAdded} 
              />
            </Grid>
          </Grid>            
          {!infoAdded &&   
            <Box display="flex">
              {registerLoading 
                ? 
                  <Box margin="0 auto" mt={3}>
                    <CircularProgress />
                  </Box>
                :
                <Button
                  sx={{
                    margin: "0 auto",
                    marginTop: "28px",
                    // padding: "10px 40px",
                    // fontWeight: "bold",
                    // textTransform: "capitalize"
                  }}
                  variant="contained"
                  type="submit"
                  // disabled={infoAdded}
                >
                  Continue
                </Button>    
              }
            </Box>
          }
        {/* </FormContainer> */}
      </Box>
      { infoAdded &&
        <Grid container spacing={1} maxWidth="680px" margin="0 auto" mt={3}>
          {/* <Grid item xs={12} md={8} mb={1} >
            <Box display="flex">
              <IntlPhoneInput
                phoneNumber={phoneNumber}  
                setPhoneNumber={setPhoneNumber}
                // disabled={infoAdded}
              />
              <Button
                variant='contained'
                size="small"
                onClick={sendCodeHandler}
              >
                Send Again
              </Button>
            </Box>
          </Grid> */}
          <Grid item xs={12} md={12} mb={1} >
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography variant="h6">Enter Verification Code: &nbsp;</Typography>
              <OTPInput 
                value={otp} 
                onChange={(value) => setOtp(value)}
                // value={otp?.current?.value} 
                // ref={otp} 
                // ref={ n => otp = n }
                autoFocus 
                // OTPLength={5} 
                otpType="number" 
                disabled={false} 
                // secure 
                // style={{
                //   display: "flex",
                //   justifyContent: "center",
                // }}
              />
            </Box>
          </Grid>
        </Grid>
      }
      
      <Stack
        direction="row"
        sx={{ mt: 8, pb: 7, justifyContent: 'space-evenly' }}
        >
          <Button
            // disabled={!activeStep}
            disabled={true}
            variant="outlined"
            color="primary"
            // onClick={handleBack}
            >
            Back
          </Button>
          <Button 
            disabled={activeStep === steps.length - 1 || !infoAdded} 
            onClick={verifyCodeHandler}
            // onClick={handleNext}
            type="submit"
            variant="contained"
          >
            Next
          </Button>
      </Stack>
    </AnimatedPage>
  )

}
export default PersonalDetails