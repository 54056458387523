import { Avatar, Badge, Box, Button, Divider, IconButton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Field from './Field'
import CopyTextBox from './CopyTextBox'
import { useDispatch, useSelector } from 'react-redux'
import ROLES from '../../constants/roleConstants'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import axios from 'axios';
import { UPDATE_ALERT } from '../../redux/constants/alertConstants';
import Loader from '../../components/ui/Loader';
import { USER_SEND_VERIFICATION_EMAIL_RESET, USER_VERIFY_UPDATE_PHONE_RESET } from '../../redux/constants/userConstants';
import { getUserDetails, sendVerificationEmail } from '../../redux/actions/userActions';

const ProfileDetails = () => {
    const dispatch = useDispatch()
    // Picture states
    const [uploading, setUploading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [image, setImage] = useState(null)
    
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo: user } = userLogin

    const userSendVerificationEmail = useSelector(state => state.userSendVerificationEmail)
    const { loading: loadingEmail, error: errorEmail, success: successEmail } = userSendVerificationEmail

    const userVerifyPhone = useSelector(state => state.userVerifyPhone)
    const { loading: loadingVerify, error: errorVerify, success: successVerify } = userVerifyPhone
  
    useEffect(() => {
      dispatch(getUserDetails())
    }, [])
  
  useEffect(() => {
    //verify sucess
      if (successVerify) {
        dispatch({ type: USER_VERIFY_UPDATE_PHONE_RESET})
    }
  
  },[successVerify]) 

    useEffect(() => {
        //update sucess
          if (success) {
            dispatch({ 
              type: UPDATE_ALERT, 
              payload: {
                open: true, 
                severity: 'success', 
                message: 'Picture updated successfully.' 
              } 
            })
        }
      
        // update error
          if(error) {
            dispatch({ 
              type: UPDATE_ALERT, 
              payload: {
                  open: true, 
                  severity: 'error', 
                  message: 'File not supported too or large (max allowed size is 5 MB).'
              }
            })
          }

          //email success
          if (successEmail) {
            dispatch({ 
              type: UPDATE_ALERT, 
              payload: {
                open: true, 
                severity: 'success', 
                message: 'Verification link has been sent to your email.' 
              } 
            })
            dispatch({ type: USER_SEND_VERIFICATION_EMAIL_RESET })
        }
      
        // email error
          if(errorEmail) {
            console.log(errorEmail)
            dispatch({ 
              type: UPDATE_ALERT, 
              payload: {
                  open: true, 
                  severity: 'error', 
                  message: 'Error sending email, please try again later.'
              }
            })
          }
      },[success, error, successEmail, errorEmail]) 

    const uploadFileHandler = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('image', file)
        setUploading(true)
        setSuccess(false)
        setError(false)  
        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${user.accessToken}`
                }
            }
            const { data } = await axios.put('/api/uploads/user-image', formData, config)
            setImage(data)
            setSuccess(true)
            setUploading(false)
        } catch (err) {
            setUploading(false)
            setError(true)
        }
    }

    const handleVerifyNow = (e) => {
      e.preventDefault()
      dispatch(sendVerificationEmail())
    }

  return (
                <Box>
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={                            
                                <IconButton color="primary" aria-label="upload picture" component="label">
                                <input hidden accept="image/*" type="file" onChange={uploadFileHandler} />
                                {uploading 
                                    ? <Loader />
                                    : <CameraAltIcon fontSize='large' />
                                }

                                </IconButton>
                        }
                    >
                        <Avatar 
                            sx={{
                            width: '140px',
                            height: '140px',
                            borderRadius: '50%',
                            margin: '0 auto',
                            objectFit: 'cover'
                        }}                       
                            alt={user?.firstName} src={image ? image : user?.avatar} 
                        />
                    </Badge>
                        <Typography variant="h6" textAlign="center"  fontWeight="bold" color="primary" mb={3} mt={2}>
                            {user?.firstName} {user?.lastName}
                        </Typography>
                        {/* <Field title="Email" value={user?.email?.emailAddress} disabled /> */}
                        {/* Email */}
                        <Divider />
                        <Box pt={1.6}>
                          <Box display="flex" justifyContent="space-between" px={3} >
                              <Typography fontWeight="bold" align="left">
                                  Email
                              </Typography>
                              <Typography 
                                  sx={{
                                      maxWidth:"240px"
                                  }}
                                  color="text.disabled"
                                  align="right"
                                  
                                  >
                                  {user?.email?.emailAddress}
                              </Typography>
                          </Box>
                          <Box display="flex" justifyContent="flex-end" alignItems="center" px={3}>
                              {user?.email?.isVerified
                                ? <Typography align="right" variant="body2"  color="success.light" >Verified</Typography> 
                                : <Typography align="right" variant="body2" color="error">
                                  Email not Verified.
                                  <Button 
                                    sx={{ textTransform: 'none', textDecoration: 'underline'}}
                                    onClick={handleVerifyNow} 
                                    color="error"
                                    disabled={loadingEmail}
                                  >
                                    Verify now
                                  </Button>
                                  </Typography>
                              }
                          </Box>
                        </Box>
                        <Divider />
                        <Field title="Phone" value={user?.phone?.phoneNumber} />
                        <Field title="Gender" value={user?.gender == "undefined" ? "-" : user?.gender } />
                        <Field title="Address" value={user?.addressDetails?.completeAddress == "" ? "-": user?.addressDetails?.completeAddress} />
                        <Field title="Joining Date" value={user?.createdAt?.toString().substring(0, 10)}/>
                        { user?.role === ROLES.BARBER && <CopyTextBox referralCode={user?.referralCode} />}
                </Box>  
  )
}

export default ProfileDetails