import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {Messenger} from '../features/chat/index';
import { CONVERSATION_ADD_RESET } from '../redux/constants/chatConstants';

const Message = () => {
  const dispatch = useDispatch()

  const conversationAdd = useSelector(state => state.conversationAdd)
  const {
          loading: loadingAddConversation, 
          error: errorAddConversation, 
          success: successAddConversation 
      } = conversationAdd

  useEffect(() => {
    if(successAddConversation || errorAddConversation) {
      dispatch({ type: CONVERSATION_ADD_RESET })
    }
}, []) 

  return (
    <div><Messenger /></div>
  )
}

export default Message