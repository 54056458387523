import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@mui/material'
import GoogleIcon from '@mui/icons-material/Google'

import { gapi } from 'gapi-script'
import { GoogleLogin } from 'react-google-login'
import { googleAuth } from '../../../redux/actions/userActions';
import roleBasedRedirect from '../../../utils/roleBasedRedirect';
import Alert from '../Alert';
import Loader from '../Loader';



const clientId='417878402253-mubvgcv45ehn4j913s1run81195k0buk.apps.googleusercontent.com'

const GoogleAuthButton = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [message, setMessage] = useState(null)
    const [openAlert, setOpenAlert] = useState(false) 

    useEffect(() => {
        const initClient = () => {
              gapi.client.init({
              clientId: clientId,
              scope: ''
            });
         };
         gapi.load('client:auth2', initClient);
    })
          
    const onSuccess = (res) => {
      console.log(res)
    dispatch(googleAuth({
        firstName: res.profileObj.givenName,
        lastName: res.profileObj?.familyName || "",
        email:  res.profileObj.email,
        avatar:  res.profileObj.imageUrl
    }))
    navigate("/phone-verification")
    }

    const onFailure = (err) => {
      navigate("/sign-in")
    }

  return (
    <>
    {message  &&
     <Alert
        type="error" 
        message={message}
        openAlert={openAlert}
        setOpenAlert={setOpenAlert}
      />
    }
    <GoogleLogin
          clientId={clientId}
          render={renderProps => (
            <Button
              onClick={renderProps.onClick} 
              disabled={renderProps.disabled}
              variant="outlined"
              fullWidth
              sx={{py: 1}}
            >
              <GoogleIcon sx={{margin: "0 10px "}} />
              Continue with Google
            </Button>
          )}
          buttonText="Login"
          onSuccess={onSuccess}
          onFailure={onFailure}
          cookiePolicy={'single_host_origin'}
          // isSignedIn={true}
          style={{width: "100"}}
      />
    </>
  )
}

export default GoogleAuthButton