import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { AppBar, Toolbar, Box, Typography, Badge, Avatar, Menu, MenuItem, Button, IconButton } from '@mui/material'
import ContentCutIcon from '@mui/icons-material/ContentCut'
import MailIcon from '@mui/icons-material/Mail'
import NotificationIcon from '@mui/icons-material/Notifications'
import MenuIcon from '@mui/icons-material/Menu'

import AddIcon from '@mui/icons-material/Add'
import { styled, useTheme } from "@mui/material/styles"
import roleBasedRedirect from '../../utils/roleBasedRedirect'
import { logout } from '../../redux/actions/userActions'
import { getNotifications, getUnreadNotificationsCount } from '../../redux/actions/notificationActions'
import ROLES from '../../constants/roleConstants'
import { format } from 'timeago.js'
import NavBarNotification from '../../components/ui/NavBarNotification';
import Loader from '../../components/ui/Loader';

// const theme = useTheme()

const StyledAppBar = styled(AppBar)(({ theme })=> ({
    position: "sticky",
    background: theme.palette.custom.black
}))


const StyledToolbar = styled(Toolbar)({
    display: "flex",
    justifyContent: "space-between",
})

const Icons = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: "20px",
}))

const Navbar = ({openSidebar, handleDrawerClose}) => {
    const [notificationCount, setNotificationCount] = useState(0)
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
  
      const handleClose = () => {
        setAnchorEl(null);
      };
    // const [openNotification, setOpenNotification] = useState([])

    // NOTI
    const [anchorNotificationEl, setAnchorNotificationEl] = useState(null);
    const openNotification = Boolean(anchorNotificationEl);
    const handleNotificationClick = (event) => {
        setAnchorNotificationEl(event.currentTarget);
        // setNotificationCount(0)
        // if(count > 0)
        dispatch(getNotifications())
        // dispatch(getUnreadNotificationsCount())
    };
    const handleNotificationClose = () => {
        setAnchorNotificationEl(null);
    };


   const dispatch = useDispatch()
   const navigate = useNavigate()
   const location = useLocation()

   const userLogin = useSelector(state => state.userLogin)
   const { userInfo } = userLogin

   const notificationUnreadCount = useSelector(state => state.notificationUnreadCount)
   const { count } = notificationUnreadCount

   const notificationList = useSelector(state => state.notificationList)
   const { loading: loadingNotifications, notifications } = notificationList

   const appointmentAdd = useSelector(state => state.appointmentAdd)
   const { success } = appointmentAdd

   useEffect(() => {
    if(!count)
        dispatch(getUnreadNotificationsCount())
    // dispatch(getNotifications())
   }, [dispatch, success])

//    useEffect(() => {
//     console.log("count effect")
//         if(count) 
//             setNotificationCount(count)
//    }, [count])


   

   const getRoleBasedLogout = (role) => {
    if (role === ROLES.BARBER) {
      return '/barber-sign-in'
    } else if (role === ROLES.ADMIN) {
      return '/admin/sign-in'
    } else {
      return '/sign-in'
    }
  }

   const logoutHandler = () => {
    navigate(getRoleBasedLogout(userInfo.role))
    dispatch(logout()) 
}


const NavMenu = ({ children }) => {
    return (
    <Menu
            id="basic-menu"
            anchorEl={anchorNotificationEl}
            open={openNotification}
            onClose={handleNotificationClose}
            PaperProps={{
                elevation: 0,
                sx: {
                overflow: 'scroll',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 5,
                height: "80vh",
                width: loadingNotifications ? 400 : "max-content",
                display: "flex",
                alignItems: loadingNotifications ? "center" : "flex-start",
                justifyContent: loadingNotifications ? "center" : "flex-start",
                // alignItems: "flex-start",
                // justifyContent: "flex-start",
                '& .MuiAvatar-root': {
                    // width: 32,
                    // height: 32,
                    ml: -0.5,
                    // mr: 1,
                },
                '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                },
                },
            }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
                {children}
        </Menu>
    )
    }

    return (
        
        // sx={{bgcolor: "#1976d2"}}
        // style={{ padding: (userInfo && location.pathname) === "/" ? "0 10px" : "0" }
        <StyledAppBar style={{ padding: (userInfo && location.pathname) === "/" ? "0 10px" : "0" }}>
            <StyledToolbar>
                <Box display="flex" sx={{alignItems: "center"}}>
                    {!openSidebar &&
                    <IconButton sx={{display: {xs: "block", md: "none"}}} onClick={() => handleDrawerClose(!openSidebar)}>
                        <MenuIcon style={{ display: (userInfo && location.pathname) === "/" ? "none" : "block"  }} color="primary"  />
                    </IconButton>
                    }
                    <ContentCutIcon  />
                    <Link to="/" style={{ textDecoration: "none", color: "#fff"}}>
                        <Typography variant="h5" pl={1}  sx={{ display: {xs: "none", sm: "block"} }}>
                            Barbarly
                        </Typography>
                        <Typography variant="h6" pl={1} sx={{ display: {xs: "block", sm: "none"} }}>
                            Barbarly
                        </Typography>
                    </Link>
                </Box>
                <Icons>
                    {
                       (userInfo?.role !== ROLES.BARBER && userInfo?.role !== ROLES.ADMIN && location.pathname !== "/") &&
                        <Box sx={{ display:  {xs: 'none', md: 'block'}}}>
                        <Link to="/book-appointment" style={{ textDecoration: 'none',}}>
                            <Button
                                variant="outlined"
                                size="small"
                                startIcon={<AddIcon />}
                            >
                                Book Appointment
                            </Button>
                        </Link>
                    </Box>
                    }
               
                    {/* <Badge badgeContent={0} color="error">
                        <MailIcon />
                    </Badge> */}
                    
                    <Badge onClick={handleNotificationClick} badgeContent={count} color="error" sx={{ cursor: "pointer"}}>
                        <NotificationIcon />
                    </Badge>
                    {/* <Badge  badgeContent={0} color="error">
                        <NotificationIcon />
                    </Badge> */}
                    
                    <IconButton
                            onClick={handleClick}
                            size="small"
                            // sx={{ ml: 2 }}
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                    >
                        <Avatar 
                            sx={{ objectFit: 'cover' }} 
                            src={userInfo?.avatar}
                        />
                    </IconButton>
                </Icons>
            </StyledToolbar>
            <Menu
                id="account-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 5,
                      '& .MuiAvatar-root': {
                        // width: 32,
                        // height: 32,
                        ml: -0.5,
                        // mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
                    <MenuItem>
                        <Link to="/sign-in" style={{ textDecoration: "none", color: "#000"}}>
                            Dashboard
                        </Link>                    
                    </MenuItem>
                    <MenuItem>
                        <Link to="/profile" style={{ textDecoration: "none", color: "#000"}}>
                            My Profile
                        </Link>                    
                    </MenuItem>
                    
                <MenuItem onClick={logoutHandler} sx={{ fontWeight: "bold" }}>Logout</MenuItem>
            </Menu>
            {/* Notifications */}
            <NavMenu>
                { 
                    loadingNotifications  
                    ? <Loader />
                    : notifications.length === 0 
                        ?   <Typography ml={2} my={2}>No notifications available.</Typography>
                        : notifications?.map((item) => (
                        <NavBarNotification notification={item} />
                    ))
                }
            </NavMenu>
        </StyledAppBar>
    )
}

export default Navbar