import { styled, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";

const Details = () => {
const CustomBox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(8, 0, 0, 0),
    margin: theme.spacing(0, 2, 0, 2),
    [theme.breakpoints.down("md")]: {
        padding: "0",
    },
    }));

  const LargeText = styled(Typography)(({ theme }) => ({
    fontSize: "54px",
    color: "#000339",
    fontWeight: "700",
    [theme.breakpoints.down("md")]: {
      fontSize: "32px",
    },
  }));

  const SmallText = styled(Typography)(({ theme }) => ({
    fontSize: "18px",
    color: "#7B8087",
    fontWeight: "500",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  }));

  const TextFlexbox = styled(Box)(({ theme }) => ({
    // marginTop: theme.spacing(0),
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(0, 5, 0, 5),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: theme.spacing(5),
    },
  }));

  const [loading, setLoading] = useState(false)
  const [stats, setStats] = useState(null)

  const getStats = async () => {
    setLoading(true)
    try {
        const { data } = await axios.get('/api/stats')
        setStats(data)
        setLoading(false)
    } catch (error) {
        setLoading(false)
        setStats(null)
    }
  }
 
  useEffect(() => {
    getStats()
   }, []);

  return (
    <Box className="section">
        <CustomBox>
            <Container>
                <TextFlexbox>
                <Box
                    sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    }}
                >
                    <LargeText>{stats?.verifiedBarbers || 0}+</LargeText>
                    <SmallText>Verified Barbers</SmallText>
                </Box>

                <Box
                    sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    }}
                >
                    <LargeText>{stats?.clients || 0}+</LargeText>
                    <SmallText>Trusted Clients</SmallText>
                </Box>

                <Box
                    sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    }}
                >
                    <LargeText>{stats?.appointments || 0}+</LargeText>
                    <SmallText>Booked Appointments</SmallText>
                </Box>
                </TextFlexbox>
            </Container>
        </CustomBox>
    </Box>
  );
};

export default Details;