import { useState, useEffect } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import { Box, Stack, useMediaQuery } from "@mui/material"

import Footer from "../Footer/Footer"
import Navbar from "../Navbar/Navbar"
import Sidebar from "./Sidebar"
import DashboardNavbar from "./DashboardNavbar"

import ROLES from '../../constants/roleConstants'

import  { barberNavList, customerNavList, adminNavList } from './navList'

import { styled } from "@mui/material/styles"
import { logout } from "../../redux/actions/userActions";
import { UPDATE_ALERT } from "../../redux/constants/alertConstants";

const StyledCenterBox = styled(Box)(({ theme }) => ({
    minHeight: "100vh",
    background: theme.palette.custom.backgroundColor,
    justifyContent: "space-between",
    alignContent: "center",
}))

const Layout = ({ mode, setMode }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)

  // const showButton = () => {
  //     if (window.innerWidth <= 960) {
  //       setOpen(false)
  //     } else {
  //       setOpen(true)
  //     }
  // };

  // useEffect(() => {
  //     showButton();
  // }, []);

  // window.addEventListener('resize', showButton);

  // const toggleSidebar = () => {
  //   setOpen(!open);
  // };

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const userDetails = useSelector(state => state.userDetails)
  const { user } = userDetails

  const isDesktop = useMediaQuery('(min-width:600px)');

  useEffect(() => {
    const parseJwt = (token) => {
      try {
        return JSON.parse(atob(token.split('.')[1]));
      } catch (e) {
        return null;
      }
    };
  
    const user = JSON.parse(localStorage.getItem("userInfo"));
    if (user) {
      const decodedJwt = parseJwt(user.accessToken);

      if (decodedJwt.exp * 1000 < Date.now()) {
        dispatch(logout())
        // dispatch({ type: UPDATE_ALERT, payload: {open: true, severity: 'error', message: 'Something wrong with your authorization, please sign in again.' } })
      }
    }
  }, [location])

  const getRoleBasedNavbar = (role) => {
    if (role === ROLES.BARBER) {
      return barberNavList
    } else if (role === ROLES.ADMIN) {
      return adminNavList
    } else {
      return customerNavList
    }
  }

  const isRouteOutsideDashboard = () => {
    return (
      location.pathname != "/" && 
      location.pathname != "/phone-verification" 
      // location.pathname != "/not-found"
    )
  }

  return (
    <>
      {userInfo && isRouteOutsideDashboard()
        ? 
         <Box>
          {!isDesktop && 
          <Sidebar 
            open={open} 
            setOpen={setOpen}
            mode={mode}
            setMode={setMode} 
            navbarList={getRoleBasedNavbar(userInfo?.role)} 
            />
          }
            <Stack direction="row" justifyContent="space-between">
                {/* <Sidebar 
                  flex={1} 
                  open={open} 
                  handleDrawerClose={toggleSidebar}
                  /> */}
             {isDesktop && 
              <Sidebar 
                open={open} 
                setOpen={setOpen}
                mode={mode}
                setMode={setMode} 
                navbarList={getRoleBasedNavbar(userInfo?.role)} 
              />  
             }
                <StyledCenterBox flex={2}>
                    <DashboardNavbar openSidebar={open} handleDrawerClose={setOpen} />
                    <Box p={4}>
                        <Outlet />
                    </Box>
                </StyledCenterBox>
            </Stack>
          </Box>
        : <>
            {userInfo 
              ? <DashboardNavbar openSidebar={open} handleDrawerClose={setOpen} />
              :
              <Navbar />
            }
            <Outlet />
            <Footer />
          </>
      }
        {/* <Navbar /> */}
          {/* <Outlet /> */}
    </>
  )
}

export default Layout