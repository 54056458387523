import { useEffect, useMemo, useState } from 'react';
import { Avatar, Box, Button, Typography, Stack } from '@mui/material';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import moment from 'moment';
import { grey } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import { listUserAppointments } from '../../../redux/actions/appointmentActions';
import BackDropLoader from '../../../components/ui/BackDropLoader';
import TextButton from '../../../components/ui/TextButton';
import { Link } from 'react-router-dom';
import AppointmentsTable from './AppointmentsTable'
import AddIcon from '@mui/icons-material/Add'
import { APPOINTMENT_ADD_RESET, APPOINTMENT_ADD_WALLET_RESET, APPOINTMENT_RESCHEDULE_RESET, APPOINTMENT_RESCHEDULE_WALLET_RESET } from '../../../redux/constants/appointmentConstants';

const Appointments = () => {
    const dispatch = useDispatch()
    const appointmentUserList = useSelector(state => state.appointmentUserList)
    const { loading, error, appointments } = appointmentUserList

  useEffect(() => {
    // if (appointments.length === 0) dispatch(listUserAppointments())
    dispatch(listUserAppointments())
    dispatch({ type: APPOINTMENT_ADD_RESET })
    dispatch({ type: APPOINTMENT_ADD_WALLET_RESET })
    dispatch({ type: APPOINTMENT_RESCHEDULE_RESET })
    dispatch({ type: APPOINTMENT_RESCHEDULE_WALLET_RESET })
  }, []);

  return (
    <>
    {
        loading 
        ? <BackDropLoader />
        :
        <Box>
            <Box display="flex" justifyContent="flex-end">
                <Link to="/book-appointment" style={{ textDecoration: 'none' }}>
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                    >
                        Book New
                    </Button>
                </Link>
            </Box>
            <Box
            sx={{
                height: '70vh',
                width: '100%',
            }}
            >
            <Typography
                variant="h4"
                component="h4"
                sx={{ textAlign: 'center', mt: 3, mb: 3 }}
            >
                Appointments List
            </Typography>
            {/* <DataGrid
                columns={columns}
                rows={appointments}
                getRowId={(row) => row._id}
                rowsPerPageOptions={[5, 10, 20]}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                components={{ NoRowsOverlay }}
                getRowSpacing={(params) => ({
                top: params.isFirstVisible ? 0 : 5,
                bottom: params.isLastVisible ? 0 : 5,
                })}
                sx={{
                [`& .${gridClasses.row}`]: {
                    bgcolor: (theme) =>
                    theme.palette.mode === 'light' ? grey[200] : grey[900],
                },
                }}
                onCellEditCommit={(params) => setRowId(params.id)}
            /> */}
            <AppointmentsTable appointments={appointments} />
            </Box>
        </Box>
    }
    </>
  );
};

export default Appointments;