import React, { useState } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import { geocodeByAddress } from 'react-places-autocomplete';
import { Paper, InputBase, Divider, Box, Typography } from "@mui/material";
// import SearchIcon from '@material-ui/icons/Search';

// import IconButton from "@material-ui/core/IconButton";
// import MyLocation from "@material-ui/icons/MyLocation";
// import LocationOn from "@material-ui/icons/LocationOn";
import { useTheme } from '@mui/material/styles'

import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import MyLocationIcon from '@mui/icons-material/LocationOn';
import useStyles from './styles'

import SearchBar from "material-ui-search-bar";
import { useLocation } from 'react-router-dom';

const SearchBox = ({ setCoordinates, getBrowserLocation, address, setAddress }) => {
  const location = useLocation()
  const theme = useTheme()
  const classes = useStyles();
  const [autocomplete, setAutocomplete] = useState(null);
  const [value, setValue] = useState(null);

  const onLoad = (autoC) => setAutocomplete(autoC);

  const onPlaceChanged = () => {
    console.log("on place ")
    // setAddress(autocomplete.getPlace().formatted_address)
    setAddress('')
    const lat = autocomplete.getPlace().geometry.location.lat();
    const lng = autocomplete.getPlace().geometry.location.lng();
    setCoordinates({ lat, lng });
  }

  const getBrowserLocationHandler = () => {
    setValue('')
    getBrowserLocation()
  }


   return (
    // <Box className='section' style={{display: 'flex', justifyContent: 'center'}}>
    //   <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
    //   <SearchBar
    //     className='search-box'
    //     id="search-box"
    //     value={address}
    //     placeholder='Enter your address here...'
    //     // onChange={(newValue) => this.setState({ value: newValue })}
    //     onChange={(e) => setAddress(e)}
    //   /> 
    //  </Autocomplete>
    //  {/* <Divider className={classes.divider} orientation="vertical" /> */}
    //       <IconButton
    //         color="primary"
    //         className={classes.iconButton}
    //         aria-label="directions"
    //         onClick={getBrowserLocation}
    //       >
    //         <MyLocation />
    //       </IconButton>
    // </Box>
    // <Box className={location.pathname === "/" || "/book-appointment" ? "section" : ""}>
    <Box
      backgroundColor={location.pathname === "/book-appointment" ? theme.palette.custom.white : ""}
        pt={3} 
    >
    <Box style={{display: 'flex', justifyContent: 'center'}}>
      <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
        <Paper
        component="form"
        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center'}}
        className='search-box'
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Enter near by location"
            inputProps={{ 'aria-label': 'search google maps' }}
            value={value}
            onClick={() => setValue(null)}
            // autoFocus
          />      
        
          {/* <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
            <SearchIcon />
          </IconButton> */}
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton 
            color="primary" 
            sx={{ p: '10px' }} 
            aria-label="directions"
            onClick={getBrowserLocationHandler}
            >
            <MyLocationIcon />
          </IconButton>
        </Paper>
      </Autocomplete>
    </Box>
    <Box display="flex" justifyContent= "center" mt={2}>
        {address && <Typography fontWeight="bold">Current location: {address}</Typography>}
      </Box>
  </Box>

 )
}

export default SearchBox;
