import { Avatar, Badge, Box, Button, Divider, IconButton, Rating, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Field } from '../profile'
import { CopyTextBox } from '../profile'
import { useDispatch, useSelector } from 'react-redux'
import ROLES from '../../constants/roleConstants'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import axios from 'axios';
import { UPDATE_ALERT } from '../../redux/constants/alertConstants';
import Loader from '../../components/ui/Loader';
import { USER_SEND_VERIFICATION_EMAIL_RESET, USER_VERIFY_UPDATE_PHONE_RESET } from '../../redux/constants/userConstants';
import { getUserDetails, sendVerificationEmail } from '../../redux/actions/userActions';

const StoreDetails = ({store}) => {
    const dispatch = useDispatch()

    // const storeAndBarberDetails = useSelector(state => state.storeAndBarberDetails)
    // const { loading: loadingDetails, error: errorDetails, data: { store } } = storeAndBarberDetails
    
    const handleVerifyNow = (e) => {
      e.preventDefault()
    //   dispatch(sendVerificationEmail())
    }

    const getTextColorBasedOnStatus = (status) => {
        switch(status) {
            case 'active': return "success.main"
            case 'inactive': return "text.disabled"
            case 'onhold': return "warning.main"
            case 'restricted': return "error.main"
            case 'blocked': return "error.dark"
            default: return ""
        }
    }

  return (
        <Box>
            <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Avatar 
                    sx={{
                    width: '140px',
                    height: '140px',
                    borderRadius: '50%',
                    margin: '0 auto',
                    objectFit: 'cover'
                }}                       
                    alt={store?.owner?.firstName} src={store?.owner?.avatar ? store?.owner?.avatar : ""} 
                />
            </Badge>
            <Typography variant="h6" textAlign="center"  fontWeight="bold" color="primary" mb={3} mt={2}>
                {store?.name} 
            </Typography>
            {/* <Field title="Email" value={user?.email?.emailAddress} disabled /> */}
            {/* Email */}
            <Divider />
            <Field title="Owner" value={store?.owner?.firstName + " " + store?.owner?.lastName }  />
            <Field title="Address" value={store?.workArea?.address} />
            <Divider />
            <Box display="flex" justifyContent="space-between" px={3} py={1.6}>
                <Typography fontWeight="bold">Rating</Typography>
                <Rating value={store?.rating} precision={0.5} readOnly/>
            </Box>
            <Field title="No. of Reviews" value={store?.numReviews} />
            <Field title="For Men" value={store?.forMen ? "Yes": "No"} />
            <Field title="For Women" value={store?.forWomen ? "Yes": "No"} />
            <Field title="Experience" value={store?.experience} />
            <Field title="Joining Date" value={store?.createdAt?.toString().substring(0, 10)}/>
            <Field title="Verified" value={store?.verified ? "Yes": "No"}/>
            <Field title="Status" value={store?.status} 
                textColor={getTextColorBasedOnStatus(store?.status)} 
            />
        </Box>  
  )
}

export default StoreDetails