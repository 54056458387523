import { Box, CircularProgress, Fab } from '@mui/material';
import { useEffect, useState } from 'react';
import { Check, Save, Visibility } from '@mui/icons-material';
import { green } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import { updateStoreDetails } from '../../../redux/actions/storeActions';
import { UPDATE_ALERT } from '../../../redux/constants/alertConstants';
import { USER_STATUS_UPDATE_RESET } from '../../../redux/constants/userConstants';
import { STORE_DETAILS_UPDATE_RESET } from '../../../redux/constants/storeConstants';
import { getContactMessageDetails, updateContactMessage } from '../../../redux/actions/contactActions';
import { CONTACT_MESSAGE_UPDATE_RESET } from '../../../redux/constants/contactConstants';
import { listTransactions, verifyTransaction } from '../../../redux/actions/transactionActions';
import { TRANSACTION_ADMIN_VERIFY_RESET } from '../../../redux/constants/transactionConstants';


const TransactionActions = ({ params, rowId, setOpenModal, setTransactionDetails}) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);

  const transactionVerify = useSelector(state => state.transactionVerify)
  const {  error, success: verifySuccess } = transactionVerify

//   const verify = (e) => {
//     console.log("view lcik")
//     e.preventDefault()
//     setOpenModal(true)
//     const { _id } = params.row;
//     dispatch(getContactMessageDetails(_id))
//   };

const viewTransactionHandler = (e) => {
  e.preventDefault()
  setOpenModal(true)
  setTransactionDetails(params.row)
};

  const verifyTransactionHandler = (e) => {
    e.preventDefault()
    setLoading(true);
    const { _id } = params.row;
    dispatch(verifyTransaction(_id))
  };

  useEffect(() => {
    console.log("use effect called")
    if (verifySuccess) {
      console.log("success")
        setLoading(false)
        dispatch({ type: UPDATE_ALERT, 
            payload: 
            {open: true, severity: 'success', message: 'Funds released successfully.' 
          } 
        })
        dispatch({ type: TRANSACTION_ADMIN_VERIFY_RESET })
        dispatch(listTransactions())
    }
    if(error) {
      setLoading(false)
      dispatch({ type: UPDATE_ALERT, 
        payload: 
        {open: true, severity: 'error', message: error 
      } 
    })
    }
  }, [rowId, verifySuccess, error]);


  return (
    <Box display="flex">
      {/* <Box
        sx={{
          m: 1,
          position: 'relative',
        }}
      >
          <Fab
            color="primary"
            sx={{
              width: 40,
              height: 40,
            }}
            onClick={viewTransactionHandler}
          >
            <Visibility />
          </Fab>
      </Box> */}
      {/* {params.row.paymentStatus === "pending" && */}
      <Box
        sx={{
          m: 1,
          position: 'relative',
        }}
      >
          <Fab
            color="primary"
            sx={{
              width: 40,
              height: 40,
            }}
            disabled={loading || params.row.paymentStatus !== "pending"}
            onClick={verifyTransactionHandler}
          >
            <Check />
          </Fab>
        {loading && (
          <CircularProgress
            size={52}
            sx={{
              color: green[500],
              position: 'absolute',
              top: -6,
              left: -6,
              zIndex: 1,
            }}
          />
        )}
      </Box>
    {/* } */}
    </Box>
  );
};

export default TransactionActions;