import axios from "axios";
import {
     ADMIN_DASHBOARD_DATA_FAIL, 
     ADMIN_DASHBOARD_DATA_REQUEST, 
     ADMIN_DASHBOARD_DATA_SUCCESS,
     ADMIN_WALLET_DATA_FAIL,
     ADMIN_WALLET_DATA_REQUEST,
     ADMIN_WALLET_DATA_SUCCESS
} from "../constants/adminConstants"

export const getAdminDashboardData = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: ADMIN_DASHBOARD_DATA_REQUEST
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.get('/api/admin/data', config)
        dispatch({
            type: ADMIN_DASHBOARD_DATA_SUCCESS,
            payload: data
        })
       
    } catch (error) {
        dispatch({
                    type: ADMIN_DASHBOARD_DATA_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const getAdminWalletDetails = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: ADMIN_WALLET_DATA_REQUEST
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.get('/api/admin/wallet', config)
        dispatch({
            type: ADMIN_WALLET_DATA_SUCCESS,
            payload: data
        })
       
    } catch (error) {
        dispatch({
                    type: ADMIN_WALLET_DATA_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}
