import React, {useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import AnimatedPage from '../../components/Layout/AnimatedPage'
import Loader from '../../components/ui/Loader'
import Alert from '../../components/ui/Alert'

import { getStoreDetails } from '../../redux/actions/storeActions'
import { saveBarberDetails } from '../../redux/actions/cartActions'

import {
  Box, CardMedia, Container, Typography, Grid,
  Card, Rating,Chip
} from '@mui/material'
import Pagination from '@mui/material/Pagination'

import { styled } from '@mui/material/styles'

import BookingForm from '../../components/ui/Form/BookingForm'
import { CART_EMPTY } from '../../redux/constants/cartConstants';
import BackDropLoader from '../../components/ui/BackDropLoader';
import { Feedback } from '../../features/feedback';

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex', 
  // flexDirection: 'column',
  margin: '0 auto', 
  padding: '12px 24px',
  width: 'max-content',
  [theme.breakpoints.down("md")]: {
    // flexDirection: "column",
    // textAlign: "center",
    width: '100vw',
  },
}))

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
}))

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: "0 4px"
}))

const BarberDetails = () => {
  const dispatch = useDispatch()
  const { barberId } = useParams()
  const [showFeebacks, setShowFeedbacks] = useState(true)

  const storeDetails = useSelector(state => state.storeDetails)
  const { loading, error, store  } = storeDetails

  
  const [reviews, setReviews] = useState([])
  const [currentItems, setCurrentItems] = useState([])
  const [startIndex, setStartIndex] = useState(0)
  const [endIndex, setEndIndex] = useState(0)
  
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(4)
  const [numPages, setNumPages] = useState(0);


  const handlePageChange = (pageNumber) => {
      setPage(pageNumber)
      const startIndexTemp = (pageNumber - 1) * itemsPerPage;
      const endIndexTemp = startIndexTemp + itemsPerPage;
      const currentItemsTemp = reviews.slice(startIndexTemp, endIndexTemp);
      setStartIndex(startIndexTemp)
      setEndIndex(endIndexTemp)
      setCurrentItems(currentItemsTemp)
  };  

  useEffect(() => {
   dispatch(getStoreDetails(barberId))
   dispatch({ type: CART_EMPTY })

  //  let items = store?.reviews || []
  //  const totalPagesTemp = Math.ceil(items.length / itemsPerPage);
  //  setNumPages(totalPagesTemp)
  },[]) 

  useEffect(() => {
    if(store) {
      dispatch(saveBarberDetails(store._id, store.name))
      // Feedback Pagination items
      let items = store?.reviews?.sort() || []
      setReviews(items)
      const totalPagesTemp = Math.ceil(items.length / itemsPerPage);
      const endIndexTemp = startIndex + itemsPerPage;
      const currentItemsTemp = items.slice(startIndex, endIndexTemp);
      setEndIndex(endIndexTemp)
      setCurrentItems(currentItemsTemp)
      setNumPages(totalPagesTemp)
    }
   },[store]) 

  return (
    <>
      { loading 
      ? <BackDropLoader />
      : 
    <AnimatedPage>
      {/* <CardMedia
        sx={{ height: { xs: "18vh", md: "30vh"}, boxShadow: "inset 0 0 0 1000px rgba(8, 22, 39, 0.5)" }}
        image={'/images/profile-cover.jpg'}
      />
      <Typography 
        color="white" 
        align="center" 
        sx={{
          position: "relative", 
          top: {xs: -80, md: -140 },
          fontSize: {xs: 24, md: 32}
        }}
        >
          Barber Profile
      </Typography> */}
      {
          store &&
          <Container component="main">
            {/* <Grid container sx={{margin: '0 auto'}}> */}
            <Grid xs={12} md={8}>
              <StyledCard elevation={3} >
                <CardMedia
                  image={store?.owner?.avatar ? store?.owner?.avatar : '/images/noAvatar.png'}
                  sx={{height: 200, width: 200, borderRadius: 1}}
                />
                <Box sx={{marginLeft: 4}}>
                    <Typography variant="h6" color="primary">{store.name}</Typography>
                    <Typography variant="body2" gutterBottom>{store.workArea?.address}</Typography>
                    <Typography variant="body2" gutterBottom>Experience: {store?.experience} year(s)</Typography>
                    <Rating sx={{my: 2}}  name="half-rating-read" defaultValue={store?.rating} precision={0.5} readOnly />
                    <Box sx={{display: 'flex', mt: 1 }}>
                      {store?.services?.map((service) =>  (
                        <Box key={service._id}>
                        <StyledChip  label={service.category} />
                        </Box>
                      ))}
                      </Box>
                </Box>

                  {
                    store?.rating !== 0 && 
                    <Box sx={{marginLeft: 8}}>
                      <Chip label={store?.rating} color="primary" />
                    </Box>
                  }
                  
              </StyledCard>
              </Grid>
              <BookingForm services={store.services} setShowFeedbacks={setShowFeedbacks} />
              {showFeebacks &&
                <Card
                  sx={{
                    padding: "20px 28px",
                    // width: 'max-content',
                    maxWidth: "800px",
                    margin: '0 auto', 
                    marginTop: 10,
                  }}
                  elevation={3} >
                    <Typography variant="h5" align="center" mt={2} mb={3} color="primary.main">Feedbacks</Typography>
                    <Box>
                      { reviews.length > 0 
                        ?
                          currentItems?.map((review, index) => (
                            <Feedback key={index} review={review} />  
                          ))
                        : 
                        <Typography align="center" mt={3}>No feedbacks available</Typography>
                      }
                    </Box>
                    {/* <div>
                    {Array.from({ length: totalPages }).map((_, index) => (
                      <button key={index} onClick={() => handlePageChange(index + 1)}>
                        {index + 1}
                      </button>
                    ))}
                  </div> */}
                  <Pagination
                    count={numPages}
                    page={page}
                    onChange={(event, value) => handlePageChange(value)}
                    sx={{ display: "flex", justifyContent: "center"}}
                  />
                </Card>
              }  
          </Container>
        }
    </AnimatedPage>     
    }
    </>
  )
}

export default BarberDetails


