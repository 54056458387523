import React from 'react'
import GoogleMapReact from 'google-map-react'
import { IconButton, Paper, Typography, useMediaQuery } from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import Rating from '@material-ui/lab/Rating'

import useStyles from './styles';

import Heading from '../../../../components/ui/Heading/Heading'
import { Link } from 'react-router-dom';


const Map = ({ setCoordinates, setBounds, coordinates, places, setChildClicked }) => {
  const classes = useStyles();
  const isDesktop = useMediaQuery('(min-width:600px)');

  //AIzaSyB3ezN_I7Dj2LPapIciwQiOGQWudADnl2A

  return (
    <div className={classes.mapContainer} >
      {/* {!isDesktop && <Heading text="Map View"/>} */}
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyB3ezN_I7Dj2LPapIciwQiOGQWudADnl2A' }}
        defaultCenter={coordinates}
        center={coordinates}
        defaultZoom={14}
        margin={[50, 50, 50, 50]}
        options={''}
        onChange={(e) => {
          // setCoordinates({lat: e.center.lat, lng: e.center.lng});
          setBounds({ne: e.marginBounds.ne, sw: e.marginBounds.sw});
        }}
        // onChildClick={(child) => setChildClicked(child)} 
      >
        {places?.map((place, i) => (
          <div
            className={classes.markerContainer}
            lat={Number(place.workArea.coordinates.lat)}
            lng={Number(place.workArea.coordinates.lng)}
            key={i}
          >
            <Link to={`/barber/${place._id}`}>
              <IconButton>
                <LocationOnIcon color="error" fontSize="large" />
              </IconButton>
            </Link>
           

            {/* {
              !isDesktop ? (
                <LocationOnOutlinedIcon color="error" fontSize="large" />
              ): (
                <Paper elevation={3} className={classes.paper} >
                  <Typography className={classes.typography} variant="subtitle2" gutterBottom>
                    {place.name}
                  </Typography>
                  <img 
                    className={classes.pointer}
                    src={place.photo ? place.photo.images.large.url : '/images/profile-cover.jpg'}
                    alt={place.name}
                  />
                  <Rating size="small" value={Number(place.rating)} readOnly />
                </Paper>
              )
            } */}
          </div>
        ))}
      </GoogleMapReact>
    </div>
  )
}

export default Map;


