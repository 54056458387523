import { alpha, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  // search: {
  //   position: 'relative',
  //   borderRadius: theme.shape.borderRadius,
  //   backgroundColor: alpha(theme.palette.common.white, 0.15),
  //   '&:hover': { backgroundColor: alpha(theme.palette.common.white, 0.25) },
  //   marginRight: theme.spacing(2),
  //   marginLeft: 0,
  //   width: '100%',
  //   [theme.breakpoints.up('sm')]: { marginLeft: theme.spacing(3), width: 'auto' },

  // },
  // searchIcon: {
  //   padding: theme.spacing(0, 2), height: '100%', position: 'absolute', pointerEvents: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center',
  // },
  // inputRoot: {
  //   color: 'inherit',
  // },
  // inputInput: {
  //   padding: theme.spacing(1, 1, 1, 0), paddingLeft: `calc(1em + ${theme.spacing(4)}px)`, transition: theme.transitions.create('width'), width: '100%', [theme.breakpoints.up('md')]: { width: '20ch' },
  // },

  //new
  // searchWrapper: {
  //   backgroundColor: "#fff",
  // },
  // rootHome: {
  //   padding: "2px 4px",
  //   display: "flex",
  //   alignItems: "center",
  //   width: 860,
  //   margin: '0 auto',
  //   borderRadius: theme.shape.borderRadius,
  // },
  // rootItems: {
  //   padding: "2px 4px",
  //   display: "flex",
  //   alignItems: "center",
  //   width: 400,
  //   backgroundColor: "#edebeb",
  // },
  // input: {
  //   marginLeft: theme.spacing(1),
  //   flex: 1,
  //   position: "relative",
  // },
  // results: {
  //   position: "absolute",
  //   bottom: -166,
  //   left: "26%",
  //   zIndex: 999,
  //   width: 760,
  //   height: "15%",
  // },
  iconButton: {
    padding: 10,
  },
  // divider: {
  //   height: 28,
  //   margin: 4,
  // },

}));
