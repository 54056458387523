import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  borderRadius: 1.5,
  boxShadow: 24,
  p: 4,
};

const BasicModal = ({ open, onClose, title, content }) => {
 
  return (
      <div>
        <Modal open={open} onClose={onClose}>
          <Box sx={style}>
            <Typography variant="h6">
                {title}
            </Typography>
            {content}
          </Box>
        </Modal>
      </div>
  )
}

export default BasicModal