import React, {useState} from 'react'
import { Box, Stack, Switch, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import FormContainer from '../../components/ui/Form/FormContainer'

import 'rc-time-picker/assets/index.css';

import moment from 'moment';

import TimePicker from 'rc-time-picker';
import { useDispatch } from 'react-redux';
import { UPDATE_ALERT } from '../../redux/constants/alertConstants';

const format = 'HH:mm';

const defaultStart = moment().hour(9).minute(0);
const defaultEnd = moment().hour(18).minute(0);
const now = moment().hour(18).minute(0);

const convertTimeToSeconds = (time) =>{
    var array = time.split(":");
    var seconds = (parseInt(array[0], 10) * 60 * 60) + (parseInt(array[1], 10) * 60)
    return seconds
}

const Slot = ({
        weekday, 
        title, 
        handleChange,
        open,
        setOpen,
        startTime,
        endTime,
    }) => {
    const theme = useTheme()
    const dispatch = useDispatch()

    const inActiveHours = []
    const activeHours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
    // const inActiveHours = []
    // const activeHours = []

    const startTimeChangeHandler = (value) => {
        const selectedTime = value && value.format(format)
        if(convertTimeToSeconds(selectedTime) >= convertTimeToSeconds(endTime)) {
            dispatch({ 
                type: UPDATE_ALERT, 
                payload: {
                    open: true, 
                    severity: 'error', 
                    message: 'Start time should be less than end time.' 
                } 
            })
        } else {
        handleChange({
            weekday,
            // open, 
            startTime: value,
            // endTime
        })
        }
    }

    const endTimeChangeHandler = (value) => {
        // const selectedTime = value && value.format(format)
        // if(convertTimeToSeconds(selectedTime) <= convertTimeToSeconds(startTime)) {
        //     dispatch({ 
        //         type: UPDATE_ALERT, 
        //         payload: {
        //             open: true, 
        //             severity: 'error', 
        //             message: 'End time cannot be less than start time.' 
        //         } 
        //     })
        // } else {
            handleChange({
                weekday,
                endTime: value
            })
        // }
       
    }

    const openDayChangeHandler = (e) => {
        setOpen(!open)
        handleChange({
            weekday,
            open, 
            // startTime,
            // endTime
        })
    }

    // const onChange = (value) => {
    //     const time = value && value.format(format)
    //     console.log(time)
    // }

    // const getInactiveHours = () => inActiveHours

    const disabledHours = () => {
        if (startTime) {
          let disabledHours = activeHours.filter(h => {
            const hour = startTime.split(":")[0]
            return h <= Number(hour);
          });
        //   disabledHours = [...inActiveHours, ...disabledHours]
          return [...inActiveHours, ...disabledHours]
        //   return [...disabledHours]
        }
        return [];
      }

const TimeSlot = ({start=false, end=false, value, onChange}) => {
    value = moment(value, 'HH:mm A')
    return (
        <Box display="flex" alignItems="center">
            {start && 
                <>
                    <Typography variant="body2" mr={1}>
                        Start Time: 
                    </Typography>
                    <TimePicker
                        defaultValue={defaultStart}
                        value={value}
                        onChange={(e) => onChange(e)}
                        inputReadOnly
                        showSecond={false}
                        showMinute={false} 
                        minuteStep={60}
                        allowEmpty={false}
                        disabled={!open}
                        hideDisabledOptions={true}
                        // disabledHours={getInactiveHours}
                    />
                </>
            }
            {end &&
            <>
                <Typography variant="body2" mr={1}>
                    End Time: 
                </Typography>
                <TimePicker
                    value={value}
                    onChange={(e) => onChange(e)}
                    inputReadOnly
                    showSecond={false}
                    showMinute={false} 
                    minuteStep={60}
                    allowEmpty={false}
                    disabled={!open}
                    disabledHours={disabledHours}
                />
            </>
            } 
        </Box>
    )
    }

  return (
    // <FormContainer>
        <Box 
            // width="340px" 
            backgroundColor={theme.palette.custom.backgroundColor} 
            margin="14px auto"
            padding="18px 24px"
        >
            <Box
                display="flex" 
                // justifyContent="flex-start"
                // gap={10}
                justifyContent="space-evenly"
                alignItems="center"
            > 
                <Box display="flex" justifyContent="flex-start" width="10%">
                    <Typography fontWeight="bold" >{title}</Typography>
                </Box>
                <Box display="flex" justifyContent="flex-start" width="16%">
                    <Stack 
                        direction="row" 
                        spacing={1}    
                        // marginLeft="auto"
                        // sx={{ background: theme.palette.custom.lightgray }}                  
                        >
                        <Typography sx={{ color: theme.palette.custom.lightgray }} align="left">Close</Typography>
                        <Switch
                            size="small"
                            // defaultChecked
                            checked={open}
                            onChange={openDayChangeHandler}
                        />
                        <Typography sx={{ color: theme.palette.primary.main }}>Open</Typography>
                    </Stack>
                </Box>
                {/* {
                    open &&
                    <> */}
                        <TimeSlot start={true} value={startTime} onChange={startTimeChangeHandler} />
                        <TimeSlot end={true} value={endTime} onChange={endTimeChangeHandler} />
                    {/* </>
                }
                 */}
            </Box> 
            {/* <Box
                mt={1}
                display="flex" 
                justifyContent="space-between"
            > 
                <Typography>Monday</Typography>
                <Switch />
            </Box>  */}
        </Box> 
    // </FormContainer>
  )
}

export default Slot