import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BasicModal from './BasicModal'
import Alert from '../Alert'
import Loader from '../Loader'

import { Box, IconButton, TextField, 
    Grid, Button, MenuItem, InputAdornment, FormControl,
    OutlinedInput, InputLabel, FormHelperText, Stack  } from '@mui/material'

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { editService, editServiceAdmin, getServiceDetails } from '../../../redux/actions/storeActions'
import { useParams } from 'react-router-dom';
import ROLES from '../../../constants/roleConstants';

const EditServiceModal = (
        { serviceId, open, onClose }
    ) => {
    const { id: storeId } = useParams()  // store id
    const dispatch = useDispatch() 

    const [name, setName] = useState('')
    const [amount, setAmount] = useState(null)
    const [duration, setDuration] = useState(null)
    const [status, setStatus] = useState('')
    const [description, setDescription] = useState('')
    const [message, setMessage] = useState({ type: 'info', text: ''})
    const [openAlert, setOpenAlert] = useState(false)

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const serviceDetails = useSelector(state => state.serviceDetails)
    const { loading: loadingService, service } = serviceDetails

    const serviceEdit = useSelector(state => state.serviceEdit)
    const { loading, error, success } = serviceEdit

    const editServiceHandler = ({ name, amount, duration, description, status }) => {
        if (userInfo?.role === ROLES.ADMIN) 
            dispatch(editServiceAdmin({storeId, serviceId, name, amount, duration, description, status }))
        else
            dispatch(editService({ serviceId, name, amount, duration, description, status }))
       }
    
    useEffect(() => {
        dispatch(getServiceDetails(serviceId))
    }, [dispatch, serviceId])

    useEffect(() => {
        if(service){
            setName(service.name)
            setAmount(service.amount)
            setDuration(service.duration)
            setStatus(service.status)
            setDescription(service.description)
        }
    }, [service])

    useEffect(() => {
        if(success) {
            setName('')
            setAmount(null)
            setDuration(null)
            setStatus('')
            setDescription('')
            onClose()
        }
        if(error){
            setMessage({ type: "error", text: error})
            setOpenAlert(true)
        }
    },[error, success])

    const submitHandler = (e) => {
        e.preventDefault()
        editServiceHandler({serviceId, name, amount, duration, description, status})
    }

    const getContent = () => (
    <Box component="form" onSubmit={submitHandler} sx={{ mt: 3 }}>
        {loadingService 
            ? <Loader />
            :
            <>
            {/* {message && 
                <Alert 
                    type={message.type}
                    message={message.text} 
                    openAlert={openAlert} 
                    setOpenAlert={setOpenAlert} 
                />
            }             */}
            {loading && <Loader />}
            <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    name="Service name"
                    label="Service name"
                    value={name}
                    autoFocus
                    variant="outlined"
                    size="small"
                    type="text"
                    required
                    fullWidth
                    onChange={(e) => setName(e.target.value)}
                    />
            </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-amount"
                            label="Amount"
                            value={amount}
                            inputProps={{ min: 1, max: 500 }}
                            size="small"
                            type="number"
                            required
                            fullWidth
                            onChange={(e) => setAmount(e.target.value)}
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="outlined-adornment-duration">Duration</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-duration"
                            label="Duration"
                            value={duration}
                            size="small"
                            type="number"
                            required
                            fullWidth
                            onChange={(e) => setDuration(e.target.value)}
                            aria-describedby="outlined-duration-helper-text"
                            startAdornment={
                            <InputAdornment position="start">
                                <IconButton edge="start">                         
                                    <AccessTimeIcon/>
                                </IconButton>
                            </InputAdornment>
                            }
                            inputProps={{
                                'aria-label': 'duration',
                                  min: 1, max: 180 
                              }}
                        />
                        <FormHelperText id="outlined-duration-helper-text">Estimated duration in minutes</FormHelperText>
                    </FormControl>
                </Grid> 
                <Grid item xs={12}>
                <TextField
                    name="Service Description"
                    label="Description"
                    value={description}
                    variant="outlined"
                    size="small"
                    type="text"
                    required
                    fullWidth
                    onChange={(e) => setDescription(e.target.value)}
                    />
            </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="outlined-select-status"
                        select
                        label="Status"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        required
                        fullWidth
                        >
                            <MenuItem value="active" >
                                Active
                            </MenuItem>
                            <MenuItem value="inactive" >
                                Inactive
                            </MenuItem>
                            
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <Stack 
                        direction="row" 
                        justifyContent="end"
                        spacing={2}
                    >
                        <Button 
                            variant="outlined"  
                            onClick={onClose}
                            >
                            Cancel
                        </Button>
                        <Button 
                            variant="contained"
                            type="submit"
                        >
                            Save
                        </Button>
                    </Stack>  
                </Grid >
            </Grid> 
        </> 
        }     
    </Box>
    )


  return (
    <BasicModal
        open={open}
        onClose={onClose}
        title="Edit Service"
        content={getContent()}
    >
    </BasicModal>
  )
}

export default EditServiceModal