import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'

const Loader = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', margin: 'auto' }}>
      <CircularProgress size={50}/>
    </Box>
  );
}

export default Loader;