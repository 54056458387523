import { useLocation, Navigate, Outlet, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import roleBasedRedirect from "../../../utils/roleBasedRedirect"
import { useEffect, useState } from "react";
import { UPDATE_ALERT } from "../../../redux/constants/alertConstants";
import { getUserDetails } from "../../../redux/actions/userActions";
import BackDropLoader from '../../../components/ui/BackDropLoader'


const RequireAuth = ({ allowedRoles }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const barberRegistrationCompleted = () => {return userInfo?.storeExists && userInfo?.workingScheduleExists}

    // const isPhoneVerified = () => {
    //   const isVerified = (userInfo?.phone?.phoneNumber !== "" && userInfo?.phone?.isVerified === true)
    //   console.log({isVerified})
    //   return isVerified 
    //  }

    // const userDetails = useSelector(state => state.userDetails)
    // const { user } = userDetails

    // useEffect(() => {
    //     if (!isPhoneVerified) 
    //     navigate('/phone')
    // }, [])

    // useEffect(() => {
    //     setLoading(true)
    //     // const user = JSON.parse(localStorage.getItem("userInfo"));
    //     if (!userInfo.role) {
    //       navigate("/dashboard")
          
    //     } 
    //     else {
    //         // location.pathname === "/phone-verification" ? navigate("/dashboard") : navigate(location)
    //         /// Check if phone number is verified
    //       if(userInfo?.phone?.phoneNumber == "" || userInfo?.phone?.isVerified == false) {
    //         console.log("phone not verified")
    //         navigate('/phone-verification')
    //         dispatch({ 
    //           type: UPDATE_ALERT, 
    //           payload: {
    //             open: true, 
    //             severity: 'info', 
    //             message: 'Please verify your phone number.' 
    //           } 
    //         })
    //     }
    //     }
    //     setLoading(false)
    //   }, [userInfo])



    // const redirectBasedOnRole = () => {
    //     if ()
    // }

    // return(
    //     // loading && !userInfo
    //     // ? <BackDropLoader />
    //     // : 
    //       userInfo
    //       ?
    //         userInfo?.role && allowedRoles?.includes(userInfo.role) 
    //             ? <Outlet />
    //             // : isPhoneVerified()
    //             //       ? 
    //                 : <Navigate to={roleBasedRedirect(userInfo?.role)} state={{ from: location}} replace />
    //       :<Navigate to="/sign-in" state={{ from: location}} replace />
    // )

    return(
      // loading && !userInfo
      // ? <BackDropLoader />
      // : 
        userInfo
        ?
          userInfo?.role && allowedRoles?.includes(userInfo.role) 
              ? <Outlet /> 
              // : <Navigate 
              //   to={roleBasedRedirect(
              //     {
              //       role: userInfo?.role, 
              //       // storeExists: userInfo.storeExists ? userInfo.storeExists : false,
              //       // workingScheduleExists: userInfo.workingScheduleExists ? userInfo.workingScheduleExists : false
              //       storeExists: userInfo?.storeExists,
              //       workingScheduleExists: userInfo?.workingScheduleExists
              //     })} 
              //   state={{ from: location}} replace 
              // />
              : <Navigate to={roleBasedRedirect({role: userInfo?.role})} state={{ from: location}} replace />
              // : isPhoneVerified()
              //   ? <Navigate to={roleBasedRedirect(userInfo?.role)} state={{ from: location}} replace />
              //   : <Navigate to="/phone-verification" />
        : <Navigate to="/sign-in" state={{ from: location}} replace />
  )
}

export default RequireAuth