import React, { useState, useRef, useEffect } from 'react'
import { Container, Card, Box, Typography, Grid, Button, TextField, Stack, MenuItem } from '@mui/material'
// import Textarea from '@mui/joy/Textarea';
import BackDropLoader from  '../../../components/ui/BackDropLoader'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from "@mui/material/styles"
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { UPDATE_ALERT } from "../../../redux/constants/alertConstants"
import { addNewTicket } from "../../../redux/actions/ticketActions"

const NewTicketForm = () => {
    const theme = useTheme()
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')
    // const subjectRef = useRef()
    // const messageRef = useRef()
    const [loading, setLoading] = useState(false)

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
    
    const ticketAdd = useSelector(state => state.ticketAdd)
    const { loading: loadingAddTicket, success, error } = ticketAdd

    useEffect(() => {
        setLoading(true)
        if(userInfo) {
            setName(userInfo.firstName + " " + userInfo.lastName)
            setEmail(userInfo.email.emailAddress)
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        if(success) {
            navigate('/support')   
            dispatch({ 
                type: UPDATE_ALERT, 
                payload: {
                    open: true, 
                    severity: 'success', 
                    message: 'Ticket opened successfully.' 
                } 
            })
        }
    }, [success])

    useEffect(() => {
        if(error) {
            dispatch({ 
                type: UPDATE_ALERT, 
                payload: {
                    open: true, 
                    severity: 'error', 
                    message: 'Error adding ticket, please try again later.' 
                } 
            })
            
        }
    }, [error])

    // const resetHandler = (e) => {
    //     console.log("called")
    //     subjectRef.current.value = ""
    //     messageRef.current.value = ""
    // }
  
    // const from = location.state?.from?.pathname || roleBasedRedirect(userInfo?.role)
  
      // const redirect = location.search ? location.search.split('=')[1] : '/sign-in'
      // console.log({from, redirect})
    //   useEffect(() => {
    //     if(success) {
    //       navigate('/sign-in')
    //     }
    //     if (error){
    //       setMessage(error)
    //       setOpenAlert(true)
    //     }
    //         // dispatch(logout())
    // },[success, error]) 

    const submitHandler = (e) => {
        e.preventDefault();
        
        if (!(subject && message)) {
            dispatch({ 
                type: UPDATE_ALERT, 
                payload: {
                    open: true, 
                    severity: 'error', 
                    message: 'Please fill all required fields.' 
                } 
            })
        } else {
            dispatch(addNewTicket({subject, message}))
        }      
    }

    return (
    <>
      {
        loading 
        ? 
            <BackDropLoader />
        :
        <>
           <Box>
                <Link to='/support' style={{ textDecoration: 'none' }}>
                <Button 
                    // style={{margin: "0 10px"}}
                    startIcon={<ArrowBackIcon />}
                    >
                    Go to Tickets
                </Button>
                </Link>
            </Box>
            <Container component="main" maxWidth="md" >
                <Card
                sx={{
                    marginTop: 3,
                    marginBottom: 3,
                    padding: "42px 32px 52px 32px",
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                elevation={2}
                >
                <Typography variant="h6">
                    Contact Us
                </Typography>
                <Typography variant="body2" align="center">
                    Please complete this form and we'll reply you as soon as possible.
                </Typography>
                <Box component="form" onSubmit={submitHandler} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} mb={1}>
                        <TextField
                        name="name"
                        label="Name"
                        variant="outlined"
                        size="small"
                        type="text"
                        required
                        fullWidth
                        value={name}
                        disabled
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} mb={1}>
                    <TextField
                        name="email"
                        label="Email"
                        variant="outlined"
                        size="small"
                        type="email"
                        required
                        fullWidth
                        value={email}
                        disabled
                        />
                    </Grid>
                    <Grid item xs={12} mb={1}>
                        {/* <TextField
                            name="subject"
                            label="Subject"
                            variant="outlined"
                            size="small"
                            type="text"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            required
                            fullWidth
                            /> */}
                            <TextField
                            id="outlined-select-category"
                            select
                            label="Select Subject"
                            value={subject}
                            // onChange={(e) => setSelectedCategoryId(e.target.value)}
                            onChange={(e) => setSubject(e.target.value)}
                            required
                            fullWidth
                            >
                            <MenuItem 
                                key="appointment"
                                value="Appointment Dispute"
                            >
                                Appointment Dispute
                            </MenuItem>
                            <MenuItem 
                                key="transaction"
                                value="Transaction Issue"
                            >
                                Transaction Issue
                            </MenuItem>
                            <MenuItem 
                                key="complain"
                                value="Complain"
                            >
                                General Complain
                            </MenuItem>
                            </TextField>
                    </Grid>
                    <Grid item xs={12} mb={1}>    
                        <TextField
                            name="message"
                            label="Message"
                            variant="outlined"
                            size="small"
                            type="text"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                            fullWidth
                            multiline
                            rows={6}
                            maxRows={12}
                            inputProps={{ maxLength: 300 }}
                        />
                        <Typography variant="caption" color="text.disabled" align="right" gutterBottom>
                            Max length 300 characters
                        </Typography>
                        {/* <TextField
                            name="message"
                            label="Message"
                            variant="outlined"
                            size="small"
                            type="text"
                            value="Max length should 300 characters"
                            required
                        /> */}
                    </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 4 }}>
                        {/* <Stack 
                            direction="row" 
                            justifyContent="end"
                            spacing={2}
                            >
                                <Button 
                                    variant="outlined"
                                    fullWidth 
                                    onClick={resetHandler} 
                                >
                                    Reset
                                </Button> */}
                                <Button 
                                    variant="contained"
                                    fullWidth
                                    type="submit"
                                >
                                    Submit
                                </Button>
                        {/* </Stack>   */}
                    </Grid >
                   
                </Box> 
                </Card>
            </Container> 
        </>
        }
    </>
  )
}

export default NewTicketForm