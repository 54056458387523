import { UPDATE_ALERT } from "../constants/alertConstants";


//ALERT
export const alertReducer = (state = { alert: {} }, action) => {
    switch (action.type) {
        case UPDATE_ALERT:
            return { alert: action.payload }
        default:
            return state
    }
}