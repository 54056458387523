import { Box, CircularProgress, Fab } from '@mui/material';
import { useEffect, useState } from 'react';
import { Check, Save } from '@mui/icons-material';
import { green } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserStatus } from '../../../redux/actions/userActions';
import { UPDATE_ALERT } from '../../../redux/constants/alertConstants';
import { USER_STATUS_UPDATE_RESET } from '../../../redux/constants/userConstants';


const UsersActions = ({ params, rowId, setRowId }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const userStatusUpdate = useSelector(state => state.userStatusUpdate)
  const {  error, success: updateStatus } = userStatusUpdate


  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true);
    const { status, _id } = params.row;
    dispatch(updateUserStatus({ _id, status }))
  };

  useEffect(() => {
    if (rowId === params.id && updateStatus) {
      setLoading(false)
        dispatch({ type: UPDATE_ALERT, 
            payload: 
            {open: true, severity: 'success', message: 'User status updated successfully.' 
          } 
        })
        dispatch({ type: USER_STATUS_UPDATE_RESET })
    }
    if(error) {
      setLoading(false)
      dispatch({ type: UPDATE_ALERT, 
        payload: 
        {open: true, severity: 'error', message: error 
      } 
    })
    }
  }, [rowId, updateStatus, error]);


  return (
    <Box
      sx={{
        m: 1,
        position: 'relative',
      }}
    >
      {/* {success ? (
        <Fab
          color="primary"
          sx={{
            width: 40,
            height: 40,
            bgcolor: green[500],
            '&:hover': { bgcolor: green[700] },
          }}
        >
          <Check />
        </Fab>
      ) : ( */}
        <Fab
          color="success"
          sx={{
            width: 40,
            height: 40,
          }}
          disabled={params.id !== rowId || loading}
          onClick={handleSubmit}
        >
          <Save />
        </Fab>
       {/* )}  */}
      {loading && (
        <CircularProgress
          size={52}
          sx={{
            color: green[500],
            position: 'absolute',
            top: -6,
            left: -6,
            zIndex: 1,
          }}
        />
      )}
    </Box>
  );
};

export default UsersActions;