import React, { useState, useRef, useEffect } from 'react'
import { Container, Card, Box, Typography, Grid, Button, TextField, Stack, MenuItem, CircularProgress } from '@mui/material'
// import Textarea from '@mui/joy/Textarea';
import BackDropLoader from  '../../../components/ui/BackDropLoader'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from "@mui/material/styles"
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { UPDATE_ALERT } from "../../../redux/constants/alertConstants"
import { addNewTicket } from "../../../redux/actions/ticketActions"
import { addNewContactMessage } from '../../../redux/actions/contactActions';
import { CONTACT_MESSAGE_ADD_RESET } from '../../../redux/constants/contactConstants';

const NewTicketForm = () => {
    const dispatch = useDispatch()
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')
    
    const contactMessageAdd = useSelector(state => state.contactMessageAdd)
    const { loading: loading, success, error } = contactMessageAdd

    useEffect(() => {
        if(success) {
            setName('') 
            setEmail('') 
            setSubject('') 
            setMessage('') 
            dispatch({ 
                type: UPDATE_ALERT, 
                payload: {
                    open: true, 
                    severity: 'success', 
                    message: 'Thanks for contacting us! We will be in touch with you shortly.' 
                } 
            })
            dispatch({ type: CONTACT_MESSAGE_ADD_RESET })
        }
        if(error) {
            dispatch({ 
                type: UPDATE_ALERT, 
                payload: {
                    open: true, 
                    severity: 'error', 
                    message: 'Error adding message, please try again later.' 
                } 
            })
            
        }
    }, [success, error])

    const submitHandler = (e) => {
        e.preventDefault();
        
        if (!(name && email && subject && message)) {
            dispatch({ 
                type: UPDATE_ALERT, 
                payload: {
                    open: true, 
                    severity: 'error', 
                    message: 'Please fill all required fields.' 
                } 
            })
        } else {
            dispatch(addNewContactMessage({name, email, subject, message}))
        }      
    }

    return (
    // <>
    //   {
    //     loading 
    //     ? 
    //         <Loader />
    //     :
    //     <>
            <Container component="main" maxWidth="md" >
                <Card
                sx={{
                    marginTop: 8,
                    marginBottom: 3,
                    padding: "42px 32px 52px 32px",
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                elevation={2}
                >
                <Typography variant="h5" gutterBottom>
                    Contact Us
                </Typography>
                <Typography variant="body1" align="center" color="primary.main" gutterBottom>
                    We will get back to you asap!
                </Typography>
                <Box component="form" onSubmit={submitHandler} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} mb={1}>
                        <TextField
                        name="name"
                        label="Full name"
                        variant="outlined"
                        size="small"
                        type="text"
                        required
                        fullWidth
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} mb={1}>
                    <TextField
                        name="email"
                        label="Email"
                        variant="outlined"
                        size="small"
                        type="email"
                        required
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} mb={1}>
                    <TextField
                        name="subejct"
                        label="Subject"
                        variant="outlined"
                        size="small"
                        type="text"
                        required
                        fullWidth
                        value={subject}
                        inputProps={{ maxLength: 100 }}
                        onChange={(e) => setSubject(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} mb={1}>    
                        <TextField
                            name="message"
                            label="Message"
                            variant="outlined"
                            size="small"
                            type="text"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                            fullWidth
                            multiline
                            rows={6}
                            maxRows={12}
                            inputProps={{ maxLength: 300 }}
                        />
                        <Typography variant="caption" color="text.disabled" align="right" gutterBottom>
                            Max length 300 characters
                        </Typography>
                        {/* <TextField
                            name="message"
                            label="Message"
                            variant="outlined"
                            size="small"
                            type="text"
                            value="Max length should 300 characters"
                            required
                        /> */}
                    </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 4 }}>
                        <Button 
                            variant="contained"
                            fullWidth
                            type="submit"
                            disabled={loading}
                        >
                             {loading && (
                                <CircularProgress
                                    size={24}
                                    sx={{ position: "absolute", top: "50%", left: "50%", marginTop: "-12px", marginLeft: "-12px" }}
                                />
                                )}
                                Submit
                        </Button>
                    </Grid >
                   
                </Box> 
                </Card>
            </Container> 
        // </>
    //     }
    // </>
  )
}

export default NewTicketForm