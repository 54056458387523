import { useDispatch, useSelector } from 'react-redux';
import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react'
import Slot from './Slot';
import moment from 'moment';
import { UPDATE_ALERT } from '../../redux/constants/alertConstants';
import BackDropLoader from '../../components/ui/BackDropLoader';
import { STORE_SCHEDULE_ADD_RESET} from '../../redux/constants/storeConstants'
import { updateStoreSchedule, getScheduleDetails } from '../../redux/actions/storeActions';

const format = 'HH:mm';
const EditSlot = () => {
    const dispatch = useDispatch()
  // const [openSunday, setOpenSunday] = useState(true)
  // const [openMonday, setOpenMonday] = useState(true)
  // const [openTuesday, setOpenTuesday] = useState(true)
  // const [openWednesday, setOpenWednesday] = useState(true)
  // const [openThursday, setOpenThursday] = useState(true)
  // const [openFriday, setOpenFriday] = useState(true)
  // const [openSaturday, setOpenSaturday] = useState(true)  
  const [open, setOpen] = useState(true)  
//   const [schedule, setSchedule] = useState([
//     {
//       weekday: 0,
//       isWorkday: false,
//       startTime: "9:00",
//       endTime: "18:00"
//     },
//     {
//       weekday: 1,
//       isWorkday: true,
//       startTime: "9:00",
//       endTime: "18:00"
//     },
//     {
//       weekday: 2,
//       isWorkday: true,
//       startTime: "9:00",
//       endTime: "18:00"
//     },
//     {
//       weekday: 3,
//       isWorkday: true,
//       startTime: "9:00",
//       endTime: "18:00"
//     },
//     {
//       weekday: 4,
//       isWorkday: true,
//       startTime: "9:00",
//       endTime: "18:00"
//     },
//     {
//       weekday: 5,
//       isWorkday: true,
//       startTime: "9:00",
//       endTime: "18:00"
//     },
//     {
//       weekday: 6,
//       isWorkday: true,
//       startTime: "9:00",
//       endTime: "18:00"
//     }
// ])
  const [schedule, setSchedule] = useState([])

  const scheduleDetails = useSelector(state => state.scheduleDetails)
  const { loading: loadingDetails, error: errorDetails, schedule: storeSchedule } = scheduleDetails

  const scheduleAdd = useSelector(state => state.scheduleAdd)
  const { loading: loadingSchedule, error: errorSchedule, success: successSchedule } = scheduleAdd

useEffect(() => {
  dispatch(getScheduleDetails())
}, [dispatch])


useEffect(() => {

  if (storeSchedule) {
    setSchedule(storeSchedule)
  }
  //update sucess
    if (successSchedule) {
      dispatch({ 
        type: UPDATE_ALERT, 
        payload: {
          open: true, 
          severity: 'success', 
          message: 'Schedule updated successfully.' 
        } 
      })
      dispatch(getScheduleDetails())
      dispatch({ type: STORE_SCHEDULE_ADD_RESET })
  }
    // user location error
     if(errorSchedule) {
      dispatch({ 
        type: UPDATE_ALERT, 
        payload: {
            open: true, 
            severity: 'error', 
            message: errorSchedule
        } 
      })
    }
},[storeSchedule, successSchedule, errorSchedule]) 


const handleChange = ({weekday, open, startTime, endTime}) => {
  // console.log("Handle Change called: ")
  // console.log({weekday, open, startTime, endTime})
  setSchedule(  
    schedule.map(x => x.weekday === weekday 
      ? 
        {...x, 
          isWorkday: !open,
          startTime: startTime ? startTime && startTime.format(format): x.startTime,
          endTime: endTime ? endTime.format(format) : x.endTime
          // startTime: startTime ? startTime : x.startTime,
          // endTime: endTime ? endTime : x.endTime
        } 
      : x
      )
  )}

    const submitHandler = (e) => {
      e.preventDefault();
      if (schedule.length === 0) {
          dispatch({ 
            type: UPDATE_ALERT, 
            payload: {
                open: true, 
                severity: 'error', 
                message: 'Please fill all requried fields.' 
            } 
        })
    } else {
        dispatch(updateStoreSchedule({ schedule }))
    }    
  }

  return (
    <Box component="form" onSubmit={submitHandler}>
    {
      loadingSchedule || loadingDetails
      ? <BackDropLoader />
      :
      <Box>
        <Slot
        weekday={1}
        title="Monday" 
        open={schedule[1]?.isWorkday}
        // setOpen={setOpenMonday}
        setOpen={setOpen}
        startTime={schedule[1]?.startTime}
        endTime={schedule[1]?.endTime}
          handleChange={handleChange}
          />
        <Slot
        weekday={2}
        title="Tuesday" 
        open={schedule[2]?.isWorkday}
        // setOpen={setOpenTuesday}
        setOpen={setOpen}
        startTime={schedule[2]?.startTime}
        endTime={schedule[2]?.endTime}
          handleChange={handleChange}
        />
        <Slot
        weekday={3}
        title="Wednesday" 
        open={schedule[3]?.isWorkday}
        // setOpen={setOpenWednesday}
        setOpen={setOpen}
        startTime={schedule[3]?.startTime}
        endTime={schedule[3]?.endTime}
        handleChange={handleChange}
        />
        <Slot
        weekday={4}
        title="Thursday" 
        open={schedule[4]?.isWorkday}
        // setOpen={setOpenThursday}
        setOpen={setOpen}
        startTime={schedule[4]?.startTime}
        endTime={schedule[4]?.endTime}
        handleChange={handleChange}
        />
        <Slot
        weekday={5}
        title="Friday" 
        open={schedule[5]?.isWorkday}
        // setOpen={setOpenFriday}
        setOpen={setOpen}
        startTime={schedule[5]?.startTime}
        endTime={schedule[5]?.endTime}
          handleChange={handleChange}
        />
        <Slot
        weekday={6}
        title="Saturday" 
        open={schedule[6]?.isWorkday}
        // setOpen={setOpenSaturday}
        setOpen={setOpen}
        startTime={schedule[6]?.startTime}
        endTime={schedule[6]?.endTime}
        handleChange={handleChange}
        />
        <Slot
        weekday={0}
        title="Sunday" 
        open={schedule[0]?.isWorkday}
        // setOpen={setOpenSunday}
        setOpen={setOpen}
        startTime={schedule[0]?.startTime}
        endTime={schedule[0]?.endTime}
        handleChange={handleChange}
        />
        <Box display="flex" justifyContent="flex-end">
          <Button
          type="submit"
          variant="contained"
          size="large"
          style={{margin: '16px 0 8px 0'}}
          >
          Save Changes
          </Button>
        </Box>
       </Box> 
    }
  </Box>
  )
}

export default EditSlot