import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Avatar, Grid, Button, TextField, Box, Typography, Stack} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import MobileFriendlyRoundedIcon from '@mui/icons-material/MobileFriendlyRounded'
import Stepper from '../../components/ui/Stepper';
// import { gapi } from 'gapi-script'

import { getUserDetails, googleAuth, login, updateUserPhone, verifyUserPhone } from '../../redux/actions/userActions'

import AnimatedPage from '../../components/Layout/AnimatedPage'
import Loader from '../../components/ui/Loader'
import Alert from '../../components/ui/Alert'
import FormContainer from '../../components/ui/Form/FormContainer'
import {EditPhone, IntlPhoneInput} from '../../features/profile'

import roleBasedRedirect from '../../utils/roleBasedRedirect'
import { USER_PASSWORD_RESET_RESET, USER_REGISTER_RESET, USER_UPDATE_PHONE_RESET, USER_VERIFY_UPDATE_PHONE_RESET } from '../../redux/constants/userConstants'
import { UPDATE_ALERT } from '../../redux/constants/alertConstants';
import OtpInput from 'react-otp-input';

const PhoneVerification = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [phoneNumber, setPhoneNumber] = useState('') 
  const [otp, setOtp] = useState('') 
 
  const userDetails = useSelector(state => state.userDetails)
  const { loading: loadingUser, error: errorUser, user } = userDetails

  const userUpdatePhone = useSelector(state => state.userUpdatePhone)
  const { loading: loadingPhone, error: errorPhone, success: successPhone } = userUpdatePhone

  const userVerifyPhone = useSelector(state => state.userVerifyPhone)
  const { loading: loadingVerify, error: errorVerify, success: successVerify } = userVerifyPhone

  // useEffect(() => {
  //   //verify sucess
  //     if (user?.phone?.phoneNumber !== "" || !user?.phone?.isVerified) {
  //       navigate('/profile')
  //   }
  // },[user]) 

  useEffect(() => {
    //verify sucess
      if (successVerify) {
        // console.log("navi")
        // navigate("/dashboard")
        // dispatch(getUserDetails())
        dispatch({ type: USER_VERIFY_UPDATE_PHONE_RESET })
    }

  },[successVerify]) 

const handleOtpChange = (value) => {
  console.log({value})
  setOtp(value)
}

  const phoneSubmitHandler = (e, handleNext) => {
      e.preventDefault()
      if (!phoneNumber || phoneNumber.length < 12) {
        dispatch({ 
          type: UPDATE_ALERT, 
          payload: {
            open: true, 
            severity: 'error', 
            message: "Please enter a valid phone number."
          }
        })
      }
       else {
        dispatch(updateUserPhone({phoneNumber}))
        handleNext()
      }       
  }

  const codeSubmitHandler = (e) => {
      e.preventDefault();
   
      if (!otp) {
          dispatch({ 
            type: UPDATE_ALERT, 
            payload: {
                open: true, 
                severity: 'error', 
                message: "Please enter verification code."
            }
          })
      }
      else {
          dispatch(verifyUserPhone({phoneNumber, otp}))
      }       
  }

  const PhoneInput = ({ steps, activeStep, handleNext }) => {
    return(
      <Box   
        component="form" 
        onSubmit={(e) => phoneSubmitHandler(e, handleNext)} 
        // sx={{ background: '#000' }}
      >
        <IntlPhoneInput
          phoneNumber={phoneNumber}  
          setPhoneNumber={setPhoneNumber}
        />
        <Stack
        direction="row"
        sx={{ mt: 8, pb: 7, justifyContent: 'space-between' }}
        >
          <Button
            disabled={!activeStep}
            variant="outlined"
            color="primary"
            // onClick={handleBack}
            >
            Back
          </Button>
          <Button 
            disabled={activeStep === steps.length - 1} 
            // onClick={handleNext}
            type="submit"
            variant="contained"
          >
            Next
          </Button>
        </Stack>
      </Box>
    )
  }

  const CodeInput = ({ activeStep, handleBack }) => {
    console.log("render code input")
    return(
      <Box 
        component="form" 
        onSubmit={codeSubmitHandler} 
      >
      <OtpInput 
        value={otp} 
        onChange={handleOtpChange}
        // value={otp?.current?.value} 
        // ref={otp} 
        // ref={ n => otp = n }
        // autoFocus 
        // OTPLength={5} 
        otpType="number" 
        disabled={false} 
        // secure 
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px"
        }}
      />
      {/* <OtpInput
        value={otp}
        onChange={handleOtpChange}
        numInputs={6}
        separator={<span>-</span>}
      /> */}
      <Stack
      direction="row"
      sx={{ mt: 8, pb: 7, justifyContent: 'space-between' }}
      >
        <Button
          disabled={activeStep === 0} 
          onClick={handleBack}
          variant="outlined"
          color="primary"
        >
          Back
        </Button>
        <Button 
          // onClick={handleNext}
          variant="contained"
          type="submit"
        >
          Verify
        </Button>
      </Stack>
    </Box>
    )
  }

  return (
    <AnimatedPage>
      <FormContainer>
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <MobileFriendlyRoundedIcon />
          </Avatar>
          <Typography variant="h6">
            Phone Verification
          </Typography>
          {/* <Box 
            sx={{ 
              // background: "#000",
              maxWidth: "380px", margin: '0 auto', 
            }}
          >
            <Stepper
              StepOneLabel="Enter Phone"
              StepOneContent={PhoneInput}
              StepTwoLabel="Verify OTP"
              StepTwoContent={CodeInput}
              // StepThreeLabel="OTP"
              // StepThreeContent={TestInput}
            />
        </Box> */}
        <EditPhone from="/profile" />
      </FormContainer>
    </AnimatedPage>
  );
}

export default PhoneVerification