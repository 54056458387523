import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Grid, Button, TextField, Box, MenuItem } from '@mui/material'

import { getUserDetails, updateUserProfile } from '../../redux/actions/userActions'
import Loader from '../../components/ui/Loader'
import { USER_UPDATE_PROFILE_RESET } from '../../redux/constants/userConstants'
import { UPDATE_ALERT } from '../../redux/constants/alertConstants';


const EditProfile = () => {
  const dispatch = useDispatch()

  const [firstName, setFirstName] = useState('') 
  const [lastName, setLastName] = useState('') 
  const [gender, setGender] = useState('') 
 
  const userUpdateProfile = useSelector(state => state.userUpdateProfile)
  const { loading, error, success } = userUpdateProfile

  // const userDetails = useSelector(state => state.userDetails)
  // const { loading: loadingUser, error: errorUser, user } = userDetails

  const userLogin = useSelector(state => state.userLogin)
  const { loading: loadingUser, error: errorUser, userInfo: user } = userLogin

  // useEffect(() => {
  //   dispatch(getUserDetails())
  // }, [])

  useEffect(() => {
    if(user) {
      setFirstName(user?.firstName)
      setLastName(user?.lastName)
      setGender(user?.gender)
    }
  }, [])

useEffect(() => {
  //update sucess
    if (success) {
      dispatch({ 
        type: UPDATE_ALERT, 
        payload: {
          open: true, 
          severity: 'success', 
          message: 'Profile updated successfully.' 
        } 
      })
      dispatch(getUserDetails())
      dispatch({ type: USER_UPDATE_PROFILE_RESET })
  }

  // update error
    if(error) {
      dispatch({ 
        type: UPDATE_ALERT, 
        payload: {
            open: true, 
            severity: 'error', 
            message: error
        }
      })
    }

     // user details error
     if(errorUser) {
      dispatch({ 
        type: UPDATE_ALERT, 
        payload: {
            open: true, 
            severity: 'error', 
            message: errorUser
        } 
      })
    }
},[success, error, errorUser]) 

  const submitHandler = (e) => {
      e.preventDefault();
      if (!firstName || !lastName) {
          dispatch({ 
            type: UPDATE_ALERT, 
            payload: {
                open: true, 
                severity: 'error', 
                message: 'Please fill all requried fields.' 
            } 
        })
    } else {
        dispatch(updateUserProfile({ firstName, lastName, gender }))
    }    
  }

  return (
        <Box component="form" onSubmit={submitHandler} sx={{ maxWidth: "480px", margin: '0 auto', mt: 5}}>
            {(loading || loadingUser)  && <Loader />}
            <Grid container spacing={2}>
              <Grid item xs={12} mb={1}>
                <TextField
                  name="firstName"
                  label="First Name"
                  autoFocus
                  variant="outlined"
                  type="text"
                  required
                  fullWidth
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} mb={1}>
                <TextField
                  name="lastName"
                  label="Last Name"
                  variant="outlined"
                  type="text"
                  required
                  fullWidth
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                    <TextField
                        id="outlined-select-gender"
                        select
                        label="Gender"
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                        fullWidth
                        >
                            <MenuItem value="male" >
                                Male
                            </MenuItem>
                            <MenuItem value="female" >
                                Female
                            </MenuItem>
                            
                    </TextField>
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="flex-end" mt={2}>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  style={{margin: '16px 0 8px 0'}}
                >
                Save Changes
              </Button>
              </Box>
        </Box>
  );
}

export default EditProfile