import { useMemo, useState } from 'react';
import { Stack } from '@mui/material';
import { DataGrid, gridClasses, GridToolbar } from '@mui/x-data-grid';
import moment from 'moment';
import { grey } from '@mui/material/colors';
import TextButton from '../../../components/ui/TextButton';

const TransactionsTable = ({transactions}) => {

    const [pageSize, setPageSize] = useState(10);
    const [rowId, setRowId] = useState(null);
  
    const NoRowsOverlay = () => {
        return (
          <Stack height="100%" alignItems="center" justifyContent="center">
            No transactions available
          </Stack>
        );
      }
    
      const columns = useMemo(
        () => [
          {
            field: 'ID',
            headerName: 'ID',
            width: 100,
            valueGetter: (params) => "T-"+params.row.id,
            sortable: false,
          },
          { 
            field: 'aptId', 
            headerName: 'APT ID', 
            width: 140, 
            sortable: false,
            valueGetter: (params) => {
              let aptID;
              if (params.row.appointmentId) {
                  if (params.row.appointmentId.id) {
                  aptID = "APT-"+params.row.appointmentId.id
                  }
              } else {
                  aptID = "";
              }
              return aptID;
              }
        },
        { 
        field: 'store',
        headerName: 'Barber Name', 
        width: 180,
        valueGetter: (params) => {
            let storeName;
            if (params.row.storeId) {
                storeName = params.row.storeId.name
              } else {
                storeName = ""
            }
            return storeName;
          }
        },
        {
          field: 'amount',
          headerName: 'Amount ($)',
          width: 140,
          valueGetter: (params) => params.row.amount + " " + params.row.currency
          // valueGetter: (params) => {
          //   let amount;
          //   if (params.row.amount > 0) {
          //       amount = "$"+params.row.amount
          //   } else {
          //       amount = params.row.amount.toString().substring(0, 1) + "$" + params.row.amount.toString().substring(1) 
          //   }
          //   return amount;
          //   }
        },
        {
          field: 'Purpose',
          headerName: 'Purpose',
          width: 180,
          sortable: false,
          filterable: false,
          renderCell: (params) => {
            let status, color="primary", disabled=false
            if (params.row.purpose === "appointment_fee") {
              status = "Appointment fee"
              color = "primary"
            }
            else if (params.row.purpose === "commission") {
              status = "Comission"
              color = "success"
              disabled=true
            } 
            else if (params.row.purpose === "reschedule_fee") {
              status = "Reschedule Fee"
              color = "warning"
            } 
            else if (params.row.purpose === "withdrawal")  {
              status = "Withdraw"
              color = "primary"
            }
            else if (params.row.purpose === "refund")  {
              status = "Refund"
              color = "success"
            }
            else if (params.row.purpose === "deposit")  {
              status = "Deposit"
              color = "primary"
            }
            else if (params.row.purpose === "cancellation_fee")  {
              status = "Cancel Fee"
              color = "error"
            }
            else if (params.row.purpose === "reschedule_fee")  {
              status = "Reschedule Fee"
              color = "error"
            }
            else status = ""
            return (
              <TextButton 
              variant="outlined" 
              size="small"
              color={color}
              disabled={disabled}
              >
                  {status}
              </TextButton>
              // status
            )
          },
          },
        {
          field: 'paymentMethod',
          headerName: 'Method',
          width: 140,
        },  
          {
              field: 'createdAt',
              headerName: 'Date & Time',
              width: 200,
              renderCell: (params) =>
              moment(params.row.createdAt).format('YYYY-MM-DD HH:MM:SS'),
            },        
            {
            field: 'status',
            headerName: 'Status',
            width: 100,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
              let status, color="primary", disabled=false
              if (params.row.paymentStatus === "successful") {
                status = "Successful"
                color = "success"
              }
              else if (params.row.paymentStatus === "pending") {
                status = "Pending"
                color = "warning"
                disabled=true
              } 
              else if (params.row.paymentStatus === "failed")  {
                status = "Failed"
                color = "error"
              }
              else status = ""
              return (
                <TextButton 
                variant="outlined" 
                size="small"
                color={color}
                disabled={disabled}
                >
                    {status}
                </TextButton>
                // status
              )
            }
            },
        //   {
        //     field: 'actions',
        //     headerName: 'Actions',
        //     type: 'actions',
        //     renderCell: (params) => (
        //       <UsersActions {...{ params, rowId, setRowId }} />
        //     ),
        //   },
        ],
        [rowId]
      );
  return (
    <DataGrid
    columns={columns}
    rows={transactions ? transactions: []}
    getRowId={(row) => row._id}
    rowsPerPageOptions={[5, 10, 20]}
    pageSize={pageSize}
    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
    components={{
      NoRowsOverlay,
      Toolbar: GridToolbar
    }}
    getRowSpacing={(params) => ({
    top: params.isFirstVisible ? 0 : 5,
    bottom: params.isLastVisible ? 0 : 5,
    })}
    sx={{
    [`& .${gridClasses.row}`]: {
        bgcolor: (theme) =>
        theme.palette.mode === 'light' ? grey[200] : grey[900],
    },
    }}
    onCellEditCommit={(params) => setRowId(params.id)}
/>
  )
}

export default TransactionsTable