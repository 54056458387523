import {
    // USER_DELETE_FAIL,
    // USER_DELETE_REQUEST,
    // USER_DELETE_SUCCESS,
    // USER_DETAILS_FAIL,
    // USER_DETAILS_REQUEST,
    // USER_DETAILS_RESET,
    // USER_DETAILS_SUCCESS,
    // USER_LIST_FAIL,
    // USER_LIST_REQUEST,
    // USER_LIST_RESET,
    // USER_LIST_SUCCESS,
    BARBER_LOGIN_FAIL,
    BARBER_LOGIN_REQUEST,
    BARBER_LOGIN_SUCCESS,
    BARBER_LOGOUT,
    BARBER_REGISTER_FAIL,
    BARBER_REGISTER_REQUEST,
    BARBER_REGISTER_SUCCESS,
    BARBER_REGISTER_RESET,
    BARBER_VERIFY_REQUEST,
    BARBER_VERIFY_SUCCESS,
    BARBER_VERIFY_FAIL,
    BARBER_VERIFY_RESET,
    BARBER_REGISTER_STORE_REQUEST,
    BARBER_REGISTER_STORE_SUCCESS,
    BARBER_REGISTER_STORE_FAIL,
    BARBER_REGISTER_STORE_RESET,
} from '../constants/barberConstants'


export const barberLoginReducer = (state = {}, action) => {
    switch (action.type) {
        case BARBER_LOGIN_REQUEST:
            return { loading: true}
        case BARBER_LOGIN_SUCCESS:
            return { loading: false, userInfo: action.payload }
        case BARBER_LOGIN_FAIL:
            return { loading: false, error: action.payload }
        case BARBER_LOGOUT:
            return {}
        default:
            return state
    }
}

export const barberRegisterReducer = (state = {}, action) => {
    switch (action.type) {
        case BARBER_REGISTER_REQUEST:
            return { loading: true}
        case BARBER_REGISTER_SUCCESS:
            return { loading: false, success: true, accessToken: action.payload }
        case BARBER_REGISTER_FAIL:
            return { loading: false, error: action.payload }
        case BARBER_REGISTER_RESET:
            return { success: false }
        default:
            return state
    }
}

export const barberVerifyReducer = (state = {}, action) => {
    switch (action.type) {
        case BARBER_VERIFY_REQUEST:
            return { loading: true}
        case BARBER_VERIFY_SUCCESS:
            return { loading: false, success: true }
        case BARBER_VERIFY_FAIL:
            return { loading: false, error: action.payload }
        case BARBER_VERIFY_RESET:
            return { success: false }
        default:
            return state
    }
}
