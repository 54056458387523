import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom'
import axios from 'axios'
import { PayPalButton } from 'react-paypal-button-v2'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js"
import { Box, Card, CardMedia, Divider, Typography, Button } from '@mui/material'

import { styled } from "@mui/material/styles"
import PaypalOrder from '../../../features/payment/PaypalOrder'
import Loader from '../Loader';

import { rescheduleAppointmentWithPaypal, rescheduleAppointmentWithWallet } from '../../../redux/actions/appointmentActions'
import { CART_EMPTY } from '../../../redux/constants/cartConstants'
import { savePaymentMethod } from '../../../redux/actions/cartActions'
import AnimatedPage from '../../Layout/AnimatedPage'
import ROLES from '../../../constants/roleConstants'
import { UPDATE_ALERT } from '../../../redux/constants/alertConstants'
import BackDropLoader from '../BackDropLoader'
import { getUserWalletDetails } from '../../../redux/actions/userActions';

const StyledBox = styled(Box)(({ theme }) => ({
   display: "flex",
   justifyContent: "space-between",
   margin: 14,
   gap: 140,
  }))

const Checkout = (
        {
          activeStep,
          handleBack,
          handleNext,  
        }
    ) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams()
    const location = useLocation()
    const [sdkReady, setSdkReady] = useState(false)
    const [paymentMethod, setPaymentMethod] = useState('paypal')

    // const initialOptions = {
    //     "client-id": "test",
    //     currency: "USD",
    //     intent: "capture",
    //     "data-client-token": "abc123xyz==",
    // };

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const appointmentDetails = useSelector(state => state.appointmentDetails)
    const { appointment } = appointmentDetails

    const appointmentRescheduleFee = useSelector(state => state.appointmentRescheduleFee)
    const { loading: loadingFee, error: errorFee, fee } = appointmentRescheduleFee

    const appointmentReschedule = useSelector(state => state.appointmentReschedule)
    const { loading: loadingReschedule, error: errorReschedule, success: successReschedule } = appointmentReschedule

    const appointmentRescheduleWallet = useSelector(state => state.appointmentRescheduleWallet)
    const { loading: loadingRescheduleWallet, error: errorRescheduleWallet, success: successRescheduleWallet } = appointmentRescheduleWallet

    const userWalletDetails = useSelector(state => state.userWalletDetails)
    const { loading: loadingWallet, wallet } = userWalletDetails

    const cart = useSelector(state => state.cart)

    const { cartItems } = cart
    var totalAmount = cartItems.reduce((acc, item) => acc + item.amount, 0) || 0

    // const appointmentAdd = useSelector(state => state.appointmentAdd)
    // const { loading, error, success } = appointmentAdd

    const addPayPalScript = async () => {
        // const { data: clientId } = await axios.get('/api/config/paypal')

        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = `https://www.paypal.com/sdk/js?currency=AUD&client-id=sb&disable-funding=venmo,credit`
        script.async = true
        script.onload = () => {
          setSdkReady(true)
        }
        document.body.appendChild(script)
      }

      useEffect(() => {
          addPayPalScript()
          dispatch(savePaymentMethod(paymentMethod))
          dispatch(getUserWalletDetails())
      }, [])

      useEffect(() => {
        if(successReschedule ||  successRescheduleWallet) {
            dispatch({ type: CART_EMPTY })
            dispatch({ 
                type: UPDATE_ALERT, 
                payload: {
                open: true, 
                severity: 'success', 
                message: 'Appointment rescheduled successfully.' 
                } 
            })
            navigate('/bookings')
        }

        if(errorRescheduleWallet) {
            dispatch({ 
                type: UPDATE_ALERT, 
                payload: {
                open: true, 
                severity: 'error', 
                message: errorRescheduleWallet
                } 
            })
        }
        
        if(errorReschedule) {
            dispatch({ 
                type: UPDATE_ALERT, 
                payload: {
                open: true, 
                severity: 'error', 
                message: errorReschedule
                } 
            })
        }
    }, [successReschedule, successRescheduleWallet, errorRescheduleWallet, errorReschedule])

      const paypalPaymentHandler = (paymentResult) => {
            dispatch(rescheduleAppointmentWithPaypal({
                id,
                appointmentDate: cart.appointmentDate,
                appointmentTime: cart.appointmentTime,
                paypalPaymentResult: {
                    paymentId: paymentResult.id,
                    payerEmail: paymentResult.payer.email_address,
                    payerId: paymentResult.payer.payer_id,
                    payerPhone: paymentResult.payer.phone.phone_number.national_number,
                    amount: paymentResult.purchase_units[0].amount.value,
                    currency: paymentResult.purchase_units[0].amount.currency_code,
                    updateTime: paymentResult.update_time,
                    status: paymentResult.status
                }
            }))
        // dispatch(payOrder(orderId, paymentResult))
      }

      const walletPaymentHandler = () => {
        dispatch(rescheduleAppointmentWithWallet({
            id,
            appointmentDate: cart.appointmentDate,
            appointmentTime: cart.appointmentTime
        }))   
  }
    

    return (
    <>
    {
        loadingReschedule || loadingRescheduleWallet
        ? <BackDropLoader />
        :
        <AnimatedPage>
            <Card sx={{p: 1, mt: 2, mb: 4}} elevation={1}>
                {/* <Box display="flex" alignItems="center">
                    <CardMedia 
                        sx={{ height: 40, width: 40  }}
                        image="/images/barber-pic.jpg"
                    /> */}
            <Typography mx={2} variant="body1" fontWeight="bold" align="center" color="primary">Appointment Details</Typography>
            </Card>
            <StyledBox>
                <Typography flex={1} variant="body2" fontWeight="bold">Appointment ID</Typography>
                <Typography align="right">APT-{appointment.id}</Typography>
            </StyledBox>
            <Divider />
            {(userInfo?.role === ROLES.BARBER) &&
                <>
                    <StyledBox>
                        <Typography flex={1} variant="body2"  fontWeight="bold">Client Name</Typography>
                        <Typography align="right">{appointment?.clientId?.firstName} {appointment?.clientId?.lastName}</Typography>
                    </StyledBox>
                    <Divider />
                </>
            }
            {(userInfo?.role === ROLES.CLIENT) &&
                <>
                    <StyledBox>
                        <Typography flex={1} variant="body2" fontWeight="bold">Barber Name</Typography>
                        <Typography align="right">{appointment?.storeId?.name}</Typography>
                    </StyledBox>
                    <Divider />
                </>
            }
            {/* Appointment Date */} 
            <StyledBox>
                <Typography variant="body2" fontWeight="bold">Appointment Date</Typography>
                <Typography>{cart.appointmentDate.toString().substring(0,15)}</Typography>
            </StyledBox>
            <Divider />

            {/* Appointment Time */}
            <StyledBox>
                <Typography variant="body2" fontWeight="bold">Appointment Time</Typography>
                <Typography>{cart.appointmentTime}</Typography>
            </StyledBox>
            <Divider />
            
            {/* Total Amount*/}
            <StyledBox>
                <Typography flex={1} variant="body2" fontWeight="bold">Reschedule Fee</Typography>
                <Typography variant="h6" color="primary" align="right" fontWeight="bold">${fee?.rescheduleFee}</Typography>
            </StyledBox>
            <Divider />

            <StyledBox>
                <Typography flex={1} variant="body2" fontWeight="bold">Select Payment Method:</Typography>
            </StyledBox>
                <Button
                    variant="contained"
                    fullWidth
                    size="medium"
                    sx={{ 
                        marginBottom: '16px',
                        textTransform: "none",
                        fontSize: '18px'
                    }}
                    disabled={wallet?.balance < fee?.rescheduleFee}
                    onClick={walletPaymentHandler}
                >
                    Wallet
                </Button>
                    {!sdkReady 
                        ? 
                            <Loader />
                        : 
                        <PayPalButton
                            shippingPreference="NO_SHIPPING"
                            currency="AUD"
                            amount={fee?.rescheduleFee}
                            onSuccess={paypalPaymentHandler}

                            // options={{
                            //     clientId: "test",
                            //     disableFunding: "none",
                            //   }}
                        />
                    }

            <Box style={{display:"flex", justifyContent: "flex-end", gap: "10px", marginTop: "20px"}}>
                <Button 
                    disabled={activeStep === 0 || cart?.appointmentTime == ''} 
                    onClick={handleBack}
                    variant="outlined"
                >
                    Back
                </Button>
                {/* <Button  
                variant="contained" 
                color="primary" 
                disabled
                >
                Next
                </Button> */}
            </Box>
        </AnimatedPage> 
    }  
    </>
    )
}

export default Checkout