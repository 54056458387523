import { Typography } from '@mui/material'
import React from 'react'
import AnimatedPage from '../../components/Layout/AnimatedPage'
import Stepper from '../../components/ui/Stepper'
import PersonalDetails from '../../features/registerBarber/PersonalDetails'
import StoreDetails from '../../features/registerBarber/StoreDetails'
import Verification from '../../features/registerBarber/Verification'
import Schedule from '../../features/registerBarber/Sechedule'

const Register = () => {

   
    //   const CodeInput = ({ activeStep, handleBack }) => {
    //     console.log("render code input")
    //     return(
    //       <Box 
    //         component="form" 
    //         onSubmit={codeSubmitHandler} 
    //       >
    //       <OTPInput 
    //         value={otp} 
    //         onChange={handleOtpChange}
    //         // value={otp?.current?.value} 
    //         // ref={otp} 
    //         // ref={ n => otp = n }
    //         autoFocus 
    //         // OTPLength={5} 
    //         otpType="number" 
    //         disabled={false} 
    //         // secure 
    //         style={{
    //           display: "flex",
    //           justifyContent: "center",
    //           marginBottom: "20px"
    //         }}
    //       />
    //       {/* <OtpInput
    //         value={otp}
    //         onChange={handleOtpChange}
    //         numInputs={6}
    //         separator={<span>-</span>}
    //       /> */}
    //       <Stack
    //       direction="row"
    //       sx={{ mt: 8, pb: 7, justifyContent: 'space-between' }}
    //       >
    //         <Button
    //           disabled={activeStep === 0} 
    //           onClick={handleBack}
    //           variant="outlined"
    //           color="primary"
    //         >
    //           Back
    //         </Button>
    //         <Button 
    //           // onClick={handleNext}
    //           variant="contained"
    //           type="submit"
    //         >
    //           Verify
    //         </Button>
    //       </Stack>
    //     </Box>
    //     )
    //   }
    const Test = () => {
        return <Typography>tEST</Typography>
    }
      
  return (
    <AnimatedPage>
      <Stepper
        StepOneLabel="Personal Details"
        StepOneContent={PersonalDetails}
        StepTwoLabel="Verification"
        StepTwoContent={Verification}
        StepThreeLabel="Store Details"
        StepThreeContent={StoreDetails}
        StepFourLabel="Schedule"
        StepFourContent={Schedule}
      />
    </AnimatedPage>
  )
}

export default Register