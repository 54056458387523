import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Avatar, Grid, Button, TextField, Box, Typography } from '@mui/material'
// import GoogleIcon from '@mui/icons-material/Google'

// import { gapi } from 'gapi-script'

import { resetUserPassword, verifyResetPasswordCode } from '../../redux/actions/userActions'

import AnimatedPage from '../../components/Layout/AnimatedPage'
import Loader from '../../components/ui/Loader'
import Alert from '../../components/ui/Alert'
import FormContainer from '../../components/ui/Form/FormContainer'
import Password from '../../components/ui/Form/Password'

import roleBasedRedirect from '../../utils/roleBasedRedirect'
import { USER_PASSWORD_RESET_RESET, USER_REGISTER_RESET } from '../../redux/constants/userConstants'
import { UPDATE_ALERT } from '../../redux/constants/alertConstants'

import OTPInput, { ResendOTP } from "otp-input-react";


const ResetPassword = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const [code, setCode] = useState('')
  const [message, setMessage] = useState(null)
  const [openAlert, setOpenAlert] = useState(false) 

  const userPasswordReset = useSelector(state => state.userPasswordReset)
  const { success } = userPasswordReset
 
  const userVerifyResetCode = useSelector(state => state.userVerifyResetCode)
  const { loading, error, token } = userVerifyResetCode

  useEffect(() => {
    if (token)
        navigate('/new-password')
        // dispatch(logout())
},[token])  

useEffect(() => {
  if (success) {
    dispatch({ 
      type: UPDATE_ALERT, 
      payload: {
          open: true, 
          severity: 'success', 
          message: 'Password reset code sent to your phone.' 
      } 
  })
    dispatch({ type: USER_PASSWORD_RESET_RESET })
}
  if(error) {
    setMessage(error)
    setOpenAlert(true)
  }
},[error, success]) 

  const submitHandler = (e) => {
      e.preventDefault();
    //   if (!email || !password) {
    //     setMessage('Please fill in all fileds.')
    //     setOpenAlert(true)
    // } else {
        setMessage(null)
        setOpenAlert(false)
        // dispatch({type: USER_REGISTER_RESET})
        dispatch(verifyResetPasswordCode(code))
    // }    
  }

  return (
    <AnimatedPage>
      <FormContainer>
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          </Avatar>
          <Typography variant="h6">
            Otp Verification
          </Typography>
          <Box component="form" onSubmit={submitHandler} sx={{ mt: 3 }}>
            {loading && <Loader />}
            {message  &&
             <Alert 
                type="error" 
                message={message}
                openAlert={openAlert}
                setOpenAlert={setOpenAlert}
              />
            }
            {/* <Grid container> */}
              {/* <Grid item xs={12} mb={1}>
                <TextField
                  name="Code"
                  label="Code"
                  autoFocus
                  variant="outlined"
                  size="small"
                  type="text"
                  maxL
                  required
                  fullWidth
                  onChange={(e) => setCode(e.target.value)}
                />
              </Grid> */}
             
              <OTPInput 
                value={code} 
                onChange={setCode} 
                OTPLength={4} 
                otpType="number" 
                disabled={false} 
                // secure 
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "20px"
                }}
              />
              {/* <ResendOTP 
                onResendClick={() => console.log("Resend clicked")} 
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "20px"
                }}
              /> */}
            {/* </Grid> */}
            <Box display="flex" justifyContent="flex-end">
              <Button
                type="submit"
                variant="contained"
                fullWidth
                style={{margin: '16px 0 8px 0'}}
              >
                Verify
              </Button>
            </Box>
          </Box>
      </FormContainer>
    </AnimatedPage>
  );
}

export default ResetPassword