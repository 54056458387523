import { useEffect, useMemo, useState } from 'react'
import { Avatar, Box, Button, Typography, Stack } from '@mui/material'
import { DataGrid, gridClasses } from '@mui/x-data-grid'
import moment from 'moment'
import { grey } from '@mui/material/colors'
import { useDispatch, useSelector } from 'react-redux'
import BackDropLoader from '../../../components/ui/BackDropLoader'
import TextButton from '../../../components/ui/TextButton'
import { Link } from 'react-router-dom'
import TicketsTable from '../../../features/support/components/TicketsTable'
import { listUserTickets } from '../../../redux/actions/ticketActions'
import AddIcon from '@mui/icons-material/Add'
import { useTheme } from "@mui/material/styles"
import { TICKET_ADD_RESET, TICKET_MARK_RESOLVED_RESET } from '../../../redux/constants/ticketConstants';

const Tickets = () => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const ticketUserList = useSelector(state => state.ticketUserList)
    const { loading, error, tickets } = ticketUserList

    
    const ticketMarkResolved = useSelector(state => state.ticketMarkResolved)
    const { success: successResolved } = ticketMarkResolved

  useEffect(() => {
    dispatch(listUserTickets())
    dispatch({ type: TICKET_ADD_RESET })
  }, []);

  useEffect(() => {
    if (successResolved) dispatch({ type: TICKET_MARK_RESOLVED_RESET })
  }, [successResolved]);

  return (
    <>
    {
        loading 
        ? <BackDropLoader />
        :
        <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            // background: theme.palette.custom.lightgray,
            padding: "8px",
            // marginBottom: "18px",
            // [theme.breakpoints.down('md')]: {
            //   justifyContent: "center",
            // },
          }}
          >
          <Link to='/support/new-ticket' style={{ textDecoration: 'none' }}>
            <Button 
              variant="contained"
              style={{margin: "0 10px"}}
              startIcon={<AddIcon />}
              >
                Add New Ticket
            </Button>
          </Link>
        </Box>
        <Box
        sx={{
            height: '60vh',
            width: '100%',
        }}
        >
        <Typography
            variant="h4"
            component="h4"
            sx={{ textAlign: 'center', mt: 3, mb: 3 }}
        >
            Tickets List
        </Typography>
        {/* <DataGrid
            columns={columns}
            rows={appointments}
            getRowId={(row) => row._id}
            rowsPerPageOptions={[5, 10, 20]}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            components={{ NoRowsOverlay }}
            getRowSpacing={(params) => ({
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
            })}
            sx={{
            [`& .${gridClasses.row}`]: {
                bgcolor: (theme) =>
                theme.palette.mode === 'light' ? grey[200] : grey[900],
            },
            }}
            onCellEditCommit={(params) => setRowId(params.id)}
        /> */}
        <TicketsTable tickets={tickets} />
        </Box>
        </>
    }
    </>
  );
};

export default Tickets;

