import React from 'react';
import './Footer.css';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='section'>
      <div className='footer-container'>
        <section className='footer-subscription'>
          <p className='footer-subscription-heading'>
            Join our newsletter to receive latest updates
          </p>
          <p className='footer-subscription-text'>
            You can unsubscribe at any time.
          </p>
          <div style={{display: "flex", alignItems: "center" }}>
            <form>
              <input
                className='footer-input'
                name='email'
                type='email'
                placeholder='Your Email'
              />
              {/* sx={{background: "#009be5"}} */}
              {/* sx={{background: "#3282B8"}} */}
              {/* sx={{background: "#009be5"}} */}
              <Button variant="contained" color="primary" >Subscribe</Button> 
            </form>
          </div>
        </section>
        <div class='footer-links'>
          <div className='footer-link-wrapper'>
            <div class='footer-link-items'>
              <h2>About Us</h2>
              <Link to='/sign-up'>Sign Up</Link>
              <Link to='/sign-in'>Login</Link>
              <Link to='/privacy-policy'>Privacy Policy</Link>
              <Link to='/terms-and-conditions'>Terms and Conditions</Link>
            </div>
            <div class='footer-link-items'>
              <h2>Contact Us</h2>
              <Link to='/contact-us'>Send Message</Link>
              <Link to='/'>Support</Link>
              <Link to='/'>Email: support@barbarly.com</Link>
            </div>
          </div>
          <div className='footer-link-wrapper'>
            <div class='footer-link-items'>
              <h2>Videos</h2>
              <Link to='/'>How it works</Link>
              <Link to='/'>For Clients</Link>
              <Link to='/'>For Barbers</Link>
            </div>
            <div class='footer-link-items'>
              <h2>Social Media</h2>
              <Link to='/'> <i class='fab fa-instagram' /> &nbsp; Instagram</Link>
              <Link to='/'> <i class='fab fa-facebook' /> &nbsp; Facebook</Link>
              <Link to='/'> <i class='fab fa-youtube' /> &nbsp; Youtube</Link>
              <Link to='/'> <i class='fab fa-twitter' /> &nbsp; Twitter</Link>
            </div>
          </div>
        </div>
        <div class='website-rights-wrapper'>
          <p class='website-rights'>Barbarly © 2023</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;