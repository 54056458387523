import { Avatar, Box, Typography } from '@mui/material';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'timeago.js'
import ROLES from '../../../constants/roleConstants'
import { useTheme } from '@mui/material/styles'
import supportIcon from '../../../images/support-icon.png'

const Message = ({message}) => {
    const theme = useTheme()
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const getUserRole = (role) => {
        if (role === ROLES.BARBER || role === ROLES.CLIENT) return "user"
        else return "support"
    }

  return (
        <Box 
            sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: getUserRole(userInfo?.role) === message?.sender
                                ? "flex-end"
                                : "flex-start",
                // background: "#333",
                gap: 1.5
            }}>
                {
                    message?.sender === "support"
                    ?  <Avatar >B</Avatar>
                    : <Avatar src={userInfo.avatar}></Avatar>
                }
 
                <Box 
                    sx={{ 
                        display: "flex", 
                        flexDirection: "column",
                        justifyContent: "flex-end",
                        padding: "6px 14px",
                        borderRadius: "12px",
                        // background: (message.sender === 'user') ? '#FFF' : '',
                        background: getUserRole(userInfo?.role) === message?.sender
                                    ? theme.palette.primary.main
                                    : theme.palette.custom.backgroundColor,
                        width: "max-content", 
                    }}>
                    {/* {
                        message.sender === 'support'
                        ?
                            <Typography fontWeight="bold">{(userInfo?.role === ROLES.ADMIN) ? "You" : "Support"}</Typography>
                        :
                            <Typography fontWeight="bold">{(userInfo?.role === ROLES.ADMIN) ? "User" : "You"}</Typography>
                    } */}
                    <Typography 
                        sx={{
                            color: getUserRole(userInfo?.role) === message?.sender
                                    ? "white"
                                    : "black"
                        }}
                    >
                        {message?.text}
                    </Typography>
                    <Typography 
                        sx={{
                            color: getUserRole(userInfo?.role) === message?.sender
                                    ? "white"
                                    : "black"
                        }}
                        align="right" 
                        variant="caption"
                    >
                        {format(message.createdAt)}
                    </Typography>
                </Box>
        </Box>
  )
}

export default Message