import { useEffect, useMemo, useState } from 'react';
import { Avatar, Box, Button, Stack, Typography } from '@mui/material';
import { DataGrid, gridClasses, GridToolbar } from '@mui/x-data-grid';
import moment from 'moment';
import { grey } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import { listTransactions } from '../../../redux/actions/transactionActions';
import BackDropLoader from '../../../components/ui/BackDropLoader';
import TextButton from '../../../components/ui/TextButton';
import { getContactMessages } from '../../../redux/actions/contactActions';
import ContactActions from './ContactActions';
import ContactModal from './ContactModal';

const Transactions = () => {
    const dispatch = useDispatch()
    const contactMessageList  = useSelector(state => state.contactMessageList )
    const { loading, error, messages } = contactMessageList 

  const [pageSize, setPageSize] = useState(10);
  const [rowId, setRowId] = useState(null);

  const [openModal, setOpenModal] = useState(false)

  const NoRowsOverlay = () => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        No messages available
      </Stack>
    );
  }

  useEffect(() => {
        dispatch(getContactMessages())
  }, []);

  const columns = useMemo(
    () => [
      { 
        field: 'name', 
        headerName: 'Name', 
        width: 180, 
        sortable: false,
        valueGetter: (params) => params.row.name
    },
    { 
        field: 'email',
        headerName: 'Email', 
        width: 240,
        valueGetter: (params) => params.row.email
    },
    { 
        field: 'subject',
        headerName: 'Subject', 
        width: 440,
        valueGetter: (params) => params.row.subject
    },
    {
        field: 'responded',
        headerName: 'Responded',
        width: 120,
        // filterable: false,
        type: 'boolean',
        editable: true
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 300,
        type: 'actions',
        renderCell: (params) => ( 
               <ContactActions {...{ params, rowId, setRowId, setOpenModal }} />
        //   <BarbersActions {...{ params, rowId, setRowId }} />
        ),
      },
    ],
    [rowId]
  );

  return (
    <>
    {
        loading 
        ? <BackDropLoader />
        :
        <Box
        sx={{
            height: '70vh',
            width: '100%',
        }}
        >
        <Typography
            variant="h4"
            component="h4"
            sx={{ textAlign: 'center', mt: 3, mb: 3 }}
        >
            Messages List
        </Typography>
        <DataGrid
            columns={columns}
            rows={messages}
            getRowId={(row) => row._id}
            rowsPerPageOptions={[5, 10, 20]}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            components={{
              NoRowsOverlay,
              Toolbar: GridToolbar
            }}
            getRowSpacing={(params) => ({
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
            })}
            sx={{
            [`& .${gridClasses.row}`]: {
                bgcolor: (theme) =>
                theme.palette.mode === 'light' ? grey[200] : grey[900],
            },
            }}
            onCellEditCommit={(params) => setRowId(params.id)}
        />
        </Box>
    }
        <ContactModal 
          openModal={openModal}
          onClose={ () => setOpenModal(false)}
        />
    </>
  );
};

export default Transactions;