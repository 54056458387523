import { 
    PAYPAL_EMAIL_UPDATE_FAIL,
    PAYPAL_EMAIL_UPDATE_REQUEST,
    PAYPAL_EMAIL_UPDATE_RESET,
    PAYPAL_EMAIL_UPDATE_SUCCESS,
    PAYPAL_EMAIL_UPDATE_VERIFY_FAIL,
    PAYPAL_EMAIL_UPDATE_VERIFY_REQUEST,
    PAYPAL_EMAIL_UPDATE_VERIFY_RESET,
    PAYPAL_EMAIL_UPDATE_VERIFY_SUCCESS,
    PAYPAL_WITHDRAW_FAIL,
    PAYPAL_WITHDRAW_REQUEST,
    PAYPAL_WITHDRAW_RESET,
    PAYPAL_WITHDRAW_SUCCESS,
    PAYPAL_WITHDRAW_VERIFY_FAIL,
    PAYPAL_WITHDRAW_VERIFY_REQUEST,
    PAYPAL_WITHDRAW_VERIFY_RESET,
    PAYPAL_WITHDRAW_VERIFY_SUCCESS,
    WALLET_PAYPAL_DETAILS_FAIL, 
    WALLET_PAYPAL_DETAILS_REQUEST, 
    WALLET_PAYPAL_DETAILS_SUCCESS 
} from "../constants/walletConstants";


export const paypalDetailsReducer = (state = { paypal: {} }, action) => {
    switch (action.type) {
        case WALLET_PAYPAL_DETAILS_REQUEST:
            return { loading: true}
        case WALLET_PAYPAL_DETAILS_SUCCESS:
            return { loading: false, paypal: action.payload }
        case WALLET_PAYPAL_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const paypalUpdateEmailReducer = (state = { }, action) => {
    switch (action.type) {
        case PAYPAL_EMAIL_UPDATE_REQUEST:
            return { loading: true}
        case PAYPAL_EMAIL_UPDATE_SUCCESS:
            return { loading: false, success: true }
        case PAYPAL_EMAIL_UPDATE_FAIL:
            return { loading: false, error: action.payload }
        case PAYPAL_EMAIL_UPDATE_RESET:
            return { success: false }
        default:
            return state
    }
}

export const paypalUpdateEmailVerifyReducer = (state = { }, action) => {
    switch (action.type) {
        case PAYPAL_EMAIL_UPDATE_VERIFY_REQUEST:
            return { loading: true}
        case PAYPAL_EMAIL_UPDATE_VERIFY_SUCCESS:
            return { loading: false, success: true }
        case PAYPAL_EMAIL_UPDATE_VERIFY_FAIL:
            return { loading: false, error: action.payload }
        case PAYPAL_EMAIL_UPDATE_VERIFY_RESET:
            return { success: false }
        default:
            return state
    }
}



export const withdrawAmountReducer = (state = { }, action) => {
    switch (action.type) {
        case PAYPAL_WITHDRAW_REQUEST:
            return { loading: true}
        case PAYPAL_WITHDRAW_SUCCESS:
            return { loading: false, success: true }
        case PAYPAL_WITHDRAW_FAIL:
            return { loading: false, error: action.payload }
        case PAYPAL_WITHDRAW_RESET:
            return { success: false }
        default:
            return state
    }
}

export const withdrawAmountVerifyReducer = (state = { }, action) => {
    switch (action.type) {
        case PAYPAL_WITHDRAW_VERIFY_REQUEST:
            return { loading: true}
        case PAYPAL_WITHDRAW_VERIFY_SUCCESS:
            return { loading: false, success: true }
        case PAYPAL_WITHDRAW_VERIFY_FAIL:
            return { loading: false, error: action.payload }
        case PAYPAL_WITHDRAW_VERIFY_RESET:
            return { success: false }
        default:
            return state
    }
}
