import * as React from 'react'
import Typography from '@mui/material/Typography'

const Title = ({ heading, subHeading }) => {
  return (
    <>
    <Typography sx={{ fontSize: "18px" }} my={2}>{heading}</Typography>
    {subHeading && 
      <Typography color="primary" sx={{ fontSize: "14px" }} my={2}>{subHeading}</Typography>
    }
    </>
  )
}

export default Title;