import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Grid, Paper, Box, Typography, Stack, useTheme, List, Button} from '@mui/material'

import PeopleIcon from '@mui/icons-material/People';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import AnimatedPage from '../../components/Layout/AnimatedPage'
import { Title, DataWidget, BarChart, LineChart, AppointmentsList } from '../../features/dashboard/index'
import Appointments from './Appointments'
import Transactions from './Transactions'

import { 
  listServices
 } from '../../redux/actions/storeActions'

import { 
  getUserWalletDetails
 } from '../../redux/actions/userActions'
import BackDropLoader from '../../components/ui/BackDropLoader';
import StyledAvatar from '../../features/dashboard/components/StyledAvatar';
import { Link } from 'react-router-dom';
import { listBarberAppointments, listBarberUpcomingAppointments } from '../../redux/actions/appointmentActions';


const barChartData = [
  {
    month: 'Jan',
    income: 200,
  },
  {
    month: 'Feb',
    income: 230,
  },
  {
    month: 'Mar',
    income: 250,
  },
  {
    month: 'Apr',
    income: 220,
  },
  {
    month: 'May',
    income: 280,
  },
  {
    month: 'Jun',
    income: 300,
  },
  {
    month: 'Jul',
    income: 290,
  },
  {
    month: 'Aug',
    income: 350,
  },
  {
    month: 'Sep',
    income: 320,
  },
  {
    month: 'Oct',
    income: 500,
  },
  {
    month: 'Nov',
    income: 450,
  },
];

// Generate Clients Data
function createData(month, noOfClients) {
  return { month, noOfClients };
}

const lineChartData = [
  createData('Jan', 0),
  createData('Feb', 8),
  createData('Mar', 10),
  createData('Apr', 8),
  createData('May', 6),
  createData('Jun', 7),
  createData('Jul', 10),
  createData('Aug', 9),
  createData('Sep', 15),
  createData('Oct', 16),
  createData('Nov', 17),
];


const Dashboard = () => {
  const dispatch = useDispatch()
  // const [loading, setLoading] = useState(false)
  // const [upcomingAppointments, setUpcomingAppointments] = useState([])

  const appointmentBarberUpcomingList = useSelector(state => state.appointmentBarberUpcomingList)
  const { loading: loadingAppointments, error, appointments } = appointmentBarberUpcomingList

  const userWalletDetails = useSelector(state => state.userWalletDetails)
  const { loading: loadingWallet, wallet } = userWalletDetails

  const serviceList = useSelector(state => state.serviceList)
  const {  loading: loadingServices, services } = serviceList

  // Add if appointment is completed
  // var totalEarnings = appointments?.reduce((acc, item) => 
  //                       item.status === "completed" && acc + item.totalPrice, 0) || 0

  // Calc unique clients
  // var totalUniqueClients = appointments?.filter(function (item, index, arr) {
  //   // console.log(arr[index].clientId._id)
  //   // console.log(item.clientId._id)
  //   return arr[index].clientId?._id.indexOf(item.clientId?._id) === index 
  // })

  // var totalServices = services?.reduce((acc, item) => acc + item.subCategories?.length, 0) || 0

  useEffect(() => {
    dispatch(listServices())
    dispatch(listBarberUpcomingAppointments())
    dispatch(getUserWalletDetails())
  },[])


  // useEffect(() => {
  //   const getUpcomingAppointments = () => {
  //     return appointments?.filter(item => item.status === "upcoming" || item.status === "rescheduled")
  //   }
  //   setLoading(true)
  //   setUpcomingAppointments(getUpcomingAppointments())
  //   setLoading(false)
  // },[appointments])



  return (
    <AnimatedPage>
      <>
      {(loadingAppointments || loadingWallet || loadingServices) 
        ? <BackDropLoader /> 
        : 
        <Box
        sx={{
          display: { xs: 'flex', md: 'grid' },
          gridTemplateColumns: 'repeat(4,1fr)',
          gridAutoRows: 'minmax(100px, auto)',
          gap: 3,
          textAlign: 'center',
          flexDirection: 'column',
        }}
      >
        <DataWidget 
          title="Wallet Balance" 
          value={wallet?.balance ? `$${wallet.balance}` : "$0"}
          Icon={<PaymentsOutlinedIcon color="primary" sx={{ height: 45, width: 90, opacity: 0.4 }} />  }
        />
        <DataWidget 
          title="Upcoming Appointments" 
          value={appointments?.length}
          Icon={<CalendarMonthIcon color="success" sx={{ height: 45, width: 90, opacity: 0.4 }} />  }
        />
        <DataWidget 
          title="Total Services" 
          value={services?.length}
          Icon={<ContentCutIcon color="warning" sx={{ height: 40, width: 80, opacity: 0.4 }} />  }
        />
        <Paper elevation={3} sx={{ p: 2, gridColumn: 4, gridRow: '1/4' }}>
          <Box>
            {/* <Typography variant='h6' gutterBottom>Recent Appointments</Typography> */}
            <Title heading="Upcoming Appointments"/>
            {
              appointments?.length === 0
                ? <p>No appointments available</p>
                :
                <List>
              {appointments?.slice(0, 8).map((item) => (
                  <Paper
                    elevation={3}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                      padding: '14px 8px',
                      marginBottom: "22px"
                    }}
                      key={item._id}
                    >
                    <Box
                      // sx={{
                      //   display: 'flex',
                      //   marginRight: '0px',
                      //   paddingLeft: '0px',
                      //   alignItems: 'center',
                      //   alignContent: 'center',
                      // }}
                    >
                      <StyledAvatar src={item?.clientId?.avatar} />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1  }}>
                      <Typography
                        component="span"
                        variant="body2"
                        fontWeight="bold"
                        // sx={{
                        //   fontFamily: 'inherit',
                        //   display: 'block',
                        //   whiteSpace: 'nowrap',
                        //   lineHeight: 'inherit',
                        //   fontWeight: 500
                        // }}
                      >
                        {item.clientId?.firstName}  {item.clientId?.lastName}
                      </Typography>
                      <Typography
                        component="span"
                        variant="body2"
                        // sx={{
                        //   display: 'block',
                        //   whiteSpace: 'nowrap',
                        //   lineHeight: 'inherit',
                        // }}
                      >
                        {item.appointmentDate.substring(0, 10)} ({item.appointmentTime})
                      </Typography>
                    </Box>
                    <Link to={`/appointment/${item._id}`} style={{textDecoration: 'none'}}>
                          <Button variant='contained' size="small">View</Button>
                    </Link>
                </Paper>
              ))}
            </List>
            }
            
          </Box>
          {/* <Divider sx={{ mt: 3, mb: 3, opacity: 0.7 }} /> */}
        </Paper>
        <Paper elevation={3} sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              paddingBottom: "24px",
              flexWrap: 'wrap',
              gridColumn: '1/4'
            }}>
            <BarChart title="Income Overview" data={barChartData} xAxisKey="month" yAxisKey="income" />
        </Paper>
        <Paper elevation={3} sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              paddingBottom: "24px",
              flexWrap: 'wrap',
              gridColumn: '1/4'
            }}>
            <LineChart title="Clients Overview" data={lineChartData} xAxisKey="month" lineKey="noOfClients" />
        </Paper>
        </Box>
      }
    </>
    </AnimatedPage>
  )
}

export default Dashboard