import DashboardOutlined from '@mui/icons-material/DashboardOutlined';
import Person from '@mui/icons-material/Person';
import Forum from '@mui/icons-material/Forum';
import Analytics from '@mui/icons-material/Analytics';
import FolderOpen from '@mui/icons-material/FolderOpen';
import BorderColor from '@mui/icons-material/BorderColor';
import Search from '@mui/icons-material/Search';

import ListItemIcon from '@mui/material/ListItemIcon';
import HomeIcon from '@mui/icons-material/Home';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MessageIcon from '@mui/icons-material/Message';
import ReceiptIcon from '@mui/icons-material/Receipt';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import GroupIcon from '@mui/icons-material/Group';
import BarChartIcon from '@mui/icons-material/BarChart';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';

const barberNavList = [
  { 
    text: 'Dashboard', 
    icon: DashboardOutlined, 
    path: '/barber-dashboard',
    badge: 0 
  },
  { 
    text: 'Appointments', 
    icon: CalendarMonthIcon, 
    path: '/barber-appointments' 
  },
  { 
      text: 'Messages', 
      icon: MessageIcon, 
      path: '/messages',
      // badge: 2
    },
  { 
    text: 'Transactions', 
    icon: ReceiptIcon, 
    path: '/barber-transactions' 
  },
  { 
    text: 'My Profile', 
    icon: ReceiptIcon, 
    path: '/profile' 
  },
  { 
    text: 'Store Management', 
    icon: StorefrontIcon, 
    path: '/store-management' 
  },
  // { 
  //   text: 'Services', 
  //   icon: ContentCutIcon, 
  //   path: '/barber-services' 
  // },
  // { 
  //   text: 'Settings', 
  //   icon: SettingsIcon, 
  //   path: '/barber-setting' 
  // },
  { 
    text: 'Support', 
    icon: ContactSupportOutlinedIcon, 
    path: '/support' 
  },
];

const customerNavList = [
  { 
    text: 'Dashboard', 
    icon: DashboardOutlined, 
    path: '/dashboard',
    badge: 0 
  },
  { 
    text: 'Appointments', 
    icon: CalendarMonthIcon, 
    path: '/bookings' 
  },
  { 
      text: 'Messages', 
      icon: MessageIcon, 
      path: '/messages',
      // badge: 2
    },
  { 
    text: 'Transactions', 
    icon: ReceiptIcon, 
    path: '/transactions' 
  },
  { 
    text: 'My Profile', 
    icon: AccountCircleIcon, 
    path: '/profile' 
  },
  { 
    text: 'Support', 
    icon: ContactSupportOutlinedIcon, 
    path: '/support' 
  },
];

const adminNavList = [
  { 
    text: 'Dashboard', 
    icon: DashboardOutlined, 
    path: '/admin-dashboard',
    badge: 0 
  },
  { 
    text: 'Barbers', 
    icon: ContentCutIcon, 
    path: '/admin-barbers-list' 
  },
  { 
    text: 'Users', 
    icon: GroupIcon, 
    path: '/admin-users-list' 
  },
  { 
    text: 'Appointments', 
    icon: CalendarMonthIcon, 
    path: '/admin-appointments' 
  },
  { 
    text: 'Transactions', 
    icon: ReceiptIcon, 
    path: '/admin-transactions' 
  },
  { 
    text: 'Analytics', 
    icon: BarChartIcon, 
    path: '/admin-analytics' 
  },
  // { 
  //     text: 'Messages', 
  //     icon: MessageIcon, 
  //     path: '/admin-messages',
  //     // badge: 2

  //   },
  { 
    text: 'Messages', 
    icon: MessageIcon, 
    path: '/admin-contact-messages' 
  },
    { 
      text: 'Support', 
      icon: ContactSupportOutlinedIcon, 
      path: '/admin-support' 
    },
    { 
      text: 'Profile', 
      icon: AccountCircleIcon, 
      path: '/profile' 
    },
 
  // { 
  //   text: 'Settings', 
  //   icon: SettingsIcon, 
  //   path: '/admin-setting' 
  // },
];

export {
  barberNavList,
  customerNavList,
  adminNavList
}