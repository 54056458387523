import { SHOW_FEEDBACK } from "../constants/localConstants";
import { UPDATE_TAB } from "../constants/localConstants"

//TAB
export const tabReducer = (state = { tab: 0 }, action) => {
    switch (action.type) {
        case UPDATE_TAB:
            return { tab: action.payload }
        default:
            return state
    }
}
