import { useEffect, useMemo, useState } from 'react';
import { Avatar, Box, Button, Typography, Stack } from '@mui/material';
import { DataGrid, gridClasses, GridToolbar } from '@mui/x-data-grid';
import moment from 'moment';
import { grey } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import { listBarberAppointments, listBarberAppointmentsAdmin } from '../../../redux/actions/appointmentActions';
import BackDropLoader from '../../../components/ui/BackDropLoader';
import TextButton from '../../../components/ui/TextButton';
import { Link, useParams } from 'react-router-dom';

const Transactions = () => {
    const dispatch = useDispatch()
    const { id } = useParams()
    const appointmentBarberList = useSelector(state => state.appointmentBarberList)
  const { loading, error, appointments } = appointmentBarberList

  const [pageSize, setPageSize] = useState(10);
  const [rowId, setRowId] = useState(null);

  useEffect(() => {
    dispatch(listBarberAppointmentsAdmin(id))
  }, []);

  const NoRowsOverlay = () => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        No appointments available
      </Stack>
    );
  }
  const columns = useMemo(
    () => [
      {
        field: 'ID',
        headerName: 'ID',
        width: 140,
        valueGetter: (params) => "APT-"+params.row.id,
        sortable: false,
      },
    { 
    field: 'client',
    headerName: 'Client Name', 
    width: 180,
    valueGetter: (params) => {
        let clientName;
        if (params.row.clientId) {
            clientName = params.row.clientId.firstName + " " + params.row.clientId.lastName
        } else {
            clientName = "Barbarly User"
        }
        return clientName;
      }
    },
    {
      field: 'amount',
      headerName: 'Amount (AUD)',
      width: 120,
      valueGetter: (params) => "$" + params.row.totalPrice 
    },
    {
      field: 'services',
      headerName: 'Services',
      width: 80,
      valueGetter: (params) => params.row.services?.length
    },
    {
      field: 'appointmentDate',
      headerName: 'Date',
      width: 140,
      // sortable: false,
      filterable: false,
      valueGetter: (params) => params.row.appointmentDate?.toString().substring(0, 10)
      // moment(params.row.appointmentDate).format('YYYY-MM-DD')
      },
    {
      field: 'appointmentTime',
      headerName: 'Time',
      width: 100,
      sortable: false,
      filterable: false,
      valueGetter: (params) => params.row.appointmentTime
      },
    // {
    //   field: 'isReviewed',
    //   headerName: 'Reviewed',
    //   type: "boolean",
    //   width: 140,
    // },        
        {
        field: 'status',
        headerName: 'Status',
        width: 140,
        filterable: false,
        // sortable: false,
        renderCell: (params) => {
          let status, color="primary", disabled=false
          if (params.row.status === "upcoming") {
            status = "Upcoming"
            color = "primary"
          }
          else if (params.row.status === "delivered") {
            status = "Delivered"
            color = "primary"
          } 
          else if (params.row.status === "completed") {
            status = "Completed"
            color = "success"
          } 
          else if (params.row.status === "cancelled")  {
            status = "Cancelled"
            color = "error"
          }
          else if (params.row.status === "rescheduled")  {
            status = "Rescheduled"
            color = "warning"
          }
          else status = ""
          return (
            <TextButton 
            variant="outlined" 
            size="small"
            color={color}
            disabled={disabled}
            >
                {status}
            </TextButton>
            // status
          )
        }
        },
      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        renderCell: (params) => (
            <Link to={`/appointment/${params.row._id}`} style={{textDecoration: 'none'}}>
                <Button variant='contained' size="small">View</Button>
            </Link>
        ),
      },
    ],
    [rowId]
  );

  return (
    <>
    {
        loading 
        ? <BackDropLoader />
        :
        <Box
        sx={{
            height: '70vh',
            width: '100%',
        }}
        >
        <DataGrid
            columns={columns}
            rows={appointments || []}
            getRowId={(row) => row._id}
            rowsPerPageOptions={[5, 10, 20]}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            components={{ 
              NoRowsOverlay,
              Toolbar: GridToolbar
            
            }}
            getRowSpacing={(params) => ({
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
            })}
            sx={{
            [`& .${gridClasses.row}`]: {
                bgcolor: (theme) =>
                theme.palette.mode === 'light' ? grey[200] : grey[900],
            },
            }}
            onCellEditCommit={(params) => setRowId(params.id)}
        />
        </Box>
    }
    </>
  );
};

export default Transactions;