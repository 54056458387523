import { removeFromCart } from '../actions/cartActions';
import {
    CART_ADD_ITEM,
    CART_REMOVE_ITEM,
    CART_SAVE_PAYMENT_METHOD,
    CART_SAVE_DATE,
    CART_SAVE_TIME,
    CART_EMPTY,
    CART_SAVE_BARBER
} from '../constants/cartConstants'

export const cartReducer = (
        state = { cartItems: [], storeDetails: {} },
        action
    ) => {
    switch (action.type) {
        case CART_ADD_ITEM:
            const item = action.payload            
            return {
                        ...state,
                        cartItems: [...state.cartItems, item]
                    }
        case CART_REMOVE_ITEM:
            return {
                ...state,
                cartItems: state.cartItems.filter(x => x.serviceId !== action.payload)
            }
        case CART_SAVE_BARBER:
            return {
                ...state,
                storeDetails: action.payload
            }
        case CART_SAVE_DATE:
            return {
                ...state,
                appointmentDate: action.payload
            }
        case CART_SAVE_TIME:
            return {
                ...state,
                appointmentTime: action.payload
            }
        case CART_SAVE_PAYMENT_METHOD:
            return {
                ...state,
                paymentMethod: action.payload
            }
        case CART_EMPTY:
            return {
                ...state,
                cartItems: [],
                storeDetails: {},
                appointmentDate: null,
                appointmentTime: null,
                paymentMethod: null
            }
        default:
            return state
    }
    
}