import {
    STORE_SERVICE_CATEGORY_ADD_FAIL,
    STORE_SERVICE_CATEGORY_ADD_REQUEST,
    STORE_SERVICE_CATEGORY_ADD_SUCCESS,
    STORE_SERVICE_ADD_FAIL,
    STORE_SERVICE_ADD_REQUEST,
    STORE_SERVICE_ADD_SUCCESS,
    STORE_SERVICE_LIST_FAIL,
    STORE_SERVICE_LIST_REQUEST,
    STORE_SERVICE_LIST_SUCCESS,
    STORE_DETAILS_REQUEST,
    STORE_DETAILS_SUCCESS,
    STORE_DETAILS_FAIL,
    STORE_SERVICE_DELETE_REQUEST,
    STORE_SERVICE_DELETE_SUCCESS,
    STORE_SERVICE_DELETE_FAIL,
    STORE_SERVICE_ADD_RESET,
    STORE_SERVICE_CATEGORY_ADD_RESET,
    STORE_SERVICE_DELETE_RESET,
    STORE_SERVICE_EDIT_REQUEST,
    STORE_SERVICE_EDIT_SUCCESS,
    STORE_SERVICE_EDIT_FAIL,
    STORE_SERVICE_EDIT_RESET,
    STORE_SERVICE_DETAILS_REQUEST,
    STORE_SERVICE_DETAILS_SUCCESS,
    STORE_SERVICE_DETAILS_FAIL,
    STORE_SCHEDULE_ADD_REQUEST,
    STORE_SCHEDULE_ADD_SUCCESS,
    STORE_SCHEDULE_ADD_FAIL,
    STORE_SCHEDULE_ADD_RESET,
    STORE_SERVICE_CATEGORY_LIST_REQUEST,
    STORE_SERVICE_CATEGORY_LIST_SUCCESS,
    STORE_SERVICE_CATEGORY_LIST_FAIL,
    STORE_ADD_REQUEST,
    STORE_ADD_SUCCESS,
    STORE_ADD_FAIL,
    STORE_ADD_RESET,
    STORE_SCHEDULE_DETAILS_REQUEST,
    STORE_SCHEDULE_DETAILS_SUCCESS,
    STORE_SCHEDULE_DETAILS_FAIL,
    STORE_DETAILS_UPDATE_REQUEST,
    STORE_DETAILS_UPDATE_SUCCESS,
    STORE_DETAILS_UPDATE_FAIL,
    STORE_DETAILS_UPDATE_RESET,
    STORE_DETAILS_VIEW_REQUEST,
    STORE_DETAILS_VIEW_SUCCESS,
    STORE_DETAILS_VIEW_FAIL
    
} from '../constants/storeConstants'

//STORE
export const registerStoreReducer = (state = {}, action) => {
    switch (action.type) {
        case STORE_ADD_REQUEST:
            return { loading: true}
        case STORE_ADD_SUCCESS:
            return { loading: false, success: true }
        case STORE_ADD_FAIL:
            return { loading: false, error: action.payload }
        case STORE_ADD_RESET:
            return { success: false }
        default:
            return state
    }
}

export const storeDetailsReducer = (state = { store: {} }, action) => {
    switch (action.type) {
        case STORE_DETAILS_REQUEST:
            return { loading: true}
        case STORE_DETAILS_SUCCESS:
            return { loading: false, store: action.payload }
        case STORE_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

//CATEGORY 

export const serviceCategoryListReducer = (state = { categories: [] }, action) => {
    switch (action.type) {
        case STORE_SERVICE_CATEGORY_LIST_REQUEST:
            return { loading: true}
        case STORE_SERVICE_CATEGORY_LIST_SUCCESS:
            return { loading: false, categories: action.payload }
        case STORE_SERVICE_CATEGORY_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const serviceCategoryAddReducer = (state = {}, action) => {
    switch (action.type) {
        case STORE_SERVICE_CATEGORY_ADD_REQUEST:
            return { loading: true}
        case STORE_SERVICE_CATEGORY_ADD_SUCCESS:
            return { loading: false, success: true }
        case STORE_SERVICE_CATEGORY_ADD_FAIL:
            return { loading: false, error: action.payload }
        case STORE_SERVICE_CATEGORY_ADD_RESET:
            return {}
        default:
            return state
    }
}

//SERVICE
export const serviceDetailsReducer = (state = { service: {} }, action) => {
    switch (action.type) {
        case STORE_SERVICE_DETAILS_REQUEST:
            return { loading: true}
        case STORE_SERVICE_DETAILS_SUCCESS:
            return { loading: false, service: action.payload }
        case STORE_SERVICE_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const serviceAddReducer = (state = {}, action) => {
    switch (action.type) {
        case STORE_SERVICE_ADD_REQUEST:
            return { loading: true}
        case STORE_SERVICE_ADD_SUCCESS:
            return { loading: false, success: true }
        case STORE_SERVICE_ADD_FAIL:
            return { loading: false, error: action.payload }
        case STORE_SERVICE_ADD_RESET:
            return {}
        default:
            return state
    }
}

export const serviceEditReducer = (state = {}, action) => {
    switch (action.type) {
        case STORE_SERVICE_EDIT_REQUEST:
            return { loading: true}
        case STORE_SERVICE_EDIT_SUCCESS:
            return { loading: false, success: true }
        case STORE_SERVICE_EDIT_FAIL:
            return { loading: false, error: action.payload }
        case STORE_SERVICE_EDIT_RESET:
            return {}
        default:
            return state
    }
}

export const serviceListReducer = (state = { services: [] }, action) => {
    switch (action.type) {
        case STORE_SERVICE_LIST_REQUEST:
            return { loading: true}
        case STORE_SERVICE_LIST_SUCCESS:
            return { loading: false, services: action.payload }
        case STORE_SERVICE_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const serviceDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case STORE_SERVICE_DELETE_REQUEST:
            return { loading: true}
        case STORE_SERVICE_DELETE_SUCCESS:
            return { loading: false, success: true }
        case STORE_SERVICE_DELETE_FAIL:
            return { loading: false, error: action.payload }
        case STORE_SERVICE_DELETE_RESET:
            return {}
        default:
            return state
    }
}

//SCHEDULE 
export const scheduleDetailsReducer = (state = { schedule: [] }, action) => {
    switch (action.type) {
        case STORE_SCHEDULE_DETAILS_REQUEST:
            return { loading: true}
        case STORE_SCHEDULE_DETAILS_SUCCESS:
            return { loading: false, schedule: action.payload }
        case STORE_SCHEDULE_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const scheduleAddReducer = (state = {}, action) => {
    switch (action.type) {
        case STORE_SCHEDULE_ADD_REQUEST:
            return { loading: true}
        case STORE_SCHEDULE_ADD_SUCCESS:
            return { loading: false, success: true }
        case STORE_SCHEDULE_ADD_FAIL:
            return { loading: false, error: action.payload }
        case STORE_SCHEDULE_ADD_RESET:
            return { success: false }
        default:
            return state
    }
}

// Admin
export const storeDetailsUpdateReducer = (state = { }, action) => {
    switch (action.type) {
        case STORE_DETAILS_UPDATE_REQUEST:
            return { loading: true}
        case STORE_DETAILS_UPDATE_SUCCESS:
            return { loading: false, success: true }
        case STORE_DETAILS_UPDATE_FAIL:
            return { loading: false, error: action.payload }
        case STORE_DETAILS_UPDATE_RESET:
            return { success: false }
    default:
            return state
    }
}


export const storeAndBarberDetailsReducer = (state = { data: {} }, action) => {
    switch (action.type) {
        case STORE_DETAILS_VIEW_REQUEST:
            return { loading: true}
        case STORE_DETAILS_VIEW_SUCCESS:
            return { loading: false, data: action.payload }
        case STORE_DETAILS_VIEW_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}