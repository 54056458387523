import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ = ({ faq }) => {
  return (
    <div style={{ paddingBottom: "24px"}}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
           <Typography
              sx={{
                fontSize: "16px",
                color: "#000339",
                fontWeight: "600",
              }}
            >
              {faq?.question}
            </Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Typography
              sx={{
                fontSize: "16px",
                color: "#000339",
                fontWeight: "400",
              }}
            >
              {faq?.answer}
            </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default FAQ;