import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { 
  Avatar, Grid, Button, TextField, 
  FormControlLabel, Checkbox, Box, 
  Typography
 } from '@mui/material'
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import FormContainer from '../../components/ui/Form/FormContainer';
import Password from '../../components/ui/Form/Password'
import Loader from '../../components/ui/Loader'
import Alert from '../../components/ui/Alert'

import AnimatedPage from '../../components/Layout/AnimatedPage'
import { register } from '../../redux/actions/userActions'
import roleBasedRedirect from '../../utils/roleBasedRedirect';

const SignUp = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  // const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [message, setMessage] = useState(null);
  // const [openMessage, setOpenMessage] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);  

  const userRegister = useSelector(state => state.userRegister)
  const { loading, error, success } = userRegister

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const from = location.state?.from?.pathname || roleBasedRedirect({role: userInfo?.role})

    // const redirect = location.search ? location.search.split('=')[1] : '/sign-in'
    // console.log({from, redirect})
    useEffect(() => {
      if(success) {
        navigate('/phone-verification')
      }
      if (error){
        setMessage(error)
        setOpenAlert(true)
      }
          // dispatch(logout())
  },[success, error])  

  const submitHandler = (e) => {
      e.preventDefault();
      if (password.length < 6) {
        setMessage('Password must be of at least 6 characters.')
        setOpenAlert(true)
      }
      else if (password !== confirmPassword) {
          setMessage('Password do not match.')
          setOpenAlert(true)
      } else {
          setMessage(null)
          setOpenAlert(false)
          dispatch(register({firstName, lastName, email, password}))
      }      
  }

  return (
    <AnimatedPage>
      <FormContainer >
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
           <PersonAddIcon />
          </Avatar>
          <Typography variant="h6">
            Sign up
          </Typography>
          <Box component="form" onSubmit={submitHandler} sx={{ mt: 3 }}>
            {loading && <Loader />}
            {message  &&
             <Alert 
                type="error" 
                message={message}
                openAlert={openAlert}
                setOpenAlert={setOpenAlert}
              />
            }

            {/* {error && 
              <Alert 
                type="error" 
                message={error}
                openAlert={openAlert}
                setOpenAlert={setOpenAlert}
              />
            } */}

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} mb={1}>
                <TextField
                  name="firstName"
                  label="First Name"
                  autoFocus
                  variant="outlined"
                  size="small"
                  type="text"
                  required
                  fullWidth
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} mb={1}>
              <TextField
                  name="lastName"
                  label="Last Name"
                  variant="outlined"
                  size="small"
                  type="text"
                  required
                  fullWidth
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} mb={1}>
              <TextField
                  name="email"
                  label="Email"
                  variant="outlined"
                  size="small"
                  type="email"
                  required
                  fullWidth
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              {/* <Grid item xs={12}>
              <TextField
                  name="phone"
                  label="Phone Number"
                  variant="outlined"
                  size="small"
                  type="text"
                  required
                  fullWidth
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Grid> */}
              <Grid item xs={12} mb={1}>
                <Password password={password} setPassword={setPassword} />
              </Grid>
              <Grid item xs={12} mb={1}>
                <Password label="Confirm Password" password={confirmPassword} setPassword={setConfirmPassword} />
              </Grid>
              {/* <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" required/>}
                  label="I've read all the terms and conditions"
                  onClick={handleOpen}
                />
              </Grid> */}

              {/* <Grid item>
                <Checkbox value="allowExtraEmails" color="primary" required/>
                <Button variant='text' onClick={handleOpen} sx={{mt: 4}}>I've read all the terms and conditions</Button>
              </Grid>
              <Modal
                sx={style}
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                  <Box>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                      Text in a modal
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                    </Typography>
                    <Button onClick={handleClose}>Close</Button>
                  </Box>
                </Modal> */}
              </Grid>


            <Button
              type="submit"
              fullWidth
              color="primary"
              variant="contained"
              style={{margin: '16px 0 8px 0'}}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to="/sign-in" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
        </Box>
      </FormContainer>
    </AnimatedPage>
  );
}

export default SignUp