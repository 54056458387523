import React from 'react'
import Typography from '@material-ui/core/Typography'

import { Title } from '../../features/dashboard/index';

const Messages = () => {
  return (
    <Title>
        Messages
    </Title>
  )
}

export default Messages