import axios from 'axios'
import {
    TRANSACTION_ADMIN_LIST_FAIL,
    TRANSACTION_ADMIN_LIST_REQUEST,
    TRANSACTION_ADMIN_LIST_SUCCESS,
    TRANSACTION_ADMIN_VERIFY_FAIL,
    TRANSACTION_ADMIN_VERIFY_REQUEST,
    TRANSACTION_ADMIN_VERIFY_SUCCESS,
    TRANSACTION_LIST_FAIL,
    TRANSACTION_LIST_REQUEST,
    TRANSACTION_LIST_SUCCESS,
    TRANSACTION_USER_LIST_FAIL,
    TRANSACTION_USER_LIST_REQUEST,
    TRANSACTION_USER_LIST_SUCCESS,
     
 } from '../constants/transactionConstants'


export const listUserTransactions = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: TRANSACTION_USER_LIST_REQUEST,
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.get('/api/transactions/client', config)

        dispatch({
            type: TRANSACTION_USER_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: TRANSACTION_USER_LIST_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const listBarberTransactions = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: TRANSACTION_USER_LIST_REQUEST,
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.get('/api/transactions/barber', config)

        dispatch({
            type: TRANSACTION_USER_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: TRANSACTION_USER_LIST_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}

export const listTransactions = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: TRANSACTION_ADMIN_LIST_REQUEST,
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.get('/api/transactions', config)

        dispatch({
            type: TRANSACTION_ADMIN_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: TRANSACTION_ADMIN_LIST_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}


export const verifyTransaction = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: TRANSACTION_ADMIN_VERIFY_REQUEST,
        })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.accessToken}`
            },
        }

        const { data } = await axios.patch(`/api/transactions/verify/${id}`, {}, config)
        dispatch({
            type: TRANSACTION_ADMIN_VERIFY_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
                    type: TRANSACTION_ADMIN_VERIFY_FAIL,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message
                })
    }
}