import React from 'react'
import { Link } from 'react-router-dom'
import { Box, Typography, Button, Card, CardMedia, CardContent, CardActions, Chip, Rating } from '@mui/material';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';

import useStyles from './styles.js';

const PlaceDetails = ({ place }) => {
  const classes = useStyles();

  // Scroll to current element
  // if (selected) refProp?.current?.scrollIntoView({ behavior: "smooth", block: "start" })

  return (
    <Card elevation={2}>
      <CardMedia
        style={{ height: 200, objectFit: 'cover' }}
        image={place?.owner?.avatar ? place?.owner?.avatar : '/images/noAvatar.png'}
        // image={place?.owner?.avatar}
        title={place.name}
      />
      <CardContent className={classes.content}>
        <Typography variant="h6">{place.name}</Typography>
        <Box gutterBottom display="flex" justifyContent="space-between">
          <Rating name="half-rating" value={place?.rating} precision={0.5} readOnly />
          <Typography component="legend">
            {place.numReviews < 1 ? 'No' : place.numReviews} reviews</Typography>
        </Box>
        {/* <Box gutterBottom display="flex" justifyContent="space-between">
          <Typography component="legend">{place.ranking}</Typography>
        </Box> */}
      </CardContent>
      <CardActions sx={{margin: "10px 6px"}}>
        {/* <Button size="large" color="primary" onClick={() => window.open(place.website, '_blank')}> */}
        <Link to={`/barber/${place._id}`} style={{ textDecoration: "none", width: "100%"}}>
          <Button 
           sx={{padding: "8px 12px"}}
            size="small" 
            color="primary" 
            variant="contained" 
            fullWidth
          >
            Book Now
          </Button>
        </Link>

        {/* <Link to={`/#`} style={{ textDecoration: "none"}}>
          <Button size="small" color="primary" variant="outlined">
            More Info
          </Button>
        </Link> */}
      </CardActions>
    </Card>
  );
};

export default PlaceDetails;