import { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'
// import ChatOnline from '../../components/chatOnline/ChatOnline';
import { Message, Conversation } from '../index'
import './messenger.css'
import { useDispatch, useSelector } from 'react-redux'

import { io } from 'socket.io-client'
import { Box, Typography } from '@mui/material'
import Title from '../../dashboard/components/Title'
import BackDropLoader from '../../../components/ui/BackDropLoader'

const ENDPOINT = 
        window.location.host.indexOf("localhost") >= 0
            ? "http://127.0.0.1:5000"
            : window.location.host
        
const Messenger = () => {
    // const dispatch = useDispatch()
    const [conversations, setConversations] = useState([])
    const [currentChat, setCurrentChat] = useState(null)
    const [friendPicture, setFriendPicture] = useState(null)
    const [messages, setMessages] = useState([])
    const [onlineUsers, setOnlineUser] = useState([])
    const [newMessage, setNewMessage] = useState('')
    const [typingStatus, setTypingStatus] = useState('');
    const [arrivalMessage, setArrivalMessage] = useState(null)
    const [loading, setLoading] = useState(false)
    const socket = useRef()
    const scrollRef = useRef()

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo: user } = userLogin

    useEffect(() => {
        setLoading(true)
        if (conversations.length === 0) getConversations()
    }, [user?._id])

    useEffect(() => {
        const getMessages = async () => {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${user.accessToken}`
                }
            }
            // console.log({currentChat})
            try {
                const res = await axios.get("/api/messages/" + currentChat?._id, config)
                setMessages(res.data)
                // console.log("mesages: ", res.data)
            } catch (err) {
                console.log(err)
            }
        }
        getMessages()
    }, [currentChat?._id])

    useEffect(() => {
        socket.current = io(ENDPOINT)
        socket.current.on("getMessage", data => {
            console.log("getMessage Socket called: ", {data})
            // dispatch(getUnreadNotificationsCoun())
            setArrivalMessage({
                sender: data.senderId,
                text: data.text,
                createdAt: Date.now()
            })
        })
    }, [])

    useEffect(() => {
        arrivalMessage && 
        (
            currentChat?.members.firstUser._id === arrivalMessage.sender
            ||
            currentChat?.members.secondUser._id === arrivalMessage.sender
            ) &&
        setMessages(prev => [...prev, arrivalMessage])
    }, [arrivalMessage, currentChat])

    useEffect(() => {
        socket.current.emit("userLogin", user._id)
        socket.current.on("getOnlineUsers", users => {
            console.log("getOnlineUsers Socket called")
            setOnlineUser(users)
        })
    }, [user])

    useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: "smooth" })
    }, [messages])

       
    useEffect(() => {
        socket.current.on('typingResponse', 
            (data) => setTypingStatus(data) 
        )

        socket.current.on('getNotification', 
            (data) => console.log("notif", {data}) 
        )
    }, [socket]);

    const getConversations = async () => {
        try {
          const config = {
              headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: `Bearer ${user.accessToken}`
              }
          }
            const res = await axios.get("/api/conversations", config)
            setConversations(res.data)
            setLoading(false)
            console.log(res.data)
        } catch (err) {
            console.log(err)
        }
      }

    const startChattingHandler = async (friendId) => {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.accessToken}`
            }
        }
      try {
          const res = await axios.get(`/conversations/${friendId}`, config)
        //   console.log("Convo with user ", res.data)
        //   if (res.data) {
        //       setCurrentChat(res.data)
        //       try {
        //         const res = await axios.get("/users/?userId=" + friendId)
        //         setFriendPicture(res.data.profilePicture)
        //     } catch (err) {
        //         console.log(err)
        //     }
        //   }
        //   else {
        //       const res = await axios.post(`/conversations`, {
        //           senderId: user?._id,
        //           receiverId: friendId
        //       })
        //     //   console.log("New con created")
        //       getConversations()
        //       setCurrentChat(res.data)
        //   }
      } catch (err) {
          console.log(err)
      }
  }

    const SetCurrentChatHandler = async (conversation) => {
            setCurrentChat(conversation)
            // Get friend details
            const friendPicture = conversation.members.firstUser._id === user._id
                                ? conversation.members.secondUser.avatar
                                : conversation.members.firstUser.avatar
            // console.log({friendPicture})
            setFriendPicture(friendPicture)
            // try {
            //     const res = await axios.get("/users/?userId=" + friendId)
            //     setFriendPicture(res.data.profilePicture)
            // } catch (err) {
            //     console.log(err)
            // }
        }

    const submitHandler = async (e) => {
        e.preventDefault()
        const message = {
            conversationId: currentChat._id,
            senderId: user._id,
            // text: newMessage.current.value,
            text: newMessage
        }
        // console.log({message})

        // Find receiver
        const receiverId = currentChat.members.firstUser._id === user._id
                        ? currentChat.members.secondUser._id
                        : currentChat.members.firstUser._id
        // console.log({receiverId})
        console.log("sendMessage socket called")
        socket.current.emit("sendMessage", {
            senderId: user._id,
            receiverId,
            text: newMessage
        })

        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${user.accessToken}`
                }
            }
            // console.log("Sending Message")
            const res = await axios.post("/api/messages", message, config)
            // console.log(res.data)
            setMessages([...messages, res.data]) 
            setNewMessage("")
            handleTyping(false)
        } catch (err) {
            console.log(err)
        }
    }

    const handleTyping = (sendText) => {
         // Find receiver
         const receiverId = currentChat.members.firstUser._id === user._id
            ? currentChat.members.secondUser._id
            : currentChat.members.firstUser._id
   
        // check typing status
        const text = sendText && newMessage !== "" ? `${user?.lastName} is typing...`: ""
        socket.current.emit('typing', {
            text,
            receiverId 
        })
    }

    // const filterConversations = (value) => {
    //     console.log({value})
    //     const lowercasedValue = value.toLowerCase().trim()
    //     if (lowercasedValue === '') getConversations()
    //     else {
    //       const filteredConversations = conversations.filter(item => 
    //        (item.members.secondUser.firstName).toLowerCase() === lowercasedValue
    //       )
    //       console.log({filteredConversations})
    //       setConversations(filteredConversations)
    //     }
    //   }

  return (
    <>
    { loading 
        // ? <Typography variant="h5" align="center">No conversation available</Typography> 
        ? <BackDropLoader /> 
        :
        // <div className="wrapper">  
        //     <div className="messenger">
        //         <div className="chatMenu">
        //             <div className="chatMenuWrapper">
        //                 {/* <input 
        //                     className="chatMenuInput"
        //                     placeholder="Search for friends"
        //                     onChange={(e) => filterConversations(e.target.value)}
        //                 /> */}
        //                 <Title heading="Conversations" />
        //                 {conversations.length > 0
        //                     ? 
        //                     <>
        //                         {conversations.map((c) => (
        //                             <div key={c._id} onClick={() => SetCurrentChatHandler(c) }>
        //                                 <Conversation
        //                                     conversation={c} 
        //                                     currentUserId={user?._id} 
        //                                     onlineUsers={onlineUsers}
        //                                     currentChat = {currentChat?._id === c._id ? true : false }
        //                                     // socket = {socket}
        //                                 />
        //                             </div>
        //                         ))}
        //                     </>
        //                     : <Typography variant="body1" align="center">No conversation available</Typography>
        //                 }
        //             </div>
        //         </div>
        //         <div className="chatBox">
        //             <div className="chatBoxWrapper">
        //                 { currentChat ? 
        //                     ( <>
        //                         <div className="chatBoxTop">
        //                             {messages?.map((m, index) => (
        //                                 <div key={index} ref={scrollRef}>
        //                                     <Message  
        //                                     message={m} 
        //                                     own={m.senderId === user._id} 
        //                                     profilePicture={
        //                                         m.senderId === user._id 
        //                                             ? user.avatar 
        //                                             : friendPicture
        //                                     }
        //                                     />
        //                                 </div>
        //                             ))}
        //                             {/* No Messages */}
        //                         </div>
        //                         <p className="chatBoxTypingText">{typingStatus}</p>
        //                         <form className="chatBoxBottom" onSubmit={submitHandler} >
        //                             {/* <textarea 
        //                                 className="chatMessageInput"
        //                                 placeholder='write something...'
        //                                 ref={newMessage}
        //                                 required
        //                             /> */}
        //                             <input 
        //                                 className="chatMessageInput"
        //                                 placeholder="write message here..."
        //                                 value={newMessage}
        //                                 onChange = {(e) => setNewMessage(e.target.value)}
        //                                 onKeyDown={handleTyping}
        //                                 onBlur={handleTyping}
        //                                 on
        //                                 required={true}   
        //                             />
        //                             <button 
        //                                 className="chatSubmitButton"
        //                                 type='submit' 
        //                             >
        //                                 Send
        //                             </button>
        //                         </form>
        //                     </> ) : 
        //                     (<span className="noCoversationText">Open a conversation to start a chat.</span>)
        //                 }     
        //             </div>
        //         </div>
        //     </div>     
        // </div>
        <div className="wrapper">  
        <div className="messenger">
            <div className="chatMenu">
                <Box sx={{
                    padding: '10px',
                    height: '100%',
                    borderRadius: '6px',
                    margin: '4px',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                    overflowY: 'scroll'
                    // backgroundColor: '#000'
                }}
                >
                    {/* <input 
                        className="chatMenuInput"
                        placeholder="Search for friends"
                        onChange={(e) => filterConversations(e.target.value)}
                    /> */}
                    <Title heading="Conversations" />
                    {conversations.length > 0
                        ? 
                        <>
                            {conversations.map((c) => (
                                <div key={c._id} onClick={() => SetCurrentChatHandler(c) }>
                                    <Conversation
                                        conversation={c} 
                                        currentUserId={user?._id} 
                                        onlineUsers={onlineUsers}
                                        currentChat = {currentChat?._id === c._id ? true : false }
                                        // socket = {socket}
                                    />
                                </div>
                            ))}
                        </>
                        : <Typography variant="body1" align="center">No conversation available</Typography>
                    }
                </Box>
            </div>
            <div className="chatBox">
                <div className="chatBoxWrapper">
                    { currentChat ? 
                        ( <>
                            <div className="chatBoxTop">
                                {messages?.map((m, index) => (
                                    <div key={index} ref={scrollRef}>
                                        <Message  
                                        message={m} 
                                        own={m.senderId === user._id} 
                                        profilePicture={
                                            m.senderId === user._id 
                                                ? user.avatar 
                                                : friendPicture
                                        }
                                        />
                                    </div>
                                ))}
                                {/* No Messages */}
                            </div>
                            <p className="chatBoxTypingText">{typingStatus}</p>
                            <form className="chatBoxBottom" onSubmit={submitHandler} >
                                {/* <textarea 
                                    className="chatMessageInput"
                                    placeholder='write something...'
                                    ref={newMessage}
                                    required
                                /> */}
                                <input 
                                    className="chatMessageInput"
                                    placeholder="write message here..."
                                    value={newMessage}
                                    onChange = {(e) => setNewMessage(e.target.value)}
                                    onKeyDown={handleTyping}
                                    onBlur={handleTyping}
                                    on
                                    required={true}   
                                />
                                <button 
                                    className="chatSubmitButton"
                                    type='submit' 
                                >
                                    Send
                                </button>
                            </form>
                        </> ) : 
                        (<span className="noCoversationText">Open a conversation to start a chat.</span>)
                    }     
                </div>
            </div>
        </div>     
    </div>
    }
    </>
  )
}

export default Messenger






